import { TypoTooltip, Typography } from '@applift/factor'
import {
  StatusApproved,
  StatusDeleted,
  StatusDraft,
  StatusInvited,
  StatusPaused,
  StatusPending,
  StatusRejected,
} from '@applift/icons'

export const StatusCell = (props: { status: string }) => {
  const { status = '' } = props
  const statusIconMapping = {
    Active: <StatusApproved color="success" fontSize={16} />,
    Deleted: <StatusDeleted color="error" fontSize={16} />,
    Draft: <StatusDraft color="primary" fontSize={16} />,
    Pending: <StatusPending color="warning" fontSize={16} />,
    Invited: <StatusInvited color="warning" fontSize={16} />,
    Suspended: <StatusPaused color="warning" fontSize={16} />,
    Rejected: <StatusRejected color="error" fontSize={16} />,
  }

  return (
    <TypoTooltip title={status}>
      <Typography sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
        {statusIconMapping[status as keyof typeof statusIconMapping]} {status}
      </Typography>
    </TypoTooltip>
  )
}
