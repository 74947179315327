import * as React from 'react'
import {
  Box,
  ListItemButton,
  SelectAdvanceComponents,
  Skeleton,
  TextField,
} from '@applift/factor'
import { RowSelectionState } from '@applift/datagrid'

import { SidebarInventoryGroup } from '../../../../models/Group'
import { DisplayInventoryGroupItem } from '../../../InventoryGroup/DisplayInventoryGroupItem'
import { GROUP_TYPE_IDS_TO_CATEGORIES } from '../../../../constants/common'
import { formatNumberWithSuffix } from '../../../../utils/format'

export interface GroupSelectProps {
  groupData?: {
    totalRecords?: number
    filteredRecords?: number
    inventoryGroupList?: (SidebarInventoryGroup | undefined)[]
  }
  groupSearch?: string
  selectedGroup?: SidebarInventoryGroup[]
  setSelectedGroup?: (val: any) => void
  isGroupListLoading: boolean
  setGroupSearch: (val: string) => void
  fetchNextGroupListPage: () => void
  groupType: string
}

export const GroupSelect = (props: GroupSelectProps) => {
  const apiRef =
    SelectAdvanceComponents.useSelectAdvanceApiRef<SidebarInventoryGroup>()

  const {
    groupData: GroupListResponse,
    setGroupSearch,
    groupSearch,
    isGroupListLoading,
    selectedGroup,
    setSelectedGroup,
    groupType,
    fetchNextGroupListPage,
  } = props

  const groupData = React.useMemo(
    // @ts-ignore
    () => GroupListResponse?.inventoryGroupList || [],
    // @ts-ignore
    [GroupListResponse?.inventoryGroupList]
  )

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const renderItem =
    SelectAdvanceComponents.DefaultListOptionItemWrapper<SidebarInventoryGroup>(
      {
        disableCheckbox: true,
        selectionStyle: 'none',
        arrowPosition: 'right',
      }
    )

  const handleTextfieldClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    Object.keys({}).reduce((prev, one) => {
      // eslint-disable-next-line no-param-reassign
      prev[one] = true
      return prev
    }, {} as RowSelectionState)
  )

  const overlay = React.useMemo(() => {
    if (!isGroupListLoading) {
      if (!Array.isArray(groupData)) {
        return 'error'
      }
      if ((groupData?.length ?? 0) <= 0) {
        if (groupSearch) {
          return 'noResult'
        }
        return 'noRows'
      }
    }
    return undefined
  }, [isGroupListLoading, groupData, groupSearch])

  const setGroupSelectPropsWrapper = (selectedRows: {
    [key: number]: boolean
  }) => {
    if (setSelectedGroup) {
      let action
      if (
        Object.keys(selectedRows).filter(val => rowSelection[val] === undefined)
          .length
      ) {
        action = 'add'
      } else {
        action = 'remove'
      }

      if (action === 'add') {
        const allSelectedItemsInArray = Object.keys(selectedRows)

        const selectedItemsInObject = selectedGroup
          ? Object.fromEntries(selectedGroup?.map(val => [val.id, val]))
          : {}

        const itemsToAdd = allSelectedItemsInArray.filter(
          val => !selectedItemsInObject[+val]
        )

        setSelectedGroup(() =>
          (groupData as SidebarInventoryGroup[]).filter((val: { id: any }) =>
            itemsToAdd.includes(String(val.id))
          )
        )
      } else if (action === 'remove') {
        const itemToRemove = selectedGroup?.filter(
          val => selectedRows[val.id]
        )[0]
        setSelectedGroup((val: any[]) =>
          val.filter(
            (innerVal: { id: number | undefined }) =>
              innerVal.id !== itemToRemove?.id
          )
        )
      }
    }
  }

  const rowSelectionWrapper = (updater: any) => {
    const selectedRows =
      typeof updater === 'function' ? updater(rowSelection) : updater

    setGroupSelectPropsWrapper(selectedRows)
    return setRowSelection(updater)
  }

  React.useEffect(() => {
    if (selectedGroup?.length) {
      const localRowSelection = Object.fromEntries(
        selectedGroup.map(val => {
          return [val.id, true]
        })
      )

      setRowSelection(localRowSelection)
    } else if (selectedGroup?.length === 0) {
      setRowSelection({})
    }
  }, [selectedGroup])

  const getSecondaryInfo = (groupData: SidebarInventoryGroup) => {
    const groupTypeLabel =
      GROUP_TYPE_IDS_TO_CATEGORIES[
        groupData.groupTypeId as keyof typeof GROUP_TYPE_IDS_TO_CATEGORIES
      ]
    return [
      {
        label: groupTypeLabel,
        value: formatNumberWithSuffix(groupData.count),
      },
      {
        label: 'Impressions',
        value: formatNumberWithSuffix(groupData.impressions),
      },
      {
        label: 'Reach',
        value: formatNumberWithSuffix(groupData.reach),
      },
    ]
  }

  return (
    <TextField
      size="medium"
      variant="outlinedDash"
      label={`${groupType[0]?.toUpperCase() + groupType.slice(1)} Group Name`}
      select="advanceSelect"
      placeholder="Select existing group"
      onClick={e => handleTextfieldClick(e)}
      style={{ width: 332 }}
      onChange={rowSelectionWrapper}
      value={rowSelection}
      SelectProps={{
        getRowId: row => String(row.id),
        slotProps: {
          InputBaseProps: {
            onChange: e => setGroupSearch(e.target.value),
            value: groupSearch,
            placeholder: 'Search by group name',
          },
          PaperProps: {
            style: {
              maxWidth: 332,
              paddingBottom: 8,
            },
          },
          PopperProps: {
            anchorEl,
            open,
            anchorOrigin: {
              horizontal: 'left',
              vertical: 'bottom',
            },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          },
        },
        data: (groupData as SidebarInventoryGroup[]) ?? [],
        loading: isGroupListLoading,
        apiRef,
        rowCount: (GroupListResponse?.filteredRecords ?? 0) - 1,
        rowHeight: 10,
        pageSize: 5,
        renderListItem: renderItem,
        hideSelectNav: true,
        renderListItemSkeleton: ({ virtualItem, measureElement, style }) => {
          return (
            <ListItemButton
              Component={'li'}
              data-index={virtualItem.index}
              ref={measureElement}
              tabIndex={-1}
              style={style}
              sx={{ width: 100, px: 12, py: 4 }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: 100,
                }}
              >
                <Box sx={{ display: 'flex', justifyContent: 'between' }}>
                  <Skeleton height={30} width={100} />
                  <Box sx={{ display: 'flex', gap: 4 }}>
                    <Skeleton height={30} width={30} />
                  </Box>
                </Box>

                <Box sx={{ mt: 4, display: 'flex', gap: 24 }}>
                  {new Array(3).fill(
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 8,
                        flexDirection: 'column',
                      }}
                    >
                      <Skeleton height={20} width={50} />
                      <Skeleton height={20} width={25} />
                    </Box>
                  )}
                </Box>
              </Box>
            </ListItemButton>
          )
        },
        renderValue: val => {
          // @ts-ignore
          return `${selectedGroup[0]?.name ? selectedGroup[0]?.name : val[0]?.name}`
        },
        onFetchRows: () => fetchNextGroupListPage(),
        getOptionLabel: data => String(data.id),
        overlay,
        renderOption: ({ row }) => {
          return (
            <DisplayInventoryGroupItem
              groupInfo={row.original}
              isAdvertiser
              secondaryInfo={getSecondaryInfo(row.original)}
              showAllowedBlockedCount
              displayGroupIcon
            />
          )
        },
      }}
    />
  )
}
