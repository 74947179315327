import * as React from 'react'
import { Container, Box, Row, Col, Typography, Button } from '@applift/factor'
import { Add, Target, Increase, ShieldLock } from '@applift/icons'
import { Marketplace } from '@applift/illustrations'

import { PmpDialog } from '../PmpDialog'
import { EmptyPageFeatures } from '../../../../components/EmptyPageFeature'

export const EmptyPage = () => {
  const [open, setOpen] = React.useState<boolean>(false)
  const features = [
    {
      icon: <Target fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />,
      title: 'Enhanced Targeting Precision',
      description:
        'Gain access to curated ad inventory, allowing for precise targeting based on audience demographics, interests, and behaviours.',
    },
    {
      icon: (
        <Increase fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Increased Efficiency',
      description:
        'Streamline the ad buying process and negotiate favourable terms directly with publishers, resulting in improved campaign performance and cost-effectiveness',
    },
    {
      icon: (
        <ShieldLock fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Exclusive Access',
      description:
        'Secure priority access to premium ad inventory before it becomes available in the open marketplace, enabling you to reach valuable audiences ahead of competitors',
    },
  ]

  return (
    <Container
      sx={{
        width: 100,
        height: 100,
        p: 40,
        bgColor: 'neutral-0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Row
        xs={{ gutterSize: 40 }}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mb: 48,
        }}
      >
        <Col xs={12} sm={6} md={5}>
          <Box sx={{ textColor: 'primary-500' }}>
            <Marketplace sx={{ width: 100, height: 'auto' }} />
          </Box>
        </Col>
        <Col xs={12} sm={6} md={7}>
          <Typography component="h1" variant="h5" weight="demi" sx={{ mb: 8 }}>
            Private Marketplace (PMP) Deals
          </Typography>
          <Typography
            component="h4"
            variant="bodyLarge"
            lineHeight="multi-line"
            weight="normal"
            sx={{ mb: 16 }}
          >
            {
              "Access exclusive ad inventory through Private Marketplace (PMP) deals, ensuring premium placements tailored to your brand's needs"
            }
          </Typography>
          <Button
            startIcon={<Add />}
            variant="contained"
            color="primary"
            disableRipple
            onClick={() => {
              setOpen(true)
            }}
          >
            Add New Deal
          </Button>
        </Col>
      </Row>
      <Row xs={{ gutterSize: 40 }} sx={{ justifyContent: 'center' }}>
        {features.map(feature => (
          <Col xs={features.length < 3 ? 5 : 4} key={feature.title}>
            <EmptyPageFeatures
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          </Col>
        ))}
      </Row>
      {open && (
        <PmpDialog
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </Container>
  )
}
