import { DEFAULT_EXCHANGE_SIDEBAR_STATE } from '../../../constants/exchange'

export const getExchangeSidebarFilterData = (
  data: typeof DEFAULT_EXCHANGE_SIDEBAR_STATE
): typeof DEFAULT_EXCHANGE_SIDEBAR_STATE => ({
  searchedInventories:
    data.searchedInventories ??
    DEFAULT_EXCHANGE_SIDEBAR_STATE.searchedInventories,
  creativeTypes:
    data.creativeTypes ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.creativeTypes,
  exchanges: data.exchanges ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.exchanges,
  country: data.country ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.country,
  deviceTypes: data.deviceTypes ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.deviceTypes,
  trafficTypes:
    data.trafficTypes ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.trafficTypes,
  inventoryTypes:
    data.inventoryTypes ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.inventoryTypes,
  creativeSizes:
    data.creativeSizes ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.creativeSizes,
  adCategories:
    data.adCategories ?? DEFAULT_EXCHANGE_SIDEBAR_STATE.adCategories,
})

export const getExchangeSidebarDataForURLPopulation = (
  data: typeof DEFAULT_EXCHANGE_SIDEBAR_STATE
): Partial<typeof DEFAULT_EXCHANGE_SIDEBAR_STATE> => ({
  ...(data.searchedInventories?.length
    ? { searchedInventories: data.searchedInventories }
    : {}),
  ...(data.creativeTypes?.length ? { creativeTypes: data.creativeTypes } : {}),
  ...(data.exchanges?.length ? { exchanges: data.exchanges } : {}),
  ...(data.country?.length ? { country: data.country } : {}),
  ...(data.deviceTypes?.length ? { deviceTypes: data.deviceTypes } : {}),
  ...(data.trafficTypes?.length ? { trafficTypes: data.trafficTypes } : {}),
  ...(data.inventoryTypes?.length
    ? { inventoryTypes: data.inventoryTypes }
    : {}),
  ...(data.creativeSizes?.length ? { creativeSizes: data.creativeSizes } : {}),
  ...(data.adCategories?.length ? { adCategories: data.adCategories } : {}),
})
