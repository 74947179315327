export const DEAL_STATUS = {
  ACTIVE: 1,
  INACTIVE: 2,
} as const

export const BUDGET_TYPE_ID = {
  DOLLAR_BASED: 1,
  IMPRESSIONS_BASED: 2,
}

export const DEAL_CURATION = {
  STANDARD: 4,
  TAILORED: 5,
  OPEN_EXCHANGE: 6,
}

export const PAYMENT_TYPE = {
  PUBLISHER: 1,
  PLATFORM: 2,
}
