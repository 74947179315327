import * as React from 'react'
import { useForm } from '@tanstack/react-form'
import { Box, LoadingButton, Typography, TypoTooltip } from '@applift/factor'
import {
  AdCategoriesFilter,
  AdCategoriesListApiRefType,
  CountryFilter,
  CreativeSizesFilter,
  CreativeSizesListApiRefType,
  DeviceTypesFilter,
  NormalizedAdCategoryData,
  NormalizedCreativeSizesData,
} from '@applift/app-utils'

import { OpenExchangeSidebarMatrix } from '../../../components/OpenExchangeSidebarMatrix/index'
import {
  SearchFilter,
  ExchangeFilter,
  CreativeTypesFilter,
  FileUploadFilter,
  SearchFilterApiRefType,
  TrafficTypesFilter,
  InventoryTypesFilter,
} from '../../../components/Filters/index'
import { OpenExchangeSidebarSearchValues } from '..'
import { getExchangeSidebarFilterData } from './index'
import { CommonFilter } from '../../../models/Filters'
import { DisplayUploadedFile } from '../../../components/Filters/FileUploadFilter/DisplayUploadedFile'
import {
  useExchangeCountData,
  useExchangeListingData,
  useExchangeListingDataFromCSV,
} from '../../../hooks'
import {
  ExchangeMatrixCountResponse,
  OpenExchangeSidebarUploadedCSVFileInfo,
} from '../../../models/OpenExchange'

interface OpenExchangeSidebarProps {
  groupEditMode?: boolean
  filters: OpenExchangeSidebarSearchValues
  setFilters?: (filters: OpenExchangeSidebarSearchValues) => void
  resetFilters?: () => void
  setUploadedFileForOpenExchangeSearch?: React.Dispatch<
    React.SetStateAction<OpenExchangeSidebarUploadedCSVFileInfo | null>
  >
  uploadedFileForSearch?: OpenExchangeSidebarUploadedCSVFileInfo | null
}

export const OpenExchangeSidebar = (props: OpenExchangeSidebarProps) => {
  const {
    groupEditMode,
    filters,
    setFilters,
    resetFilters,
    uploadedFileForSearch,
    setUploadedFileForOpenExchangeSearch,
  } = props

  const [isDirty, setIsDirty] = React.useState<boolean>(false)
  const [currentAction, setCurrentAction] = React.useState<
    'SEARCH' | 'RESET' | undefined
  >(undefined)

  const searchRef = React.useRef<SearchFilterApiRefType>(null)
  const adCategoriesFilterRef = React.useRef<AdCategoriesListApiRefType>()
  const creativeSizesFilterRef = React.useRef<CreativeSizesListApiRefType>()

  const { data: entireAvailableCountData, isFetching: isCountBeingFetched } =
    useExchangeCountData()

  const { isFetching: isListingDataBeingFetchedFromSidebarSearch } =
    useExchangeListingData(filters, {
      enabled: false,
    })

  const { isFetching: isListingDataBeingFetchedFromCSV } =
    useExchangeListingDataFromCSV(
      uploadedFileForSearch as OpenExchangeSidebarUploadedCSVFileInfo,
      { enabled: false }
    )

  const isTableDataBeingFetched =
    isListingDataBeingFetchedFromCSV ||
    isListingDataBeingFetchedFromSidebarSearch

  const stats = React.useMemo(() => {
    const statValues: {
      label: string
      value: number
    }[] = []
    Object.keys(entireAvailableCountData ?? {}).forEach(item =>
      statValues.push({
        label: item,
        value: (entireAvailableCountData as ExchangeMatrixCountResponse)[
          item as keyof ExchangeMatrixCountResponse
        ],
      })
    )
    return statValues
  }, [entireAvailableCountData])

  const form = useForm({
    defaultValues: getExchangeSidebarFilterData(filters),
    onSubmit: async ({ value: exchangeSidebarFilterValues }) => {
      setCurrentAction('SEARCH')
      setIsDirty(false)
      setFilters?.(exchangeSidebarFilterValues)
    },
  })

  React.useEffect(() => {
    if (!isTableDataBeingFetched) {
      setCurrentAction(undefined)
    }
  }, [isTableDataBeingFetched])

  // set data from route
  React.useEffect(() => {
    const initialSearchField = (filters.searchedInventories ?? '')
      .split(',')
      .map(value => ({ label: value, error: false }))
    searchRef.current?.setInitValue(initialSearchField)

    if (filters.adCategories?.length) {
      const existingAdCategoriesSelection: Record<
        string,
        NormalizedAdCategoryData
      > = {}
      filters.adCategories.forEach(item => {
        existingAdCategoriesSelection[`${item.value}`] = {
          ...item,
          subRows: [],
        }
      })
      adCategoriesFilterRef.current?.initializeRowSelection(
        existingAdCategoriesSelection
      )
    }

    if (filters.creativeSizes?.length) {
      const existingCreativeSizesSelection: Record<
        string,
        NormalizedCreativeSizesData
      > = {}
      filters.creativeSizes.forEach(item => {
        existingCreativeSizesSelection[`${item.value}`] = {
          value: String(item.value),
          label: item.label,
          subRows: [],
          applicableCreativeTypeIds: [] as number[],
        }
      })
      creativeSizesFilterRef.current?.initializeRowSelection(
        existingCreativeSizesSelection
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // No dependency as only want to run initially

  const resetSidebarFormData = () => {
    setCurrentAction('RESET')
    setUploadedFileForOpenExchangeSearch?.(null)
    setIsDirty(false)
    form.reset()
    searchRef.current?.reset()
    creativeSizesFilterRef.current?.reset()
    adCategoriesFilterRef.current?.reset()
    // reset filter values from sidebar selection in route
    resetFilters?.()
  }

  return uploadedFileForSearch ? (
    <Box
      sx={{
        py: 16,
        px: 12,
        display: 'flex',
        flexDirection: 'column',
        bgColor: 'neutral-0',
        gap: 16,
      }}
    >
      <Box sx={{ mb: 8 }}>
        {!groupEditMode && (
          <TypoTooltip
            TypgraphyProps={{
              sx: { textWeight: 'demi', mb: 16 },
              variant: 'bodyLarge',
            }}
          >
            Open Exchange
          </TypoTooltip>
        )}
        <OpenExchangeSidebarMatrix
          areStatsLoading={isCountBeingFetched}
          stats={stats}
        />
      </Box>
      <DisplayUploadedFile uploadedFileInfo={uploadedFileForSearch} />
      <LoadingButton
        variant="outlined"
        size="medium"
        color="error"
        loading={isTableDataBeingFetched && currentAction === 'RESET'}
        onClick={() => {
          setUploadedFileForOpenExchangeSearch?.(null)
          setIsDirty(false)
        }}
      >
        Reset
      </LoadingButton>
    </Box>
  ) : (
    <form
      style={{ height: '100%' }}
      onSubmit={e => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
      onReset={resetSidebarFormData}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: 100,
          bgColor: 'neutral-0',
          pt: groupEditMode ? 16 : undefined,
        }}
      >
        {!groupEditMode && (
          <Box
            sx={{
              py: 16,
              px: 12,
            }}
          >
            <TypoTooltip
              TypgraphyProps={{
                sx: { textWeight: 'demi' },
                variant: 'bodyLarge',
              }}
            >
              Open Exchange
            </TypoTooltip>
          </Box>
        )}
        <Box
          sx={{
            pb: 16,
            px: 12,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto',
          }}
        >
          <Box sx={{ mb: 8 }}>
            <OpenExchangeSidebarMatrix
              areStatsLoading={isCountBeingFetched}
              stats={stats}
            />
          </Box>
          <Box sx={{ mb: 16 }}>
            <form.Field name="searchedInventories">
              {field => (
                <SearchFilter
                  ref={searchRef}
                  placeholderMsg="Enter comma separated inventories"
                  onChange={val => {
                    setIsDirty(true)
                    const searchedValues = val
                      .filter(val => !val.error && Boolean(val.label))
                      .map(val => val.label)
                      .join(',')
                    field.handleChange(searchedValues)
                  }}
                />
              )}
            </form.Field>
          </Box>
          <Box sx={{ mb: 24 }}>
            <FileUploadFilter
              setUploadedFile={data => {
                resetSidebarFormData()
                setUploadedFileForOpenExchangeSearch?.({
                  fileName: data.file.name,
                  fileSize: data.file.size,
                  formData: data.formData,
                  uploadedFile: data.file,
                })
              }}
            />
          </Box>

          <Typography sx={{ textWeight: 'demi', mb: 12 }} variant="bodySmall">
            Where do you want the Ads to be published?
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              mb: 24,
            }}
          >
            <form.Field name="country">
              {field => (
                <CountryFilter
                  value={field.state.value}
                  textFieldProps={{ variant: 'outlinedDash' }}
                  onChange={value => {
                    setIsDirty(true)
                    field.handleChange(value)
                  }}
                />
              )}
            </form.Field>

            <form.Field name="deviceTypes">
              {field => (
                <DeviceTypesFilter
                  value={field.state.value}
                  textFieldProps={{ variant: 'outlinedDash' }}
                  onChange={value => {
                    setIsDirty(true)
                    field.handleChange(value)
                  }}
                />
              )}
            </form.Field>
          </Box>

          <Typography sx={{ textWeight: 'demi', mb: 12 }} variant="bodySmall">
            Channel
          </Typography>
          <Box sx={{ mb: 24 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 12,
              }}
            >
              <form.Field name="trafficTypes">
                {field => {
                  return (
                    <TrafficTypesFilter
                      onChange={value => {
                        setIsDirty(true)
                        field.handleChange(value)
                      }}
                      value={field.state.value}
                    />
                  )
                }}
              </form.Field>
              <form.Field name="inventoryTypes">
                {field => {
                  return (
                    <InventoryTypesFilter
                      onChange={value => {
                        setIsDirty(true)
                        field.handleChange(value)
                      }}
                      value={field.state.value}
                    />
                  )
                }}
              </form.Field>
            </Box>
          </Box>
          <Typography sx={{ textWeight: 'demi', mb: 12 }} variant="bodySmall">
            Specify your ad type
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              mb: 24,
            }}
          >
            <form.Field name="creativeTypes">
              {field => (
                <CreativeTypesFilter
                  setValue={value => {
                    setIsDirty(true)
                    field.handleChange(value)
                    creativeSizesFilterRef.current?.reset()
                    form.setFieldValue('creativeSizes', [])
                  }}
                  creative={field.state.value}
                />
              )}
            </form.Field>
            <form.Field name="creativeSizes">
              {field => (
                <CreativeSizesFilter
                  ref={creativeSizesFilterRef}
                  textFieldProps={{ variant: 'outlinedDash' }}
                  applicableCreativeTypeIds={form
                    .getFieldValue('creativeTypes')
                    .map(item => item.value)}
                  onChange={() => {
                    const selectedCreativeSizesMap =
                      creativeSizesFilterRef.current?.getRowSelectionData()
                    const selectedValues = [
                      ...(selectedCreativeSizesMap?.values() ?? []),
                    ].map(selectedItemValue => ({
                      label: selectedItemValue.label as string,
                      value: selectedItemValue.value,
                    }))
                    setIsDirty(true)
                    field.handleChange(selectedValues)
                  }}
                />
              )}
            </form.Field>
          </Box>

          <Typography sx={{ textWeight: 'demi', mb: 12 }} variant="bodySmall">
            Narrow your search by
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 16,
              mb: 24,
            }}
          >
            <form.Field name="adCategories">
              {field => (
                <AdCategoriesFilter
                  ref={adCategoriesFilterRef}
                  textFieldProps={{ variant: 'outlinedDash' }}
                  onChange={() => {
                    const selectedAdCategoriesMap =
                      adCategoriesFilterRef.current?.getRowSelectionData()

                    const selectedValues = [
                      ...(selectedAdCategoriesMap?.values() ?? []),
                    ].map(selectedItemValue => ({
                      label: selectedItemValue.label as string,
                      value: selectedItemValue.value as number,
                    }))
                    setIsDirty(true)
                    field.handleChange(selectedValues)
                  }}
                />
              )}
            </form.Field>

            <form.Field name="exchanges">
              {field => (
                <ExchangeFilter
                  TextFieldProps={{
                    sx: { width: 100 },
                    variant: 'outlinedDash',
                  }}
                  value={field.state.value}
                  onChange={value => {
                    setIsDirty(true)
                    field.handleChange(value)
                  }}
                />
              )}
            </form.Field>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 8,
            py: 16,
            px: 16,
            borderTop: 1,
            flexGrow: 0,
            position: 'sticky',
            bottom: 0,
          }}
        >
          {/* @ts-ignore */}
          <form.Subscribe selector={state => [state.values]}>
            {/* @ts-ignore */}
            {([formValues]) => {
              const hasValues = Object.values(formValues ?? {}).every(
                value => !(value as CommonFilter[]).length
              )
              const disableResetBtn = hasValues || isTableDataBeingFetched
              return (
                <>
                  <LoadingButton
                    type="reset"
                    variant="outlined"
                    color="error"
                    loading={
                      isTableDataBeingFetched && currentAction === 'RESET'
                    }
                    disabled={disableResetBtn}
                  >
                    Reset
                  </LoadingButton>
                  <LoadingButton
                    type="submit"
                    variant="outlined"
                    disabled={!isDirty}
                    loading={
                      isTableDataBeingFetched && currentAction === 'SEARCH'
                    }
                  >
                    Search
                  </LoadingButton>
                </>
              )
            }}
          </form.Subscribe>
        </Box>
      </Box>
    </form>
  )
}
