import { Box, TypographyProps, TruncateMiddle, Avatar } from '@applift/factor'

interface CustomerNameCellProp {
  avtarPath: string
  customerName: string
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CustomerNameCell = ({
  customerName,
  avtarPath,
  textTypographyProps,
}: CustomerNameCellProp) => {
  return (
    <Box
      sx={{
        width: 100,
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        gap: 8,
      }}
    >
      <Avatar size={24} src={avtarPath} />
      <TruncateMiddle
        arrow
        placement="top"
        TypgraphyProps={{
          ...textTypographyProps,
          gutterBottom: false,
          sx: {
            overflow: 'hidden',
            ...textTypographyProps?.sx,
          },
        }}
      >
        {customerName ?? ''}
      </TruncateMiddle>
    </Box>
  )
}
