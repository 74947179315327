import { GridActionCellParams } from '@applift/datagrid'
import { Link, Tooltip, Typography, TypographyProps } from '@applift/factor'

import { ACTION } from '../PmpGrid/action'
import { PmpListResponse } from '../../../../models/Pmp'
import { DEAL_CURATION } from '../../../../constants/filters'

interface CustomerCellProps {
  rowData: PmpListResponse
  textTypographyProps?: Omit<TypographyProps, 'children'>
  action?: (args: GridActionCellParams) => any
}

export const CustomerCell = (props: CustomerCellProps) => {
  const { rowData, action } = props

  const isAllCustomerSelected =
    rowData.dealCurationTypeId !== DEAL_CURATION.TAILORED

  const customers = rowData?.assignedToCustomers
  const noOfCustomers = customers?.length ? customers?.length : 0

  const customerLink = () => {
    if (noOfCustomers === 0) {
      return (
        <Typography variant="p" gutterBottom={false}>
          0 Customers
        </Typography>
      )
    }
    return (
      <Tooltip arrow placement="top" title="Click to view customers">
        <Link
          Component="button"
          color="primary"
          onClick={() => {
            action?.({
              actionName: ACTION.OPEN_CUSTOMER_DIALOG,
              metaData: { data: rowData },
            })
          }}
        >
          {`${noOfCustomers} Customers`}
        </Link>
      </Tooltip>
    )
  }

  return isAllCustomerSelected ? (
    <Typography variant="p" gutterBottom={false}>
      All Customers
    </Typography>
  ) : (
    customerLink()
  )
}
