export const CAMPAIGN_TYPE_BY_NAME = {
  ADVANCED: 1,
  PG: 2,
}

export const CAMPAIGN_TYPE_BY_ID = {
  1: 'Advanced',
  2: 'PG',
}

export const CAMPAIGN_STATUSES = {
  DRAFT: 'draft',
  PENDING: 'pending',
  RUNNING: 'running',
  EXPIRED: 'expired',
  DELETED: 'deleted',
  PAUSED: 'paused',
  REJECTED: 'rejected',
}
