import {
  Button,
  Col,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Row,
  Typography,
} from '@applift/factor'

import { PgListResponse } from '../../../../models/Pg'
import { CampaignGridWrapper } from './CampaignGridWrapper'
import { BasicInfoList } from '../../../../components/BasicInfoList'
import { useExchangeList, usePaymentTypes } from '../../../../hooks'

interface PgCampaignDialogProps {
  onClose: () => void
  data?: PgListResponse
}

export const PgCampaignDialog = ({ onClose, data }: PgCampaignDialogProps) => {
  const { data: exchangeData } = useExchangeList()
  const { data: paymentData } = usePaymentTypes()

  const exchange = data?.exchangeId
    ? exchangeData?.exchangeIdMapping[data?.exchangeId]
    : ''

  const paymentType = data?.paymentTypeId
    ? paymentData?.paymentIdMapping?.[data?.paymentTypeId]
    : ''

  const infoList = [
    { key: 'Deal ID', value: data?.dealId as string },
    { key: 'Name', value: data?.dealId ? data?.dealName : '' },
    { key: 'Exchange', value: exchange as string },
    { key: 'Payment To', value: paymentType as string },
  ]

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      sx={{ height: 100 }}
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle onClose={onClose}>
        Assigned Campaigns{' '}
        <Typography component="span" variant="bodyLarge">
          {`(${data?.activeCampaignIds?.length})`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Container
          sx={{ height: 100, display: 'flex', flexDirection: 'column', p: 0 }}
        >
          <Row>
            <Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'between',
              }}
            >
              <BasicInfoList data={infoList} infoValueMaxWidth={200} />
            </Col>
          </Row>
          <Row sx={{ flexGrow: 1, mt: 24 }}>
            <Col xs={12}>
              <CampaignGridWrapper
                campaignIds={
                  data?.activeCampaignIds?.length ? data.activeCampaignIds : []
                }
              />
            </Col>
          </Row>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
