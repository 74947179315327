import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { WithResponse } from '@applift/platform'
import { downloadBlob, downloadDataAsXLSXFile } from '@applift/app-utils'

import {
  getContextualGroupDetailKey,
  getGroupListKey,
  getGroupPmpListKey,
  getOpenExchangeGroupCountDataKey,
  getOpenExchangeGroupDistributionDataKey,
  getOpenExchangeGroupListingDataKey,
} from './QueryKeys'
import {
  ContextualGroupDetail,
  GroupChangeNameRequest,
  GroupChangeNameResponse,
  GroupListTableResponse,
  GroupRemovalRequest,
  GroupsStatistics,
  SidebarInventoryGroup,
} from '../models/Group'
import { PmpTableResponse } from '../models/Pmp'
import { DEFAULT_NUMBER_OF_LIST_RECORDS } from '../constants/exchange'
import {
  DistributionResponse,
  ExchangeMatrixCountResponse,
  Exchange,
} from '../models/OpenExchange'
import { getInstance } from './instance'
import { ListResponse } from '../models/Response'

export const getGroupList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getGroupListKey)['keys']>>) => {
  const {
    searchField,
    noOfEntries,
    ids,
    groupFilterId,
    groupTypeIds,
    includeStatistics,
    provideAccountLevelExcludedGroup,
  } = queryKey[0] || {}
  try {
    const params = {
      ...(ids ? { ids } : {}),
      ...(groupFilterId ? { groupFilterId } : {}),
      ...(groupTypeIds ? { groupTypeIds } : {}),
      ...(searchField ? { searchField: searchField } : {}),
      pageNo: String(pageParam),
      ...(typeof noOfEntries === 'number'
        ? { noOfEntries: String(noOfEntries) }
        : {}),
      ...(typeof includeStatistics === 'boolean'
        ? { includeStatistics: String(includeStatistics) }
        : {}),
      ...(typeof provideAccountLevelExcludedGroup === 'boolean'
        ? {
            provideAccountLevelExcludedGroup: String(
              provideAccountLevelExcludedGroup
            ),
          }
        : {}),
      sortBy: '-id',
    }
    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<WithResponse<GroupListTableResponse>> =
      await getInstance().get(`/v3/inv/groups/list?${paramString}`)

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getGroupStatistics = async () => {
  try {
    const response: AxiosResponse<WithResponse<GroupsStatistics>> =
      await getInstance().get('/v3/inv/groups/statistics')
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

interface GroupCountRepose {
  inventoryGroupsCount: number
}

export const getGroupCount = async () => {
  try {
    const response: AxiosResponse<WithResponse<GroupCountRepose>> =
      await getInstance().get('/v3/inv/groups/count')
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getContextualGroupDetail = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getContextualGroupDetailKey)['keys']>
>) => {
  const { groupId } = queryKey[0]!
  try {
    const response: AxiosResponse<
      WithResponse<ListResponse<ContextualGroupDetail[]>>
    > = await getInstance().get(
      `/v3/inv/group/${groupId}/contextual-inventories?pageNo=-1`
    )

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const removeInventoriesFromGroup = async (
  payload: GroupRemovalRequest
) => {
  const { groupId, groupTypeId, inventoryIds, dealIds } = payload
  const params = {
    groupId: Number(groupId),
    groupTypeId: Number(groupTypeId),
    ...(inventoryIds ? { inventoryIds } : {}),
    ...(dealIds ? { dealIds } : {}),
  }
  try {
    const response: AxiosResponse<WithResponse<SidebarInventoryGroup>> =
      await getInstance().post(`v3/inv/group/removeMappings`, params)
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getGroupPmpList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getGroupPmpListKey)['keys']>>) => {
  const { groupId, searchField, noOfEntries, sortBy } = queryKey[0]!
  const sorting = sortBy?.length
    ? `${sortBy?.[0]?.desc ? '-' : '+'}${sortBy?.[0]?.id}`
    : ''
  try {
    const params = {
      ...(searchField ? { searchField } : {}),
      sortBy: sorting,
      pageNo: String(pageParam),
      ...(typeof noOfEntries === 'number'
        ? { noOfEntries: String(noOfEntries) }
        : {}),
    }

    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<WithResponse<PmpTableResponse>> =
      await getInstance().get(
        `/v3/inv/group/${groupId}/pmp-deals?${paramString}`
      )

    return response.data.data as PmpTableResponse
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getOpenExchangeGroupCountData = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getOpenExchangeGroupCountDataKey)['keys']>
>) => {
  const { searchField, groupId } = queryKey[0]!
  try {
    const params = {
      ...(searchField ? { searchField } : {}),
    }
    const paramString = new URLSearchParams(params).toString()
    const { CancelToken } = axios
    const source = CancelToken.source()

    signal?.addEventListener('abort', () => {
      source.cancel(
        `v3/inv/group/${groupId}/open-exchange-inventories/count?${paramString} - Request cancelled`
      )
    })

    const response: AxiosResponse<WithResponse<ExchangeMatrixCountResponse>> =
      await getInstance().get(
        `/v3/inv/group/${groupId}/open-exchange-inventories/count?${paramString}`
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getOpenExchangeGroupDistributionData = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getOpenExchangeGroupDistributionDataKey)['keys']>
>) => {
  const { groupId, searchField } = queryKey[0]!
  try {
    const params = {
      ...(searchField ? { searchField } : {}),
    }

    const { CancelToken } = axios
    const source = CancelToken.source()

    signal?.addEventListener('abort', () => {
      source.cancel(
        `/v3/inv/group/${groupId}/open-exchange-inventories/distributions?${paramString} - Request cancelled`
      )
    })

    const paramString = new URLSearchParams(params).toString()
    const response: AxiosResponse<WithResponse<DistributionResponse>> =
      await getInstance().get(
        `/v3/inv/group/${groupId}/open-exchange-inventories/distributions?${paramString}`,
        {
          signal,
        }
      )

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getOpenExchangeGroupListingData = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getOpenExchangeGroupListingDataKey)['keys']>
>) => {
  const { searchField, noOfEntries, groupId } = queryKey[0]!
  try {
    const params = {
      ...(searchField ? { searchField } : {}),
      noOfEntries: String(noOfEntries ?? DEFAULT_NUMBER_OF_LIST_RECORDS),
      pageNo: String(pageParam),
    }

    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<
      WithResponse<{
        openExchangeInventoryData: Exchange[]
        totalRecords: number
        filteredRecords: number
        uniqueRecords: number
      }>
    > = await getInstance().get(
      `/v3/inv/group/${groupId}/open-exchange-inventories?${paramString}`
    )

    return {
      totalRecords: response.data.data?.totalRecords,
      filteredRecords: response.data.data?.filteredRecords,
      uniqueRecords: response.data.data?.uniqueRecords,
      openExchangeList: response.data.data?.openExchangeInventoryData.map(
        item => ({
          ...item,
          uniqueId: crypto.randomUUID() as string,
        })
      ),
    }
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const downloadOpenExchangeGroupInventories = async (payload: {
  groupId: string | number
  fileType: 'csv' | 'xlsx'
}) => {
  try {
    const { fileType, groupId } = payload
    const paramString = new URLSearchParams({
      fileType,
    }).toString()

    const response: AxiosResponse<{ data: string }> = await getInstance().post(
      `v3/inv/group/${groupId}/open-exchange/download?${paramString}`
    )

    window.open(response.data.data)
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const downloadContextualGroupInventories = async (payload: {
  groupId: string | number
  organizationName: string
  groupName: string
  fileType: 'csv' | 'xlsx'
}) => {
  try {
    const { groupId, organizationName, groupName, fileType } = payload

    const body = await getInstance().get(
      `v3/inv/group/${groupId}/contextual/inventories/csv`
    )
    if (fileType === 'csv') {
      downloadBlob(
        new Blob([body.data], { type: 'text/csv' }),
        `${groupName}-${organizationName}.csv`
      )
    } else {
      downloadDataAsXLSXFile(body.data, `${groupName}-${organizationName}.xlsx`)
    }
    return body
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const downloadPMPGroupInventories = async (payload: {
  groupId: string | number
  organizationName: string
  groupName: string
  fileType: 'csv' | 'xlsx'
}) => {
  try {
    const { groupId, organizationName, groupName, fileType } = payload

    const body = await getInstance().get(
      `v3/inv/group/${groupId}/pmp-deals/csv`
    )

    if (fileType === 'csv') {
      downloadBlob(
        new Blob([body.data], { type: 'text/csv' }),
        `${groupName}-${organizationName}.csv`
      )
    } else {
      downloadDataAsXLSXFile(body.data, `${groupName}-${organizationName}.xlsx`)
    }
    return body
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const changeGroupName = async (payload: GroupChangeNameRequest) => {
  try {
    const response: AxiosResponse<WithResponse<GroupChangeNameResponse>> =
      await getInstance().patch(`v3/inv/groups/${payload.groupId}`, {
        groupName: payload.newName,
      })
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export type ExcludeInventoryGroupFromCampaignArgs = {
  groupId: number
  excludedCampaignIds: string
  includedCampaignIds: string
}

export const excludeIncludeInventoryGroupFromCampaignWithLatency = async ({
  groupId,
  excludedCampaignIds,
  includedCampaignIds,
}: ExcludeInventoryGroupFromCampaignArgs): Promise<
  AxiosResponse<WithResponse<{ message: string }>>
> => {
  try {
    const response: AxiosResponse<
      WithResponse<{
        message: string
      }>
    > = await getInstance().post(
      `v3/cmp/inventorygroup/${groupId}/includeExclude`,
      {
        excludedCampaignIds,
        includedCampaignIds,
      }
    )
    return response
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
