import * as React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  TextField,
  IconButton,
  Button,
  Chip,
  Box,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { Search, Close } from '@applift/icons'
import { RowSelectionState } from '@applift/datagrid'

import { ConfirmationDialog } from '../../../../components/Dialogs'
import { useRemoveInventoriesMutation } from '../../../../hooks'
import { GROUP_TYPE_IDS } from '../../../../constants/common'
import {
  DownloadTableAction,
  FileTypes,
} from '../../../../components/DownloadTableAction'
import {
  GroupListTableResponse,
  GroupRemovalRequest,
  SidebarInventoryGroup,
} from '../../../../models/Group'
import { ContextualTableData } from '../../../../models/Contextual'

interface ContextualGroupActionPanelProps {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  tableData: ContextualTableData[]
  overlay?: 'error' | 'noResult' | 'noRows'
  groupDetail: SidebarInventoryGroup
  handleDownload: (fileTypes: FileTypes) => void
  isDownloadInProgress: boolean
}

export const ContextualGroupActionPanel = (
  props: ContextualGroupActionPanelProps
) => {
  const {
    search,
    overlay,
    setSearch,
    rowSelection,
    setRowSelection,
    tableData,
    groupDetail,
    handleDownload,
    isDownloadInProgress,
  } = props

  const [showDeleteDialog, toggleDeleteDialogVisibility] = React.useState(false)

  const queryClient = useQueryClient()

  const onSettled = () => {
    setRowSelection({})
    toggleDeleteDialogVisibility(false)
  }

  const onSuccess = (_: any, payload: GroupRemovalRequest) => {
    enqueueSnackbar({
      message: `Selected inventories removed from the group successfully.`,
      variant: 'success',
    })

    queryClient.setQueriesData(
      {
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getGroupList',
      },
      (oldData: { pages: GroupListTableResponse[] } | undefined) => {
        if (!oldData) return oldData

        const updatedPages = oldData.pages.map(page => {
          const newData = page.inventoryGroupList.map(item => {
            if (item.id === payload?.groupId) {
              return {
                ...item,
                count: item.count - (payload.inventoryIds?.length ?? 0),
              }
            }
            return item
          })
          return {
            ...page,
            inventoryGroupList: newData,
          }
        })

        return {
          ...oldData,
          pages: updatedPages,
        }
      }
    )

    queryClient.resetQueries({
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getContextualGroupDetail',
    })
  }

  const removeMutation = useRemoveInventoriesMutation(onSettled, onSuccess)

  const selectedItemIds = Object.keys(rowSelection).map(id => Number(id))
  const selectedItems = tableData.filter(item =>
    selectedItemIds.includes(item.id)
  )
  const selectedItemsCount = selectedItems.length

  const chipLabel = (
    <Typography
      sx={{ textWeight: 'demi' }}
    >{`${selectedItemsCount} out of ${tableData.length} selected`}</Typography>
  )

  const onRemoveClick = () => {
    removeMutation.mutate({
      groupId: groupDetail.id,
      groupTypeId: GROUP_TYPE_IDS.CONTEXTUAL,
      inventoryIds: selectedItems.map(item => item.id),
    })
    setSearch('')
  }

  const deleteDialogText =
    'Are you sure you want to remove the selected inventories from the group?'

  return (
    <>
      <Box
        sx={{ px: 24, mt: 16, display: 'flex' }}
        // to prevent jump when selection
        style={{ height: '28px' }}
      >
        {overlay !== 'noRows' && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedItemsCount > 0 && (
                <Chip
                  size="small"
                  label={chipLabel}
                  color="secondary"
                  onDelete={() => setRowSelection({})}
                />
              )}
              {selectedItemsCount > 0 && (
                <Button
                  onClick={() => toggleDeleteDialogVisibility(true)}
                  color="error"
                  size="small"
                  variant="outlined"
                  sx={{ marginLeft: 8 }}
                >
                  Remove From Group
                </Button>
              )}
            </Box>

            <Box
              sx={{
                ml: 'auto',
                display: 'flex',
                gap: 2,
              }}
            >
              <TextField
                type="text"
                value={search}
                onChange={e => setSearch(e.target.value)}
                placeholder="Search by Name or Type"
                variant="outlinedDash"
                sx={{ ml: 8 }}
                InputProps={{
                  startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
                  slotProps: { root: { style: { width: '220px' } } },
                  endAdornment:
                    search.trim().length > 0 ? (
                      <IconButton
                        onClick={() => {
                          setRowSelection({})
                          setSearch('')
                        }}
                        size="small"
                      >
                        <Close fontSize={16} />
                      </IconButton>
                    ) : null,
                }}
              />

              <DownloadTableAction
                downloadfile={handleDownload}
                isDownloading={isDownloadInProgress}
              />
            </Box>
          </>
        )}
      </Box>
      {showDeleteDialog && (
        <ConfirmationDialog
          isActionLoading={removeMutation.isLoading}
          onClose={() => toggleDeleteDialogVisibility(false)}
          onConfirmation={onRemoveClick}
          primaryBtnText="Remove"
          theme="error"
          title="Remove Selected Inventories"
          dialogContent={deleteDialogText}
        />
      )}
    </>
  )
}
