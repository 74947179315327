import { Box, Button, Typography, sx } from '@applift/factor'
import {
  CreativeVideo,
  CreativeAudio,
  CreativeNative,
  CreativeImage,
} from '@applift/icons'

import { Option } from '../../../api/filters'

import { useCreativeType } from '../../../hooks'
import { CreativeFilterType } from '../../../models/Filters'

interface Props {
  className?: string
  creative: CreativeFilterType[]
  setValue: (creative: CreativeFilterType[]) => void
}

const CREATIVES_ID_MAP: { [key: number]: any } = {
  1: CreativeImage,
  2: CreativeAudio,
  3: CreativeVideo,
  4: CreativeNative,
}

export const CreativeTypesFilter = ({ creative, setValue }: Props) => {
  const { data, isLoading, isFetching } = useCreativeType()
  const creativeTypes = isLoading || isFetching ? [] : data ?? []

  return (
    <Box
      sx={{
        display: 'flex',
        width: 100,
        justifyContent: 'around',
        gap: 4,
        flexWrap: 'wrap',
      }}
    >
      {creativeTypes?.map((crtType: Option<number>) => {
        const Icon = CREATIVES_ID_MAP[crtType.value]
        const selectedIds = creative?.map(i => i.value)

        return (
          <Button
            key={crtType.value}
            sx={{ display: 'flex', flexDirection: 'column' }}
            classes={{
              selected: sx({ border: 1, borderColor: 'primary-600' }),
            }}
            iconOrientation="vertical"
            variant="text"
            selected={selectedIds?.includes(crtType.value)}
            onClick={() => {
              if (creative?.map(i => i.value)?.includes(crtType.value)) {
                setValue(
                  creative?.filter(creative => creative.value !== crtType.value)
                )
                return
              }
              setValue([
                ...creative,
                { value: crtType.value, label: crtType.label },
              ])
            }}
          >
            <Icon sx={{ mb: 4 }} />
            <Typography
              sx={{
                textColor: 'neutral-1000',
                textWeight: selectedIds?.includes(crtType.value)
                  ? 'demi'
                  : 'normal',
              }}
              variant="label"
            >
              {crtType.label}
            </Typography>
          </Button>
        )
      })}
    </Box>
  )
}
