import * as React from 'react'
import { Layout } from '@applift/factor'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RowSelectionState, SortingState } from '@applift/datagrid'

import { SaveToExistingGroupRequest } from '../../../../../models/OpenExchange'
import { SidebarInventoryGroup } from '../../../../../models/Group'

import { PmpSearch } from '../../../../../models/Pmp'
import {
  PmpGridWrapper,
  PmpGridWrapperApiRefType,
} from '../../../../../routes/pmp/-component/PmpGrid/PmpGridWrapper'
import { PmpSidebar } from '../../../../../routes/pmp/-pages/PmpSidebar'
import { DEFAULT_PMP_LIST_SORTING } from '../../../../../constants/pmp'
import { GROUP_TYPE_IDS } from '../../../../../constants/common'

interface PmpGroupEditPageWrapperProps {
  setSaveToExistingGroupPayload: React.Dispatch<
    React.SetStateAction<SaveToExistingGroupRequest | undefined>
  >
  groupDetail: SidebarInventoryGroup
  setError: React.Dispatch<React.SetStateAction<string>>
  pmpGridWrapperRef: React.MutableRefObject<
    PmpGridWrapperApiRefType | undefined
  >
}

export const PmpGroupEditPageWrapper = (
  props: PmpGroupEditPageWrapperProps
) => {
  const {
    setSaveToExistingGroupPayload,
    groupDetail,
    pmpGridWrapperRef,
    setError,
  } = props

  const filters = useSearch({
    from: '/groups/$groupId/edit',
  }) as PmpSearch

  const navigate = useNavigate({
    from: '/groups/$groupId/edit',
  })

  const onRowSelectionChange = React.useCallback(
    (rowSelection: RowSelectionState) => {
      const isInactiveDealSelected =
        pmpGridWrapperRef?.current?.isInactiveDealSelected()
      setError(
        isInactiveDealSelected
          ? 'Only active PMP deals can be saved to group'
          : ''
      )

      const payload: SaveToExistingGroupRequest = {
        groupIds: [groupDetail.id],
        groupTypeId: GROUP_TYPE_IDS.PMP,
        dealIds: Object.keys(rowSelection).map(id => parseInt(id, 10)),
      }
      setSaveToExistingGroupPayload(payload)
    },
    [setSaveToExistingGroupPayload, groupDetail, pmpGridWrapperRef, setError]
  )

  const searchParams = useSearch({ from: '/groups/$groupId/edit' })

  const setFilters = (value: PmpSearch) => {
    navigate({
      to: '/groups/$groupId/edit',
      search: {
        ...searchParams,
        ...(typeof value.keywords === 'string'
          ? { keywords: value.keywords }
          : {}),
        ...(value.creativeTypeList
          ? { creativeTypeList: value.creativeTypeList }
          : {}),
        ...(value.exchangeList ? { exchangeList: value.exchangeList } : {}),
        ...(value.dealStatus ? { dealStatus: value.dealStatus } : {}),
      },
    })
  }

  const resetFilters = () => {
    navigate({
      to: '/groups/$groupId/edit',
      search: {
        sortBy: DEFAULT_PMP_LIST_SORTING,
      },
    })
  }

  const onSortingChangeWrapper = (
    value: React.SetStateAction<SortingState>
  ) => {
    const currentSort = filters.sortBy
    // @ts-ignore
    const sortValue = value(currentSort)
    navigate({
      to: '/groups/$groupId/edit',
      search: { ...filters, sortBy: sortValue },
    })
  }

  return (
    <Layout
      sidebar={
        <PmpSidebar
          groupEditMode
          filters={filters}
          setFilters={setFilters}
          resetFilters={resetFilters}
        />
      }
      sidebarWidth={355}
      maxWidth={355}
      minWidth={355}
      hideResizerHandle
    >
      <>
        <PmpGridWrapper
          ref={pmpGridWrapperRef}
          filters={filters}
          hideTableCTAs
          onRowSelectionChange={onRowSelectionChange}
          onSortingChange={onSortingChangeWrapper}
        />
      </>
    </Layout>
  )
}
