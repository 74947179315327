import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  LoadingButton,
} from '@applift/factor'

interface DiscardChangesDialogProps {
  onConfirmation: () => void
  onClose: () => void
}

export const DiscardChangesDialog = (props: DiscardChangesDialogProps) => {
  const { onClose, onConfirmation } = props
  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>
        <Typography weight="demi"> Discard Unsaved Changes </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>
          You have unsaved changes, are you sure you want to cancel?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color="error"
          variant="contained"
          onClick={onConfirmation}
        >
          Discard
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
