import React from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Col,
  Row,
  useDebounceValue,
  LoadingButton,
} from '@applift/factor'

import { GroupSelect } from './GroupsSelect/GroupSelect'
import { useGrouplist } from '../../../hooks/useGroup'
import { SidebarInventoryGroup } from '../../../models/Group'
import { getIcon } from '../../../registry'
import { CREATED_BY_SELF_GROUP_FILTER_ID } from '../../../constants/group'

interface SaveToExisitingGroupDialogProps {
  onClose: () => void
  onConfirmation: (Groupid: number | undefined) => void
  groupType: 'PMP' | 'open exchange' | 'contextual'
  groupId: number
  isLoading: boolean
}

const iconMapper = {
  PMP: getIcon('InventoryPmpDeals', { fontSize: 32 }),
  'open exchange': getIcon('InventoryOpenExchange', { fontSize: 32 }),
  contextual: getIcon('InventoryContextual', { fontSize: 32 }),
}

export const SaveToExisitingGroupDialog = (
  props: SaveToExisitingGroupDialogProps
) => {
  const { onClose, groupType, onConfirmation, groupId, isLoading } = props

  const [groupSearch, setGroupSearch] = React.useState('')

  const debounceSearch = useDebounceValue(groupSearch, 500)
  const {
    data: groupList,
    fetchNextPage,
    isFetching,
  } = useGrouplist({
    searchField: debounceSearch,
    groupTypeIds: String(groupId),
    groupFilterId: CREATED_BY_SELF_GROUP_FILTER_ID,
  })

  const flatData = React.useMemo(() => {
    // @ts-ignore
    const responsePages = groupList?.pages
      .map(val => val?.inventoryGroupList)
      .flat(1)

    return {
      totalRecords: groupList?.pages[0]?.totalRecords,
      filteredRecords: groupList?.pages[0]?.filteredRecords,
      inventoryGroupList: responsePages,
    }
  }, [groupList?.pages])

  const [selectedGroup, setSelectedGroup] = React.useState<
    SidebarInventoryGroup[]
  >([])

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        {iconMapper[groupType as keyof typeof iconMapper]}
        <Typography weight="demi">Save To Existing Inventory Group</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Row sx={{ mb: 16 }}>
          <Col xs={12}>
            <Typography>{`Choose ${groupType} inventory group where you want to add all the selected inventories.`}</Typography>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <GroupSelect
              groupType={groupType}
              groupData={flatData}
              fetchNextGroupListPage={fetchNextPage}
              isGroupListLoading={isFetching}
              setGroupSearch={setGroupSearch}
              groupSearch={groupSearch}
              selectedGroup={selectedGroup}
              setSelectedGroup={setSelectedGroup}
            />
          </Col>
        </Row>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          loading={isLoading}
          variant="contained"
          disabled={!selectedGroup.length}
          onClick={() => onConfirmation(selectedGroup[0]?.id)}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
