import { DataGrid } from '@applift/datagrid'
import { Paper, sx } from '@applift/factor'
import { colDef } from './colDef'
import { NoResultsOverlay } from '../../../../components/GridOverLay'
import { SearchData } from '@applift/illustrations'
import { BasicCampaignListType } from '../../../../api/campaign'

interface Props {
  data: BasicCampaignListType[]
  isLoading: boolean
  rowCount: number
  overlay?: 'error' | 'noRows' | 'noResult' | undefined
  pageSize: number
}

export const ViewCampaignGrid = (props: Props) => {
  const { data, isLoading, rowCount, overlay, pageSize } = props

  return (
    <Paper sx={{ height: 100 }} elevation={2}>
      <DataGrid
        density="compact"
        columns={colDef}
        data={data ?? []}
        pageSize={pageSize}
        rowCount={rowCount}
        showColumnRightBorder
        showCellRightBorder
        disableRowSelectionOnClick
        disableColumnReorder
        hideFooter
        hideHeader
        classes={{
          root: sx({
            borderRadius: 0,
            border: 0,
            borderTop: 1,
          }),
        }}
        loading={isLoading}
        overlay={overlay}
        components={{
          NoResultsOverlay: NoResultsOverlay,
          NoRowsOverlay: NoResultsOverlay,
          ErrorOverlay: NoResultsOverlay,
        }}
        componentsProps={{
          noResultsOverlay: {
            text: 'No Campaigns assigned',
            subText: 'Inventory Group is not assigned to any campaigns yet.',
            illustration: (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  pb: 8,
                  height: 100,
                  width: 100,
                }}
              />
            ),
          },
          noRowsOverlay: {
            // @ts-ignore
            text: 'No Campaigns assigned',
            subText: 'Inventory Group is not assigned to any campaigns yet.',
            illustration: (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  pb: 8,
                  height: 100,
                  width: 100,
                }}
              />
            ),
          },
          errorOverlay: {
            // @ts-ignore
            text: 'No Campaigns assigned',
            subText: 'Inventory Group is not assigned to any campaigns yet.',
            illustration: (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  pb: 8,
                  height: 100,
                  width: 100,
                }}
              />
            ),
          },
        }}
      />
    </Paper>
  )
}
