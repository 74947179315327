import { Box, Typography } from '@applift/factor'
import styles from './index.module.scss'
interface TooltipPropsType {
  comment: string
}
export const CommentTooltip = (TooltipProp: TooltipPropsType) => {
  const { comment } = TooltipProp
  return (
    <Box sx={{ overflowY: 'scroll' }} className={styles.commentTooltip}>
      <Typography>{comment}</Typography>
    </Box>
  )
}
