import * as React from 'react'
import { Layout } from '@applift/factor'
import { createFileRoute, useNavigate, useSearch } from '@tanstack/react-router'

import { DEFAULT_EXCHANGE_SIDEBAR_STATE } from '../../constants/exchange'
import {
  OpenExchangePage,
  getExchangeSidebarDataForURLPopulation,
  OpenExchangeSidebar,
} from './-pages/index'
import { ScreenLoader } from '../../components/ScreenLoader'
import { getOpenExchangeListingData } from '../../api'
import { getOpenExchangeListingDataKey } from '../../api/QueryKeys'
import { OpenExchangeSidebarUploadedCSVFileInfo } from '../../models/OpenExchange'

export type OpenExchangeSidebarSearchValues =
  typeof DEFAULT_EXCHANGE_SIDEBAR_STATE

export const Route = createFileRoute('/exchange/')({
  validateSearch: (
    routeSearch: OpenExchangeSidebarSearchValues
  ): Partial<OpenExchangeSidebarSearchValues> => ({
    ...getExchangeSidebarDataForURLPopulation(routeSearch),
  }),
  loader: ({ context, location }) => {
    const search = location.search ?? {}
    // @ts-ignore
    return context?.queryClient?.fetchInfiniteQuery(
      getOpenExchangeListingDataKey.keys({
        scope: 'getOpenExchangeListingData',
        filters: search as OpenExchangeSidebarSearchValues,
      }),
      getOpenExchangeListingData
    )
  },
  pendingComponent: () => <ScreenLoader />,
  errorComponent: () => (
    <Layout
      sidebarWidth={350}
      maxWidth={350}
      minWidth={350}
      hideResizerHandle
      sidebar={
        <OpenExchangeSidebar filters={{} as OpenExchangeSidebarSearchValues} />
      }
    >
      <OpenExchangePage
        filters={{} as OpenExchangeSidebarSearchValues}
        uploadedFileForSearch={null}
      />
    </Layout>
  ),
  component: Exchange,
})

function Exchange() {
  const filters = useSearch({
    from: '/exchange/',
  }) as OpenExchangeSidebarSearchValues

  const navigate = useNavigate({
    from: '/exchange',
  })

  const [uploadedFileForSearch, setUploadedFileForOpenExchangeSearch] =
    React.useState<OpenExchangeSidebarUploadedCSVFileInfo | null>(null)

  const setFilters = (filters: OpenExchangeSidebarSearchValues) => {
    navigate({
      to: '/exchange',
      search: {
        // set filter values from sidebar in route
        ...getExchangeSidebarDataForURLPopulation(filters),
      },
    })
  }

  const resetFilters = () => {
    navigate({
      to: '/exchange',
    })
  }

  return (
    <Layout
      sidebar={
        <OpenExchangeSidebar
          filters={filters}
          resetFilters={resetFilters}
          setFilters={setFilters}
          uploadedFileForSearch={uploadedFileForSearch}
          setUploadedFileForOpenExchangeSearch={
            setUploadedFileForOpenExchangeSearch
          }
        />
      }
      sidebarWidth={355}
      maxWidth={355}
      minWidth={355}
      hideResizerHandle
    >
      <OpenExchangePage
        filters={filters}
        uploadedFileForSearch={uploadedFileForSearch}
      />
    </Layout>
  )
}
