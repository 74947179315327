import { Typography } from '@applift/factor'

export const getGroupDeletedMessage = (groupName: string) => (
  <Typography>
    <Typography sx={{ textWeight: 'demi' }}>{groupName}</Typography> group
    deleted successfully.
  </Typography>
)

export const getGroupCreatedMessage = (groupName: string) => (
  <Typography>
    <Typography sx={{ textWeight: 'demi' }}>{groupName}</Typography> group
    created successfully.
  </Typography>
)
