import * as React from 'react'
import { sx } from '@applift/factor'
import {
  DataGrid,
  SortingState,
  useGridApiRef,
  getSortedRowModel,
} from '@applift/datagrid'
import { SearchData, Warning } from '@applift/illustrations'

import { NoResultsOverlay } from '../../../../components/GridOverLay'

export interface CampaignGridProp {
  data: any[]
  columnDef: any[]
  totalRecords?: number
  filteredRecords?: number
  rowIdKey: string
  pageSize: number
  loading: boolean
  onFetchRows?: () => void
  initialState?: any
  defaultSorting?: SortingState
  overlay?: 'error' | 'noRows' | 'noResult' | undefined
}

export const CampaignGrid = (props: CampaignGridProp) => {
  const {
    data,
    loading,
    overlay,
    pageSize,
    rowIdKey,
    columnDef,
    onFetchRows,
    initialState,
    defaultSorting,
    filteredRecords,
  } = props

  const [sorting, setSortinig] = React.useState<SortingState>(
    defaultSorting ?? []
  )

  const apiRef = useGridApiRef()

  return (
    <DataGrid
      apiRef={apiRef}
      data={data}
      columns={columnDef}
      loading={loading}
      pageSize={pageSize}
      getRowId={(row: any) => {
        if (row && typeof row[rowIdKey] === 'number') {
          return `${row[rowIdKey]}`
        }
        return row[rowIdKey]
      }}
      hideFooter
      state={{
        sorting,
      }}
      hideHeader
      rowHeight={46}
      showColumnRightBorder
      showCellRightBorder
      overlay={overlay}
      overscrollBehaviorX="contain"
      components={{
        NoResultsOverlay,
        ErrorOverlay: NoResultsOverlay,
      }}
      componentsProps={{
        noResultsOverlay: {
          text: 'No results found',
          subText: 'We can’t find any items matching your search.',
          illustration: (
            <SearchData
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 24,
              }}
            />
          ),
        },
        // @ts-ignore
        errorOverlay: {
          // @ts-ignore
          subText:
            'Oops, something went wrong. Please try again after some time.',
          illustration: (
            <Warning
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 24,
              }}
            />
          ),
        },
      }}
      disableRowSelectionOnClick
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      rowCount={filteredRecords}
      onFetchRows={onFetchRows}
      getSortedRowModel={getSortedRowModel()}
      onSortingChange={value => {
        setSortinig(value)
      }}
      initialState={initialState}
    />
  )
}
