import React from 'react'
import {
  Typography,
  DialogContent,
  DialogTitle,
  Container,
  Paper,
  Row,
  Col,
  Box,
  sx,
} from '@applift/factor'
import {
  SelectIOCampaignApiRefType,
  SelectIOCampaignList,
  NormalizedIOCampaignList,
  // normalizeIOCampaignData,
  useAppContext,
} from '@applift/platform'
import { Warning } from '@applift/illustrations'

import { CampaignInfoType } from '../../../models/Group'
import { colDef } from './CampaignInfogrid/colDef'
import {
  DataGrid,
  useGridApiRef,
  GridActionCellParams,
  getSortedRowModel,
  SortingState,
} from '@applift/datagrid'
import {
  // useBasicIoCampaignList,
  useCampaignDealCount,
} from '../../../hooks/useCampaign'
import { CAMPAIGN_TYPE_BY_NAME } from '../../../constants/campaign'

interface AssignToCampaignsDialogProps {
  onClose: () => void
  campaignsInfo: CampaignInfoType[]
  setCampaignsInfo: React.Dispatch<React.SetStateAction<CampaignInfoType[]>>
  initData: CampaignInfoType[]
  intialNormalizedData: any
  headingText?: JSX.Element
}
export interface AssingToCampaignPgApiRef {
  hasChange: () => boolean
}

export const AssignToCampaignsDealDialog = React.forwardRef<
  AssingToCampaignPgApiRef,
  AssignToCampaignsDialogProps
>((props, ref) => {
  const {
    onClose,
    campaignsInfo,
    setCampaignsInfo,
    initData,
    intialNormalizedData,
    headingText,
  } = props

  const [sorting, setSortinig] = React.useState<SortingState>([
    { id: 'id', desc: true },
  ])
  const [deleteDisabled, setDeleteDisabled] = React.useState<number[]>([])
  const [campaignWithMaxDealAttached, setCampaignWithMaxDealAttached] =
    React.useState<number[]>([])

  const ctx = useAppContext()
  const owId = ctx.appMeta.loggedInOwId

  const campaignIds = React.useMemo(
    () => initData.map(item => item.id),
    [initData]
  )

  // const { data: ioCampaignList, isFetching } = useBasicIoCampaignList(
  //   '',
  //   campaignIds,
  //   {
  //     enabled: !!campaignIds.length,
  //   }
  // )

  // const flatData = React.useMemo(() => {
  //   return (
  //     ioCampaignList?.pages
  //       ?.map(page => {
  //         return page?.ioCampaignsList ?? []
  //       })
  //       .flat(1) || []
  //   )
  // }, [ioCampaignList?.pages])
  const apiRef = React.useRef<SelectIOCampaignApiRefType>()
  const tableApiRef = useGridApiRef()

  // const normalizedParent = normalizeIOCampaignData(flatData)

  // flatData?.map((item, index) => {
  //   const parent = normalizedParent?.[index]
  //   if (parent !== undefined && item.campaigns !== undefined) {
  //   }
  // })

  const setCampaignsInfoWrapper = (
    value: Record<string, NormalizedIOCampaignList>,
    _
  ) => {
    const newTableData = [...campaignsInfo]

    const rejectedStatus = ['deleted', 'expired', 'rejected']

    // const includedIds = campaignsInfo.map(item => {
    //   return item.id
    // })

    const filteredIncludedIds = campaignsInfo
      ?.filter(item => !rejectedStatus.includes(item.status))
      .map(item => {
        return item.id
      })

    let action = ''
    if (filteredIncludedIds.length < Object.keys(value).length) {
      action = 'add'
    } else {
      action = 'remove'
    }

    if (action === 'add') {
      const idsToBeAdded = Object.keys(value)
        .filter(item => {
          return !filteredIncludedIds.includes(Number(item))
        })
        .filter(id => {
          // remove ids that has reached limit of 25 deals per campaign
          if (campaignWithMaxDealAttached.includes(parseInt(id))) {
            // on select all and the parent selection id with maximum limit gets selected internally
            // to deselect them
            apiRef.current?.removeSelection(id)
          }
          return !campaignWithMaxDealAttached.includes(parseInt(id))
        })

      idsToBeAdded.map(id => {
        const obj: CampaignInfoType = {
          id: Number(value[id]?.value),
          name: value[id]?.label || '',
          creativeTypeId: Number(value[id]?.creativeTypeId),
          status: value[id]?.status || '',
          ioId: Number(value[id]?.parent?.value),
          ioName: value[id]?.parent?.label || '',
          ioBudgetTypeId: Number(value[id]?.parent?.ioBudgetTypeId),
        }
        newTableData.push(obj)
      })
      setCampaignsInfo(newTableData)
    }

    if (action === 'remove') {
      const initDataIds = campaignsInfo
        .filter(item => !rejectedStatus.includes(item.status))
        .map(item => {
          return item.id
        })
      const idsToBeRemoved = initDataIds
        .filter(item => {
          return !Object.keys(value).includes(String(item))
        })
        .filter(id => {
          if (deleteDisabled.includes(id)) {
            apiRef.current?.setSelection(String(id))
          }
          return !deleteDisabled.includes(id)
        })
      const removedTable = campaignsInfo.filter(item => {
        return !idsToBeRemoved.includes(item.id)
      })

      setCampaignsInfo(removedTable)
    }
  }

  const actions = {
    updateRow: (params: { rowId: number; payload: any }) => {
      const { rowId, payload } = params

      setCampaignsInfo(prev => {
        return (
          prev?.map(grp => (grp.id === rowId ? { ...grp, ...payload } : grp)) ??
          []
        )
      })
    },
    removeRow: ({ rowId }: { rowId: number }) => {
      setCampaignsInfo(prev => prev?.filter(grp => grp.id !== rowId))

      apiRef.current?.removeSelection(String(rowId))
    },
  }

  const onAction = (params: GridActionCellParams) => {
    actions[params.actionName as keyof typeof actions](params.metaData)
  }

  function compareWithoutOrder(
    intialData: CampaignInfoType[],
    currentData: CampaignInfoType[]
  ) {
    // Parse the JSON strings into JavaScript objects

    // Sort the arrays of objects
    const sortFunction = (a: { id: number }, b: { id: number }) => {
      return a.id - b.id
    }

    intialData.sort(sortFunction)
    currentData.sort(sortFunction)

    // Convert the sorted arrays back to JSON strings
    const sortedJson1 = JSON.stringify(intialData)
    const sortedJson2 = JSON.stringify(currentData)

    // Compare the resulting strings
    return sortedJson1 === sortedJson2
  }

  const { data: campaignCountData } = useCampaignDealCount('2')

  React.useEffect(() => {
    const countIds = Object.keys(campaignCountData || {})
    if (campaignIds.length && countIds.length) {
      campaignIds?.forEach(id => {
        if (campaignCountData?.[id] && campaignCountData?.[id].length === 1) {
          setDeleteDisabled(prev => [...prev, id])
        }
      })
    }
    if (countIds.length) {
      countIds.forEach(id => {
        if (campaignCountData?.[id] && campaignCountData?.[id].length >= 25) {
          setCampaignWithMaxDealAttached(prev => [...prev, parseInt(id)])
        }
      })
    }
  }, [campaignIds, campaignCountData])

  React.useImperativeHandle(ref, () => ({
    hasChange: () => {
      return compareWithoutOrder(initData, campaignsInfo)
    },
  }))

  return (
    <>
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <Typography weight="demi"> Assign To Campaigns </Typography>
      </DialogTitle>
      <DialogContent
        dividers
        style={{ minHeight: '400px', height: 100 }}
        sx={{ display: 'flex', flexDirection: 'column', pb: 24 }}
      >
        <Container
          sx={{
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            p: 0,
            pb: 24,
          }}
        >
          <Row sx={{ mb: 16 }}>
            <Col xs={12}>{headingText}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <SelectIOCampaignList
                ref={apiRef}
                multiple={true}
                initialData={intialNormalizedData}
                useCase="basic"
                enabled
                slotProps={{
                  PaperProps: {
                    style: {
                      maxWidth: 500,
                    },
                  },
                }}
                params={{
                  owIds: [owId],
                  status: ['running', 'pending', 'paused', 'draft'], // 1:pending
                  campaignTypeIds: [CAMPAIGN_TYPE_BY_NAME.PG],
                  paymentTypeId: 2,
                }}
                getOptionDisabled={row =>
                  campaignWithMaxDealAttached.includes(parseInt(row.value)) ||
                  deleteDisabled.includes(parseInt(row.value))
                }
                getOptionTooltip={row => {
                  if (deleteDisabled.includes(parseInt(row.value))) {
                    return (
                      <Typography>
                        This campaign cannot be removed because it is the only
                        associated deal
                      </Typography>
                    )
                  }
                  if (
                    campaignWithMaxDealAttached.includes(parseInt(row.value))
                  ) {
                    return (
                      <Typography>
                        Maximum limit of 25 deals per campaign is reached
                      </Typography>
                    )
                  }
                  return ''
                }}
                onChange={(data, reason) => {
                  if (reason !== 'apiAction') {
                    setCampaignsInfoWrapper(data, reason)
                  }
                }}
                TextFieldProps={{
                  variant: 'outlinedDash',
                  style: {
                    width: 245,
                  },
                }}
                hideSelectNav
              />
            </Col>
          </Row>
          {/* {isFetching ? (
            <></>
          ) : ( */}
          <Row sx={{ flexGrow: 1 }}>
            <Col xs={12}>
              {campaignsInfo.length ? (
                <Paper elevation={2} sx={{ mt: 24, pt: 56, height: 100 }}>
                  <Box
                    sx={{ height: 100 }}
                    style={{
                      minHeight: 96,
                    }}
                  >
                    <DataGrid
                      apiRef={tableApiRef}
                      onAction={onAction}
                      data={campaignsInfo}
                      columns={colDef}
                      rowCount={campaignsInfo?.length}
                      componentsProps={{
                        // @ts-ignore
                        errorOverlay: {
                          // @ts-ignore
                          subText:
                            'Oops, something went wrong. Please try again after some time.',
                          illustration: (
                            <Warning
                              sx={{
                                textColor: 'primary-500',
                                width: 100,
                                height: 'auto',
                                mb: 40,
                              }}
                            />
                          ),
                        },
                      }}
                      hideHeader
                      hideFooter
                      rowHeight={40}
                      checkboxSelection={false}
                      overscrollBehaviorX="contain"
                      showColumnRightBorder
                      showCellRightBorder
                      disableSelectAll
                      disableRowSelectionOnClick
                      state={{ sorting }}
                      initialState={{
                        columnVisibility: {
                          included: false,
                        },
                      }}
                      getSortedRowModel={getSortedRowModel()}
                      meta={{ deleteDisabled }}
                      onSortingChange={value => {
                        setSortinig(value)
                      }}
                      classes={{
                        root: sx({
                          borderRadius: 0,
                          border: 0,
                          borderTop: 1,
                        }),
                      }}
                    />
                  </Box>
                </Paper>
              ) : null}
            </Col>
          </Row>
          {/* )} */}
        </Container>
      </DialogContent>
    </>
  )
})

AssignToCampaignsDealDialog.displayName = 'AssignToCampaignsDealDialog'
