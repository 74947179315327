import * as React from 'react'
import {
  BaseTextFieldProps,
  SelectAdvanceComponents,
  TextField,
  Typography,
  useDebounceValue,
} from '@applift/factor'

import { ExchangeFilterType } from '../../../models/Filters'
import { useExchangeList } from '../../../hooks'

interface ExchangeFilterProps {
  TextFieldProps?: Omit<BaseTextFieldProps<ExchangeFilterType>, 'selectProps'>
  value: ExchangeFilterType[]
  multiSelect?: boolean
  required?: boolean
  onChange: (creative: ExchangeFilterType[]) => void
}

export const ExchangeFilter = (props: ExchangeFilterProps) => {
  const {
    TextFieldProps,
    value,
    onChange,
    multiSelect = true,
    required = false,
  } = props
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounceValue(search, 250)
  const apiRef =
    SelectAdvanceComponents.useSelectAdvanceApiRef<ExchangeFilterType>()

  const currentSelection = React.useMemo(() => {
    return value.reduce((acc: { [key: number]: boolean }, curr) => {
      acc[curr.value] = true
      return acc
    }, {})
  }, [value])

  const rowSelectionRef = React.useRef(currentSelection)
  rowSelectionRef.current = currentSelection

  const renderItem =
    SelectAdvanceComponents.DefaultListOptionItemWrapper<ExchangeFilterType>({
      disableCheckbox: true,
      selectionStyle: 'none',
      arrowPosition: 'right',
    })

  const { data, isLoading, isFetching } = useExchangeList()

  const rowData = React.useRef<ExchangeFilterType[]>()
  // Local search filter
  const filteredData = React.useMemo(() => {
    if (debouncedSearch.trim()) {
      return data?.exchangeList?.filter(data =>
        data.label.toLowerCase().includes(debouncedSearch.trim().toLowerCase())
      )
    }
    rowData.current = data?.exchangeList
    return data?.exchangeList || []
  }, [debouncedSearch, data])

  const overlay = React.useMemo(() => {
    if (!(isLoading || isFetching)) {
      if (!Array.isArray(filteredData)) {
        return 'error'
      }
      if ((filteredData?.length ?? 0) <= 0) {
        if (search) {
          return 'noResult'
        }
        return 'noRows'
      }
    }
    return undefined
  }, [filteredData, search, isLoading, isFetching])

  return (
    <TextField
      {...TextFieldProps}
      select="advanceSelect"
      variant="outlinedDash"
      placeholder="Select Exchange"
      label="Exchange"
      required={required}
      SelectProps={{
        data: filteredData as ExchangeFilterType[],
        multiple: multiSelect,
        hideSelectNav: !multiSelect,
        apiRef: apiRef,
        inputValue: search,
        getRowId: row => `${row.value}`,
        onInputChange: (_, newSearchVal, reason) => {
          if (reason !== 'reset') {
            setSearch(newSearchVal)
          }
        },
        rowCount: filteredData?.length || 0,
        overlay: overlay,
        renderValue: value => {
          const rowSelected = Object.keys(rowSelectionRef.current)
          const selectedItem = Array.isArray(value) ? value : []
          if (multiSelect) {
            return <Typography>{`${selectedItem.length} Selected`}</Typography>
          }
          if (selectedItem[0]?.label) {
            return <Typography>{`${selectedItem[0]?.label}`}</Typography>
          }
          return (
            <Typography>
              {
                rowData.current?.filter(
                  data => String(data?.value) === rowSelected?.[0]
                )?.[0]?.label
              }
            </Typography>
          )
        },
        renderListItem: renderItem,
        renderOption: ({ row }) => {
          const exchange = row.original.label
          return <Typography>{exchange}</Typography>
        },
      }}
      value={currentSelection}
      onChange={selectedRowData => {
        // @ts-ignore
        const rowSelection = selectedRowData(currentSelection)
        const selectedIds = Object.keys(rowSelection).map(id => parseInt(id))
        const value = data?.exchangeList?.filter(data =>
          selectedIds.includes(data?.value)
        )
        onChange(value?.length ? value : [])
      }}
    />
  )
}
