import { ColumnDef } from '@applift/datagrid'

import { formatNumber, formatNumberWithSuffix } from '../../../../utils/format'
import { RawDataCell, IconTextCell } from '../../../../components/CellTypes'
import { Exchange } from '../../../../models/OpenExchange'

const inventoryTypeIconMapping = {
  'Android App': 'BrandAndroid',
  'Other App': 'EllipsisHorizontalCircle',
  Site: 'Globe',
  'IOS App': 'BrandApple',
}

export const openExchangeColumnDef: ColumnDef<Exchange>[] = [
  {
    accessorKey: 'appId',
    id: 'appId',
    header: 'ID',
    size: 220,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Name',
    size: 240,
    maxSize: 500,
    enableSorting: false,
    minSize: 100,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },

  {
    accessorKey: 'publisher',
    id: 'publisher',
    header: 'Publisher',
    size: 160,
    enableSorting: false,
    maxSize: 500,
    minSize: 100,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },

  {
    accessorKey: 'inventoryType',
    id: 'inventoryType',
    header: 'Type',
    size: 150,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      //  const rowData = props.row.original

      return (
        <IconTextCell
          label={props.renderValue() as string}
          textTypographyProps={{ variant: 'bodySmall' }}
          iconName={
            inventoryTypeIconMapping[
              props.renderValue() as keyof typeof inventoryTypeIconMapping
            ]
          }
          iconSize={20}
        />
      )
    },
  },

  {
    accessorKey: 'impressions',
    id: 'impressions',
    header: 'Impressions',
    size: 150,
    maxSize: 500,
    enableSorting: false,
    meta: {
      align: 'right',
    },
    minSize: 100,
    cell: props => (
      <RawDataCell
        textTypographyProps={{
          variant: 'bodySmall',
          sx: { textWeight: 'demi' },
        }}
        value={formatNumberWithSuffix((props.renderValue() as number) ?? 0)}
      />
    ),
  },

  {
    accessorKey: 'reach',
    id: 'reach',
    header: 'Reach',
    enableSorting: false,
    size: 100,
    meta: {
      align: 'right',
    },
    maxSize: 500,
    minSize: 100,
    cell: props => (
      <RawDataCell
        textTypographyProps={{
          variant: 'bodySmall',
          sx: { textWeight: 'demi' },
        }}
        value={formatNumberWithSuffix((props.renderValue() as number) ?? 0)}
      />
    ),
  },

  {
    accessorKey: 'videoPercentage',
    id: 'videoPercentage',
    header: 'Video%',
    size: 100,
    enableSorting: false,
    maxSize: 500,
    minSize: 100,
    meta: {
      align: 'right',
    },
    cell: props => (
      <RawDataCell
        textTypographyProps={{
          variant: 'bodySmall',
          sx: { textWeight: 'demi' },
        }}
        value={`${formatNumber((props.renderValue() as number) ?? 0)}%`}
      />
    ),
  },

  {
    accessorKey: 'displayPercentage',
    id: 'displayPercentage',
    header: 'Display%',
    enableSorting: false,
    size: 100,
    meta: {
      align: 'right',
    },
    maxSize: 500,
    minSize: 100,

    cell: props => (
      <RawDataCell
        textTypographyProps={{
          variant: 'bodySmall',
          sx: { textWeight: 'demi' },
        }}
        value={`${formatNumber((props.renderValue() as number) ?? 0)}%`}
      />
    ),
  },
]
