import * as React from 'react'
import {
  SortingState,
  RowSelectionState,
  GridActionCellParams,
} from '@applift/datagrid'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { Row, Col, Paper, Container } from '@applift/factor'

import { colDef } from './colDef'
import { Route } from '../../index'
import { PgGrid } from './PgGrid'
import { ActionPanel } from './ActionPanel'
import { EditPgDealDialog } from '../Dialog'
import { ACTION, ActionNameType } from './action'
import { isEqual } from '../../../../utils/helper'
import { PgListResponse } from '../../../../models/Pg'
import { PgCampaignDialog } from '../PgCampaignDialog'
import { DEFAULT_SIDEBAR_STATE } from '../../../../constants/pmp'
import { AssingToCampaignDealWrapper } from '../../../../components/Dialogs'

import styles from './styles.module.scss'

interface PgGridWrapperProps {
  data: PgListResponse[]
  onFetchRows?: () => void
  isError: boolean
  loading: boolean
  filteredRecords: number
  totalRecords: number
}

export const PgGridWrapper = (props: PgGridWrapperProps) => {
  const { data, loading, isError, filteredRecords, totalRecords, onFetchRows } =
    props

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [pgData, setPgData] = React.useState<PgListResponse>()
  const [assignToCampaignData, setAssignToCampaignData] = React.useState<any>()
  const [dialogToShow, setDialogToShow] = React.useState<
    'openCampaign' | 'openEdit' | 'assignToCampaign'
  >()
  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    { data?: PgListResponse; id: string; params: any }
  > | null>(null)

  const navigate = useNavigate({ from: Route.to })
  const search = useSearch({ from: '/pg/' })

  const hideDialog = () => setDialogToShow(undefined)

  const onSortingChangeWrapper = (
    value: React.SetStateAction<SortingState>
  ) => {
    const currentSort = search.sortBy
    // @ts-ignore
    const sortValue = value(currentSort)
    navigate({ to: Route.to, search: { ...search, sortBy: sortValue } })
    setRowSelection({})
  }

  const isStateChanged = React.useMemo(() => {
    return !isEqual(search, DEFAULT_SIDEBAR_STATE)
  }, [search])

  React.useEffect(() => {
    setRowSelection({})
  }, [search])

  React.useEffect(() => {
    switch (action?.actionName) {
      case ACTION.OPEN_CAMPAIGN_DIALOG: {
        const actionRowId = action?.metaData?.data?.id
        if (actionRowId) {
          setDialogToShow('openCampaign')
          setPgData(action.metaData.data)
          // open dialog here
        }
        break
      }
      case ACTION.EDIT_PG_DEAL: {
        const actionRowId = action?.metaData?.data?.id
        if (actionRowId) {
          setDialogToShow('openEdit')
          setPgData(action.metaData.data)
        }
        break
      }
      case ACTION.ASSIGN_TO_CAMPAIGN: {
        const actionRow = action?.metaData?.params
        if (actionRow.length) {
          setDialogToShow('assignToCampaign')
          setAssignToCampaignData(action.metaData.params)
        }
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if (!data?.length && !loading && isStateChanged) {
      return 'noResult'
    }
    return undefined
  }, [data?.length, loading, isError, isStateChanged])

  const selectedIds = Object.keys(rowSelection).map(id => String(id))

  const selectedItems = React.useMemo(
    () =>
      data.length
        ? data.filter(data => selectedIds.includes(String(data?.id)))
        : [],
    [selectedIds, data]
  )

  const clearSelection = () => setRowSelection({})

  return (
    <Container className={styles.container} sx={{ height: 100 }}>
      <Row sx={{ height: 100, py: 24, pl: 16, pr: 4 }}>
        <Col sx={{ pl: 4 }}>
          <Paper
            elevation={2}
            sx={{
              flexGrow: 1,
              height: 100,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Row
              className={styles.actionRow}
              sx={{ px: 24, alignItems: 'center' }}
              xs={{ gutterSize: 0, gutterDirection: 'x' }}
            >
              <ActionPanel
                colDef={colDef}
                setAction={setAction}
                isTableLoading={loading}
                rowSelection={rowSelection}
                totalRecords={totalRecords}
                selectedItems={selectedItems}
                clearSelection={clearSelection}
              />
            </Row>
            <Row sx={{ flexGrow: 1 }}>
              <Col>
                <PgGrid
                  data={data}
                  colDef={colDef}
                  overlay={overlay}
                  loading={loading}
                  onAction={setAction}
                  sorting={search.sortBy || []}
                  onFetchRows={onFetchRows}
                  rowSelection={rowSelection}
                  totalRecords={filteredRecords}
                  setRowSelection={setRowSelection}
                  onSortingChange={onSortingChangeWrapper}
                />
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
      {dialogToShow === 'openCampaign' && (
        <PgCampaignDialog onClose={hideDialog} data={pgData} />
      )}
      {dialogToShow === 'openEdit' && pgData && (
        <EditPgDealDialog
          onClose={hideDialog}
          onSuccess={clearSelection}
          data={pgData}
        />
      )}
      {dialogToShow === 'assignToCampaign' && (
        <AssingToCampaignDealWrapper
          onClose={hideDialog}
          selectedDealData={assignToCampaignData}
          onSuccess={clearSelection}
          attachedCampaign={
            assignToCampaignData.length === 1
              ? [
                  ...(assignToCampaignData[0].activeCampaignIds
                    ? assignToCampaignData[0].activeCampaignIds
                    : []),
                ]
              : []
          }
        />
      )}
    </Container>
  )
}
