import { ColumnDef } from '@applift/datagrid'

import { PmpListResponse } from '../../../../models/Pmp'
import {
  DateCell,
  RawDataCell,
  CurrencyCell,
  IconTextCell,
  CreativeIdsCell,
} from '../../../../components/CellTypes'
import { CustomerCell, StatusCell } from '../Celltype'
import { ExchangeCell } from '../../../pg/-component/Celltype'
import { DealCurationTypeCell } from '../../../../components/CellTypes/DealCurationTypeCell'

export const colDef: ColumnDef<PmpListResponse>[] = [
  {
    accessorKey: 'dealId',
    id: 'dealId',
    header: 'Deal ID',
    size: 220,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      const rowData = props.row.original
      return (
        <IconTextCell
          position="after"
          label={props.renderValue() as string}
          textTypographyProps={{ variant: 'bodySmall' }}
          {...(rowData.shared
            ? {
                iconName: 'FolderConnect',
                iconSize: 24,
                iconTooltip: 'Shared PMP deal',
              }
            : {})}
        />
      )
    },
  },
  {
    accessorKey: 'dealName',
    id: 'dealName',
    header: 'Name',
    size: 220,
    maxSize: 500,
    minSize: 100,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },
  {
    accessorKey: 'active',
    id: 'active',
    header: 'Status',
    size: 150,
    maxSize: 250,
    minSize: 120,
    enableSorting: false,
    cell: props => {
      const rowData = props.row.original
      return (
        <StatusCell
          active={props.renderValue() as boolean}
          rowData={rowData}
          action={props.onAction}
        />
      )
    },
  },
  {
    accessorKey: 'dealCurationTypeId',
    id: 'dealCurationTypeId',
    header: 'Deal Curation',
    size: 160,
    maxSize: 250,
    minSize: 100,
    enableSorting: false,
    cell: props => (
      <DealCurationTypeCell
        dealCurationTypeId={props.renderValue() as number}
      />
    ),
  },
  {
    accessorKey: '_customer',
    id: '_customer',
    header: 'Customers',
    size: 160,
    maxSize: 250,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      const rowData = props.row.original
      return <CustomerCell rowData={rowData} action={props.onAction} />
    },
  },
  {
    accessorKey: 'exchanges',
    id: 'exchanges',
    header: 'Exchange',
    size: 200,
    maxSize: 300,
    minSize: 100,
    enableSorting: false,
    cell: props => (
      <ExchangeCell
        textTypographyProps={{ variant: 'bodySmall' }}
        // @ts-ignore
        exchangeId={props.renderValue()?.[0] as number}
      />
    ),
  },
  {
    accessorKey: 'cpm',
    id: 'cpm',
    header: 'CPM',
    size: 100,
    maxSize: 200,
    minSize: 70,
    meta: {
      align: 'right',
    },
    cell: props => (
      <CurrencyCell
        value={props.renderValue() as number}
        textTypographyProps={{ variant: 'bodySmall' }}
      />
    ),
  },
  {
    accessorKey: 'created',
    id: 'created',
    header: 'Created',
    size: 120,
    maxSize: 200,
    minSize: 70,
    cell: props => {
      const date = props.row.original.created
      const meta = props.table.options.meta
      return (
        // @ts-ignore
        <DateCell date={date} timezone={meta.organizationTimezone} />
      )
    },
  },
  {
    accessorKey: 'creativeTypes',
    id: 'creativeTypes',
    header: 'Creative Type',
    size: 200,
    maxSize: 300,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      const rowData = props.row.original
      const creatives = rowData.creativeTypes
      return <CreativeIdsCell creativeIds={creatives} />
    },
  },
  {
    accessorKey: 'description',
    id: 'description',
    header: 'Description',
    size: 200,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },
]
