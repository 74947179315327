import * as React from 'react'
import {
  Box,
  Col,
  Row,
  Radio,
  Tooltip,
  FormLabel,
  TextField,
  RadioGroup,
  Typography,
  FormControl,
  FormControlLabel,
  Container,
} from '@applift/factor'
import { CurrencyDollar, InfoCircle } from '@applift/icons'
import {
  CustomerDropdown,
  ORGANIZATION_TYPE,
  CustomerListApiRefType,
  NormalizedCustomerList,
} from '@applift/platform'

import {
  ExchangeFilter,
  CreativeTypeFilterDropdown,
} from '../../../../components/Filters'
import {
  CommonFilter,
  DealStatusIds,
  DealCurationObject,
  CampaignCreativeType,
} from '../../../../models/Filters'
import {
  filterObjectByIdArray,
  getChangedKeys,
  isEqual,
} from '../../../../utils/helper'
import { PmpFormState } from '../../../../models/Pmp'
import { formatNumber } from '../../../../utils/format'
import { CUSTOMER_STATUS } from '../../../../constants/customer'
import {
  useAssociatedCustomer,
  useCustomerCount,
  useCustomerList,
} from '../../../../hooks'
import { DEAL_CURATION, DEAL_STATUS } from '../../../../constants/filters'
import { DealCurationFilter } from '../../../../components/Filters/DealCurationFilter'
import { transformCustomerDataIntoCustomerInitialData } from '../../../../utils/transform'

export type { PmpFormState }

export const DEFAULT_PMP_FORM_STATE = {
  dealId: '',
  dealName: '',
  exchange: [] as CommonFilter[],
  dealStatus: DEAL_STATUS.ACTIVE as DealStatusIds,
  creativeTypes: [] as CampaignCreativeType[],
  cpm: null as null | number,
  description: '',
  dealCuration: [] as DealCurationObject[],
  customers: [] as number[],
}

export const DEFAULT_DISABLED_PMP_STATE = {
  dealId: false,
  dealName: false,
  dealStatus: false,
  cpm: false,
  description: false,
}

export const DEFAULT_READ_ONLY_PMP_STATE = {
  dealId: false,
  dealName: false,
  exchange: false,
  creativeTypes: false,
  cpm: false,
  description: false,
  dealCuration: false,
  customers: false,
  dealStatus: false,
}

export interface DisabledPmpState {
  dealId?: boolean
  dealName?: boolean
  dealStatus?: boolean
  cpm?: boolean
  description?: boolean
}

export interface ReadOnlyPmpState {
  dealId?: boolean
  dealName?: boolean
  exchange?: boolean
  creativeTypes?: boolean
  cpm?: boolean
  description?: boolean
  dealCuration?: boolean
  customers?: boolean
  dealStatus?: boolean
}

export interface PmpCreationFormProps {
  organizationType: keyof typeof ORGANIZATION_TYPE
  loggedInOwId: number
  createdByOwId?: number
  initValue?: PmpFormState
  disabledStates?: DisabledPmpState
  readOnlyStates?: ReadOnlyPmpState
  dealAssignedToCampaign?: boolean
  editMode?: boolean
  /**
   * Deal ID is required in the editMode
   */
  dealId?: number
  onChange?: (formState: PmpFormState) => void
}

interface ErrorState {
  dealId?: string
  dealName?: string
  description?: string
}

export interface PmpCreationApiRef {
  getCurrentState: () => PmpFormState
  setError: (error: ErrorState) => void
  hasError: () => boolean
  /**
   * compare with the initValue passed and the current form state
   * @returns {boolean} Returns `true` if there is some change, else `false`.
   */
  hasChange: () => boolean
  isAllRequiredFilled: () => boolean
  getChangedState: () => Partial<PmpFormState>
}

const defaultErrorState = {
  dealId: '',
  dealName: '',
  description: '',
}

export const PmpCreationForm = React.forwardRef<
  PmpCreationApiRef,
  PmpCreationFormProps
>((props, ref) => {
  const {
    initValue = DEFAULT_PMP_FORM_STATE,
    dealId,
    onChange,
    loggedInOwId,
    createdByOwId,
    editMode = false,
    organizationType,
    dealAssignedToCampaign,
    disabledStates = DEFAULT_DISABLED_PMP_STATE,
    readOnlyStates = DEFAULT_READ_ONLY_PMP_STATE,
  } = props

  const [formState, setFormState] = React.useState<PmpFormState>(initValue)
  const [errorState, setErrorState] =
    React.useState<ErrorState>(defaultErrorState)
  const apiRef = React.useRef<CustomerListApiRefType | null>(null)

  const hasError = Boolean(
    Object.values(errorState).filter(error => Boolean(error)).length
  )

  const hasChange = !isEqual(formState, initValue)

  React.useEffect(() => {
    // setting forstate againg if initvalue changed
    setFormState(initValue)
  }, [initValue])

  const { data: customerCountData } = useCustomerCount(
    {
      enabled:
        organizationType === ORGANIZATION_TYPE.SUPER ||
        organizationType === ORGANIZATION_TYPE.WORKSPACE,
    },
    createdByOwId
      ? {
          owIds: [createdByOwId],
        }
      : undefined
  )

  const activeCustomerCount =
    customerCountData?.filter(
      customer => customer.id === CUSTOMER_STATUS.ACTIVE
    )?.[0]?.statusCount ?? undefined

  React.useImperativeHandle(ref, () => ({
    getCurrentState: () => formState,
    hasError: () => hasError,
    setError: error => {
      setErrorState(prev => {
        return { ...prev, ...error }
      })
    },
    hasChange: () => {
      return hasChange
    },
    isAllRequiredFilled: () => {
      if (organizationType === ORGANIZATION_TYPE.ADVERTISER) {
        return Boolean(
          formState?.dealId &&
            formState?.dealName &&
            formState.exchange.length &&
            formState.dealStatus
        )
      }
      if (
        organizationType === ORGANIZATION_TYPE.WORKSPACE ||
        organizationType === ORGANIZATION_TYPE.SUPER
      ) {
        return Boolean(
          formState?.dealId &&
            formState?.dealName &&
            formState.exchange.length &&
            formState.dealStatus &&
            formState.dealCuration?.length &&
            (formState.dealCuration[0]?.id === DEAL_CURATION.TAILORED
              ? formState.customers?.length
              : true)
        )
      }
      return false
    },
    getChangedState: () => getChangedKeys(initValue, formState),
  }))

  React.useEffect(() => {
    onChange?.(formState)
  }, [formState, onChange])

  const getGroupNameHelperText = (errors: string) =>
    errors.length ? (
      <Typography variant="label" color="error">
        {errors}
      </Typography>
    ) : null

  const { data: customerData } = useCustomerList({
    enabled: organizationType !== ORGANIZATION_TYPE.ADVERTISER,
  })

  React.useEffect(() => {
    if (initValue.customers?.length && customerData) {
      const transformedData = transformCustomerDataIntoCustomerInitialData(
        customerData,
        organizationType
      )
      const filteredCustomer = filterObjectByIdArray(
        transformedData,
        initValue.customers
      )
      apiRef?.current?.reinitializeRowSelection(filteredCustomer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRef, customerData, initValue.customers])

  const id = dealId ? dealId : ''

  const { data: associatedCustomerWithAttachedCampaign } =
    useAssociatedCustomer(String(id), {
      enabled:
        Boolean(String(id)?.length) &&
        organizationType !== ORGANIZATION_TYPE.ADVERTISER &&
        initValue?.dealCuration?.[0]?.id === DEAL_CURATION.TAILORED &&
        initValue?.dealStatus === DEAL_STATUS.ACTIVE &&
        !readOnlyStates.customers,
    })

  const getDisableCallback = React.useCallback(
    (row: NormalizedCustomerList) => {
      return (
        Boolean(
          associatedCustomerWithAttachedCampaign?.includes(parseInt(row.value))
        ) ||
        (organizationType === 'SUPER' && !row.isAccess)
      )
    },
    [associatedCustomerWithAttachedCampaign, organizationType]
  )

  return (
    <Container sx={{ gap: 24 }}>
      <Row sx={{ mb: 24 }}>
        <Col xs={4}>
          <TextField
            id={'dealId'}
            name={'dealId'}
            disabled={disabledStates.dealId}
            InputProps={{ readOnly: readOnlyStates.dealId }}
            value={formState.dealId}
            onChange={e => {
              e.persist()

              if (!e.target.value.trim()) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealId: 'Deal ID is required',
                  }
                })
              }
              if (e.target.value.trim().length > 500) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealId: 'Deal ID cannot exceed 500 characters',
                  }
                })
              }
              // removeing error
              if (
                e.target.value.trim().length > 0 &&
                e.target.value.trim().length <= 500
              ) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealId: '',
                  }
                })
              }
              // setting value
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, dealId: e.target.value }
              })
            }}
            required={!readOnlyStates.dealId}
            label={'Deal ID'}
            InputLabelProps={{
              renderSuffix: <>{formState.dealId.trim().length}/500</>,
            }}
            placeholder="Enter Deal ID"
            variant="outlinedDash"
            fullWidth
            helperText={getGroupNameHelperText(
              errorState.dealId ? errorState.dealId : ''
            )}
            error={Boolean(errorState?.dealId?.length)}
          />
        </Col>
        <Col xs={4}>
          <TextField
            id={'dealName'}
            name={'dealName'}
            value={formState.dealName}
            disabled={disabledStates.dealName}
            InputProps={{ readOnly: readOnlyStates.dealName }}
            required={!readOnlyStates.dealName}
            onChange={e => {
              e.persist()

              // setting error
              if (!e.target.value.trim()) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealName: 'Deal name is required',
                  }
                })
              }
              if (e.target.value.trim().length > 500) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealName: 'Deal Name cannot exceed 500 characters',
                  }
                })
              }
              // removing error
              if (
                e.target.value.trim().length > 0 &&
                e.target.value.trim().length <= 500
              ) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealName: '',
                  }
                })
              }
              // setting value
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, dealName: e.target.value }
              })
            }}
            InputLabelProps={{
              renderSuffix: <>{formState.dealName.trim().length}/500</>,
            }}
            label={'Deal Name'}
            placeholder="Enter Deal Name"
            variant="outlinedDash"
            fullWidth
            helperText={getGroupNameHelperText(
              errorState.dealName ? errorState.dealName : ''
            )}
            error={Boolean(errorState?.dealName?.length)}
          />
        </Col>
        <Col xs={4}>
          <ExchangeFilter
            key="exchange"
            TextFieldProps={{
              sx: { width: 100 },
              InputProps: {
                readOnly: readOnlyStates.exchange,
              },
            }}
            value={formState.exchange}
            multiSelect={false}
            required={!readOnlyStates.exchange}
            onChange={value => {
              if (errorState.dealId?.includes('already exists')) {
                // removing the deal ID and Exchange combination error when Exchange is getting updated
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealId: '',
                  }
                })
              }
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, exchange: value }
              })
            }}
          />
        </Col>
      </Row>
      <Row sx={{ gapRow: 24 }}>
        {organizationType !== ORGANIZATION_TYPE.ADVERTISER && (
          <>
            <Col>
              {editMode ? (
                <>
                  <Typography
                    variant="label"
                    sx={{ textColor: 'neutral-600', pl: 12, mb: 4 }}
                  >
                    Deal Curation
                    {!readOnlyStates.dealCuration && (
                      <Typography
                        component="span"
                        sx={{ textColor: 'danger-400', ml: 2 }}
                      >
                        *
                      </Typography>
                    )}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ textColor: 'neutral-1000', ml: 16, mt: 8 }}
                  >
                    {formState?.dealCuration?.length
                      ? formState?.dealCuration[0]?.name
                      : ''}
                  </Typography>
                </>
              ) : (
                <DealCurationFilter
                  value={formState?.dealCuration || []}
                  TextFieldProps={{
                    sx: { width: 100 },
                    InputProps: {
                      readOnly: readOnlyStates.dealCuration,
                    },
                  }}
                  required={!readOnlyStates.dealCuration}
                  onChange={value =>
                    setFormState(prev => {
                      const state = structuredClone(prev)
                      return { ...state, dealCuration: value }
                    })
                  }
                />
              )}
            </Col>
            <Col xs={4} sx={{ pb: 2 }}>
              {formState?.dealCuration?.[0]?.id === DEAL_CURATION.STANDARD ||
              formState?.dealCuration?.[0]?.id ===
                DEAL_CURATION.OPEN_EXCHANGE ? (
                <>
                  <Typography
                    variant="label"
                    sx={{ textColor: 'neutral-600', pl: 12, mb: 4 }}
                  >
                    Customers
                    {!readOnlyStates.customers && (
                      <Typography
                        component="span"
                        sx={{ textColor: 'danger-400', ml: 2 }}
                      >
                        *
                      </Typography>
                    )}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{ textColor: 'neutral-1000', ml: 16, mt: 8 }}
                  >
                    All Selected{' '}
                    {activeCustomerCount ? `(${activeCustomerCount})` : ''}
                  </Typography>
                </>
              ) : (
                <Tooltip
                  title={
                    !formState?.dealCuration?.length
                      ? 'Customers can be selected once the Deal Curation is selected'
                      : ''
                  }
                  placement="top"
                  arrow
                >
                  <Box>
                    {/* @ts-ignore */}
                    <CustomerDropdown
                      ref={apiRef}
                      TextFieldProps={{
                        fullWidth: true,
                        variant: 'outlinedDash',
                        label: 'Customers',
                        required: !readOnlyStates.customers,
                        disabled: !formState?.dealCuration?.length,
                        InputProps: {
                          readOnly: readOnlyStates.customers,
                        },
                      }}
                      filters={{ status: `${CUSTOMER_STATUS.ACTIVE}` }}
                      disabled={!formState?.dealCuration?.length}
                      // @ts-ignore
                      hideSelectNav={editMode}
                      slotProps={{
                        PaperProps: {
                          style: {
                            maxWidth: 427,
                            minHeight: 262,
                          },
                        },
                      }}
                      {...(organizationType === ORGANIZATION_TYPE.WORKSPACE
                        ? { userType: 'WORKSPACE' }
                        : {})}
                      {...(organizationType === ORGANIZATION_TYPE.SUPER
                        ? {
                            userType: 'SUPER',
                            apiType: 'multiLevel',
                            allowParentNodeSelection: true,
                          }
                        : {})}
                      multiple
                      getOptionDisabled={getDisableCallback}
                      getOptionTooltip={row =>
                        associatedCustomerWithAttachedCampaign?.includes(
                          parseInt(row.value)
                        ) ? (
                          <Typography>
                            Customer can’t be deselected once Deal is attached
                            to any campaigns
                          </Typography>
                        ) : (
                          ''
                        )
                      }
                      loggedInOwId={loggedInOwId}
                      placeholder="Select Customers"
                      onChange={(data, reason) => {
                        if (reason === 'userAction') {
                          const customerIds = Object.keys(data).map(id => {
                            return Number(id)
                          })
                          setFormState(prev => {
                            const state = structuredClone(prev)
                            return { ...state, customers: customerIds }
                          })
                        }
                      }}
                    />
                  </Box>
                </Tooltip>
              )}
            </Col>
          </>
        )}
        <Col xs={4}>
          <FormControl
            disabled={disabledStates.dealStatus || dealAssignedToCampaign}
          >
            <Tooltip
              title={
                dealAssignedToCampaign
                  ? "Deal status can't be changed if it's assigned to any campaign or saved to group"
                  : ''
              }
              arrow
              placement="top"
            >
              <Box>
                <FormLabel
                  key="dealStatus"
                  id="radio-buttons-deal-status"
                  sx={{ mx: 12, display: 'flex', alignItems: 'center' }}
                >
                  <Typography
                    variant="label"
                    component="span"
                    sx={{ textColor: 'neutral-600' }}
                  >
                    Deal Status
                  </Typography>
                  {(!disabledStates.dealStatus || dealAssignedToCampaign) && (
                    <Typography
                      component="span"
                      sx={{ textColor: 'danger-400' }}
                    >
                      *
                    </Typography>
                  )}
                  <Tooltip
                    title={
                      dealAssignedToCampaign
                        ? ''
                        : 'Only active PMP deals can be used in Advanced campaigns'
                    }
                    arrow
                    placement="top"
                  >
                    <InfoCircle
                      sx={{ fontSize: 18, textColor: 'neutral-400', ml: 2 }}
                    />
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  defaultValue={DEAL_STATUS.ACTIVE}
                  value={formState.dealStatus}
                  title="DealStatus"
                  name="radio-buttons-group"
                  onChange={value => {
                    const status = parseInt(
                      value.target.value,
                      10
                    ) as DealStatusIds
                    setFormState(prev => {
                      const state = structuredClone(prev)
                      return { ...state, dealStatus: status }
                    })
                  }}
                  sx={{ display: 'flex', flexDirection: 'row', mx: 8 }}
                >
                  <FormControlLabel
                    value={DEAL_STATUS.ACTIVE}
                    control={
                      readOnlyStates.dealStatus ? (
                        <Radio readOnly inputProps={{ readOnly: true }} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Active"
                    disabled={
                      disabledStates.dealStatus || dealAssignedToCampaign
                    }
                  />
                  <FormControlLabel
                    value={DEAL_STATUS.INACTIVE}
                    control={
                      readOnlyStates.dealStatus ? (
                        <Radio readOnly inputProps={{ readOnly: true }} />
                      ) : (
                        <Radio />
                      )
                    }
                    label="Inactive"
                    disabled={
                      disabledStates.dealStatus || dealAssignedToCampaign
                    }
                  />
                </RadioGroup>
              </Box>
            </Tooltip>
          </FormControl>
        </Col>
        <Col xs={4}>
          <CreativeTypeFilterDropdown
            key="creativeTypes"
            value={formState.creativeTypes}
            onChange={value =>
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, creativeTypes: value }
              })
            }
            TextFieldProps={{
              sx: { width: 100 },
              InputProps: {
                readOnly: readOnlyStates.creativeTypes,
              },
            }}
          />
        </Col>
        <Col xs={4}>
          <TextField
            id={'cpm'}
            name={'cpm'}
            value={formState.cpm}
            disabled={disabledStates.cpm}
            onBlur={() => {
              const cpm = formState.cpm
              const initFormated = formatNumber(cpm as number, {
                useGrouping: false,
              })
              const formatedCpm =
                typeof cpm === 'number' && !isNaN(cpm)
                  ? parseFloat(initFormated)
                  : null
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, cpm: formatedCpm }
              })
            }}
            type="number"
            onChange={e => {
              e.persist()

              // avoid negative value
              if (parseFloat(e.target.value) < 0) {
                return
              }
              // Max limit for the CPM field
              if (parseFloat(e.target.value) > 1000) {
                return
              }
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, cpm: parseFloat(e.target.value) }
              })
            }}
            label={'CPM'}
            placeholder={readOnlyStates.cpm ? '-' : 'Enter CPM'}
            variant="outlinedDash"
            fullWidth
            InputProps={{
              startAdornment: (
                <CurrencyDollar sx={{ textColor: 'neutral-400' }} />
              ),
              readOnly: readOnlyStates.cpm,
            }}
          />
        </Col>
        <Col xs={4}>
          <TextField
            id={'description'}
            name={'description'}
            value={formState.description}
            disabled={disabledStates.description}
            InputProps={{
              readOnly: readOnlyStates.description,
            }}
            // onBlur={field.handleBlur}
            // onChange={e => field.handleChange(e.target.value)}
            onChange={e => {
              e.persist()

              if (e.target.value.trim().length > 1000) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    description: 'Description cannot exceed 1000 characters',
                  }
                })
              }
              if (e.target.value.trim().length <= 1000) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    description: '',
                  }
                })
              }
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, description: e.target.value }
              })
            }}
            InputLabelProps={{
              renderSuffix: <>{formState.description.trim().length}/1000</>,
            }}
            label={'Description'}
            rows={3}
            multiline
            placeholder={
              readOnlyStates.description ? '-' : 'Enter Deal Description'
            }
            variant="outlinedDash"
            fullWidth
            helperText={getGroupNameHelperText(
              errorState.description ? errorState.description : ''
            )}
            error={Boolean(errorState?.description?.length)}
          />
        </Col>
      </Row>
    </Container>
  )
})

PmpCreationForm.displayName = 'PmpCreationForm'
