import * as React from 'react'
import { Typography, Tabs, Tab, Box } from '@applift/factor'

import { getIcon } from '../../../../registry'
import { GROUP_TYPE_IDS } from '../../../../constants/common'

interface GroupTypeSelectionProps {
  value: any | null
  onChange?: (value: any) => void
}

function tabPanelProps(index: number) {
  return {
    id: `group_type_${index}`,
    'aria-controls': `group_type_${index}`,
  }
}

export const GroupTypeSelection = (props: GroupTypeSelectionProps) => {
  const { value, onChange } = props
  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography
          component="span"
          variant="label"
          sx={{ ml: 12, textColor: 'neutral-600' }}
        >
          Group Type
        </Typography>
        <Typography component="span" sx={{ textColor: 'danger-400', ml: 2 }}>
          *
        </Typography>
      </Box>
      <Tabs
        value={value}
        onChange={(_: React.SyntheticEvent, newValue: any) => {
          onChange?.(newValue)
        }}
        aria-label="group type tabs"
        size="medium"
        variant="pill"
        gap={8}
      >
        <Tab
          key={1}
          value={GROUP_TYPE_IDS.OPEN_EXCHANGE}
          style={{ flex: 1 }}
          icon={getIcon('InventoryOpenExchange', { fontSize: 16 })}
          iconPosition="start"
          sx={{ justifyContent: 'start' }}
          label={
            <Typography
              sx={{
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              Open Exchange
            </Typography>
          }
          {...tabPanelProps(1)}
        />
        <Tab
          key={2}
          value={GROUP_TYPE_IDS.PMP}
          style={{ flex: 1 }}
          icon={getIcon('InventoryPmpDeals', { fontSize: 16 })}
          iconPosition="start"
          sx={{ justifyContent: 'start' }}
          label={
            <Typography
              sx={{
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              PMP Deals
            </Typography>
          }
          {...tabPanelProps(2)}
        />
        <Tab
          key={3}
          value={GROUP_TYPE_IDS.CONTEXTUAL}
          style={{ flex: 1 }}
          icon={getIcon('InventoryContextual', { fontSize: 16 })}
          iconPosition="start"
          sx={{ justifyContent: 'start' }}
          label={
            <Typography
              sx={{
                display: 'flex',
                gap: 4,
                alignItems: 'center',
              }}
            >
              Contextual
            </Typography>
          }
          {...tabPanelProps(2)}
        />
      </Tabs>
    </>
  )
}
