import { ColumnDef } from '@applift/datagrid'
import { BasicCampaignListType } from '../../../../api/campaign'
import {
  CampaignNameCell,
  IconTextCell,
  RawDataCell,
  CustomerNameCell,
} from '../../../../components/CellTypes'
import { capitalize } from '@applift/factor'

export const colDef: ColumnDef<BasicCampaignListType>[] = [
  {
    accessorKey: 'campaignId',
    header: 'ID',
    size: 100,
    enableResizing: false,
    enableSorting: false,
    cell: props => {
      return <RawDataCell value={props.renderValue() as number} />
    },
  },
  {
    accessorKey: 'name',
    header: 'Campaign Name',
    size: 500,
    enableResizing: false,
    enableSorting: false,
    cell: props => {
      return (
        <CampaignNameCell
          campaignName={props.renderValue() as string}
          creativeTypeId={props.row.original.creativeTypeId}
        />
      )
    },
  },
  {
    accessorKey: 'status',
    header: 'Status',
    size: 120,
    enableResizing: false,
    enableSorting: false,
    cell: props => {
      const status = capitalize(props.renderValue() as string)
      return <IconTextCell label={status} iconName={status} iconSize={20} />
    },
  },
  {
    accessorKey: 'owId',
    header: 'Customer ID',
    size: 120,
    enableResizing: false,
    enableSorting: false,
    cell: props => {
      return <RawDataCell value={props.renderValue() as number} />
    },
  },
  {
    accessorKey: 'organizationName',
    header: 'Customer Name',
    size: 250,
    enableResizing: false,
    enableSorting: false,
    cell: props => {
      return (
        <CustomerNameCell
          customerName={props.renderValue() as string}
          avtarPath={props.row.original.organizationLogo}
        />
      )
    },
  },
]
