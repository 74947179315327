import {
  ORGANIZATION_TYPE,
  normalizeWorkspaceMultilevelData,
} from '@applift/platform'
import {
  NestedWorkspaceCustomerList,
  NormalizedCustomerList,
} from '@applift/platform/dist/models/customerList'

export const transformCustomerDataIntoCustomerInitialData = (
  customerData: NestedWorkspaceCustomerList[],
  organizationType: keyof typeof ORGANIZATION_TYPE
) => {
  const normalizedCustomerData = normalizeWorkspaceMultilevelData(customerData)
  const record: { [key: string]: NormalizedCustomerList } = {}

  normalizedCustomerData.forEach(data => {
    if (data.subRows?.length !== 0) {
      data.subRows?.forEach(subRow => {
        record[subRow.value] = subRow
      })
      if (organizationType === 'SUPER') {
        record[data.value] = data
      }
    } else {
      record[data.value] = data
    }
  })

  return record as Record<string, NormalizedCustomerList>
}
