import {
  Box,
  Tooltip,
  Typography,
  TypoTooltip,
  IconButton,
} from '@applift/factor'
import { downloadBlob } from '@applift/app-utils'
import { Download } from '@applift/icons'

import { useExchangeListingDataFromCSV } from '../../../hooks'

interface DisplayUploadedFileProps {
  uploadedFileInfo: {
    fileName: string
    fileSize: number
    formData: FormData
  }
}

export const DisplayUploadedFile = (props: DisplayUploadedFileProps) => {
  const { uploadedFileInfo } = props

  const { data } = useExchangeListingDataFromCSV(
    {
      fileName: uploadedFileInfo.fileName,
      fileSize: uploadedFileInfo.fileSize,
      formData: uploadedFileInfo.formData,
    },
    { enabled: false }
  )

  const downloadNotFound = () => {
    if (data?.unmatchedBundles.length) {
      const csvID = [
        '"ID"',
        ...data.unmatchedBundles.map(id => `"${id}"`),
      ].join('\n')
      downloadBlob(
        new Blob([csvID], { type: 'text/csv' }),
        'missing-inventories-ids.csv'
      )
    }

    if (data?.unmatchedDomains?.length) {
      const csvName = [
        '"Name"',
        ...data.unmatchedDomains.map(id => `"${id}"`),
      ].join('\n')
      downloadBlob(
        new Blob([csvName], { type: 'text/csv' }),
        'missing-inventories-names.csv'
      )
    }
  }

  return (
    <>
      <Box sx={{ border: 1, borderRadius: 4, width: 100, py: 16, px: 12 }}>
        <TypoTooltip
          arrow
          TypgraphyProps={{
            gutterBottom: false,
            sx: { display: 'block', textWeight: 'demi' },
          }}
        >
          {uploadedFileInfo.fileName}
        </TypoTooltip>
        {(data?.unmatchedBundles.length ?? 0) +
          (data?.unmatchedDomains.length ?? 0) >
        0 ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography>{`${data?.unmatchedBundles.length ?? 0} ID and ${
              data?.unmatchedDomains.length ?? 0
            } Name not found`}</Typography>
            <Tooltip
              placement="top"
              title="Download missing inventories in the file"
            >
              <IconButton onClick={downloadNotFound} sx={{ ml: 8 }}>
                <Download fontSize={16} />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
    </>
  )
}
