import * as React from 'react'
import { Add, Close, Search } from '@applift/icons'
import { useForm } from '@tanstack/react-form'
import {
  Box,
  Button,
  IconButton,
  LoadingButton,
  TextField,
  Typography,
} from '@applift/factor'

import { Route } from '../../index'
import { PgDialog } from '../PgDialog'
import { isEqual } from '../../../../utils/helper'
import { useNavigate, useSearch } from '@tanstack/react-router'
import {
  DEFAULT_PG_LIST_SORTING,
  DEFAULT_SIDEBAR_STATE,
} from '../../../../constants/pg'
import { ExchangeFilter } from '../../../../components/Filters/ExchangeFilter'
import { DealStatusFilter } from '../../../../components/Filters/DealStatusFilter'
import { SearchFilterApiRefType } from '../../../../components/Filters/SearchFilter'

interface SidebarProps {
  isLoading?: boolean
  totalRecords?: number
}

export const Sidebar = (props: SidebarProps) => {
  const { isLoading, totalRecords } = props
  const [open, setOpen] = React.useState<boolean>(false)
  const [isDirty, setIsDirty] = React.useState<boolean>(false)
  const [currentAction, setCurrentAction] = React.useState<
    'SEARCH' | 'RESET' | undefined
  >(undefined)

  const searchFilterRef = React.useRef<SearchFilterApiRefType>(null)

  const navigate = useNavigate({ from: Route.to })
  const searchParams = useSearch({ from: '/pg/' })

  React.useEffect(() => {
    if (!isLoading) {
      setCurrentAction(undefined)
    }
  }, [isLoading])

  React.useEffect(() => {
    if (searchParams.keywords) {
      const initSearchField = searchParams.keywords
        .split(',')
        .map(value => ({ label: value, error: false }))
      searchFilterRef.current?.setInitValue(initSearchField)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // No dependency as only want to run initially

  const paramValue = React.useMemo(() => {
    return {
      searchedKeyword: searchParams.keywords ?? '',
      dealStatus: searchParams.dealStatus ?? [],
      exchanges: searchParams.exchangeList ?? [],
    }
  }, [searchParams])

  const form = useForm({
    defaultValues: paramValue,
    onSubmit: async ({ value }) => {
      setCurrentAction('SEARCH')
      setIsDirty(false)
      navigate({
        to: '/pg',
        search: {
          ...searchParams,
          ...(typeof value.searchedKeyword === 'string'
            ? { keywords: value.searchedKeyword }
            : {}),
          ...(value.exchanges ? { exchangeList: value.exchanges } : {}),
          ...(value.dealStatus ? { dealStatus: value.dealStatus } : {}),
        },
      })
    },
  })

  return (
    <>
      <form
        style={{ height: '100%' }}
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
        onReset={() => {
          setCurrentAction('RESET')
          setIsDirty(false)
          form.reset()
          searchFilterRef.current?.reset()
          navigate({
            to: '/pg',
            search: {
              sortBy: DEFAULT_PG_LIST_SORTING,
            },
          })
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 100,
            width: 100,
            overflow: 'hidden',
          }}
        >
          <Box sx={{ overflow: 'auto' }}>
            <Box sx={{ px: 12, pt: 24 }}>
              <Typography
                component={'p'}
                variant="bodyMedium"
                weight="demi"
                sx={{ mb: 16 }}
              >
                {`PG ${totalRecords ? `(${totalRecords})` : ''}`}
              </Typography>
              <Button
                color="primary"
                disableRipple
                startIcon={<Add />}
                sx={{ mb: 24 }}
                onClick={() => {
                  setOpen(true)
                }}
              >
                Add New Deal
              </Button>
              <form.Field name="searchedKeyword">
                {field => (
                  <>
                    <TextField
                      placeholder="Search by Deal ID or Name"
                      InputProps={{
                        startAdornment: (
                          <Search sx={{ textColor: 'neutral-400' }} />
                        ),
                        endAdornment:
                          (field.state.value?.length ?? 0) > 0 ? (
                            <IconButton
                              onClick={() => {
                                setIsDirty(true)
                                field.handleChange('')
                              }}
                              size="small"
                            >
                              <Close />
                            </IconButton>
                          ) : null,
                      }}
                      variant="outlined"
                      value={field.state.value}
                      sx={{ ml: 'auto', width: 100 }}
                      onChange={(e: any) => {
                        setIsDirty(true)
                        const searchedValues = e.target.value
                        field.handleChange(searchedValues)
                      }}
                    />
                  </>
                )}
              </form.Field>
              <Typography weight="demi" variant="bodySmall" sx={{ mt: 24 }}>
                Narrow your search by
              </Typography>
              <form.Field name="exchanges">
                {field => (
                  <>
                    <ExchangeFilter
                      TextFieldProps={{ sx: { width: 100 } }}
                      value={field.state.value ?? []}
                      onChange={value => {
                        setIsDirty(true)
                        field.handleChange(value)
                      }}
                    />
                  </>
                )}
              </form.Field>
              <form.Field name="dealStatus">
                {field => {
                  return (
                    <>
                      <DealStatusFilter
                        TextFieldProps={{ sx: { width: 100, mt: 16 } }}
                        value={field.state.value ?? []}
                        onChange={value => {
                          setIsDirty(true)
                          const status = value.map(s => s.id)
                          field.handleChange(status)
                        }}
                      />
                    </>
                  )
                }}
              </form.Field>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 8,
              py: 16,
              px: 16,
              borderTop: 1,
              mt: 'auto',
            }}
          >
            <form.Subscribe selector={state => [state.values]}>
              {([values]) => {
                const disableReset = isEqual(values, DEFAULT_SIDEBAR_STATE)
                return (
                  <>
                    <LoadingButton
                      type="reset"
                      variant="outlined"
                      color="error"
                      loading={isLoading && currentAction === 'RESET'}
                      disabled={disableReset}
                    >
                      Reset
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      disabled={!isDirty}
                      loading={isLoading && currentAction === 'SEARCH'}
                    >
                      Search
                    </LoadingButton>
                  </>
                )
              }}
            </form.Subscribe>
          </Box>
        </Box>
      </form>
      {open && (
        <PgDialog
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </>
  )
}
