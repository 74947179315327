import {
  DataGrid,
  DataGridProps,
  SortingState,
  getSortedRowModel,
} from '@applift/datagrid'
import { Paper, sx } from '@applift/factor'
import { colDef } from './colDef'
import { useState } from 'react'

export interface CustomerTableProps extends Partial<DataGridProps> {
  data: any
  onAction: any
}

export const CustomerTable = (props: CustomerTableProps) => {
  const { data, onAction, ...other } = props
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'id', desc: true },
  ])
  return (
    <Paper elevation={2} sx={{ overflow: 'auto', mt: 24, pt: 56, height: 100 }}>
      <DataGrid
        data={data}
        columns={colDef}
        hideHeader
        hideFooter
        showCellRightBorder
        showColumnRightBorder
        headerHeight={40}
        rowHeight={40}
        onAction={onAction}
        state={{ sorting }}
        onSortingChange={setSorting}
        getSortedRowModel={getSortedRowModel()}
        classes={{
          root: sx({
            borderRadius: 0,
            borderTop: 1,
            borderLeft: 1,
            borderRight: 1,
            borderBottom: 1,
            borderTopColor: 'neutral-300',
          }),
        }}
        {...other}
      />
    </Paper>
  )
}
