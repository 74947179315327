import { Box, Col, Row, TypoTooltip, Typography } from '@applift/factor'

import styles from './styles.module.scss'

interface BasicInfoList {
  key: string
  value: string
}

interface BasicInfoListProps {
  data: BasicInfoList[]
  /**
   * Width in pixel
   * @default 250
   */
  infoValueMaxWidth?: number
}

export const BasicInfoList = (props: BasicInfoListProps) => {
  const { data, infoValueMaxWidth = 250 } = props
  const getTitle = (title: string) => (
    <Typography
      variant="bodySmall"
      component="label"
      sx={{
        mr: 2,
        flexShrink: 0,
        textColor: 'neutral-500',
        flexWrap: 'nowrap',
      }}
    >
      {title}
    </Typography>
  )

  const getValue = (val: string | number | JSX.Element) => (
    <TypoTooltip
      style={{ maxWidth: `${infoValueMaxWidth}px` }}
      placement="top"
      arrow
      TypgraphyProps={{
        gutterBottom: false,
        sx: { display: 'block', mr: 4, flexShrink: 0 },
      }}
    >
      {val}
    </TypoTooltip>
  )
  return (
    <Row
      sx={{
        width: 100,
        flexWrap: 'nowrap',
        overflowX: 'hidden',
      }}
      xs={{ gutterSize: 4 }}
    >
      {data.map((item, index) => (
        <Col key={index} className={styles.infoItem} xs={'auto'}>
          <Box sx={{ display: 'flex' }}>
            {getTitle(`${item.key}:`)}
            {getValue(item.value)}
          </Box>
        </Col>
      ))}
    </Row>
  )
}
