import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { useMutation } from '@tanstack/react-query'
import { ORGANIZATION_TYPE, useAppContext } from '@applift/platform'

import {
  PmpFormState,
  PmpCreationForm,
  PmpCreationApiRef,
  DEFAULT_PMP_FORM_STATE,
} from './PmpCreationForm'
import { queryClient } from '../../../../cache'
import { createPmpDeal } from '../../../../api'
import { DEAL_CURATION, DEAL_STATUS } from '../../../../constants/filters'

interface PmpDialogProps {
  onClose: () => void
}

export const PmpDialog = (props: PmpDialogProps) => {
  const { onClose } = props

  const [formState, setFormState] = React.useState<PmpFormState>(
    DEFAULT_PMP_FORM_STATE
  )
  const pmpFormRef = React.useRef<PmpCreationApiRef>(null)

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType
  const owId = ctx.appMeta.loggedInOwId

  const createPmpMutation = useMutation(createPmpDeal, {
    mutationKey: ['createPmpDeal'],
    onSuccess: () => {
      queryClient.resetQueries({
        predicate: (query: any) => {
          return query.queryKey?.[0]?.scope === 'getPmpList'
        },
      })
      enqueueSnackbar('PMP Deal added successfully', {
        variant: 'success',
      })
      onClose()
    },
    onError: e => {
      console.log({ e }) // dealId + exchangeId
      // @ts-ignore
      const errorFields = e?.errorObjects?.[0]?.field as string
      // @ts-ignore
      const errorMessage = e?.errorObjects?.[0]?.error as string

      const errorFieldArray = errorFields.split('+').map(field => field.trim())
      if (
        errorFieldArray.includes('dealId') &&
        errorFieldArray.includes('exchangeId')
      ) {
        pmpFormRef.current?.setError({
          dealId:
            'Deal ID already exists for selected exchange. Use a different Deal ID or Exchange',
        })
      } else {
        enqueueSnackbar(
          errorMessage.length
            ? errorMessage
            : 'Something went wrong. Please try after some time.',
          {
            variant: 'error',
          }
        )
      }
    },
  })

  const onSubmit = async (value: PmpFormState) => {
    const commonParams = {
      dealId: value.dealId,
      dealName: value.dealName,
      description: value.description,
      cpm: value.cpm as number,
      creativeTypes: value.creativeTypes.map(creative => creative.id),
      exchangeId: value.exchange[0]?.value as number,
      active: value.dealStatus === DEAL_STATUS.ACTIVE,
    }

    const workspaceParams = {
      dealCurationTypeId: value?.dealCuration?.[0]?.id,
      ...(value?.dealCuration?.[0]?.id === DEAL_CURATION.TAILORED
        ? { assignToCustomers: value.customers }
        : {}),
    }

    const mutationParams = {
      ...commonParams,
      ...(orgType === ORGANIZATION_TYPE.WORKSPACE ||
      orgType === ORGANIZATION_TYPE.SUPER
        ? workspaceParams
        : {}),
    }

    createPmpMutation.mutate(mutationParams)
  }

  const isAllRequiredFilled = pmpFormRef.current?.isAllRequiredFilled()

  const disableSubmit = !isAllRequiredFilled || pmpFormRef.current?.hasError()

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <Typography weight="demi">Add Deal</Typography>
      </DialogTitle>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          if (formState) {
            onSubmit(formState)
          }
        }}
      >
        <DialogContent dividers>
          <PmpCreationForm
            ref={pmpFormRef}
            organizationType={orgType}
            loggedInOwId={owId}
            onChange={state => {
              setFormState(state)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={disableSubmit}
            loading={createPmpMutation.isLoading}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
