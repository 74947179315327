import axios, { AxiosError, AxiosResponse } from 'axios'
import { QueryFunctionContext } from '@tanstack/react-query'

import { getInstance } from './instance'
import { WithRecordResponse, WithResponse } from '../models/Response'
import {
  CampaignCreativeErrorResponse,
  CampaignCreativeTypeResponse,
  DealStatusResponse,
  ExchangeFilterType,
  InventoryTypes,
  PaymentTypeResponse,
  TrafficTypes,
  DealCurationTypeResponse,
} from '../models/Filters'

export const getOptionsKey = {
  keys: (scope: string) => [{ scope: scope } as const],
}

interface CreativeTypeResponse {
  name: string
  id: number
}

interface ExchangeResponse {
  name: string
  id: number
}

export type Option<T = any> = {
  label: string
  value: T
  uncountable?: boolean
  original?: T
}

export const getCreativeTypes = async ({
  signal,
}: QueryFunctionContext<ReturnType<(typeof getOptionsKey)['keys']>>) => {
  try {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })
    const response: AxiosResponse<WithRecordResponse<CreativeTypeResponse[]>> =
      await getInstance().get('/v3/master/rtb-creative-types', {
        cancelToken: source.token,
      })
    return response.data.data.data.map(i => ({
      label: i.name,
      value: i.id,
    })) as Option<number>[]
  } catch (e) {
    throw Promise.reject('Error while get creative types')
  }
}

export const getCampaignCreativeTypes = async ({
  signal,
}: QueryFunctionContext<any>): Promise<CampaignCreativeTypeResponse> => {
  try {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })
    const response: AxiosResponse<WithResponse<CampaignCreativeTypeResponse>> =
      await getInstance().get('/v3/crt/master/static/creative-types', {
        cancelToken: source.token,
      })
    const creativeIdMapping = response?.data?.data?.creativeTypeList?.reduce(
      (acc, curr) => {
        acc[`${curr.id}`] = curr.name
        return acc
      },
      {} as { [key: number]: string }
    )

    return { ...response.data.data, creativeIdMapping }
  } catch (e) {
    throw (e as AxiosError)?.response?.data as CampaignCreativeErrorResponse
  }
}

interface ExchangeResponse {
  exchangeList: ExchangeFilterType[]
  exchangeIdMapping: { [key: number]: string }
}

export const getExchanges = async ({
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getOptionsKey)['keys']>
>): Promise<ExchangeResponse> => {
  try {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })
    const response: AxiosResponse<WithRecordResponse<ExchangeResponse[]>> =
      await getInstance().get('/v3/master/exchanges', {
        cancelToken: source.token,
      })

    const exchangeList = response.data.data.data.map(i => ({
      label: i.name,
      value: i.id,
    })) as ExchangeFilterType[]
    const exchangeIdMapping = exchangeList.reduce(
      (acc, curr) => {
        // @ts-ignore
        acc[`${curr.value}`] = curr.label
        return acc
      },
      {} as { [key: number]: string }
    )
    return { exchangeList, exchangeIdMapping } as ExchangeResponse
  } catch (e) {
    Promise.reject('Error while get exchanges')
    throw Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getTrafficTypes = async () => {
  try {
    const response: AxiosResponse<WithRecordResponse<TrafficTypes[]>> =
      await getInstance().get(`/v3/master/traffic-types`)

    return {
      ...response.data.data,
      data: response.data.data.data.map(item => ({
        label: item.name,
        value: item.id,
      })),
    }
  } catch (e) {
    throw Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getPaymentTypes = async (): Promise<PaymentTypeResponse> => {
  try {
    const response: AxiosResponse<WithResponse<PaymentTypeResponse>> =
      await getInstance().get('/v3/fa/static/pg/payment-type')

    const paymentIdMapping = response.data.data.pgPaymentTypes.reduce(
      (acc, curr) => {
        acc[`${curr.id}`] = curr.label
        return acc
      },
      {} as { [key: number]: string }
    )
    return { ...response.data.data, paymentIdMapping }
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}

export const getDealStatus = async (): Promise<DealStatusResponse> => {
  try {
    const response: AxiosResponse<WithResponse<DealStatusResponse>> =
      await getInstance().get(`/v3/inv/static/deal-status`)

    return response.data.data
  } catch (e) {
    throw Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getDealCuration = async (): Promise<DealCurationTypeResponse> => {
  try {
    const response: AxiosResponse<WithResponse<DealCurationTypeResponse>> =
      await getInstance().get(`/v3/master/deal-curation-type`)

    const dealCurationIdMapping = response.data.data.data.reduce(
      (acc, curr) => {
        acc[`${curr.id}`] = curr.name
        return acc
      },
      {} as { [key: number]: string }
    )

    return { ...response.data.data, dealCurationIdMapping }
  } catch (e) {
    throw Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getInventoryTypes = async () => {
  try {
    const response: AxiosResponse<WithRecordResponse<InventoryTypes[]>> =
      await getInstance().get(`/v3/master/inventory-types`)

    return {
      ...response.data.data,
      data: response.data.data.data.map(item => ({
        label: item.name,
        value: item.id,
      })),
    }
  } catch (e) {
    throw Promise.reject((e as AxiosError)?.response?.data)
  }
}
