export const ACTION = {
  EDIT_PMP_DEAL: 'EDIT_PMP_DEAL',
  SAVE_TO_NEW_GROUP: 'SAVE_TO_NEW_GROUP',
  ASSIGN_TO_CAMPAIGN: 'ASSIGN_TO_CAMPAIGN',
  OPEN_CAMPAIGN_DIALOG: 'OPEN_CAMPAIGN_DIALOG',
  OPEN_CUSTOMER_DIALOG: 'OPEN_CUSTOMER_DIALOG',
  SAVE_TO_EXISTING_GROUP: 'SAVE_TO_EXISTING_GROUP',
}

export type ActionNameType = keyof typeof ACTION
