import { Box, Typography } from '@applift/factor'

export interface EmptyFeaturesPageProps {
  icon: JSX.Element
  title: string
  description: string
  className?: string
}

export const EmptyPageFeatures = ({
  icon,
  title,
  description,
  className,
}: EmptyFeaturesPageProps) => {
  return (
    <Box className={className}>
      {icon}
      <Typography
        component="h5"
        variant="bodyMedium"
        weight="demi"
        sx={{ mb: 8 }}
      >
        {title}
      </Typography>
      <Typography variant="bodySmall" gutterBottom={false}>
        {description}
      </Typography>
    </Box>
  )
}
