import { AxiosResponse } from 'axios'

import { getInstance } from './instance'
import { WithRecordResponse } from '../models/Response'
import { Timezone, TimezoneApiResponse } from '../models/Timezone'

export const Timezones = async () => {
  try {
    const response: AxiosResponse<WithRecordResponse<TimezoneApiResponse[]>> =
      await getInstance().get('/v3/master/timezones')

    return response.data.data.data.map(one => ({
      id: one.id,
      label: one.label,
      value: one.name,
    })) as Timezone[]
  } catch (e) {
    return []
  }
}
