import * as React from 'react'
import {
  Box,
  Col,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Row,
  TextField,
  Tooltip,
  Typography,
} from '@applift/factor'
import { InfoCircle } from '@applift/icons'

import { ExchangeFilter } from '../../../../components/Filters'
import {
  CommonFilter,
  DealStatusIds,
  PaymentTypeObject,
} from '../../../../models/Filters'
import { PaymentTypeFilter } from '../../../../components/Filters/PaymentTypeFilter'
import { getChangedKeys, isEqual } from '../../../../utils/helper'
import { DEAL_STATUS } from '../../../../constants/filters'

export const DEFAULT_PG_FORM_STATE = {
  dealId: '',
  dealName: '',
  exchange: [] as CommonFilter[],
  dealStatus: DEAL_STATUS.ACTIVE as DealStatusIds,
  paymentTo: [] as PaymentTypeObject[],
  description: '',
}

export const DEFAULT_DISABLED_PG_STATE = {
  dealId: false,
  dealName: false,
  dealStatus: false,
  description: false,
}

export interface DisabledPgState {
  dealId?: boolean
  dealName?: boolean
  dealStatus?: boolean
  cpm?: boolean
  description?: boolean
}

export type PgFormState = typeof DEFAULT_PG_FORM_STATE

export interface PgCreationFormProps {
  initValue?: PgFormState
  disabledStates?: DisabledPgState
  onChange?: (formState: PgFormState) => void
  dealAssignedToCampaign?: boolean
}

interface ErrorState {
  dealId?: string
  dealName?: string
  description?: string
}

const defaultErrorState = {
  dealId: '',
  dealName: '',
  description: '',
}

export interface PgCreationApiRef {
  getCurrentState: () => PgFormState
  setError: (error: ErrorState) => void
  /**
   * @returns {boolean} Returns `true` if there is some error, else `false`. it checks for only dealId, dealName and description field
   */
  hasError: () => boolean
  /**
   * compare with the initValue passed and the current form state
   * @returns {boolean} Returns `true` if there is some change, else `false`.
   */
  hasChange: () => boolean
  isAllRequiredFilled: () => boolean
  getChangedState: () => Partial<PgFormState>
}

export const PgCreationForm = React.forwardRef<
  PgCreationApiRef,
  PgCreationFormProps
>((props, ref) => {
  const {
    initValue = DEFAULT_PG_FORM_STATE,
    onChange,
    disabledStates = DEFAULT_DISABLED_PG_STATE,
    dealAssignedToCampaign,
  } = props

  const [formState, setFormState] = React.useState<PgFormState>(initValue)
  const [errorState, setErrorState] =
    React.useState<ErrorState>(defaultErrorState)

  React.useEffect(() => {
    // setting forstate againg if initvalue changed
    setFormState(initValue)
  }, [initValue])

  const hasError = Boolean(
    Object.values(errorState).filter(error => Boolean(error)).length
  )
  const hasChange = !isEqual(formState, initValue)

  React.useImperativeHandle(ref, () => ({
    getCurrentState: () => formState,
    hasError: () => hasError,
    setError: error => {
      setErrorState(prev => {
        return { ...prev, ...error }
      })
    },
    hasChange: () => {
      return hasChange
    },
    isAllRequiredFilled: () => {
      return Boolean(
        formState?.dealId &&
          formState?.dealName &&
          formState.exchange.length &&
          formState.dealStatus &&
          formState.paymentTo.length
      )
    },
    getChangedState: () => getChangedKeys<PgFormState>(initValue, formState),
  }))

  React.useEffect(() => {
    onChange?.(formState)
  }, [formState, onChange])

  const getGroupNameHelperText = (errors: string) =>
    errors.length ? (
      <Typography variant="label" color="error">
        {errors}
      </Typography>
    ) : null

  return (
    <>
      <Row sx={{ mb: 24 }}>
        <Col xs={4}>
          <TextField
            id={'dealId'}
            name={'dealId'}
            value={formState.dealId}
            disabled={disabledStates?.dealId}
            onChange={e => {
              if (!e.target.value.trim()) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealId: 'Deal ID is required',
                  }
                })
              }
              if (e.target.value.trim().length > 500) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealId: 'Deal ID cannot exceed 500 characters',
                  }
                })
              }
              // removeing error
              if (
                e.target.value.trim().length > 0 &&
                e.target.value.trim().length <= 500
              ) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealId: '',
                  }
                })
              }
              // setting value
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, dealId: e.target.value }
              })
            }}
            required
            label={'Deal ID'}
            InputLabelProps={{
              renderSuffix: <>{formState.dealId.trim().length}/500</>,
            }}
            placeholder="Enter Deal ID"
            variant="outlinedDash"
            fullWidth
            helperText={getGroupNameHelperText(
              errorState?.dealId ? errorState?.dealId : ''
            )}
            error={Boolean(errorState?.dealId?.length)}
          />
        </Col>
        <Col xs={4}>
          <TextField
            id={'dealName'}
            name={'dealName'}
            value={formState.dealName}
            required
            onChange={e => {
              // setting error
              if (!e.target.value.trim()) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealName: 'Deal name is required',
                  }
                })
              }
              if (e.target.value.trim().length > 500) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealName: 'Deal Name cannot exceed 500 characters',
                  }
                })
              }
              // removing error
              if (
                e.target.value.trim().length > 0 &&
                e.target.value.trim().length <= 500
              ) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    dealName: '',
                  }
                })
              }
              // setting value
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, dealName: e.target.value }
              })
            }}
            InputLabelProps={{
              renderSuffix: <>{formState.dealName.trim().length}/500</>,
            }}
            label={'Deal Name'}
            placeholder="Enter Deal name"
            variant="outlinedDash"
            fullWidth
            helperText={getGroupNameHelperText(
              errorState?.dealName ? errorState?.dealName : ''
            )}
            error={Boolean(errorState?.dealName?.length)}
          />
        </Col>
        <Col xs={4}>
          <ExchangeFilter
            key="exchange"
            TextFieldProps={{ sx: { width: 100 } }}
            value={formState.exchange}
            multiSelect={false}
            required
            onChange={value =>
              setFormState(prev => {
                if (errorState.dealId?.includes('already exists')) {
                  // removing the deal ID and Exchange combination error when Exchange is getting updated
                  setErrorState(prev => {
                    return {
                      ...prev,
                      dealId: '',
                    }
                  })
                }
                const state = structuredClone(prev)
                return { ...state, exchange: value }
              })
            }
          />
        </Col>
      </Row>
      <Row sx={{ mb: 24, gapRow: 24 }}>
        <Col xs={4}>
          <FormControl
            disabled={disabledStates.dealStatus || dealAssignedToCampaign}
          >
            <Tooltip
              title={
                dealAssignedToCampaign
                  ? 'Deal status can’t be changed if the deal is assigned to any campaign'
                  : ''
              }
              arrow
              placement="top"
            >
              <Box>
                <FormLabel
                  key="dealStatus"
                  id="radio-buttons-deal-status"
                  sx={{ mx: 12, display: 'flex', alignItems: 'center' }}
                >
                  <Typography
                    variant="label"
                    component="span"
                    sx={{ textColor: 'neutral-600' }}
                  >
                    Deal Status
                  </Typography>
                  <Typography component="span" sx={{ textColor: 'danger-400' }}>
                    *
                  </Typography>
                  <Tooltip
                    title={
                      dealAssignedToCampaign
                        ? ''
                        : 'Only active PG deals can be used in PG campaigns'
                    }
                    arrow
                    placement="top"
                  >
                    <InfoCircle
                      sx={{ fontSize: 18, textColor: 'neutral-400', ml: 2 }}
                    />
                  </Tooltip>
                </FormLabel>
                <RadioGroup
                  defaultValue={DEAL_STATUS.ACTIVE}
                  value={formState.dealStatus}
                  title="dealStatus"
                  name="radio-buttons-group"
                  onChange={value => {
                    const status = parseInt(
                      value.target.value,
                      10
                    ) as DealStatusIds
                    setFormState(prev => {
                      const state = structuredClone(prev)
                      return { ...state, dealStatus: status }
                    })
                  }}
                  sx={{ ml: 8, display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    label="Active"
                    control={<Radio />}
                    value={DEAL_STATUS.ACTIVE}
                    disabled={
                      disabledStates.dealStatus || dealAssignedToCampaign
                    }
                  />
                  <FormControlLabel
                    label="Inactive"
                    control={<Radio />}
                    value={DEAL_STATUS.INACTIVE}
                    disabled={
                      disabledStates.dealStatus || dealAssignedToCampaign
                    }
                  />
                </RadioGroup>
              </Box>
            </Tooltip>
          </FormControl>
        </Col>
        <Col xs={4}>
          <Tooltip
            title={
              dealAssignedToCampaign
                ? 'Payment to method can’t be changed if the Deal is assigned to any campaign'
                : ''
            }
            arrow
            placement="top"
          >
            <Box>
              <PaymentTypeFilter
                value={formState.paymentTo}
                TextFieldProps={{ sx: { width: 100 } }}
                required
                disabled={dealAssignedToCampaign}
                onChange={value => {
                  setFormState(prev => {
                    const state = structuredClone(prev)
                    return { ...state, paymentTo: value }
                  })
                }}
              />
            </Box>
          </Tooltip>
        </Col>
        <Col xs={4}>
          <TextField
            id={'description'}
            name={'description'}
            disabled={disabledStates.description}
            value={formState.description}
            onChange={e => {
              if (e.target.value.trim().length > 1000) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    description: 'Description cannot exceed 1000 characters',
                  }
                })
              }
              if (e.target.value.trim().length <= 1000) {
                setErrorState(prev => {
                  return {
                    ...prev,
                    description: '',
                  }
                })
              }
              setFormState(prev => {
                const state = structuredClone(prev)
                return { ...state, description: e.target.value }
              })
            }}
            InputLabelProps={{
              renderSuffix: <>{formState.description.trim().length}/1000</>,
            }}
            label={'Description'}
            rows={3}
            multiline
            placeholder="Enter Deal Description"
            variant="outlinedDash"
            fullWidth
            helperText={getGroupNameHelperText(
              errorState.description ? errorState.description : ''
            )}
            error={Boolean(errorState?.description?.length)}
          />
        </Col>
      </Row>
    </>
  )
})

PgCreationForm.displayName = 'PgCreationForm'
