import { TypographyProps } from '@applift/factor'

import { usePaymentTypes } from '../../../../hooks'
import { RawDataCell } from '../../../../components/CellTypes'

interface PaymentTypeCellProps {
  paymentId: number
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const PaymentTypeCell = (props: PaymentTypeCellProps) => {
  const { paymentId, textTypographyProps } = props
  const { data: paymentData } = usePaymentTypes()
  const paymentType = paymentData?.paymentIdMapping?.[paymentId]
  return paymentType?.length ? (
    <RawDataCell
      textTypographyProps={textTypographyProps}
      value={paymentType}
    />
  ) : (
    '-'
  )
}
