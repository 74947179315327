import { Row, Col, Container, Box } from '@applift/factor'
import { BlocklistGrid } from './BlocklistGrid'
import { BlocklistResponse } from '../../../../models/BlocklistType'
import { ActionPanel } from './ActionPanel'
import { RowSelectionState } from '@applift/datagrid'
import React from 'react'
import { MAX_BLOCK_INVENTORY_DISPLAY_COUNT } from '../../../../constants/common'

interface BlocklistGridWrapperProps {
  data: BlocklistResponse[]
  onFetchRows?: () => void
  isLoading: boolean
  setSearchField: React.Dispatch<React.SetStateAction<string>>
  searchField: string
  rowSelection: RowSelectionState
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  filteredRecords?: number
  isError?: boolean
  totalUniqueCount: number
}

export const BlocklistGridWrapper = (props: BlocklistGridWrapperProps) => {
  const {
    data,
    onFetchRows,
    isLoading,
    searchField,
    setSearchField,
    rowSelection,
    setRowSelection,
    filteredRecords,
    isError,
    totalUniqueCount,
  } = props

  const [globalSelectAllEnabled, toggleGlobalSelectAllStatus] =
    React.useState<boolean>(false)

  const overlay = React.useMemo(() => {
    if (!filteredRecords && !isLoading && searchField) {
      return 'noResult'
    } else if (isError) {
      return 'error'
    }
    return undefined
  }, [filteredRecords, isError, isLoading, searchField])

  const setRowSelectionWrapper = (_value: any) => {
    const value = typeof _value === 'function' ? _value() : _value

    const isRemovalAction =
      Object.keys(value).length < Object.keys(rowSelection).length

    // logic to remove all the selection of duplicates once an item's selection is removed
    if (isRemovalAction) {
      toggleGlobalSelectAllStatus(false)
      const uniqueIdOfRemovedItem = Object.keys(rowSelection).find(
        key => !(key in value)
      ) as string
      const inventoryIdOfRemovedItem = data.find(
        item => item._id === uniqueIdOfRemovedItem
      )?.id
      const associatedUniqueIds = data
        .filter(item => item.id === inventoryIdOfRemovedItem)
        .map(filteredItem => filteredItem._id)
      associatedUniqueIds.forEach(
        associatedUniqueId => delete value[associatedUniqueId]
      )

      return setRowSelection(value)
    }

    // logic to select all the duplicates once an item is selected
    const selectedItemsInventoryIds = data
      .filter(item => Object.keys(value).includes(item._id))
      .map(filteredItem => filteredItem.id)

    const newSelection: RowSelectionState = {}
    selectedItemsInventoryIds.forEach(inventoryId => {
      data
        .filter(item => item.id === inventoryId)
        .map(filteredItem => filteredItem._id)
        .forEach(newUniqueId => {
          newSelection[newUniqueId] = true
        })
    })

    setRowSelection(newSelection)
  }

  if (
    globalSelectAllEnabled &&
    data.length !== Object.keys(rowSelection ?? {}).length
  ) {
    const newSelection: RowSelectionState = {}
    data.forEach(item => {
      newSelection[item._id] = true
    })
    setRowSelection(newSelection)
  }

  const isLocalSelectAll =
    Boolean(data.length) &&
    data.length === Object.keys(rowSelection ?? {}).length &&
    !globalSelectAllEnabled &&
    !isLoading &&
    !isError

  const inventoryIds = React.useMemo(() => {
    const selectedRowIdsSet: Set<number> = new Set()

    // Populate the set with IDs from rowSelection
    Object.keys(rowSelection).forEach(selectedId => {
      const rowData = data.find(item => item._id === selectedId)
      if (rowData?.id) {
        selectedRowIdsSet.add(rowData.id)
      }
    })
    return Array.from(selectedRowIdsSet)
  }, [rowSelection, data])

  const countToShow =
    globalSelectAllEnabled && totalUniqueCount
      ? Math.min(MAX_BLOCK_INVENTORY_DISPLAY_COUNT, totalUniqueCount)
      : inventoryIds.length

  const clearSelection = () => setRowSelection({})

  return (
    <Container
      sx={{
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        flexGrow: 1,
        width: 100,
      }}
    >
      <Row sx={{ height: 100, py: 24 }}>
        <Box
          sx={{
            flexGrow: 1,
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
          }}
        >
          <ActionPanel
            isTableLoading={isLoading}
            searchField={searchField}
            setSearchField={setSearchField}
            rowSelection={rowSelection}
            clearSelection={clearSelection}
            data={data}
            filteredRecords={filteredRecords}
            isLocalSelectAll={isLocalSelectAll}
            toggleGlobalSelectAllStatus={toggleGlobalSelectAllStatus}
            globalSelectAllEnabled={globalSelectAllEnabled}
            selectedCount={countToShow}
            totalUniqueCount={totalUniqueCount}
          />
          <Row sx={{ flexGrow: 1 }}>
            <Col sx={{ p: 0 }}>
              <BlocklistGrid
                isLoading={isLoading}
                data={data}
                onFetchRows={onFetchRows}
                rowSelection={rowSelection}
                overlay={overlay}
                filteredRecords={filteredRecords}
                setRowSelectionWrapper={setRowSelectionWrapper}
              />
            </Col>
          </Row>
        </Box>
      </Row>
    </Container>
  )
}
