import { CommonFilter } from '../models/Filters'

export const DEFAULT_EXCHANGE_SIDEBAR_STATE = {
  searchedInventories: '',
  creativeTypes: [] as CommonFilter[],
  exchanges: [] as CommonFilter[],
  country: [] as CommonFilter[],
  deviceTypes: [] as CommonFilter[],
  trafficTypes: [] as CommonFilter[],
  inventoryTypes: [] as CommonFilter[],
  creativeSizes: [] as { label: string; value: string }[],
  adCategories: [] as CommonFilter[],
}

export const DEFAULT_NUMBER_OF_LIST_RECORDS = 200

export const OPEN_EXCHANGE_INVENTORY_ID = 1

export const MAX_INVENTORY_DISPLAY_COUNT = 10000
