import * as React from 'react'
import {
  Chip,
  Col,
  IconButton,
  LoadingButton,
  Row,
  TextField,
  enqueueSnackbar,
} from '@applift/factor'
import { Close, Search } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'
import { RowSelectionState } from '@applift/datagrid'
import { useAppContext } from '@applift/platform'

import {
  useDownloadPMPGroupInventoriesMutation,
  useRemoveInventoriesMutation,
} from '../../../../../hooks'
import { ConfirmationDialog } from '../../../../../components/Dialogs'
import { PmpListResponse } from '../../../../../models/Pmp'
import { DownloadTableAction } from '../../../../../components/DownloadTableAction'
import { GROUP_TYPE_IDS } from '../../../../../constants/common'
import {
  GroupListTableResponse,
  GroupRemovalRequest,
  SidebarInventoryGroup,
} from '../../../../../models/Group'

import styles from './styles.module.scss'

interface PmpGroupGridActionPanelProps {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  totalRecords: number
  isTableLoading: boolean
  rowSelection: RowSelectionState
  selectedItems?: PmpListResponse[]
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  groupDetail: SidebarInventoryGroup
  overlay?: 'error' | 'noResult' | 'noRows'
}

export const PmpGroupGridActionPanel = (
  props: PmpGroupGridActionPanelProps
) => {
  const {
    search,
    overlay,
    setSearch,
    groupDetail,
    isTableLoading,
    rowSelection,
    totalRecords,
    setRowSelection,
  } = props

  const { mutate: download, isLoading: isDownloadInProgress } =
    useDownloadPMPGroupInventoriesMutation()

  const queryClient = useQueryClient()

  const organizationName = useAppContext().appMeta.organizationName

  const [showRemoveDialog, toggleRemoveDialogVisibility] =
    React.useState<boolean>(false)

  const selectedItemsCount = React.useMemo(
    () => (rowSelection ? Object.keys(rowSelection)?.length : 0),
    [rowSelection]
  )
  const chipLabel = React.useMemo(() => {
    return `${selectedItemsCount} out of ${totalRecords} selected`
  }, [selectedItemsCount, totalRecords])

  const deleteDialogText =
    'Are you sure you want to remove the selected deals from the group?'

  const onSettled = () => {
    setRowSelection({})
    toggleRemoveDialogVisibility(false)
  }

  const onSuccess = (_: any, payload: GroupRemovalRequest) => {
    enqueueSnackbar({
      message: `Selected deals removed from the group successfully.`,
      variant: 'success',
    })

    queryClient.setQueriesData(
      {
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getGroupList',
      },
      (oldData: { pages: GroupListTableResponse[] } | undefined) => {
        if (!oldData) return oldData

        const updatedPages = oldData.pages.map(page => {
          const newData = page.inventoryGroupList.map(item => {
            if (item.id === payload?.groupId) {
              return {
                ...item,
                count: item.count - (payload.dealIds?.length ?? 0),
              }
            }
            return item
          })
          return {
            ...page,
            inventoryGroupList: newData,
          }
        })

        return {
          ...oldData,
          pages: updatedPages,
        }
      }
    )

    queryClient.resetQueries({
      predicate: (query: any) =>
        query.queryKey?.[0]?.scope === 'getGroupPmpList',
    })
  }

  const removeMutation = useRemoveInventoriesMutation(onSettled, onSuccess)

  const onRemoveClick = () => {
    const ids = Object.keys(rowSelection).map(id => parseInt(id))
    removeMutation.mutate({
      dealIds: ids,
      groupId: groupDetail.id,
      groupTypeId: GROUP_TYPE_IDS.PMP,
    })
  }

  return (
    <Row
      className={styles.actionRow}
      sx={{ px: 24, alignItems: 'center' }}
      xs={{ gutterSize: 0, gutterDirection: 'x' }}
    >
      {overlay !== 'noRows' && (
        <>
          {Boolean(selectedItemsCount) &&
            selectedItemsCount > 0 &&
            !isTableLoading && (
              <Col xs={7} sx={{ display: 'flex', alignItems: 'center' }}>
                <Chip
                  size="small"
                  label={chipLabel}
                  color="secondary"
                  onDelete={() => {
                    setRowSelection({})
                  }}
                />
                <LoadingButton
                  size="small"
                  color="error"
                  variant="outlined"
                  sx={{ marginLeft: 8 }}
                  onClick={() => toggleRemoveDialogVisibility(true)}
                >
                  Remove From Group
                </LoadingButton>
              </Col>
            )}
          <Col
            xs={5}
            sx={{
              display: 'flex',
              justifyContent: 'end',
              alignItems: 'center',
              ml: 'auto',
              my: 16,
            }}
          >
            <TextField
              placeholder="Search by Deal ID, Name or Description"
              InputProps={{
                startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
                endAdornment:
                  search?.length > 0 ? (
                    <IconButton
                      onClick={() => {
                        setRowSelection({})
                        setSearch('')
                      }}
                      size="small"
                    >
                      <Close />
                    </IconButton>
                  ) : null,
              }}
              style={{ maxWidth: '320px' }}
              variant="outlinedDash"
              value={search}
              sx={{ ml: 'auto', width: 100, mr: 8 }}
              onChange={(e: any) => {
                setSearch(e.target.value)
              }}
            />
            <DownloadTableAction
              downloadfile={fileType =>
                download({
                  groupId: groupDetail.id,
                  groupName: groupDetail.name,
                  organizationName,
                  fileType,
                })
              }
              isDownloading={isDownloadInProgress}
            />
          </Col>
        </>
      )}
      {showRemoveDialog && (
        <ConfirmationDialog
          onClose={() => toggleRemoveDialogVisibility(false)}
          onConfirmation={onRemoveClick}
          isActionLoading={removeMutation.isLoading}
          primaryBtnText="Remove"
          theme="error"
          title="Remove Selected Deals"
          dialogContent={deleteDialogText}
        />
      )}
    </Row>
  )
}
