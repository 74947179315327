import * as React from 'react'
import {
  DataGrid,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useGridApiRef,
} from '@applift/datagrid'
import { sx } from '@applift/factor'
import { ClipboardRemove } from '@applift/icons'
import { SearchData } from '@applift/illustrations'

import { NoResultsOverlay } from '../../../../components/GridOverLay/index'
import { contextualColDef } from './contextualColDef'

interface ContextualGridProps {
  data: {
    id: number
    name: string
    type: string
  }[]
  setRowSelection: (value: React.SetStateAction<RowSelectionState>) => void
  setSorting: (value: React.SetStateAction<SortingState>) => void
  sorting: SortingState
  rowSelection: RowSelectionState
}

export const ContextualGrid = (props: ContextualGridProps) => {
  const { data, setRowSelection, setSorting, sorting, rowSelection } = props

  const [height, setHeight] = React.useState(0)

  const apiRef = useGridApiRef()

  const onSortingChangeWrapper = (value: any) => {
    setRowSelection({})
    setSorting(value)
  }

  const setRowSelectionWrapper = (_value: any) => {
    const value = typeof _value === 'function' ? _value() : _value
    setRowSelection(value)
  }

  React.useEffect(() => {
    apiRef.current.subscribeEvent('viewportInnerSizeChange', params => {
      setHeight(params.height)
    })
  }, [apiRef])

  return (
    <DataGrid
      apiRef={apiRef}
      data={data}
      columns={contextualColDef}
      overlay={data.length === 0 ? 'noResult' : undefined}
      getRowId={row =>
        row && typeof row['id'] === 'number' ? `${row['id']}` : row['id']
      }
      state={{
        sorting,
        rowSelection,
        columnOrder: ['__check__', 'keyword', 'url'],
      }}
      hideHeader
      components={{ NoResultsOverlay }}
      componentsProps={{
        noResultsOverlay: {
          text: 'No results found',
          subText: 'We can’t find any items matching your search.',
          illustration:
            height <= 250 ? (
              <ClipboardRemove
                sx={{ textColor: 'neutral-400', mb: 8 }}
                fontSize={36}
              />
            ) : (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  width: height <= 400 ? 50 : 100,
                  height: 'auto',
                  mb: height <= 400 ? 24 : 40,
                }}
              />
            ),
        },
      }}
      hideFooter
      rowHeight={40}
      checkboxSelection
      overscrollBehaviorX="contain"
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      rowCount={data.length}
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      onSortingChange={onSortingChangeWrapper}
      onRowSelectionChange={setRowSelectionWrapper}
      getSortedRowModel={getSortedRowModel()}
    />
  )
}
