import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Typography,
  enqueueSnackbar,
  Divider,
  Row,
  Col,
  Box,
} from '@applift/factor'
import { useMutation } from '@tanstack/react-query'
import { ORGANIZATION_TYPE, useAppContext } from '@applift/platform'

import {
  PmpFormState,
  PmpCreationForm,
  PmpCreationApiRef,
} from '../../PmpDialog/PmpCreationForm'
import { queryClient } from '../../../../../cache'
import { editPmpDeal } from '../../../../../api'
import { PmpListResponse } from '../../../../../models/Pmp'
import { DEAL_STATUS } from '../../../../../constants/filters'
import {
  useCampaignCreativeType,
  useDealCurationData,
  useExchangeList,
  usePmpDeal,
} from '../../../../../hooks'
import { CommonFilter } from '../../../../../models/Filters'
import { CampaignGrid } from '../../../../../routes/pg/-component/PgCampaignDialog/CampaignGrid'
import {
  DEFAULT_LIST_SORTING,
  DEFAULT_PAGE_SIZE,
} from '../../../../../routes/pg/-component/PgCampaignDialog/CampaignGridWrapper'
import { useBasicCampaignList } from '../../../../../hooks/useCampaign'
import { assignedCampaignColDef } from './assingnedCampaignColDef'

interface EditPmpDealDialogProps {
  onClose: () => void
  onSuccess?: () => void
  data: PmpListResponse
}

export const EditPmpDealDialog = (props: EditPmpDealDialogProps) => {
  const { onClose, data, onSuccess } = props

  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true)

  const { data: pmpData, isFetching: isPmpDetailLoading } = usePmpDeal(
    String(data.id),
    {
      enabled: Boolean(String(data.id).length),
    }
  )

  const pmpFormRef = React.useRef<PmpCreationApiRef>(null)

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType
  const owId = ctx.appMeta.loggedInOwId

  const editPmpMutation = useMutation(editPmpDeal, {
    mutationKey: ['editPmpDeal'],
    onSuccess: () => {
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getPmpList',
      })
      enqueueSnackbar('PMP Deal saved successfully', {
        variant: 'success',
      })
      onClose()
      onSuccess?.()
    },
    onError: e => {
      // @ts-ignore
      const errorFields = e?.errorObjects?.[0]?.field as string
      // @ts-ignore
      const errorMessage = e?.errorObjects?.[0]?.error as string

      const errorFieldArray =
        errorFields?.split('+')?.map(field => field.trim()) ?? []
      if (
        errorFieldArray?.includes('dealId') &&
        errorFieldArray?.includes('exchangeId')
      ) {
        pmpFormRef.current?.setError({
          dealId:
            'Deal ID already exists for selected exchange. Use a different Deal ID or Exchange',
        })
      } else {
        enqueueSnackbar(
          errorMessage?.length
            ? errorMessage
            : 'Something went wrong. Please try after some time.',
          {
            variant: 'error',
          }
        )
        onSuccess?.()
      }
    },
  })

  const onSubmit = async (value: Partial<PmpFormState>) => {
    const commonParams = {
      ...(Object.prototype.hasOwnProperty.call(value, 'dealId')
        ? { dealId: value.dealId }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'dealName')
        ? { dealName: value.dealName }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'description')
        ? { description: value.description }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'cpm')
        ? { cpm: value.cpm as number }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'creativeTypes')
        ? { creativeTypes: value?.creativeTypes?.map(creative => creative.id) }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'exchange')
        ? { exchangeId: value?.exchange?.[0]?.value }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'dealStatus')
        ? { active: value.dealStatus === DEAL_STATUS.ACTIVE }
        : {}),
    }

    const workspaceParams = {
      // dealCurationTypeId: value?.dealCuration?.[0]?.id,
      ...(Object.prototype.hasOwnProperty.call(value, 'customers')
        ? { assignToCustomers: value.customers }
        : {}),
    }

    const mutationParams = {
      ...commonParams,
      ...(orgType === ORGANIZATION_TYPE.WORKSPACE ||
      orgType === ORGANIZATION_TYPE.SUPER
        ? workspaceParams
        : {}),
    }

    editPmpMutation.mutate({ params: mutationParams, dealId: data.id })
  }

  const { data: exchangeData } = useExchangeList()
  const { data: creativeTypeIds } = useCampaignCreativeType()
  const { data: dealCurationData } = useDealCurationData()

  const initExchange = React.useMemo(
    () =>
      exchangeData?.exchangeList.filter(exchange => {
        return data.exchanges.includes(exchange.value)
      }),
    [data, exchangeData]
  )
  const initCreativeData = React.useMemo(
    () =>
      creativeTypeIds?.creativeTypeList?.filter(creative =>
        data?.creativeTypes?.includes(creative.id)
      ),
    [data, creativeTypeIds]
  )
  const initDealCuration = React.useMemo(
    () =>
      dealCurationData?.data.filter(
        curation => curation.id === data.dealCurationTypeId
      ),
    [data, dealCurationData]
  )

  const formInitValue = React.useMemo((): PmpFormState => {
    if (orgType !== 'ADVERTISER') {
      return {
        dealId: data.dealId,
        dealName: data.dealName,
        exchange: initExchange as CommonFilter[],
        dealStatus: data.active ? 1 : 2,
        creativeTypes: initCreativeData as any,
        cpm: data.cpm,
        description: data.description,
        dealCuration: initDealCuration,
        customers: data?.assignedToCustomers ? data?.assignedToCustomers : [],
      }
    }
    return {
      dealId: data.dealId,
      dealName: data.dealName,
      exchange: initExchange as CommonFilter[],
      dealStatus: data.active ? 1 : 2,
      creativeTypes: initCreativeData as any,
      cpm: data.cpm,
      description: data.description,
    }
  }, [data, initExchange, initCreativeData, initDealCuration, orgType])

  // Table state
  const {
    data: campaignListData,
    isFetching: campaignListLoading,
    fetchNextPage: fetchNextPageGrid,
    isError,
  } = useBasicCampaignList(
    '',
    pmpData?.assignedToCampaigns as number[],
    DEFAULT_LIST_SORTING,
    undefined,
    undefined,
    undefined,
    { enabled: Boolean(pmpData?.assignedToCampaigns?.length) }
  )

  const flatData = React.useMemo(() => {
    return (
      campaignListData?.pages
        ?.map(page => {
          return page.data ?? []
        })
        .flat(1) || []
    )
  }, [campaignListData])

  const filteredRecords = React.useMemo(
    () => campaignListData?.pages[0]?.filteredRecords,
    [campaignListData?.pages]
  )

  const totalRecords = React.useMemo(
    () => campaignListData?.pages[0]?.totalRecords,
    [campaignListData?.pages]
  )

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    return undefined
  }, [isError])

  const isCampaignAttached =
    Boolean(data?.assignedToCampaigns?.length) ||
    Boolean(pmpData?.assignedToCampaigns?.length)

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { ...(isCampaignAttached ? { height: 100 } : {}) } }}
    >
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <Typography weight="demi">Edit Deal</Typography>
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        dividers
      >
        <Row>
          <Col>
            <PmpCreationForm
              editMode
              dealId={data.id}
              ref={pmpFormRef}
              loggedInOwId={owId}
              organizationType={orgType}
              initValue={formInitValue}
              dealAssignedToCampaign={isCampaignAttached}
              onChange={() => {
                const disable =
                  !pmpFormRef.current?.isAllRequiredFilled() ||
                  pmpFormRef.current?.hasError() ||
                  !pmpFormRef.current?.hasChange()
                setDisableSubmit(disable)
              }}
            />
          </Col>
        </Row>
        {isCampaignAttached ? (
          <>
            <Divider sx={{ my: 24 }} color="secondary" />
            <Row sx={{ flexGrow: 1 }}>
              <Col>
                <Box sx={{ height: 100 }}>
                  <Typography component="p" variant="bodySmall" sx={{ mb: 16 }}>
                    The deal will also be updated in the below campaigns.
                  </Typography>
                  <CampaignGrid
                    data={flatData}
                    columnDef={assignedCampaignColDef}
                    totalRecords={totalRecords || 0}
                    filteredRecords={filteredRecords || 0}
                    defaultSorting={[{ id: 'id', desc: true }]}
                    rowIdKey="id"
                    pageSize={DEFAULT_PAGE_SIZE}
                    loading={campaignListLoading || isPmpDetailLoading}
                    onFetchRows={() => {
                      // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                      setTimeout(fetchNextPageGrid, 100)
                    }}
                    overlay={overlay}
                    initialState={{
                      columnVisibility: {
                        owId: orgType !== ORGANIZATION_TYPE.ADVERTISER,
                        organizationName:
                          orgType !== ORGANIZATION_TYPE.ADVERTISER,
                      },
                    }}
                  />
                </Box>
              </Col>
            </Row>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={disableSubmit}
          loading={editPmpMutation.isLoading}
          onClick={() => {
            const changedState = pmpFormRef.current?.getChangedState()
            if (changedState) {
              onSubmit(changedState)
            }
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
