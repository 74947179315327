import { Box, Container, Paper, Typography } from '@applift/factor'
import { RowSelectionState } from '@applift/datagrid'

import {
  ContextualGridWrapper,
  ContextualGridWrapperApiRefType,
} from '../-component/index'
import { EmptyContextualPage } from './index'
import { ContextualTableData } from '../../../models/Contextual'

interface ContextualContentProps {
  setAddedKeywords: React.Dispatch<React.SetStateAction<string[]>>
  addedKeywords: string[]
  addedURLs: string[]
  setAddedURLs: React.Dispatch<React.SetStateAction<string[]>>
  contextualGridWrapperRef?: React.MutableRefObject<
    ContextualGridWrapperApiRefType | undefined
  >
  onRowSelectionChange?: (
    rowSelection: RowSelectionState,
    tableData: ContextualTableData[]
  ) => void
  hideTableCTAs?: boolean
}

export const ContextualContent = (props: ContextualContentProps) => {
  const {
    addedKeywords,
    addedURLs,
    setAddedKeywords,
    setAddedURLs,
    contextualGridWrapperRef,
    onRowSelectionChange,
    hideTableCTAs,
  } = props

  const addedKeywordsCount = addedKeywords.length
  const addedURLsCount = addedURLs.length

  const totalCount = addedKeywordsCount + addedURLsCount

  return (
    <Container
      sx={{
        height: 100,
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        py: 24,
        px: 16,
        gap: 24,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          width: 100,
          py: 16,
          px: 24,
          borderRadius: 4,
          gap: 32,
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: 8 }}>
          <Typography variant="bodyMedium" gutterBottom={false}>
            Keywords
          </Typography>
          <Typography
            sx={{ textWeight: 'demi' }}
            gutterBottom={false}
            variant="bodyMedium"
          >
            {addedKeywordsCount
              ? addedKeywordsCount.toString().padStart(2, '0')
              : addedKeywordsCount}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 8 }}>
          <Typography variant="bodyMedium" gutterBottom={false}>
            URLs
          </Typography>
          <Typography
            sx={{ textWeight: 'demi' }}
            variant="bodyMedium"
            gutterBottom={false}
          >
            {addedURLsCount
              ? addedURLsCount.toString().padStart(2, '0')
              : addedURLsCount}
          </Typography>
        </Box>
      </Paper>

      <Paper
        elevation={2}
        sx={{
          width: 100,
          borderRadius: 4,
          flexGrow: 1,
        }}
      >
        {totalCount > 0 ? (
          <ContextualGridWrapper
            setAddedKeywords={setAddedKeywords}
            addedKeywords={addedKeywords}
            addedURLs={addedURLs}
            setAddedURLs={setAddedURLs}
            ref={contextualGridWrapperRef}
            onRowSelectionChange={onRowSelectionChange}
            hideTableCTAs={hideTableCTAs}
          />
        ) : (
          <EmptyContextualPage />
        )}
      </Paper>
    </Container>
  )
}
