import {
  Box,
  Tooltip,
  TextField,
  Typography,
  BaseTextFieldProps,
  SelectAdvanceComponents,
  Chip,
} from '@applift/factor'
import { InfoCircle, Hourglass } from '@applift/icons'

import { usePaymentTypes } from '../../../hooks'
import { PaymentTypeObject } from '../../../models/Filters'

interface PaymentTypeFilterProps {
  TextFieldProps?: Omit<BaseTextFieldProps<PaymentTypeObject>, 'selectProps'>
  value: PaymentTypeObject[]
  required?: boolean
  disabled?: boolean
  onChange: (creative: PaymentTypeObject[]) => void
}

export const PaymentTypeFilter = (props: PaymentTypeFilterProps) => {
  const { TextFieldProps, value, onChange, required = false, disabled } = props
  const apiRef =
    SelectAdvanceComponents.useSelectAdvanceApiRef<PaymentTypeObject>()

  const currentSelection = value.reduce(
    (acc: { [key: number]: boolean }, curr) => {
      acc[curr.id] = true
      return acc
    },
    {}
  )

  const renderItem =
    SelectAdvanceComponents.DefaultListOptionItemWrapper<PaymentTypeObject>({
      disableCheckbox: true,
      selectionStyle: 'none',
      arrowPosition: 'right',
    })

  const { data: paymentTypes } = usePaymentTypes()

  return (
    <TextField
      {...TextFieldProps}
      select="advanceSelect"
      variant="outlinedDash"
      placeholder="Select Payment Type"
      disabled={disabled}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="label"
            component="span"
            sx={{ textColor: 'neutral-600' }}
          >
            Payment To
          </Typography>
          {required && (
            <Typography component="span" sx={{ textColor: 'danger-400' }}>
              *
            </Typography>
          )}
          <Tooltip
            arrow
            title={
              disabled
                ? ''
                : 'Define how payments for the PG campaigns assigned to this deal are to be processed'
            }
            placement="top"
          >
            <Box>
              <InfoCircle
                fontSize={18}
                sx={{ textColor: 'neutral-400', ml: 2 }}
              />
            </Box>
          </Tooltip>
        </Box>
      }
      SelectProps={{
        slotProps: {
          PaperProps: { sx: { height: 'auto', py: 4 } },
        },
        getOptionDisabled: row => {
          return row.id === 1
        },
        allowClearingSingleSelect: !required,
        hideSearch: true,
        data: paymentTypes?.pgPaymentTypes as PaymentTypeObject[],
        multiple: false,
        apiRef: apiRef,
        getRowId: row => `${row.id}`,
        rowCount: paymentTypes?.filteredRecords || 0,
        renderValue: value => {
          const selectedItem = Array.isArray(value) ? value : []
          return (
            <Typography>{`${selectedItem[0]?.label ? selectedItem[0]?.label : ''}`}</Typography>
          )
        },
        renderListItem: renderItem,
        renderOption: ({ row }) => {
          const paymentType = row.original
          if (paymentType.id === 1) {
            return (
              <Box sx={{ display: 'flex', width: 100 }}>
                <Typography>{paymentType.label}</Typography>
                <Chip
                  size="small"
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Hourglass fontSize={12} />
                      <Typography variant="label">Coming Soon</Typography>
                    </Box>
                  }
                  color="secondary"
                  sx={{ ml: 'auto' }}
                />
              </Box>
            )
          }
          return <Typography>{paymentType.label}</Typography>
        },
      }}
      value={currentSelection}
      onChange={selectedRowData => {
        // @ts-ignore
        const rowSelection = selectedRowData(currentSelection)
        const selectedIds = Object.keys(rowSelection).map(id => parseInt(id))
        const value = paymentTypes?.pgPaymentTypes?.filter(data =>
          selectedIds.includes(data.id)
        )
        onChange(value?.length ? value : [])
      }}
    />
  )
}
