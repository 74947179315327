import * as React from 'react'
import {
  Col,
  Button,
  Chip,
  Tooltip,
  Box,
  enqueueSnackbar,
  Typography,
} from '@applift/factor'
import { useRouter } from '@tanstack/react-router'
import {
  ColumnDef,
  RowSelectionState,
  GridActionCellParams,
} from '@applift/datagrid'
import { ACTION } from './action'
import {
  useSaveToNewGroup,
  useSaveToExistingGroup,
} from '../../../../hooks/useAction'
import {
  SaveToNewGroupRequest,
  SaveToExistingGroupRequest,
} from '../../../../models/OpenExchange'
import { queryClient } from '../../../../cache'
import { usePmpDelete } from '../../../../hooks'
import { PmpListResponse } from '../../../../models/Pmp'
import { PMP_INVENTORY_ID } from '../../../../constants/pmp'
import {
  ConfirmationDialog,
  CreateNewGroupDialog,
} from '../../../../components/Dialogs'
import { SaveToExisitingGroupDialog } from '../../../../components/Dialogs/SaveToExistingGroupDialog/SaveToExisitingGroupDialog'
import {
  ORGANIZATION_TYPE,
  useAppContext,
  WithResponse,
} from '@applift/platform'
import { SidebarInventoryGroup } from '../../../../models/Group'
import { getGroupCreatedMessage } from '../../../../utils/messages'

interface ActionPanelProps {
  totalRecords: number
  isTableLoading: boolean
  rowSelection: RowSelectionState
  selectedItems?: PmpListResponse[]
  colDef: ColumnDef<PmpListResponse>[]
  setAction: (params: GridActionCellParams<any, any>) => void
  clearRowSelection: () => void
  inventoryIds: number[]
  hideTableCTAs?: boolean
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    setAction,
    totalRecords,
    rowSelection,
    selectedItems,
    isTableLoading,
    clearRowSelection,
    inventoryIds,
    hideTableCTAs,
  } = props

  const [dialogToShow, setDialogToShow] = React.useState<
    'newGroup' | 'existingGroup' | 'delete'
  >()

  const closeDialog = () => setDialogToShow(undefined)

  const router = useRouter()

  const selectedItemsCount = Object.keys(rowSelection).length

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const chipLabel = React.useMemo(() => {
    return `${selectedItemsCount} out of ${totalRecords} selected`
  }, [selectedItemsCount, totalRecords])

  const activeDealSeleted = React.useMemo(
    () => selectedItems?.some(deal => deal.active),
    [selectedItems]
  )
  const inactiveDealSeleted = React.useMemo(
    () => selectedItems?.some(deal => !deal.active),
    [selectedItems]
  )
  const onlyInactiveDealSelected = React.useMemo(
    () => selectedItems?.every(deal => !deal.active),
    [selectedItems]
  )
  const onlyActiveDealSelected = React.useMemo(
    () => selectedItems?.every(deal => deal.active),
    [selectedItems]
  )
  const sharedDealSelected = React.useMemo(
    () => selectedItems?.some(deal => deal.shared),
    [selectedItems]
  )

  const deleteDisabledSelected = React.useMemo(
    () =>
      selectedItems?.some(
        deal => typeof deal.deleteAllowed === 'boolean' && !deal.deleteAllowed
      ),
    [selectedItems]
  )

  const isEditDisabled = selectedItemsCount > 1 || sharedDealSelected

  const hasCampaignAttached = selectedItems?.some(
    deal => deal?.assignedToCampaigns?.length
  )

  const getEditActionTooltip = () => {
    if (selectedItemsCount > 1) {
      return 'Multiple deals can’t be edited'
    }
    if (sharedDealSelected) {
      return 'PMP Deals shared by the admin can’t be edited'
    }
  }

  const onNewGroupCreateActionComplete = () => {
    closeDialog()
    clearRowSelection()
  }

  const onNewGroupCreateActionSuccess = (
    data: WithResponse<SidebarInventoryGroup>,
    payload: SaveToNewGroupRequest
  ) => {
    enqueueSnackbar(getGroupCreatedMessage(payload.groupName), {
      variant: 'success',
    })
    const appliedFilters =
      router.state.location.search.currentlyAppliedGroupFilters
    if (data?.data?.id) {
      router.navigate({
        to: '/groups/$groupId',
        params: { groupId: String(data.data.id) },
        ...(appliedFilters?.length
          ? {
              search: {
                currentlyAppliedGroupFilters: appliedFilters,
              },
            }
          : {}),
      })
    }
  }

  const onSaveToExistingGroupActionSuccess = (
    _: any,
    payload: SaveToExistingGroupRequest
  ) => {
    if (payload.groupIds) {
      router.navigate({
        to: '/groups/$groupId',
        params: { groupId: String(payload.groupIds[0]) },
      })
    }
    enqueueSnackbar(`Selected deals saved to the group successfully`, {
      variant: 'success',
    })
  }

  const onSaveToExistingGroupActionError = (e: any) => {
    enqueueSnackbar(
      e.errorObjects
        ? (e.errorObjects[0]?.error as string)
        : 'Something went wrong',
      {
        variant: 'error',
      }
    )
    closeDialog()
    clearRowSelection()
  }

  const {
    mutate: saveToExisiting,
    isLoading: isSaveToExistingGroupInProgress,
  } = useSaveToExistingGroup(
    onSaveToExistingGroupActionError,
    onSaveToExistingGroupActionSuccess
  )

  const { mutate: saveToNew, isLoading: newGroupSaveInProgress } =
    useSaveToNewGroup({
      onSettled: onNewGroupCreateActionComplete,
      onSuccess: onNewGroupCreateActionSuccess,
    })

  const handleSaveToNew = (groupName: string) => {
    if (groupName) {
      const payload: SaveToNewGroupRequest = {
        groupName: groupName,
        dealIds: inventoryIds,
        groupTypeId: PMP_INVENTORY_ID,
      }
      saveToNew(payload)
    }
  }

  const handleSaveExisting = (groupId: number | undefined) => {
    if (groupId) {
      const payload: SaveToExistingGroupRequest = {
        groupIds: [groupId],
        dealIds: inventoryIds,
        groupTypeId: PMP_INVENTORY_ID,
      }
      saveToExisiting(payload)
    }
  }

  const deleteSuccess = (res: any) => {
    queryClient.resetQueries({
      predicate: (query: any) => query.queryKey?.[0]?.scope === 'getPmpList',
    })
    enqueueSnackbar({
      message: res?.data?.message?.length
        ? res?.data?.message
        : 'PMP Deal deleted successfully',
      variant: 'success',
    })
    clearRowSelection()
    closeDialog()
  }
  const deleteError = (prop: any) => {
    enqueueSnackbar({
      message: prop?.errorObjects?.length
        ? prop?.errorObjects?.[0]?.error
        : 'Something went wrong!',
      variant: 'error',
    })
    closeDialog()
    clearRowSelection()
  }

  const deletePmpMutation = usePmpDelete({
    onSuccess: deleteSuccess,
    onError: deleteError,
  })

  const onDelete = () => {
    const ids = Object.keys(rowSelection).map(id => parseInt(id))
    deletePmpMutation.mutate({ ids })
  }

  const deleteActionTooltip = React.useMemo(() => {
    if (
      selectedItemsCount > 1 &&
      ((activeDealSeleted && inactiveDealSeleted) || onlyActiveDealSelected)
    ) {
      return 'Only inactive deals can be deleted in bulk'
    }
    if (sharedDealSelected) {
      return 'PMP Deals shared by the admin can’t be deleted'
    }
    if (deleteDisabledSelected && hasCampaignAttached) {
      if (
        orgType === ORGANIZATION_TYPE.WORKSPACE ||
        orgType === ORGANIZATION_TYPE.SUPER
      ) {
        return 'PMP Deals assigned to Campaigns can’t be deleted'
      }
      return 'PMP Deals assigned to Pending, Running, Paused or Expired Campaigns can’t be deleted'
    }
    if (deleteDisabledSelected) {
      if (
        orgType === ORGANIZATION_TYPE.WORKSPACE ||
        orgType === ORGANIZATION_TYPE.SUPER
      ) {
        return 'PMP Deals assigned to Campaigns can’t be deleted'
      }
      return 'PMP Deals assigned to Pending, Running, Paused or Expired Campaigns can’t be deleted'
    }
    return ''
  }, [
    activeDealSeleted,
    inactiveDealSeleted,
    sharedDealSelected,
    selectedItemsCount,
    onlyActiveDealSelected,
    deleteDisabledSelected,
    hasCampaignAttached,
    orgType,
  ])

  const isDeleteDisabled = React.useMemo(() => {
    if (deleteDisabledSelected) {
      return true
    }
    if (
      selectedItemsCount > 1 &&
      ((activeDealSeleted && inactiveDealSeleted) || onlyActiveDealSelected)
    ) {
      return true
    }
    if (sharedDealSelected) {
      return true
    }
    return false
  }, [
    activeDealSeleted,
    inactiveDealSeleted,
    sharedDealSelected,
    selectedItemsCount,
    onlyActiveDealSelected,
    deleteDisabledSelected,
  ])

  const deleteDialogContent = () => {
    if (hasCampaignAttached) {
      return 'Once the deal is deleted, it will be removed from the assigned campaigns. Are you sure you want to delete it ?'
    }
    if (onlyInactiveDealSelected && selectedItemsCount > 1) {
      return (
        <Typography component="p" sx={{ display: 'flex' }}>
          {`Are you sure that you want to delete the `}
          <Typography component="span" weight="demi" sx={{ mx: 4 }}>
            {selectedItemsCount}
          </Typography>{' '}
          {` deals ?`}
        </Typography>
      )
    }

    return 'Are you sure that you want to delete the selected deal ?'
  }

  const assignToCampaignBtnTooltip = () => {
    return inactiveDealSeleted
      ? 'Only active PMP deals can be assigned to a campaign'
      : ''
  }

  return (
    <>
      {selectedItemsCount > 0 && !isTableLoading && (
        <Col xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            size="small"
            label={chipLabel}
            color="secondary"
            onDelete={clearRowSelection}
          />

          <Box
            sx={{
              display: 'flex',
              visibility: hideTableCTAs === true ? 'hidden' : 'visible',
            }}
          >
            <Tooltip title={getEditActionTooltip()} arrow placement="top">
              <Box>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  sx={{ marginLeft: 8 }}
                  disabled={isEditDisabled}
                  onClick={() => {
                    setAction?.({
                      actionName: ACTION.EDIT_PMP_DEAL,
                      metaData: { data: selectedItems?.[0] },
                    })
                  }}
                >
                  Edit
                </Button>
              </Box>
            </Tooltip>
            {orgType === ORGANIZATION_TYPE.ADVERTISER ? (
              <Tooltip
                title={assignToCampaignBtnTooltip()}
                arrow
                placement="top"
              >
                <Box>
                  <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ marginLeft: 8 }}
                    disabled={inactiveDealSeleted}
                    onClick={() => {
                      setAction?.({
                        actionName: ACTION.ASSIGN_TO_CAMPAIGN,
                        metaData: { params: selectedItems },
                      })
                    }}
                  >
                    Assign To Campaign
                  </Button>
                </Box>
              </Tooltip>
            ) : null}
            <Tooltip
              title={
                inactiveDealSeleted
                  ? 'Only active PMP deals can be saved to group'
                  : ''
              }
              arrow
              placement="top"
            >
              <Box>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  sx={{ marginLeft: 8 }}
                  disabled={inactiveDealSeleted}
                  onClick={() => setDialogToShow('newGroup')}
                >
                  Save To New Group
                </Button>
              </Box>
            </Tooltip>
            <Tooltip
              title={
                inactiveDealSeleted
                  ? 'Only active PMP deals can be saved to group'
                  : ''
              }
              arrow
              placement="top"
            >
              <Box>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  disabled={inactiveDealSeleted}
                  sx={{ marginLeft: 8 }}
                  onClick={() => {
                    setDialogToShow('existingGroup')
                  }}
                >
                  Save To Existing Group
                </Button>
              </Box>
            </Tooltip>
            <Tooltip title={deleteActionTooltip} arrow placement="top">
              <Box>
                <Button
                  size="small"
                  color="error"
                  variant="outlined"
                  sx={{ marginLeft: 8 }}
                  disabled={isDeleteDisabled}
                  onClick={() => {
                    setDialogToShow('delete')
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Tooltip>
          </Box>
        </Col>
      )}
      {dialogToShow === 'newGroup' && (
        <CreateNewGroupDialog
          onClose={closeDialog}
          onConfirmation={handleSaveToNew}
          isLoading={newGroupSaveInProgress}
          groupType="PMP"
        />
      )}
      {dialogToShow === 'existingGroup' && (
        <SaveToExisitingGroupDialog
          onClose={closeDialog}
          onConfirmation={handleSaveExisting}
          groupType="PMP"
          isLoading={isSaveToExistingGroupInProgress}
          groupId={PMP_INVENTORY_ID}
        />
      )}
      {dialogToShow === 'delete' && (
        <ConfirmationDialog
          onClose={closeDialog}
          onConfirmation={onDelete}
          primaryBtnText="Delete"
          theme="error"
          title={`Delete Deal${selectedItemsCount > 1 ? 's' : ''}?`}
          dialogContent={deleteDialogContent()}
          isActionLoading={deletePmpMutation.isLoading}
        />
      )}
    </>
  )
}
