import * as React from 'react'
import { useRouter } from '@tanstack/react-router'
import { useAppContext } from '@applift/platform'
import { RowSelectionState } from '@applift/datagrid'
import {
  SelectAdvanceComponents,
  SelectAdvanceList,
  ListItemButton,
  Typography,
  Box,
  Skeleton,
  IconButton,
  TextField,
  useDebounceValue,
  TypoTooltip,
  Button,
  enqueueSnackbar,
} from '@applift/factor'
import { Search, Close, Add } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'

import { DisplayInventoryGroupItem } from '../../../../components/InventoryGroup/DisplayInventoryGroupItem/index'
import { formatNumberWithSuffix } from '../../../../utils/format'
import { GroupsFilter } from './GroupsFilter'
import {
  DEFAULT_NUMBER_OF_GROUP_SIDEBAR_RECORDS,
  STORAGE_INVENTOY_GROUP_SHOW_DELETE_DIALOG_NAME,
} from '../../../../constants/group'
import { useGrouplist } from '../../../../hooks/useGroup'
import {
  DeleteInventroyGroupArgs,
  GroupChangeNameResponse,
  GroupListTableResponse,
  SidebarInventoryGroup,
} from '../../../../models/Group'
import { GROUP_TYPE_IDS_TO_CATEGORIES } from '../../../../constants/common'
import { DeleteDialog } from '../../-components/DeleteDialog'
import { localStorageService } from '../../-components/healper'
import { useDeleteGroup, useChangeGroupNameMutation } from '../../../../hooks'
import { EditNameDialog } from '../../-components/EditNameDialog'
import { AssignToCustomerDialog } from '../../-components/AssignToCustomerDialog/AssignToCustomerDialog'
import { ViewCampaignDialog } from '../../-components/ViewCampaignDialog'
import { AssingToCampaignDialog } from '../../-components/AssignToCampaignsDialog'

export interface GroupsSidebarApiRef {
  clearSearch: () => void
}

export const GroupsSidebar = React.forwardRef<GroupsSidebarApiRef>((_, ref) => {
  const router = useRouter()
  const ctx = useAppContext()
  const queryClient = useQueryClient()

  const selectedGroupId =
    (router.routeTree.useParams() as { [key: string]: string })?.groupId ?? ''

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [searchText, setSearchText] = React.useState(
    selectedGroupId ? selectedGroupId : ''
  )
  const [dialogToShow, setDialogToShow] = React.useState<
    | 'delete'
    | 'changeName'
    | 'assignToCustomer'
    | 'viewCampaign'
    | 'assignToCampaign'
    | 'cannotDelete'
  >()

  const apiRef =
    SelectAdvanceComponents.useSelectAdvanceApiRef<SidebarInventoryGroup>()

  const renderItem =
    SelectAdvanceComponents.DefaultCardOptionItemWrapper<SidebarInventoryGroup>(
      {
        selectionStyle: 'partial',
      }
    )
  const isAdvertiser = ctx.appMeta.organizationType === 'ADVERTISER'

  const currentlyAppliedGroupFilters =
    router.state.location.search.currentlyAppliedGroupFilters ?? []

  const debouncedSearch = useDebounceValue(searchText, 250)

  const [selectedActionGroup, setSelectedActionGroup] = React.useState('')
  const [selectedId, setSelectedId] = React.useState<number>()

  const hideDialog = () => setDialogToShow(undefined)

  const onGroupSuccessfullyDeleted = (
    _: any,
    payload: DeleteInventroyGroupArgs
  ) => {
    enqueueSnackbar('Inventory group deleted successfully', {
      variant: 'success',
    })

    queryClient.setQueriesData(
      {
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getGroupList',
      },
      (oldData: { pages: GroupListTableResponse[] } | undefined) => {
        if (!oldData) return oldData

        const updatedPages = oldData.pages.map(page => {
          const newData = page.inventoryGroupList.filter(
            item => item.id !== payload?.id
          )
          return {
            ...page,
            inventoryGroupList: newData,
            filteredRecords: page.filteredRecords - 1,
            totalRecords: page.totalRecords - 1,
          }
        })

        return {
          ...oldData,
          pages: updatedPages,
        }
      }
    )

    // if selected group is being deleted, then we remove the group Id from URL
    const shouldResetGroupIdInURL = router.state.location.pathname.includes(
      payload.id.toString()
    )

    setDialogToShow(undefined)
    if (shouldResetGroupIdInURL) {
      setSearchText('')
    }

    queryClient.resetQueries({
      predicate: (query: any) => {
        return query.queryKey?.[0]?.scope === 'getGroupStatistics'
      },
    })

    if (selectedGroupId.length && !shouldResetGroupIdInURL) {
      router.navigate({
        to: '/groups/$groupId',
        params: {
          groupId: selectedGroupId,
        },
        ...(router.state.location.search.currentlyAppliedGroupFilters?.length
          ? {
              search: {
                currentlyAppliedGroupFilters:
                  router.state.location.search.currentlyAppliedGroupFilters,
              },
            }
          : {}),
      })
    } else {
      router.navigate({
        to: '/groups',
        ...(router.state.location.search.currentlyAppliedGroupFilters?.length
          ? {
              search: {
                currentlyAppliedGroupFilters:
                  router.state.location.search.currentlyAppliedGroupFilters,
              },
            }
          : {}),
      })
    }
  }

  const onGroupNameChangeSuccess = (
    data: GroupChangeNameResponse | undefined
  ) => {
    queryClient.setQueriesData(
      {
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getGroupList',
      },
      (oldData: { pages: GroupListTableResponse[] } | undefined) => {
        if (!oldData) return oldData

        const updatedPages = oldData.pages.map(page => {
          const newData = page.inventoryGroupList.map(item => {
            if (item.id === data?.id) {
              return {
                ...item,
                name: data.name,
              }
            }
            return item
          })
          return {
            ...page,
            inventoryGroupList: newData,
          }
        })

        return {
          ...oldData,
          pages: updatedPages,
        }
      }
    )

    queryClient.setQueriesData<any>(
      {
        predicate: (query: any) => query.queryKey[0].scope === 'getGroupDetail',
      },
      (groupDetailData: SidebarInventoryGroup) =>
        groupDetailData.id !== data?.id
          ? groupDetailData
          : { ...groupDetailData, name: data.name }
    )
    enqueueSnackbar('Group name changed successfully.', {
      variant: 'success',
    })
    hideDialog()
  }

  const onGroupDeleteError = (e: any) => {
    enqueueSnackbar(
      e.errorObjects
        ? (e.errorObjects[0]?.error as string)
        : 'Something went wrong',
      {
        variant: 'error',
      }
    )
    hideDialog()
  }

  const { mutate: deleteGroup, isLoading: isGroupBeingDeleted } =
    useDeleteGroup(onGroupSuccessfullyDeleted, onGroupDeleteError)

  const { mutate: changeName, isLoading: isNameBeingChanged } =
    useChangeGroupNameMutation(onGroupNameChangeSuccess)

  const [allowedCampaignIds, setAllowedCampaignIds] = React.useState<number[]>(
    []
  )
  const [blockedCampaignIds, setBlockedCampaignIds] = React.useState<number[]>(
    []
  )

  const { data, isFetching, fetchNextPage, isLoading, isSuccess } =
    useGrouplist({
      searchField: debouncedSearch,
      groupFilterId: currentlyAppliedGroupFilters
        .find(filter => filter?.includes('groupFilter'))
        ?.split('-')[1],
      groupTypeIds: currentlyAppliedGroupFilters
        .filter(filter => filter?.includes('groupType'))
        ?.map(groupType => groupType.split('-')[1])
        .join(','),
      noOfEntries: DEFAULT_NUMBER_OF_GROUP_SIDEBAR_RECORDS,
    })

  const onDeleteClick = (groupInfo: SidebarInventoryGroup) => {
    setSelectedActionGroup(groupInfo.name)
    setSelectedId(groupInfo.id)
    const isDialogHidden = localStorageService.getLocal<boolean>(
      STORAGE_INVENTOY_GROUP_SHOW_DELETE_DIALOG_NAME
    )?.data

    const isDeleteNotAllowedForWorkspace =
      !isAdvertiser &&
      (groupInfo.blackListedCampaignIds?.length ||
        groupInfo.whiteListedCampaignIds.length)

    if (isDeleteNotAllowedForWorkspace) {
      setAllowedCampaignIds(groupInfo.whiteListedCampaignIds)
      setBlockedCampaignIds(groupInfo.blackListedCampaignIds)
      return setDialogToShow('delete')
    }

    if (isDialogHidden) {
      deleteGroup({ id: groupInfo.id })
    } else {
      setDialogToShow('delete')
    }
  }
  const onAssignToCampaign = (groupInfo: SidebarInventoryGroup) => {
    setSelectedId(groupInfo.id)
    setSelectedActionGroup(groupInfo.name)
    setAllowedCampaignIds(groupInfo.whiteListedCampaignIds)
    setBlockedCampaignIds(groupInfo.blackListedCampaignIds)
    setDialogToShow('assignToCampaign')
  }
  const onChangeNameClick = (groupInfo: SidebarInventoryGroup) => {
    setSelectedActionGroup(groupInfo.name)
    setSelectedId(groupInfo.id)
    setDialogToShow('changeName')
  }

  const onAssignToCustomer = (groupInfo: SidebarInventoryGroup) => {
    setSelectedActionGroup(groupInfo.name)
    setSelectedId(groupInfo.id)
    setDialogToShow('assignToCustomer')
  }

  const onViewCampaignClick = (groupInfo: SidebarInventoryGroup) => {
    setSelectedActionGroup(groupInfo.name)
    setSelectedId(groupInfo.id)
    setDialogToShow('viewCampaign')
    setAllowedCampaignIds(groupInfo.whiteListedCampaignIds)
    setBlockedCampaignIds(groupInfo.blackListedCampaignIds)
  }

  React.useEffect(() => {
    if (isSuccess) {
      const newSelection: RowSelectionState = {}
      newSelection[`${selectedGroupId}`] = true
      setRowSelection(newSelection)
    }
  }, [isSuccess, selectedGroupId])

  const sidebarData =
    data?.pages
      ?.map(page => {
        return (
          page?.inventoryGroupList.map(item => ({
            ...item,
            label: item.name,
            value: item.id,
          })) ?? []
        )
      })
      .flat(1) || []

  const getAdvertiserUserActions = (groupData: SidebarInventoryGroup) => [
    {
      label: 'Delete',
      value: 'delete',
      onClick: onDeleteClick,
    },
    {
      label: 'Change Name',
      value: 'changeName',
      onClick: onChangeNameClick,
    },
    ...(groupData.count > 0
      ? [
          {
            label: 'Assign to Campaign',
            value: 'assignToCampaign',
            onClick: onAssignToCampaign,
          },
        ]
      : []),
  ]

  const workspaceUserActions = [
    { label: 'Delete', value: 'delete', onClick: onDeleteClick },
    { label: 'Change Name', value: 'changeName', onClick: onChangeNameClick },
    {
      label: 'Assign to Customer',
      value: 'assignToCustomer',
      onClick: onAssignToCustomer,
    },
    {
      label: 'View Campaign',
      value: 'viewCampaign',
      onClick: onViewCampaignClick,
    },
  ]

  const getSecondaryInfo = (groupData: SidebarInventoryGroup) => {
    const groupTypeLabel =
      GROUP_TYPE_IDS_TO_CATEGORIES[
        groupData.groupTypeId as keyof typeof GROUP_TYPE_IDS_TO_CATEGORIES
      ]
    return [
      {
        label: groupTypeLabel,
        value: formatNumberWithSuffix(groupData.count),
      },
      {
        label: 'Impressions',
        value: formatNumberWithSuffix(groupData.impressions),
      },
      {
        label: 'Reach',
        value: formatNumberWithSuffix(groupData.reach),
      },
    ]
  }

  const getOverlay = () => {
    if (!(isLoading || isFetching)) {
      if (!Array.isArray(sidebarData)) {
        return 'error'
      }
      if ((sidebarData?.length ?? 0) <= 0) {
        if (searchText) {
          return 'noResult'
        }
        return 'noRows'
      }
    }
    return undefined
  }

  const removeSearchParams = () =>
    router.navigate({
      to: '/groups/$groupId',
      params: {
        groupId: '',
      },
      ...(currentlyAppliedGroupFilters?.length
        ? {
            search: {
              currentlyAppliedGroupFilters,
            },
          }
        : {}),
    })

  const handleDelete = () => {
    if (selectedId) {
      deleteGroup({ id: selectedId })
    }
  }

  const handleEditName = (groupName: string) => {
    if (selectedId) {
      const payload = { groupId: selectedId, newName: groupName }
      changeName(payload)
    }
  }

  const cannotDeleteGroupDialogText = (
    <Typography sx={{ display: 'flex', gap: 2 }}>
      You are not allowed to delete
      <TypoTooltip
        placement="top"
        TypgraphyProps={{ weight: 'demi', sx: { mx: 2 } }}
        style={{ maxWidth: '50%' }}
      >
        {selectedActionGroup}
      </TypoTooltip>
      is used by the following campaigns
    </Typography>
  )

  const viewCampaignDialogText = (
    <Typography sx={{ display: 'flex', gap: 2 }}>
      The inventory group
      <TypoTooltip
        placement="top"
        TypgraphyProps={{ weight: 'demi', sx: { mx: 2 } }}
        style={{ maxWidth: '50%' }}
      >
        {selectedActionGroup}
      </TypoTooltip>
      is used by the following campaigns
    </Typography>
  )

  React.useImperativeHandle(ref, () => ({
    clearSearch: () => {
      setSearchText('')
    },
  }))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 8,
          mb: 16,
          pt: 8,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'between',
            width: 100,
            px: 16,
          }}
        >
          <Typography
            sx={{ textWeight: 'demi', display: 'block' }}
            variant="bodyLarge"
          >
            Groups
          </Typography>
          <GroupsFilter
            setRowSelection={setRowSelection}
            setSearchText={setSearchText}
          />
        </Box>
        <Box>
          <Box style={{ width: 'fit-content' }}>
            <Button
              startIcon={<Add fontSize={20} />}
              variant="contained"
              color="primary"
              disableRipple
              sx={{ ml: 8 }}
              onClick={() => {
                if (selectedGroupId) {
                  router.navigate({
                    to: '/groups/$groupId',
                    params: {
                      groupId: selectedGroupId,
                    },
                    search: {
                      ...(currentlyAppliedGroupFilters.length
                        ? { currentlyAppliedGroupFilters }
                        : {}),
                      createGroup: true,
                    },
                  })
                } else {
                  router.navigate({
                    to: '/groups',
                    search: {
                      ...(currentlyAppliedGroupFilters.length
                        ? { currentlyAppliedGroupFilters }
                        : {}),
                      createGroup: true,
                    },
                  })
                }
              }}
            >
              Create Group
            </Button>
          </Box>
        </Box>
        <TextField
          type="text"
          value={searchText}
          onChange={e => {
            removeSearchParams()
            setRowSelection({})
            setSearchText(e.target.value)
          }}
          placeholder="Search by inventory group name or ID"
          variant="outlined"
          sx={{ px: 8 }}
          InputProps={{
            startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
            endAdornment:
              searchText.trimStart().trimEnd().length > 0 ? (
                <IconButton
                  onClick={() => {
                    removeSearchParams()
                    setRowSelection({})
                    setSearchText('')
                  }}
                  size="small"
                >
                  <Close fontSize={16} />
                </IconButton>
              ) : null,
          }}
        />
      </Box>
      <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
        <SelectAdvanceList
          size="medium"
          apiRef={apiRef}
          data={sidebarData}
          pageSize={DEFAULT_NUMBER_OF_GROUP_SIDEBAR_RECORDS}
          slotProps={{
            ListProps: {},
            ListItemButtonProps: {
              disableRipple: true,
            },
          }}
          rowCount={(data?.pages?.[0]?.filteredRecords ?? 1) - 1}
          hideSelectNav
          hideSearch
          loading={isFetching}
          // eslint-disable-next-line
          onFetchRows={() => fetchNextPage()}
          overlay={getOverlay()}
          renderListItem={renderItem}
          renderListItemSkeleton={({ virtualItem, measureElement, style }) => {
            return (
              <ListItemButton
                Component={'li'}
                data-index={virtualItem.index}
                ref={measureElement}
                tabIndex={-1}
                style={style}
                sx={{ width: 100, px: 24, py: 16, borderBottom: 1 }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: 100,
                  }}
                >
                  <Box sx={{ display: 'flex', justifyContent: 'between' }}>
                    <Skeleton height={30} width={100} />
                    <Box sx={{ display: 'flex', gap: 4 }}>
                      <Skeleton height={30} width={30} />
                    </Box>
                  </Box>

                  <Box sx={{ mt: 12, display: 'flex', gap: 24 }}>
                    {new Array(3).fill(
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 2,
                          flexDirection: 'column',
                        }}
                      >
                        <Skeleton height={20} width={50} />
                        <Skeleton height={20} width={25} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </ListItemButton>
            )
          }}
          renderOption={({ row }) => {
            return (
              <Box
                sx={{
                  py: 8,
                  px: 12,
                  display: 'flex',
                  flexDirection: 'column',
                  width: 100,
                  overflow: 'hidden',
                }}
              >
                <DisplayInventoryGroupItem
                  secondaryInfo={getSecondaryInfo(row.original)}
                  isAdvertiser={isAdvertiser}
                  displayGroupIcon
                  actionOptions={
                    isAdvertiser
                      ? getAdvertiserUserActions(row.original)
                      : workspaceUserActions
                  }
                  showAllowedBlockedCount={isAdvertiser}
                  groupInfo={row.original}
                />
              </Box>
            )
          }}
          value={rowSelection}
          onChange={(val: any) => {
            const newSelection = typeof val === 'function' ? val() : val
            const updatedId = Object.keys(newSelection)?.[0] as string

            if (updatedId) {
              router.navigate({
                to: '/groups/$groupId',
                params: {
                  groupId: updatedId,
                },
                ...(currentlyAppliedGroupFilters?.length
                  ? {
                      search: {
                        currentlyAppliedGroupFilters,
                      },
                    }
                  : {}),
              })
            }
            if (updatedId) {
              setRowSelection(newSelection)
            }
          }}
        />
      </Box>
      {dialogToShow === 'delete' && (
        <DeleteDialog
          isLoading={isGroupBeingDeleted}
          onClose={hideDialog}
          onConfirmation={handleDelete}
          primaryBtnText="Delete"
          theme="error"
          title="Delete Group?"
          dialogContent={selectedActionGroup}
          showDividers
        />
      )}
      {dialogToShow === 'changeName' && (
        <EditNameDialog
          onClose={hideDialog}
          onConfirmation={handleEditName}
          isLoading={isNameBeingChanged}
          groupName={selectedActionGroup}
        />
      )}
      {dialogToShow === 'assignToCustomer' && (
        <AssignToCustomerDialog
          groupId={Number(selectedId)}
          onClose={hideDialog}
          groupName={selectedActionGroup}
        />
      )}

      {dialogToShow === 'viewCampaign' && (
        <ViewCampaignDialog
          onClose={hideDialog}
          allowedCampaignIds={allowedCampaignIds}
          blockedCampaignIds={blockedCampaignIds}
          primaryText={viewCampaignDialogText}
          dialogTitle="View Campaigns"
        />
      )}
      {dialogToShow === 'assignToCampaign' && (
        <AssingToCampaignDialog
          onClose={() => {
            hideDialog()
            // resetting the selected values
            setSelectedId(undefined)
            setSelectedActionGroup('')
            setAllowedCampaignIds([])
            setBlockedCampaignIds([])
          }}
          id={selectedId as number}
          name={selectedActionGroup}
          includedIds={allowedCampaignIds}
          excludedIds={blockedCampaignIds}
        />
      )}
      {dialogToShow === 'cannotDelete' && (
        <ViewCampaignDialog
          onClose={hideDialog}
          allowedCampaignIds={allowedCampaignIds}
          blockedCampaignIds={blockedCampaignIds}
          primaryText={cannotDeleteGroupDialogText}
          dialogTitle="Delete is not allowed"
        />
      )}
    </Box>
  )
})

GroupsSidebar.displayName = 'GroupsSidebar'
