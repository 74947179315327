import { TypographyProps } from '@applift/factor'

import { RawDataCell } from './RawDataCell'
import { useDealCurationData } from '../../hooks'

interface DealCurationTypeCellProps {
  dealCurationTypeId: number
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const DealCurationTypeCell = (props: DealCurationTypeCellProps) => {
  const { dealCurationTypeId, textTypographyProps } = props
  const { data: curationData } = useDealCurationData()
  const dealCuration = curationData?.dealCurationIdMapping?.[dealCurationTypeId]
  return dealCuration?.length ? (
    <RawDataCell
      textTypographyProps={textTypographyProps}
      value={dealCuration}
    />
  ) : (
    '-'
  )
}
