import { ColumnDef } from '@applift/datagrid'
import { NormalizedCustomerList } from '@applift/platform/dist/models/customerList'
import { CustomerNameCell, RawDataCell } from '../../../../components/CellTypes'
import { StatusCell } from './CellTypes/StatusCell'
import { ActionCell } from './CellTypes/ActionCell'

export const colDef: ColumnDef<
  NormalizedCustomerList & { id: number; campaignCount?: number }
>[] = [
  {
    id: 'id',
    accessorKey: 'id',
    header: 'ID',
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    id: 'label',
    accessorKey: 'label',
    header: 'Customer Name',
    cell: props => {
      return (
        <CustomerNameCell
          customerName={
            (props.renderValue() as string) ??
            props.row.original.ownerUserEmail ??
            ''
          }
          avtarPath={props.row.original.logoUrl}
        />
      )
    },
    size: 280,
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: 'Status',
    cell: props => <StatusCell status={props.renderValue() as string} />,
    size: 130,
  },
  {
    id: 'activeCampaignsCount',
    accessorKey: 'activeCampaignsCount',
    header: 'Running Campaigns',
    meta: {
      align: 'right',
    },
    size: 160,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    id: 'ownerUserEmail',
    accessorKey: 'ownerUserEmail',
    header: 'Email',
    enableSorting: false,
    size: 340,
    cell: props => <RawDataCell value={props.renderValue() as string} />,
  },
  {
    id: 'action',
    header: 'Action',
    cell: props => (
      <ActionCell onAction={props.onAction} rowData={props.row.original} />
    ),
    meta: { align: 'center' },
    size: 70,
  },
]
