import { useMutation, useQueryClient } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { BlocklistTableResponse, CommentPayload } from '../models/BlocklistType'
import { addComment } from '../api/blocklist'

export const useAddComment = () => {
  const queryClient = useQueryClient()
  const mutationResult = useMutation(addComment, {
    mutationKey: ['addComment'],
    onSuccess: (data: CommentPayload) => {
      queryClient.setQueriesData(
        {
          predicate: (query: any) => {
            return query.queryKey?.[0]?.scope === 'getBlocklist'
          },
        },
        (oldData: { pages: BlocklistTableResponse[] } | undefined) => {
          if (!oldData) return oldData

          const updatedPages = oldData.pages.map(page => {
            const newData = page.globalBlockedInventoryData.map(item => {
              if (item.id === data.inventoryId) {
                return {
                  ...item,
                  comment: data.comment,
                }
              }
              return item
            })
            return {
              ...page,
              globalBlockedInventoryData: newData,
            }
          })

          return {
            ...oldData,
            pages: updatedPages,
          }
        }
      )
      enqueueSnackbar('Your changes have been saved.', {
        variant: 'success',
      })
    },

    onError: (e: any) => {
      enqueueSnackbar(
        e.errorObjects
          ? (e.errorObjects[0]?.error as string)
          : 'something went wrong',
        {
          variant: 'error',
        }
      )
    },
  })

  return mutationResult
}
