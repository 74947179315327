import { MutationOptions, useMutation } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import {
  DeleteInventroyGroupArgs,
  SidebarInventoryGroup,
} from '../models/Group'
import {
  blockInventory,
  deleteInventroyGroup,
  saveToExistingGroup,
  saveToNewGroup,
} from '../api/action'
import { WithResponse } from '../models/Response'
import {
  BlockInventoryRequest,
  SaveToExistingGroupRequest,
  SaveToNewGroupRequest,
} from '../models/OpenExchange'
import { unblockInventories } from '../api/blocklist'
import { UnblockPayload } from '../models/BlocklistType'

const onError = (e: any) =>
  enqueueSnackbar(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong',
    {
      variant: 'error',
    }
  )

export const useSaveToExistingGroup = (
  onError?: MutationOptions<any, any, SaveToExistingGroupRequest>['onError'],
  onSuccess?: MutationOptions<any, any, SaveToExistingGroupRequest>['onSuccess']
) =>
  useMutation(saveToExistingGroup, {
    mutationKey: ['saveToExistingGroup'],
    onSuccess,
    onError,
  })

export type SaveNewGroupOptions = {
  onError?: MutationOptions<any, any, SaveToNewGroupRequest>['onError']
  onSuccess?: MutationOptions<
    WithResponse<SidebarInventoryGroup>,
    any,
    SaveToNewGroupRequest
  >['onSuccess']
  onSettled?: MutationOptions<
    WithResponse<SidebarInventoryGroup>,
    any,
    SaveToNewGroupRequest
  >['onSettled']
}

export const useSaveToNewGroup = (options?: SaveNewGroupOptions) => {
  const mutationResult = useMutation(saveToNewGroup, {
    mutationKey: ['saveToNewGroup'],
    onSuccess: options?.onSuccess,
    onError,
    onSettled: options?.onSettled,
  })

  return mutationResult
}

export const useBlockInventory = (
  onSuccess?: MutationOptions<any, any, BlockInventoryRequest>['onSuccess']
) => {
  const mutationResult = useMutation(blockInventory, {
    mutationKey: ['blockInventory'],
    onSuccess,
    onError,
  })

  return mutationResult
}

export const useDeleteGroup = (
  onSuccess: MutationOptions<any, any, DeleteInventroyGroupArgs>['onSuccess'],
  onError: MutationOptions<any, any, DeleteInventroyGroupArgs>['onError']
) => {
  const mutationResult = useMutation(deleteInventroyGroup, {
    mutationKey: ['deleteInventoryGroup'],
    onSuccess,
    onError,
  })

  return mutationResult
}

export const useUnblockInventories = (
  onSuccess: MutationOptions<any, any, UnblockPayload>['onSuccess']
) =>
  useMutation(unblockInventories, {
    mutationKey: ['unblockInventories'],
    onSuccess,
    onError,
  })
