import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { getCampaignCreativeTypes, getCreativeTypes } from '../api/filters'
import { WithResponse } from '../models/Response'
import {
  CampaignCreativeErrorResponse,
  CampaignCreativeTypeResponse,
} from '../models/Filters'

export const getCreativeTypesKey = {
  keys: (args: { scope: string }) => [args],
}

export const useCreativeType = () => {
  const data = useQuery(
    getCreativeTypesKey.keys({ scope: 'getCreativeType' }),
    getCreativeTypes,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e?.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      keepPreviousData: true,
    }
  )
  return data
}

export const useCampaignCreativeType = () => {
  const data = useQuery<
    CampaignCreativeTypeResponse,
    CampaignCreativeErrorResponse
  >(
    getCreativeTypesKey.keys({ scope: 'getCampaignCreativeType' }),
    getCampaignCreativeTypes,
    {
      keepPreviousData: true,
    }
  )
  if (data.isError) {
    enqueueSnackbar(data?.error?.message ?? 'Something went wrong!', {
      variant: 'error',
    })
  }
  return data
}
