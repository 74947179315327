import * as React from 'react'
import { RowSelectionState, SortingState } from '@applift/datagrid'
import { Box, Col, Row } from '@applift/factor'

import { ContextualActionPanel } from './ContextualActionPanel'
import { DEFAULT_CONTEXTUAL_LIST_SORTING } from '../../../../constants/contextual'
import { ContextualGrid } from './ContextualGrid'
import { ContextualTableData } from '../../../../models/Contextual'

interface ContextualGridWrapperProps {
  setAddedKeywords: React.Dispatch<React.SetStateAction<string[]>>
  addedKeywords: string[]
  addedURLs: string[]
  setAddedURLs: React.Dispatch<React.SetStateAction<string[]>>
  onRowSelectionChange?: (
    rowSelection: RowSelectionState,
    tableData: ContextualTableData[]
  ) => void
  hideTableCTAs?: boolean
}

export interface ContextualGridWrapperApiRefType {
  clearRowSelection: () => void
}

export const ContextualGridWrapper = React.forwardRef<
  ContextualGridWrapperApiRefType | undefined,
  ContextualGridWrapperProps
>((props, ref) => {
  const {
    addedKeywords: previouslyAddedKeywords,
    addedURLs: previouslyAddedURLs,
    setAddedKeywords,
    setAddedURLs,
    onRowSelectionChange,
    hideTableCTAs,
  } = props

  const [searchTerm, updateSearchTerm] = React.useState('')
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [sorting, setSorting] = React.useState<SortingState>(
    DEFAULT_CONTEXTUAL_LIST_SORTING
  )

  const setRowSelectionWrapper = (selection: any) => {
    let value = selection
    if (typeof selection === 'function') {
      value = selection()
    }
    setRowSelection({ ...value })
    onRowSelectionChange?.({ ...value }, tableData)
  }

  const trimmedLowerCaseSearchTerm = searchTerm
    .trimStart()
    .trimEnd()
    .toLowerCase()

  const addedKeywords =
    previouslyAddedKeywords.map(keyword => ({
      name: keyword,
      type: 'Keyword',
    })) ?? []
  const addedURLs =
    previouslyAddedURLs.map(keyword => ({
      name: keyword,
      type: 'URL',
    })) ?? []

  const tableData = [...addedKeywords, ...addedURLs].map((item, itemIdx) => ({
    ...item,
    id: itemIdx,
  })) as ContextualTableData[]

  const filteredTableData = searchTerm
    ? tableData.filter(
        tableItem =>
          tableItem.type.toLowerCase().includes(trimmedLowerCaseSearchTerm) ||
          tableItem.name.toLowerCase().includes(trimmedLowerCaseSearchTerm)
      )
    : tableData

  const clearRowSelection = () => {
    setRowSelection({})
    onRowSelectionChange?.({}, tableData)
  }

  React.useImperativeHandle(ref, () => ({
    clearRowSelection,
  }))

  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ContextualActionPanel
        search={searchTerm}
        setSearch={updateSearchTerm}
        rowSelection={rowSelection}
        clearRowSelection={clearRowSelection}
        tableData={tableData}
        setAddedKeywords={setAddedKeywords}
        setAddedURLs={setAddedURLs}
        hideTableCTAs={hideTableCTAs}
      />
      <Row sx={{ flexGrow: 1, mt: 12 }}>
        <Col xs={12}>
          <ContextualGrid
            data={filteredTableData}
            setRowSelection={setRowSelectionWrapper}
            setSorting={setSorting}
            rowSelection={rowSelection}
            sorting={sorting}
          />
        </Col>
      </Row>
    </Box>
  )
})

ContextualGridWrapper.displayName = 'ContextualGridWrapper'
