import { Typography, Row, Col, Box, Button } from '@applift/factor'
import { Add } from '@applift/icons'

interface AddToGroupOverlayProps {
  text: string
  subText?: string
  actionText?: string
  onAction?: () => void
  illustration: JSX.Element
}

export const AddToGroupOverlay = (props: AddToGroupOverlayProps) => {
  const { text, subText, illustration, actionText, onAction } = props

  return (
    <Row
      sx={{
        flexDirection: 'column',
        height: 100,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Col
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {illustration}
        </Box>
      </Col>
      <Col
        xs={12}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
      >
        {Boolean(text) && (
          <Typography
            component="p"
            variant="bodyMedium"
            weight="demi"
            sx={{
              textAlign: 'center',
            }}
          >
            {text}
          </Typography>
        )}
        {Boolean(subText) && (
          <Typography
            component="p"
            variant="p"
            sx={{
              textAlign: 'center',
              mb: 16,
            }}
          >
            {subText}
          </Typography>
        )}
        {actionText?.length ? (
          <Button
            size="medium"
            startIcon={<Add />}
            color="primary"
            variant="outlined"
            onClick={onAction}
          >
            {actionText}
          </Button>
        ) : null}
      </Col>
    </Row>
  )
}
