import * as React from 'react'
import {
  BaseTextFieldProps,
  SelectAdvanceComponents,
  TextField,
  Typography,
} from '@applift/factor'

import { DealCurationObject } from '../../../models/Filters'
import { useDealCurationData } from '../../../hooks'

interface DealCurationFilterProps {
  TextFieldProps?: Omit<BaseTextFieldProps<DealCurationObject>, 'selectProps'>
  value: DealCurationObject[]
  required?: boolean
  onChange: (creative: DealCurationObject[]) => void
}

export const DealCurationFilter = (props: DealCurationFilterProps) => {
  const { TextFieldProps, value, onChange, required = false } = props
  const apiRef =
    SelectAdvanceComponents.useSelectAdvanceApiRef<DealCurationObject>()

  const currentSelection = React.useMemo(() => {
    return value.reduce((acc: { [key: number]: boolean }, curr) => {
      acc[curr.id] = true
      return acc
    }, {})
  }, [value])

  const renderItem =
    SelectAdvanceComponents.DefaultListOptionItemWrapper<DealCurationObject>({
      disableCheckbox: true,
      selectionStyle: 'none',
      arrowPosition: 'right',
    })

  const { data: curationData } = useDealCurationData()

  return (
    <TextField
      {...TextFieldProps}
      select="advanceSelect"
      variant="outlinedDash"
      placeholder="Select Deal Curation"
      label="Deal Curation"
      required={required}
      SelectProps={{
        slotProps: {
          PaperProps: { sx: { height: 'auto' } },
        },
        allowClearingSingleSelect: !required,
        hideSearch: true,
        data: curationData?.data as DealCurationObject[],
        multiple: false,
        apiRef: apiRef,
        getRowId: row => `${row.id}`,
        rowCount: curationData?.filteredRecords || 0,
        renderValue: value => {
          const selectedItem = Array.isArray(value) ? value : []
          return <Typography>{`${selectedItem[0]?.name}`}</Typography>
        },
        renderListItem: renderItem,
        renderOption: ({ row }) => {
          const curationDeal = row.original.name
          return <Typography>{curationDeal}</Typography>
        },
      }}
      value={currentSelection}
      onChange={selectedRowData => {
        // @ts-ignore
        const rowSelection = selectedRowData(currentSelection)
        const selectedIds = Object.keys(rowSelection).map(id => parseInt(id))
        const value = curationData?.data?.filter(data =>
          selectedIds.includes(data.id)
        )
        onChange(value?.length ? value : [])
      }}
    />
  )
}
