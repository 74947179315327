import * as React from 'react'
import { Add } from '@applift/icons'
import { Box, Button, LoadingButton, Typography } from '@applift/factor'
import { useForm } from '@tanstack/react-form'

import { CreativeTypesFilter } from '../../../../components/Filters/CreativeTypeFIlter'
import { ExchangeFilter } from '../../../../components/Filters/ExchangeFilter'
import { DEFAULT_SIDEBAR_STATE } from '../../../../constants/pmp'
import {
  SearchFilter,
  SearchFilterApiRefType,
} from '../../../../components/Filters/SearchFilter'
import { isEqual } from '../../../../utils/helper'
import { DealStatusFilter } from '../../../../components/Filters/DealStatusFilter'
import { PmpDialog } from '../PmpDialog'
import { PmpSearch } from '../../../../models/Pmp'

interface SidebarProps {
  filters: PmpSearch
  isLoading?: boolean
  groupEditMode?: boolean
  totalRecords?: number
  setFilters?: (filters: PmpSearch) => void
  resetFilters?: () => void
}

export const Sidebar = (props: SidebarProps) => {
  const {
    isLoading,
    filters,
    totalRecords,
    setFilters,
    resetFilters,
    groupEditMode,
  } = props
  const [isDirty, setIsDirty] = React.useState<boolean>(false)
  const [currentAction, setCurrentAction] = React.useState<
    'SEARCH' | 'RESET' | undefined
  >(undefined)
  const [open, setOpen] = React.useState<boolean>(false)

  const searchRef = React.useRef<SearchFilterApiRefType>(null)

  React.useEffect(() => {
    if (!isLoading) {
      setCurrentAction(undefined)
    }
  }, [isLoading])

  React.useEffect(() => {
    if (filters.keywords) {
      const initSearchField = filters.keywords
        .split(',')
        .map(value => ({ label: value, error: false }))
      searchRef.current?.setInitValue(initSearchField)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // No dependency as only want to run initially

  const paramValue = React.useMemo(() => {
    return {
      keywords: filters.keywords,
      creativeTypeList: filters.creativeTypeList,
      exchangeList: filters.exchangeList,
      dealStatus: filters.dealStatus,
    }
  }, [filters])

  const form = useForm({
    defaultValues: paramValue,
    onSubmit: async ({ value }) => {
      setCurrentAction('SEARCH')
      setIsDirty(false)
      setFilters?.(value)
    },
  })

  return (
    <>
      <form
        style={{ height: '100%' }}
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
        onReset={() => {
          setCurrentAction('RESET')
          setIsDirty(false)
          form.reset()
          searchRef.current?.reset()
          resetFilters?.()
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: 100,
            width: 100,
            overflow: 'hidden',
          }}
        >
          <Box sx={{ overflow: 'auto' }}>
            <Box sx={{ px: 12, pt: 24 }}>
              {groupEditMode === true ? null : (
                <>
                  <Typography
                    component={'p'}
                    variant="bodyMedium"
                    weight="demi"
                    sx={{ mb: 16 }}
                  >
                    {`PMP ${totalRecords ? `(${totalRecords})` : ''}`}
                  </Typography>
                  <Button
                    color="primary"
                    disableRipple
                    startIcon={<Add />}
                    sx={{ mb: 24 }}
                    onClick={() => {
                      setOpen(true)
                    }}
                  >
                    Add New Deal
                  </Button>
                </>
              )}
              <form.Field name="keywords">
                {field => (
                  <SearchFilter
                    ref={searchRef}
                    placeholderMsg="Search comma separated deals"
                    onChange={val => {
                      setIsDirty(true)
                      const searchedValues = val
                        .filter(val => !val.error)
                        .map(val => val.label)
                        .join(',')
                      field.handleChange(searchedValues)
                    }}
                  />
                )}
              </form.Field>
              <Typography weight="demi" variant="bodySmall" sx={{ mt: 24 }}>
                Specify your ad type
              </Typography>
              <form.Field name="creativeTypeList">
                {field => {
                  return (
                    <>
                      <CreativeTypesFilter
                        setValue={value => {
                          setIsDirty(true)
                          field.handleChange(value)
                        }}
                        creative={field.state.value ?? []}
                      />
                    </>
                  )
                }}
              </form.Field>
              <form.Field name="exchangeList">
                {field => (
                  <>
                    <Typography
                      weight="demi"
                      variant="bodySmall"
                      sx={{ mt: 24, mb: 16 }}
                    >
                      Narrow your search by
                    </Typography>
                    <ExchangeFilter
                      TextFieldProps={{ sx: { width: 100 } }}
                      value={field.state.value ?? []}
                      onChange={value => {
                        setIsDirty(true)
                        field.handleChange(value)
                      }}
                    />
                  </>
                )}
              </form.Field>
              <form.Field name="dealStatus">
                {field => {
                  return (
                    <>
                      <DealStatusFilter
                        TextFieldProps={{ sx: { width: 100, mt: 16 } }}
                        value={field.state.value ?? []}
                        onChange={value => {
                          setIsDirty(true)
                          const status = value.map(s => s.id)
                          field.handleChange(status)
                        }}
                      />
                    </>
                  )
                }}
              </form.Field>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 8,
              py: 16,
              px: 16,
              borderTop: 1,
              mt: 'auto',
            }}
          >
            <form.Subscribe selector={state => [state.values]}>
              {([values]) => {
                const hasValues = Object.values(values ?? {}).every(
                  value => !value
                )
                const disableReset =
                  isEqual(values, DEFAULT_SIDEBAR_STATE) ||
                  isLoading ||
                  hasValues
                const disableSearch = !isDirty || isLoading
                return (
                  <>
                    <LoadingButton
                      type="reset"
                      variant="outlined"
                      color="error"
                      loading={isLoading && currentAction === 'RESET'}
                      disabled={disableReset}
                    >
                      Reset
                    </LoadingButton>
                    <LoadingButton
                      variant="outlined"
                      type="submit"
                      disabled={disableSearch}
                      loading={isLoading && currentAction === 'SEARCH'}
                    >
                      Search
                    </LoadingButton>
                  </>
                )
              }}
            </form.Subscribe>
          </Box>
        </Box>
      </form>
      {open && (
        <PmpDialog
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </>
  )
}
