import React from 'react'
import { Chip, InputWithTags, Tooltip, Typography } from '@applift/factor'
import { Search } from '@applift/icons'

export interface SearchInventoryType {
  label: string
  error: boolean
}

export interface SearchFilterApiRefType {
  reset: () => void
  setInitValue: (initValue: SearchInventoryType[]) => void
}

interface SearchFilterProps {
  onChange: (value: SearchInventoryType[]) => void
  placeholderMsg: string
}

export const SearchFilter = React.forwardRef<
  SearchFilterApiRefType,
  SearchFilterProps
>((props, ref) => {
  const { onChange, placeholderMsg } = props

  const [enteredTerms, setSearchValue] = React.useState<SearchInventoryType[]>(
    []
  )

  const uniqueByLabel = (newArr: SearchInventoryType[]) => {
    const seen = new Set()
    return newArr.filter(obj => !seen.has(obj.label) && seen.add(obj.label))
  }

  const reset = () => {
    setSearchValue([])
  }

  const setInitValue = (initValue: SearchInventoryType[]) => {
    setSearchValue(initValue)
  }

  React.useImperativeHandle(ref, () => ({
    reset: reset,
    setInitValue: setInitValue,
  }))

  return (
    <Tooltip title={placeholderMsg} placement="top" arrow>
      <>
        <InputWithTags
          placeholder={placeholderMsg}
          value={enteredTerms}
          InputProps={{
            startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
          }}
          onChange={(_, value) => {
            if (value.length > 10) {
              return
            }
            const updatedArr = uniqueByLabel(value)
            setSearchValue(updatedArr)
            onChange(updatedArr)
          }}
          createOption={value => ({
            label: value.trim(),
            error: false,
          })}
          renderTags={(value, getTagProps) =>
            value.map((one, index) =>
              one.label ? (
                <Chip
                  {...getTagProps({ index })}
                  color={one.error ? 'error' : 'primary'}
                  key={index}
                  label={one.label}
                  clickable
                />
              ) : null
            )
          }
          getOptionLabel={value => value.label}
          createOptionOnBlur
          splitCharacter=","
        />
        <Typography sx={{ ml: 8, textColor: 'neutral-600' }} variant="label">
          Max Limit: 10 Keywords
        </Typography>
      </>
    </Tooltip>
  )
})

SearchFilter.displayName = 'SearchFilter'

export default SearchFilter
