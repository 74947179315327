import { TypographyProps, TypoTooltip } from '@applift/factor'
import { CommentTooltip } from '../CommentTooltip/CommentTooltip'

interface ViewCommentCellProps {
  value: string
  isFooterCell?: boolean
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const ViewCommentCell = ({
  value,
  isFooterCell,
  textTypographyProps,
}: ViewCommentCellProps) => {
  return value != null ? (
    <TypoTooltip
      arrow
      title={<CommentTooltip comment={value} />}
      placement="top"
      TypgraphyProps={{
        ...textTypographyProps,
        gutterBottom: false,
        weight: isFooterCell ? 'demi' : 'normal',
      }}
    >
      {value}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
