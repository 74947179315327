import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { getInstance } from './instance'
import {
  campaignsDealCountKey,
  getBasicCampaignListKey,
  getBasicIoCampaignListKey,
} from './QueryKeys'
import { WithResponse } from '../models/Response'
import { IOCampaignList } from '@applift/platform'

export interface BasicCampaignListType {
  campaignId: number
  name: string
  creativeTypeId: number
  status: string
  startTime: number
  endTime: number
  createdAt: number
  modifiedAt: number
  owId: number
  organizationName: string
  organizationLogo: string
  ioBudgetTypeId: number
  ioId: number
  ioName: string
}

export interface BasicCampaignListApiData {
  data: BasicCampaignListType[]
  filteredRecords: number
  totalRecords: number
}

export const getBasicCampaignList = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getBasicCampaignListKey)['keys']>
>): Promise<BasicCampaignListApiData> => {
  const {
    searchField,
    noOfEntries,
    ids,
    campaignIds,
    sortBy,
    pageNo,
    creativeTypeIds,
    status,
  } = queryKey[0] || {}
  try {
    const response: AxiosResponse<WithResponse<BasicCampaignListApiData>> =
      await getInstance().post('/v3/cmp/basic/list', {
        searchField,
        noOfEntries,
        pageNo,
        ids,
        campaignIds,
        creativeTypeIds,
        status,
        ...(sortBy?.length
          ? { sortBy: sortBy[0]?.id, order: sortBy[0]?.desc ? 'desc' : 'asc' }
          : {}),
      })

    return response.data.data
  } catch (e) {
    throw Promise.reject((e as AxiosError).response)
  }
}

export interface BasicIoCampaignListApiData {
  ioCampaignsList: IOCampaignList[]
  filteredRecords: number
  totalRecords: number
}

export const getBasicIoCampaignList = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getBasicIoCampaignListKey)['keys']>
>): Promise<BasicIoCampaignListApiData> => {
  const {
    searchField,
    noOfEntries,
    ids,
    campaignIds,
    sortBy,
    pageNo,
    creativeTypeIds,
    ioBudgetTypeIdsList,
    ioIdList,
    ioStatusIdsList,
    isAllOWIds,
    offset,
    owIdList,
    campaignStatus,
  } = queryKey[0] || {}
  try {
    const response: AxiosResponse<WithResponse<BasicIoCampaignListApiData>> =
      await getInstance().post('/v3/cmp/io/campaign/basic/list', {
        searchField,
        noOfEntries,
        pageNo,
        ids,
        campaignIds,
        creativeTypeIds,
        ioBudgetTypeIdsList,
        ioIdList,
        ioStatusIdsList,
        isAllOWIds,
        offset,
        owIdList,
        ...(campaignStatus?.length ? { status: campaignStatus } : {}),
        ...(sortBy?.length
          ? { sortBy: sortBy[0]?.id, order: sortBy[0]?.desc ? 'desc' : 'asc' }
          : {}),
      })

    return response.data.data
  } catch (e) {
    throw Promise.reject((e as AxiosError).response)
  }
}

export const getCampaignsDealCount = async ({
  signal,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof campaignsDealCountKey)['keys']>
>) => {
  try {
    const { campaignTypeId } = queryKey[0]!
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    const response: AxiosResponse<WithResponse<any>> = await getInstance().get(
      `/v3/cmp/campaigns/${campaignTypeId}/deals`,
      {
        signal,
      }
    )
    return response.data.data
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}
