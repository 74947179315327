import { GridRenderEditCellParams } from '@applift/datagrid'
import { EditableCell } from '../../../../components/CellTypes/EditTableCell'

export const EditCommentCell = (props: GridRenderEditCellParams) => {
  return (
    <EditableCell
      /* eslint-disable-next-line */
      {...props}
    />
  )
}
