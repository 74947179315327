import * as React from 'react'
import {
  Row,
  Col,
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  DialogTitle,
  DialogContent,
  LoadingButton,
  DialogActions,
  enqueueSnackbar,
} from '@applift/factor'
import { useMutation } from '@tanstack/react-query'
import { ORGANIZATION_TYPE, useAppContext } from '@applift/platform'

import {
  PgFormState,
  PgCreationForm,
  PgCreationApiRef,
} from '../../PgDialog/PgCreationForm'
import { editPgDeal } from '../../../../../api'
import { queryClient } from '../../../../../cache'
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_LIST_SORTING,
} from '../../PgCampaignDialog/CampaignGridWrapper'
import { PgListResponse } from '../../../../../models/Pg'
import { CampaignGrid } from '../../PgCampaignDialog/CampaignGrid'
import { assignedCampaignColDef } from './assingnedCampaignColDef'
import { useBasicCampaignList } from '../../../../../hooks/useCampaign'
import { useExchangeList, usePaymentTypes } from '../../../../../hooks'
import { CommonFilter, DealStatusIds } from '../../../../../models/Filters'

interface EditPgDealDialogProps {
  onClose: () => void
  onSuccess?: () => void
  data: PgListResponse
}

export const EditPgDealDialog = (props: EditPgDealDialogProps) => {
  const { onClose, data, onSuccess } = props

  const [disableSubmit, setDisableSubmit] = React.useState<boolean>(true)

  const pgFormRef = React.useRef<PgCreationApiRef>(null)

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const editPgMutation = useMutation(editPgDeal, {
    mutationKey: ['editPgDeal'],
    onSuccess: () => {
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getPgList',
      })
      enqueueSnackbar('PG Deal saved successfully', {
        variant: 'success',
      })
      onSuccess?.()
      onClose()
    },
    onError: e => {
      // @ts-ignore
      const errorMessage = e?.errorObjects?.[0]?.error as string

      if (errorMessage.includes('already exists')) {
        pgFormRef.current?.setError({
          dealId:
            'Deal ID already exists for selected exchange. Use a different Deal ID or Exchange',
        })
      } else {
        enqueueSnackbar(
          errorMessage?.length
            ? errorMessage
            : 'Something went wrong. Please try after some time.',
          {
            variant: 'error',
          }
        )
        onSuccess?.()
      }
    },
  })

  const onSubmit = async (value: Partial<PgFormState>) => {
    const params = {
      ...(Object.prototype.hasOwnProperty.call(value, 'dealId')
        ? { dealId: value.dealId }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'dealName')
        ? { dealName: value.dealName }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'description')
        ? { description: value.description }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'paymentTo')
        ? { paymentTypeId: value?.paymentTo?.map(creative => creative.id)[0] }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'exchange')
        ? { exchangeId: value?.exchange?.[0]?.value }
        : {}),
      ...(Object.prototype.hasOwnProperty.call(value, 'dealStatus')
        ? { statusId: value.dealStatus }
        : {}),
    }

    editPgMutation.mutate({ params: params, dealId: data.id })
  }

  const { data: exchangeData } = useExchangeList()
  const { data: paymentTypeData } = usePaymentTypes()

  const initExchange = React.useMemo(
    () =>
      exchangeData?.exchangeList.filter(exchange => {
        return data.exchangeId === exchange.value
      }),
    [data, exchangeData]
  )
  const initPaymentType = React.useMemo(
    () =>
      paymentTypeData?.pgPaymentTypes?.filter(
        payment => data.paymentTypeId === payment.id
      ),
    [data, paymentTypeData]
  )

  const formInitValue = React.useMemo((): PgFormState => {
    return {
      dealId: data.dealId,
      dealName: data.dealName,
      exchange: initExchange as CommonFilter[],
      dealStatus: data.statusId as DealStatusIds,
      paymentTo: initPaymentType as any,
      description: data.description,
    }
  }, [data, initExchange, initPaymentType])

  const activeCampaignIds = data?.activeCampaignIds
    ? data?.activeCampaignIds
    : []
  const otherCam = data?.otherCampaignIds ? data?.otherCampaignIds : []
  // Table state
  const {
    data: campaignListData,
    isFetching: campaignListLoading,
    fetchNextPage: fetchNextPageGrid,
    isError,
  } = useBasicCampaignList(
    '',
    [...activeCampaignIds, ...otherCam] ?? [],
    DEFAULT_LIST_SORTING,
    undefined,
    undefined,
    undefined,
    { enabled: Boolean([...activeCampaignIds, ...otherCam].length) }
  )

  const flatData = React.useMemo(() => {
    return (
      campaignListData?.pages
        ?.map(page => {
          return page.data ?? []
        })
        .flat(1) || []
    )
  }, [campaignListData])

  const filteredRecords = React.useMemo(
    () => campaignListData?.pages[0]?.filteredRecords,
    [campaignListData?.pages]
  )

  const totalRecords = React.useMemo(
    () => campaignListData?.pages[0]?.totalRecords,
    [campaignListData?.pages]
  )

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if (
      !flatData.length &&
      !campaignListLoading &&
      !filteredRecords &&
      totalRecords
    ) {
      return 'noResult'
    }
    if (!flatData.length && !campaignListLoading) {
      return 'noResult'
    }
    return undefined
  }, [
    isError,
    totalRecords,
    flatData.length,
    filteredRecords,
    campaignListLoading,
  ])

  const showCampaignTable = Boolean([...activeCampaignIds, ...otherCam].length)

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      PaperProps={{ sx: { ...(showCampaignTable ? { height: 100 } : {}) } }}
    >
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <Typography weight="demi">Edit Deal</Typography>
      </DialogTitle>
      <DialogContent
        sx={{ display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        dividers
      >
        <Row>
          <Col>
            <PgCreationForm
              ref={pgFormRef}
              initValue={formInitValue}
              dealAssignedToCampaign={Boolean(
                [...activeCampaignIds, ...otherCam].length
              )}
              onChange={() => {
                const disable =
                  !pgFormRef.current?.isAllRequiredFilled() ||
                  pgFormRef.current?.hasError() ||
                  !pgFormRef.current?.hasChange()
                setDisableSubmit(disable)
              }}
            />
          </Col>
        </Row>
        {showCampaignTable ? (
          <>
            <Divider sx={{ my: 24 }} color="secondary" />
            <Row sx={{ flexGrow: 1 }}>
              <Col>
                <Box sx={{ height: 100 }}>
                  <Typography component="p" variant="bodySmall" sx={{ mb: 16 }}>
                    The deal will also be updated in the below campaigns.
                  </Typography>
                  <CampaignGrid
                    data={flatData}
                    columnDef={assignedCampaignColDef}
                    totalRecords={totalRecords || 0}
                    filteredRecords={filteredRecords || 0}
                    rowIdKey="id"
                    pageSize={DEFAULT_PAGE_SIZE}
                    defaultSorting={[{ id: 'id', desc: true }]}
                    loading={campaignListLoading}
                    onFetchRows={() => {
                      // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                      setTimeout(fetchNextPageGrid, 100)
                    }}
                    overlay={overlay}
                    initialState={{
                      columnVisibility: {
                        owId: orgType !== ORGANIZATION_TYPE.ADVERTISER,
                        organizationName:
                          orgType !== ORGANIZATION_TYPE.ADVERTISER,
                      },
                    }}
                  />
                </Box>
              </Col>
            </Row>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={disableSubmit}
          loading={editPgMutation.isLoading}
          onClick={() => {
            const changedState = pgFormRef.current?.getChangedState()
            if (changedState) {
              onSubmit(changedState)
            }
          }}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
