import { Col, Row, SkeletonSwitchProvider } from '@applift/factor'

import { DisplayDistributionData } from '../../components/DisplayDistributionData/DisplayDistributionData'
import { DistributionResponse } from '../../models/OpenExchange'

interface DisplayDistributionDataWrapperProps {
  data?: DistributionResponse
  isFetching?: boolean
}

export const DisplayDistributionDataWrapper = (
  props: DisplayDistributionDataWrapperProps
) => {
  const { data, isFetching } = props

  return (
    <SkeletonSwitchProvider animation="wave" loading={isFetching}>
      <Row xs={{ gutterSize: 16, gutterDirection: 'x' }} sx={{ height: 100 }}>
        <Col xs={3} sx={{ height: 100 }}>
          <DisplayDistributionData
            skeletonBarCount={5}
            data={data?.countries}
            chartName="Geography"
          />
        </Col>
        <Col xs={3} sx={{ height: 100 }}>
          <DisplayDistributionData
            skeletonBarCount={5}
            data={data?.deviceTypes}
            chartName="Device Type"
          />
        </Col>
        <Col xs={3} sx={{ height: 100 }}>
          <DisplayDistributionData
            skeletonBarCount={4}
            data={data?.creativeTypes}
            chartName="Creative type"
          />
        </Col>
        <Col xs={3} sx={{ height: 100 }}>
          <DisplayDistributionData
            skeletonBarCount={2}
            data={data?.trafficTypes}
            chartName="Channel"
          />
        </Col>
      </Row>
    </SkeletonSwitchProvider>
  )
}
