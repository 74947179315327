import * as React from 'react'
import {
  Badge,
  BaseTextFieldProps,
  SelectAdvanceComponents,
  TextField,
  Typography,
  sx,
} from '@applift/factor'

import { DealStatus } from '../../../models/Filters'
import { useDealStatusData } from '../../../hooks'
import { toInitCap } from '../../../utils/format'

interface DealStatusFilterProps {
  TextFieldProps?: Omit<BaseTextFieldProps<DealStatus>, 'selectProps'>
  value: number[]
  onChange: (creative: DealStatus[]) => void
}

export const DealStatusFilter = (props: DealStatusFilterProps) => {
  const { TextFieldProps, value, onChange } = props
  const apiRef = SelectAdvanceComponents.useSelectAdvanceApiRef<DealStatus>()

  const currentSelection = React.useMemo(() => {
    return value.reduce((acc: { [key: number]: boolean }, curr) => {
      acc[curr] = true
      return acc
    }, {})
  }, [value])

  const renderItem =
    SelectAdvanceComponents.DefaultListOptionItemWrapper<DealStatus>({
      disableCheckbox: true,
      selectionStyle: 'none',
      arrowPosition: 'right',
    })

  const { data, isFetching } = useDealStatusData()

  const dealStatus = data?.dealStatuses

  return isFetching ? (
    <></>
  ) : (
    <TextField
      {...TextFieldProps}
      select="advanceSelect"
      placeholder="Select Status"
      label="Deal Status"
      variant="outlinedDash"
      classes={{ root: sx({ height: 50 }) }}
      SelectProps={{
        slotProps: {
          PaperProps: { sx: { height: 'auto' } },
        },
        allowClearingSingleSelect: true,
        classes: {},
        hideSearch: true,
        hideSelectNav: true,
        data: dealStatus || [],
        multiple: true,
        apiRef: apiRef,
        getRowId: row => `${row.id}`,
        rowCount: dealStatus?.length ?? 0,
        renderValue: value => {
          const selecteItem = Array.isArray(value) ? value : []
          if (selecteItem.length === dealStatus?.length) {
            return (
              <>
                <Typography>{`All Selected`}</Typography>
              </>
            )
          }
          if (selecteItem.length > 1) {
            return (
              <>
                <Typography>{`${selecteItem.length} Selected`}</Typography>
              </>
            )
          }

          return (
            <>
              <Typography>{`${selecteItem[0]?.label ? selecteItem[0]?.label : ''}`}</Typography>
            </>
          )
        },
        renderListItem: renderItem,
        renderOption: ({ row }) => {
          const status = row.original.label
          const isActive = row.original.id === 1
          return (
            <>
              <Badge
                color={isActive ? 'success' : 'error'}
                variant="dot"
                sx={{ m: 4 }}
                size="small"
              />
              <Typography>{toInitCap(status)}</Typography>
            </>
          )
        },
      }}
      value={currentSelection}
      onChange={selectedRowData => {
        // @ts-ignore
        const rowSelection = selectedRowData(currentSelection)
        const selectedIds = Object.keys(rowSelection).map(id => parseInt(id))
        const value = dealStatus?.filter(data => selectedIds.includes(data?.id))
        onChange(value?.length ? value : [])
      }}
    />
  )
}
