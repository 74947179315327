import { ColumnDef } from '@applift/datagrid'

import {
  IoNameCell,
  RawDataCell,
  IconTextCell,
  CampaignNameCell,
} from '../../../../../../components/CellTypes'
import { toInitCap } from '../../../../../../utils/format'
import { AssignCampaignDeleteCell } from '../../../Celltype'
import { CampaignInfoType } from '../../../../../../models/Group'

export const colDef: ColumnDef<CampaignInfoType>[] = [
  {
    accessorKey: 'id',
    id: 'id',
    header: 'Campaign ID',
    size: 150,
    maxSize: 500,
    minSize: 100,
    cell: props => {
      return (
        <RawDataCell
          textTypographyProps={{ variant: 'bodySmall' }}
          value={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Campaign Name',
    size: 300,
    maxSize: 500,
    minSize: 100,
    sortingFn: (rowA, rowB) => {
      return rowA.original.name.toLowerCase() < rowB.original.name.toLowerCase()
        ? -1
        : 1
    },
    cell: props => {
      return (
        <CampaignNameCell
          campaignName={props.renderValue() as string}
          creativeTypeId={props.row.original.creativeTypeId}
          textTypographyProps={{ variant: 'bodySmall' }}
        />
      )
    },
  },
  {
    accessorKey: 'status',
    id: 'status',
    header: 'Campaign Status',
    size: 150,
    maxSize: 350,
    minSize: 120,
    sortingFn: (rowA, rowB) => {
      return rowA.original.status.toLowerCase() <
        rowB.original.status.toLowerCase()
        ? -1
        : 1
    },
    cell: props => {
      return (
        <IconTextCell
          iconName={props.renderValue() as string}
          iconSize={20}
          label={toInitCap(props.renderValue() as string)}
          textTypographyProps={{ variant: 'bodySmall' }}
        />
      )
    },
  },
  {
    accessorKey: 'ioId',
    id: 'ioId',
    header: 'Insertion Order ID',
    size: 150,
    maxSize: 350,
    minSize: 120,
    cell: props => {
      return (
        <RawDataCell
          textTypographyProps={{ variant: 'bodySmall' }}
          value={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'ioName',
    id: 'ioName',
    header: 'Insertion Order Name',
    size: 300,
    maxSize: 350,
    minSize: 120,
    sortingFn: (rowA, rowB) => {
      return rowA.original.ioName.toLowerCase() <
        rowB.original.ioName.toLowerCase()
        ? -1
        : 1
    },
    cell: props => {
      return (
        <IoNameCell
          textTypographyProps={{ variant: 'bodySmall' }}
          ioName={props.renderValue() as string}
          ioTypeId={props.row.original?.ioBudgetTypeId}
        />
      )
    },
  },
  {
    header: 'Action',
    id: 'action',
    // @ts-ignore
    cell: props => (
      <AssignCampaignDeleteCell
        onAction={props.onAction}
        row={props.row.original}
        meta={props?.table?.options?.meta}
      />
    ),
    enableSorting: false,
    meta: {
      align: 'center',
    },
    size: 60,
  },
]
