import { Box, Typography, Skeleton, TypoTooltip } from '@applift/factor'

import { formatNumberWithSuffix } from '../../utils/format'

interface OpenExchangeSidebarMatrixProps {
  stats: { label: string; value: number }[]
  areStatsLoading: boolean
}

export const OpenExchangeSidebarMatrix = (
  props: OpenExchangeSidebarMatrixProps
) => {
  const { stats, areStatsLoading } = props

  return (
    <Box
      sx={{
        py: 16,
        px: 12,
        gap: 8,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        bgColor: 'neutral-75',
      }}
    >
      Total Open Exchange Inventories
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'evenly',
          width: 100,
          gap: 8,
          flexWrap: 'wrap',
        }}
      >
        {areStatsLoading ? (
          <>
            {new Array(4).fill(null).map((_, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  gap: 2,
                  flexDirection: 'column',
                }}
              >
                <Skeleton height={20} width={50} />
                <Skeleton height={20} width={35} />
              </Box>
            ))}
          </>
        ) : (
          ['inventories', 'publishers', 'impressions', 'reach'].map(stat => (
            <Box
              key={stat}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                textColor: 'neutral-600',
              }}
            >
              <Typography variant="label">
                {stat[0]?.toUpperCase() + stat.substring(1)}
              </Typography>
              <TypoTooltip
                TypgraphyProps={{ sx: { textWeight: 'demi' } }}
                sx={{ display: 'block' }}
              >
                {formatNumberWithSuffix(
                  stats.find(statValue => statValue.label === stat)
                    ?.value as number
                )}
              </TypoTooltip>
            </Box>
          ))
        )}
      </Box>
    </Box>
  )
}
