import {
  TypoTooltip,
  Box,
  SkeletonSwitch,
  LinearProgress,
  Typography,
} from '@applift/factor'

import { LabelValue } from '../../models/OpenExchange'

interface DisplayDistributionDataProps {
  skeletonBarCount: number
  data?: LabelValue[]
  chartName: string
}

export const DisplayDistributionData = (
  props: DisplayDistributionDataProps
) => {
  const { skeletonBarCount, data, chartName } = props

  const getColor = (percentage: number) => {
    switch (true) {
      case percentage <= 20:
        return 'primary-400'
      case percentage <= 40:
        return 'primary-500'
      case percentage <= 60:
        return 'primary-600'
      case percentage <= 80:
        return 'primary-700'
      case percentage <= 100:
        return 'primary-800'
    }
    return 'primary-800'
  }

  return (
    <Box
      sx={{
        borderRadius: 4,
        boxShadow: 200,
        pt: 16,
        px: 24,
        height: 100,
      }}
    >
      <SkeletonSwitch sx={{ my: 8 }}>
        <TypoTooltip
          TypgraphyProps={{
            component: 'h4',
            variant: 'bodyMedium',
            weight: 'demi',
            gutterBottom: false,
          }}
          arrow
        >
          {chartName}
        </TypoTooltip>
      </SkeletonSwitch>
      {Array.from({ length: data?.length ?? skeletonBarCount }, (_, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            gap: 4,
            my: 16,
            width: 100,
          }}
        >
          <SkeletonSwitch height={10} sx={{ width: 100 }}>
            {loading =>
              loading ? (
                <TypoTooltip
                  TypgraphyProps={{
                    component: 'h2',
                    variant: 'bodySmall',
                    gutterBottom: false,
                  }}
                  arrow
                >
                  Progress Bar Progress Bar Progr
                </TypoTooltip>
              ) : (
                <LinearProgress
                  variant="determinate"
                  value={data?.[index]?.value}
                  sx={{ width: 100 }}
                  style={{
                    // @ts-ignore
                    '--progressBarHeight': '6px',
                    '--progressBarColor': `var(--${getColor(data?.[index]?.value ?? 0)})`,
                    '--progressBarBufferColor': `transparent`,
                  }}
                />
              )
            }
          </SkeletonSwitch>
          <SkeletonSwitch sx={{ width: 100 }}>
            <TypoTooltip
              TypgraphyProps={{
                gutterBottom: false,
              }}
              sx={{ width: 100 }}
              arrow
            >
              <Box sx={{ display: 'flex', overflow: 'hidden', width: 100 }}>
                <Typography component="span">
                  {typeof data?.[index]?.value === 'number'
                    ? `${data?.[index]?.value}%`
                    : // below text for skeleton
                      'XXXXXXXXXX'}
                </Typography>
                <TypoTooltip
                  TypgraphyProps={{
                    variant: 'bodySmall',
                    component: 'span',
                  }}
                  sx={{ ml: 4, textColor: 'neutral-600' }}
                  title={data?.[index]?.label}
                >
                  {/* below text for skeleton */}
                  {data?.[index]?.label ?? 'XXXXXXXXXX'}
                </TypoTooltip>
              </Box>
            </TypoTooltip>
          </SkeletonSwitch>
        </Box>
      ))}
    </Box>
  )
}
