import { ColumnDef } from '@applift/datagrid'
import {
  RawDataCell,
  CustomerNameCell,
  IconTextCell,
} from '../../../../../components/CellTypes'
import { BasicCustomerList } from '../../../../../models/Customer'

export const colDef: ColumnDef<BasicCustomerList>[] = [
  {
    accessorKey: 'owId',
    header: 'Customer ID',
    id: 'owId',
    size: 130,
    minSize: 100,
    maxSize: 200,
    cell: props => <RawDataCell value={`${props.renderValue()}`} />,
    meta: {
      description: 'Campaign ID',
      excludeColumnFromColumnVisibility: true,
    },
  },
  {
    accessorKey: 'organizationName',
    id: 'organizationName',
    header: 'Customer Name',
    size: 210,
    minSize: 150,
    maxSize: 500,
    sortingFn: (rowA, rowB) => {
      return rowA.original.organizationName.toLowerCase() <
        rowB.original.organizationName.toLowerCase()
        ? -1
        : 1
    },
    cell: props => {
      const rowData = props.row.original
      return (
        <CustomerNameCell
          customerName={rowData.organizationName}
          avtarPath={rowData?.organizationLogo}
        />
      )
    },
  },
  {
    accessorKey: 'owStatus',
    id: 'owStatus',
    header: 'Campaign Status',
    size: 150,
    minSize: 100,
    maxSize: 200,
    enableSorting: false,
    cell: props => {
      return (
        <IconTextCell
          iconName={(props.renderValue() as string) || 'Error'}
          iconSize={16}
          label={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'runningCampaigns',
    header: 'Running Campaigns',
    id: 'runningCampaigns',
    size: 180,
    minSize: 100,
    maxSize: 200,
    cell: props => <RawDataCell value={`${props.renderValue()}`} />,
  },
  {
    accessorKey: 'ownerUserEmail',
    id: 'ownerUserEmail',
    header: 'Email',
    size: 240,
    minSize: 150,
    maxSize: 500,
    cell: props => <RawDataCell value={`${props.renderValue()}`} />,
  },
]
