import axios, { AxiosError, AxiosResponse } from 'axios'
import { QueryFunctionContext, QueryMeta } from '@tanstack/react-query'

import { getInstance } from './instance'
import { WithResponse } from '../models/Response'
import {
  DistributionResponse,
  DownloadOpenExchangeSearchedInventoriesRequest,
  ExchangeListFromFileResponse,
  ExchangeListResponse,
  ExchangeMatrixCountResponse,
} from '../models/OpenExchange'
import {
  getDistributionDataFromCSVKey,
  getDistributionDataKey,
  getExchangeMatrixCountKey,
  getOpenExchangeCountDataFromCSVKey,
  getOpenExchangeListingDataFromCSVKey,
  getOpenExchangeListingDataKey,
} from './QueryKeys'
import { DEFAULT_NUMBER_OF_LIST_RECORDS } from '../constants/exchange'
import { getPayloadFromFilterSelection } from '../routes/exchange/-component/ExchangeGrid/helpers'
import { OpenExchangeSidebarSearchValues } from '../routes/exchange'

export const getDistributionData = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getDistributionDataKey)['keys']>
>) => {
  const { filters } = queryKey[0]!

  const {
    videoPlayerSizes,
    creativeDurations,
    creativeSizes,
    creativeTypes,
    keywords,
    categories,
    countries,
    deviceTypes,
    inventoryTypes,
    exchanges,
    trafficTypes,
  } = getPayloadFromFilterSelection(filters)

  try {
    const params = {
      ...(videoPlayerSizes ? { videoPlayerSizes } : {}),
      ...(creativeDurations ? { creativeDurations } : {}),
      ...(creativeSizes ? { creativeSizes } : {}),
      ...(creativeTypes ? { creativeTypes } : {}),
      ...(categories ? { categories } : {}),
      ...(keywords ? { keywords } : {}),
      ...(countries ? { countries } : {}),
      ...(deviceTypes ? { deviceTypes } : {}),
      ...(inventoryTypes ? { inventoryTypes } : {}),
      ...(exchanges ? { exchanges } : {}),
      ...(trafficTypes ? { trafficTypes } : {}),
    }
    const paramString = new URLSearchParams(params).toString()
    const { CancelToken } = axios
    const source = CancelToken.source()

    signal?.addEventListener('abort', () => {
      source.cancel(`v3/inv/inventories/distribution - Request cancelled`)
    })

    const response: AxiosResponse<WithResponse<DistributionResponse>> =
      await getInstance().get(
        `/v3/inv/inventories/distributions?${paramString}`
      )

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getDistributionDataFromCSV = async ({
  signal,
  meta,
}: QueryFunctionContext<
  ReturnType<(typeof getDistributionDataFromCSVKey)['keys']>
>) => {
  try {
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    const { formData } = meta as QueryMeta

    const response: AxiosResponse<WithResponse<DistributionResponse>> =
      await getInstance().post(
        'v3/inv/inventories/csv/distributions',
        formData as FormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: source.token,
        }
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getExchangeCountData = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getExchangeMatrixCountKey)['keys']>
>) => {
  const {
    videoPlayerSizes,
    creativeDurations,
    creativeSizes,
    creativeTypes,
    keywords,
    categories,
    countries,
    deviceTypes,
    inventoryTypes,
    exchanges,
    trafficTypes,
  } = queryKey[0]!
  try {
    const params = {
      ...(videoPlayerSizes ? { videoPlayerSizes } : {}),
      ...(creativeDurations ? { creativeDurations } : {}),
      ...(creativeSizes ? { creativeSizes } : {}),
      ...(creativeTypes ? { creativeTypes } : {}),
      ...(categories ? { categories } : {}),
      ...(keywords ? { keywords } : {}),
      ...(countries ? { countries } : {}),
      ...(deviceTypes ? { deviceTypes } : {}),
      ...(inventoryTypes ? { inventoryTypes } : {}),
      ...(exchanges ? { exchanges } : {}),
      ...(trafficTypes ? { trafficTypes } : {}),
    }

    const { CancelToken } = axios
    const source = CancelToken.source()

    signal?.addEventListener('abort', () => {
      source.cancel(`v3/inv/inventories/count - Request cancelled`)
    })

    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<WithResponse<ExchangeMatrixCountResponse>> =
      await getInstance().get(`/v3/inv/inventories/count?${paramString}`, {
        signal,
      })

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getExchangeCountDataFromCSV = async ({
  signal,
  meta,
}: QueryFunctionContext<
  ReturnType<(typeof getOpenExchangeCountDataFromCSVKey)['keys']>
>) => {
  try {
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    const { formData } = meta as QueryMeta

    const response: AxiosResponse<WithResponse<ExchangeMatrixCountResponse>> =
      await getInstance().post(
        'v3/inv/inventories/csv/count',
        formData as FormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          cancelToken: source.token,
        }
      )
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getOpenExchangeListingDataFromCSV = async ({
  signal,
  meta,
}: QueryFunctionContext<
  ReturnType<(typeof getOpenExchangeListingDataFromCSVKey)['keys']>
>) => {
  try {
    const { CancelToken } = axios
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('v3/inv/inventories/csv/list - Request was cancelled')
    })

    const { formData } = meta as any
    const response: AxiosResponse<WithResponse<ExchangeListFromFileResponse>> =
      await getInstance().post('v3/inv/inventories/csv/list', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        cancelToken: source.token,
      })
    return {
      matchedInventories:
        response.data?.data?.matchedInventories.map(exchange => ({
          ...exchange,
          uniqueId: crypto.randomUUID() as string,
        })) ?? [],
      unmatchedBundles: response.data?.data?.unmatchedBundles ?? [],
      unmatchedDomains: response.data?.data?.unmatchedDomains ?? [],
    }
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getOpenExchangeListingData = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getOpenExchangeListingDataKey)['keys']>
>) => {
  const { filters } = queryKey[0]!
  const {
    videoPlayerSizes,
    creativeDurations,
    creativeSizes,
    creativeTypes,
    keywords,
    categories,
    countries,
    deviceTypes,
    inventoryTypes,
    exchanges,
    trafficTypes,
  } = getPayloadFromFilterSelection(filters)
  try {
    const params = {
      ...(videoPlayerSizes ? { videoPlayerSizes } : {}),
      ...(creativeDurations ? { creativeDurations } : {}),
      ...(creativeSizes ? { creativeSizes } : {}),
      ...(creativeTypes ? { creativeTypes } : {}),
      ...(categories ? { categories } : {}),
      ...(keywords ? { keywords } : {}),
      ...(countries ? { countries } : {}),
      ...(deviceTypes ? { deviceTypes } : {}),
      ...(inventoryTypes ? { inventoryTypes } : {}),
      ...(exchanges ? { exchanges } : {}),
      ...(trafficTypes ? { trafficTypes } : {}),
      noOfEntries: String(DEFAULT_NUMBER_OF_LIST_RECORDS),
      pageNo: String(pageParam),
    }

    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<WithResponse<ExchangeListResponse>> =
      await getInstance().get(`/v3/inv/inventories/list?${paramString}`)

    return {
      inventoryDataList: response.data.data.inventoryDataList.map(item => ({
        ...item,
        uniqueId: crypto.randomUUID() as string,
      })),
    }
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const downloadOpenExchangeSearchedInventories = async (
  payload: DownloadOpenExchangeSearchedInventoriesRequest
) => {
  try {
    const { filters, isCsvSearch, fileType, formData } = payload

    const {
      keywords,
      countries,
      deviceTypes,
      trafficTypes,
      inventoryTypes,
      creativeTypes,
      categories,
      exchanges,
      videoPlayerSizes,
      creativeSizes,
      creativeDurations,
    } = getPayloadFromFilterSelection(
      (filters ?? {}) as OpenExchangeSidebarSearchValues
    )

    const params = {
      ...(videoPlayerSizes ? { videoPlayerSizes } : {}),
      ...(creativeDurations ? { creativeDurations } : {}),
      ...(creativeSizes ? { creativeSizes } : {}),
      ...(creativeTypes ? { creativeTypes } : {}),
      ...(categories ? { categories } : {}),
      ...(keywords ? { keywords } : {}),
      ...(countries ? { countries } : {}),
      ...(deviceTypes ? { deviceTypes } : {}),
      ...(inventoryTypes ? { inventoryTypes } : {}),
      ...(exchanges ? { exchanges } : {}),
      ...(trafficTypes ? { trafficTypes } : {}),
      ...(fileType ? { fileType } : {}),
      ...(typeof isCsvSearch === 'boolean'
        ? { isCsvSearch: `${isCsvSearch}` }
        : {}),
    }

    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().post(
        `v3/inv/inventories/open-exchange/download?${paramString}`,
        formData,
        {
          headers: formData
            ? {
                'Content-Type': 'multipart/form-data',
              }
            : undefined,
        }
      )

    window.open(response.data.data)
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
