import * as React from 'react'
import { SortingState } from '@applift/datagrid'
import {
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useMutation,
} from '@tanstack/react-query'

import { deletePgDeal, getPgList } from '../api/pg'
import { getPgListKey } from '../api/QueryKeys'
import { PgListResponse, PgTableResponse } from '../models/Pg'

export const usePgList = (
  searchField: string,
  exchangeIds: string,
  statusIds?: number,
  sorting?: SortingState,
  noOfEntries = 50,
  options?: {
    enabled?: UseInfiniteQueryOptions<PgListResponse>['enabled']
  }
): UseInfiniteQueryResult<PgTableResponse> => {
  const sortBy = React.useMemo(
    () => (sorting && sorting?.length > 0 ? sorting[0]?.id : ''),
    [sorting]
  )
  const order = React.useMemo(() => {
    if (sortBy) {
      return sorting && sorting?.length > 0 && sorting[0]?.desc ? 'DESC' : 'ASC'
    }
    return undefined
  }, [sorting, sortBy])

  const queryData = useInfiniteQuery(
    getPgListKey.keys({
      scope: 'getPgList',
      statusIds,
      searchField,
      exchangeIds,
      noOfEntries,
      order,
      sortBy,
    }),
    getPgList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce((prev, one) => {
          const datalength = one?.pgDealData?.length
          if (datalength) {
            return prev + datalength
          }
          return prev
        }, 0)
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return null
      },
      enabled: options?.enabled ?? true,
      cacheTime: 0,
    }
  )
  return queryData
}

export const usePgDelete = (options?: {
  onSuccess?: (res: any, payload: any) => void
  onError?: (props: any) => void
  onSettled?: (props: any) => void
}) => {
  const mutationResult = useMutation(deletePgDeal, {
    mutationKey: ['deletePgDeal'],
    onSuccess: options?.onSuccess,
    onError: options?.onError,
    onSettled: options?.onSettled,
  })

  return mutationResult
}
