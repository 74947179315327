import { GridActionCellParams } from '@applift/datagrid'
import { IconButton, Tooltip, Box } from '@applift/factor'
import { Delete } from '@applift/icons'

export interface DeleteCellProps {
  onAction?: (params: GridActionCellParams<string, any>) => void
  row?: any
  meta?: any
}

export const DeleteCell = (props: DeleteCellProps) => {
  const { onAction, row, meta } = props

  const handleRemoveSelection = () => {
    onAction?.({
      actionName: 'removeRow',
      metaData: { rowId: row?.id },
    })
  }
  const expiredCamapign = row.status === 'expired'
  const rejectedStatus = ['deleted', 'expired', 'rejected']

  const deleteDisabled =
    (meta?.deleteDisabled?.includes?.(row?.id) &&
      !rejectedStatus.includes(row.status)) ||
    expiredCamapign

  const tooltipMessage = () => {
    if (expiredCamapign) {
      return 'PG Deals can’t be removed from expired campaigns'
    }
    if (meta?.deleteDisabled?.includes?.(row?.id)) {
      return 'This campaign cannot be removed because it is the only associated deal'
    }
    return ''
  }

  return (
    <Tooltip title={tooltipMessage()} placement="top" arrow>
      <Box>
        <IconButton
          onClick={handleRemoveSelection}
          sx={{ fontSize: 24, textColor: 'neutral-400' }}
          borderRadius="circle"
          disabled={deleteDisabled}
        >
          <Delete />
        </IconButton>
      </Box>
    </Tooltip>
  )
}
