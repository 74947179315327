import * as React from 'react'
import {
  Button,
  Dialog,
  Typography,
  DialogTitle,
  TypoTooltip,
  LoadingButton,
  DialogActions,
  enqueueSnackbar,
} from '@applift/factor'
import {
  normalizeCampaignData,
  normalizeIOCampaignData,
  NormalizedIOCampaignList,
} from '@applift/platform'

import { useAssignGroupToCampaign } from '../../../../hooks'
import {
  CampaignInfoType,
  GroupListTableResponse,
} from '../../../../models/Group'
import { ExcludeInventoryGroupFromCampaignArgs } from '../../../../api'
import { ScreenLoader } from '../../../../components/ScreenLoader'
import { useBasicIoCampaignList } from '../../../../hooks/useCampaign'
import { AssignToCampaignsDialogContent } from './AssignToCampaignsDialogContent'
import { queryClient } from '../../../../cache'

interface AssingToCampaignDialogProps {
  onClose: () => void
  onSuccess?: () => void
  id: number
  name: string
  includedIds: number[]
  excludedIds: number[]
}

export const AssingToCampaignDialog = (props: AssingToCampaignDialogProps) => {
  const { id, name, onClose, onSuccess, includedIds, excludedIds } = props
  const [campaignsInfo, setCampaignsInfo] = React.useState<CampaignInfoType[]>(
    []
  )
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false)

  const { data: ioIncludedCampaignData, isFetching: isIncludedLoading } =
    useBasicIoCampaignList({
      searchField: '',
      campaignIds: includedIds,
      campaignStatus: ['running', 'pending', 'paused', 'draft', 'rejected'],
      options: {
        enabled: Boolean(includedIds.length),
      },
    })

  const { data: ioExcludedCampaignData, isFetching: isExcludedLoading } =
    useBasicIoCampaignList({
      searchField: '',
      campaignIds: excludedIds,
      campaignStatus: ['running', 'pending', 'paused', 'draft', 'rejected'],
      options: {
        enabled: Boolean(excludedIds.length),
      },
    })

  const ioIncludedCampaignList = ioIncludedCampaignData
  const ioExcludedCampaignList = ioExcludedCampaignData

  const flatIncludedIoData = React.useMemo(() => {
    return (
      ioIncludedCampaignList?.pages
        ?.map(page => {
          return page?.ioCampaignsList ?? []
        })
        .flat(1) || []
    )
  }, [ioIncludedCampaignList?.pages])

  const flatExcludedIoData = React.useMemo(() => {
    return (
      ioExcludedCampaignList?.pages
        ?.map(page => {
          return page?.ioCampaignsList ?? []
        })
        .flat(1) || []
    )
  }, [ioExcludedCampaignList?.pages])

  const combinedIoData = [...flatIncludedIoData, ...flatExcludedIoData]

  const flatIncludedCampaignData = React.useMemo(() => {
    return (
      flatIncludedIoData
        .map(io => {
          return (
            io?.campaigns.map(cmp => ({
              ...cmp,
              ioId: io.ioId,
              ioName: io.ioName,
              ioBudgetTypeId: io.ioBudgetTypeId,
              included: 1,
            })) ?? []
          )
        })
        .flat(1) || []
    )
  }, [flatIncludedIoData])

  const flatExcludedCampaignData = React.useMemo(() => {
    return (
      flatExcludedIoData
        .map(io => {
          return (
            io?.campaigns.map(cmp => ({
              ...cmp,
              ioId: io.ioId,
              ioName: io.ioName,
              ioBudgetTypeId: io.ioBudgetTypeId,
              included: 0,
            })) ?? []
          )
        })
        .flat(1) || []
    )
  }, [flatExcludedIoData])

  const combineData = [...flatIncludedCampaignData, ...flatExcludedCampaignData]

  // Formating the data for the selection
  const initData = combineData.map(cmp => {
    return {
      id: cmp.campaignId,
      name: cmp.name,
      creativeTypeId: cmp.creativeTypeId,
      status: cmp.status,
      ioId: cmp.ioId,
      ioName: cmp.ioName,
      ioBudgetTypeId: cmp.ioBudgetTypeId,
      included: cmp.included,
    }
  })

  React.useEffect(() => {
    if (
      initData?.length &&
      !campaignsInfo.length &&
      !isInitialized &&
      !isExcludedLoading &&
      !isIncludedLoading
    ) {
      setCampaignsInfo(initData)
      setIsInitialized(true)
    }
  }, [
    initData,
    campaignsInfo,
    isInitialized,
    isExcludedLoading,
    isIncludedLoading,
  ])

  const intialNormalizedData: Record<string, NormalizedIOCampaignList> = {}
  const normalizedParent = normalizeIOCampaignData(combinedIoData)

  combinedIoData?.forEach((item, index) => {
    const parent = normalizedParent?.[index]
    if (parent !== undefined && item.campaigns !== undefined) {
      const data = normalizeCampaignData(item.campaigns, parent)
      data?.map(val => {
        intialNormalizedData[val.value] = val
      })
    }
  })

  const assingToCampaignMutation = useAssignGroupToCampaign({
    onSuccess: (_: any, payload: ExcludeInventoryGroupFromCampaignArgs) => {
      queryClient.setQueriesData(
        {
          predicate: (query: any) =>
            query.queryKey?.[0]?.scope === 'getGroupList',
        },
        (oldData: { pages: GroupListTableResponse[] } | undefined) => {
          if (!oldData) return oldData
          const updatedPages = oldData.pages.map(page => {
            const newData = page.inventoryGroupList.map(group => {
              if (group.id === payload.groupId) {
                return {
                  ...group,
                  whiteListedCampaignIds: payload.includedCampaignIds.length
                    ? payload.includedCampaignIds
                        .split(',')
                        .map(id => parseInt(id))
                    : [],
                  blackListedCampaignIds: payload.excludedCampaignIds.length
                    ? payload.excludedCampaignIds
                        .split(',')
                        .map(id => parseInt(id))
                    : [],
                }
              }
              return group
            })
            return {
              ...page,
              inventoryGroupList: newData,
            }
          })

          return {
            ...oldData,
            pages: updatedPages,
          }
        }
      )
      enqueueSnackbar('Changes saved successfully', {
        variant: 'success',
      })
      onSuccess?.()
      onClose()
    },
    onError: e => {
      // @ts-ignore
      const errorMessage = e?.errorObjects?.[0]?.error as string

      enqueueSnackbar(
        errorMessage?.length
          ? errorMessage
          : 'Something went wrong. Please try after some time.',
        {
          variant: 'error',
        }
      )
      onClose()
    },
  })

  function compareWithoutOrder(
    intialData: CampaignInfoType[],
    currentData: CampaignInfoType[]
  ) {
    // Parse the JSON strings into JavaScript objects

    // Sort the arrays of objects
    const sortFunction = (a: { id: number }, b: { id: number }) => {
      return a.id - b.id
    }

    intialData.sort(sortFunction)
    currentData.sort(sortFunction)

    // Convert the sorted arrays back to JSON strings
    const sortedJson1 = JSON.stringify(intialData)
    const sortedJson2 = JSON.stringify(currentData)

    // Compare the resulting strings
    return sortedJson1 === sortedJson2
  }

  const disableUpdate =
    compareWithoutOrder(initData, campaignsInfo) ||
    isExcludedLoading ||
    isIncludedLoading

  const getParam = React.useCallback(() => {
    const excludedCampaignIds: number[] = []
    const includedCampaignIds: number[] = []

    campaignsInfo.forEach(cmp => {
      if (cmp.included) {
        includedCampaignIds.push(cmp.id)
      }
      if (!cmp.included) {
        excludedCampaignIds.push(cmp.id)
      }
    })

    return {
      groupId: id,
      excludedCampaignIds: excludedCampaignIds.join(','),
      includedCampaignIds: includedCampaignIds.join(','),
    } as ExcludeInventoryGroupFromCampaignArgs
  }, [campaignsInfo, id])

  function removeDeletedEntries(data: any) {
    const result = {}
    const ignoredStatus = ['deleted', 'expired']

    for (const key in data) {
      if (!ignoredStatus.includes(data[key].status)) {
        // @ts-ignore
        result[key] = data[key]
      }
    }

    return result
  }

  const getHeadingText = () => {
    return (
      <Typography component="p" sx={{ display: 'flex' }}>
        {`Select the campaigns below to which you want to assign the inventory group `}
        <TypoTooltip
          style={{ maxWidth: '450px' }}
          TypgraphyProps={{ component: 'span', weight: 'demi' }}
          sx={{ mx: 4 }}
          arrow
          placement="top"
        >
          {name}
        </TypoTooltip>{' '}
        {` for:`}
      </Typography>
    )
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      sx={{ height: 100 }}
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <Typography weight="demi"> Assign To Campaigns </Typography>
      </DialogTitle>
      {isExcludedLoading || isIncludedLoading ? (
        <ScreenLoader />
      ) : (
        <AssignToCampaignsDialogContent
          campaignsInfo={campaignsInfo}
          setCampaignsInfo={setCampaignsInfo}
          initData={initData}
          intialNormalizedData={removeDeletedEntries(intialNormalizedData)}
          headingText={getHeadingText()}
        />
      )}
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={disableUpdate}
          loading={assingToCampaignMutation.isLoading}
          onClick={() => {
            const params = getParam()
            assingToCampaignMutation.mutate(params)
          }}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
