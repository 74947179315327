import { ColumnDef } from '@applift/datagrid'

import {
  DateCell,
  RawDataCell,
  IconTextCell,
} from '../../../../components/CellTypes'
import { PgListResponse } from '../../../../models/Pg'
import { ExchangeCell, PaymentTypeCell, StatusCell } from '../Celltype'

export const colDef: ColumnDef<PgListResponse>[] = [
  {
    accessorKey: 'dealId',
    id: 'dealId',
    header: 'Deal ID',
    size: 220,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      return (
        <IconTextCell
          position="after"
          label={props.renderValue() as string}
          textTypographyProps={{ variant: 'bodySmall' }}
        />
      )
    },
  },
  {
    accessorKey: 'dealName',
    id: 'dealName',
    header: 'Name',
    size: 220,
    maxSize: 500,
    minSize: 100,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },
  {
    accessorKey: 'statusId',
    id: 'statusId',
    header: 'Status',
    size: 150,
    maxSize: 250,
    minSize: 120,
    enableSorting: false,
    cell: props => {
      const rowData = props.row.original
      return <StatusCell rowData={rowData} action={props.onAction} />
    },
  },
  {
    accessorKey: 'exchangeId',
    id: 'exchangeId',
    header: 'Exchange',
    size: 200,
    maxSize: 300,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      return (
        <ExchangeCell
          textTypographyProps={{ variant: 'bodySmall' }}
          exchangeId={props.renderValue() as number}
        />
      )
    },
  },
  {
    accessorKey: 'paymentTypeId',
    id: 'paymentTypeId',
    header: 'Payment To',
    size: 120,
    maxSize: 300,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      return (
        <PaymentTypeCell
          textTypographyProps={{ variant: 'bodySmall' }}
          paymentId={props.renderValue() as number}
        />
      )
    },
  },
  {
    accessorKey: 'created',
    id: 'created',
    header: 'Created',
    size: 120,
    maxSize: 200,
    minSize: 70,
    cell: props => {
      const date = props.row.original.created
      const meta = props.table.options.meta
      return (
        // @ts-ignore
        <DateCell date={date} timezone={meta.organizationTimezone} />
      )
    },
  },
  {
    accessorKey: 'description',
    id: 'description',
    header: 'Description',
    size: 200,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },
]
