import { Box, enqueueSnackbar, Typography } from '@applift/factor'
import {
  UploadFile,
  SampleFile,
  getExcelOrCsvFileData,
} from '@applift/app-utils'

interface FileUploadFilterProps {
  setUploadedFile: (data: { file: File; formData: FormData }) => void
}
export const FileUploadFilter = (props: FileUploadFilterProps) => {
  const { setUploadedFile } = props

  const onFileUpload = async (files: FileList) => {
    const file = files[0] as File
    if (file.size > 3145728) {
      enqueueSnackbar({
        variant: 'error',
        message: "File size can't be larger that 3MB",
      })
      return
    }

    getExcelOrCsvFileData(
      file,
      (data: string[][]) => {
        if (data) {
          const headers = data.shift()
          if (
            data.length > 0 &&
            headers?.length === 2 &&
            headers.includes('Bundles') &&
            headers.includes('Domains')
          ) {
            const formData = new FormData()
            formData.append('domainsFile', file)
            setUploadedFile({
              file: file,
              formData,
            })
          } else {
            enqueueSnackbar({
              message: 'Unable to read file content. Please try again.',
              variant: 'error',
            })
            return
          }
        }
      },
      () => {
        enqueueSnackbar({
          message: 'Unable to read file content. Please try again.',
          variant: 'error',
        })
        return
      }
    )
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      <Typography>or search by</Typography>
      <UploadFile
        fileTypes={['csv']}
        onUpload={onFileUpload}
        uploadBtnText="Upload"
        uploadBtnVariant="text"
        buttonProps={{ sx: { textWeight: 'normal' } }}
      />
      <Typography
        sx={{ textColor: 'neutral-300' }}
        component="span"
        variant="bodyLarge"
        gutterBottom={false}
      >
        &bull;
      </Typography>
      <SampleFile
        sampleFileText="Sample File"
        sampleFileURL={`${window.location.origin}/example-files/exchange-search-sample.csv`}
        sampleFileName="exchange-search-sample"
        buttonProps={{ sx: { textWeight: 'normal' } }}
      />
    </Box>
  )
}
