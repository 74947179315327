import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { getInstance } from './instance'
import {
  getAssociatedCustomerKey,
  getBasicCustomersListKey,
  getCustomerCountKey,
  getCustomerListKey,
} from './QueryKeys'
import {
  ListResponse,
  WithRecordResponse,
  WithResponse,
} from '../models/Response'
import {
  BasicCustomerList,
  CustomerStatus,
  NestedWorkspaceCustomerList,
  SharedGroupCustomer,
} from '../models/Customer'

export const getBasicCustomersList = async ({
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getBasicCustomersListKey)['keys']>
>): Promise<ListResponse<BasicCustomerList[]>> => {
  const {
    searchField,
    noOfEntries,
    pageNo,
    owIds,
    owStatusIds,
    provideRunningCampaigns,
  } = queryKey[0] || {}
  try {
    const response: AxiosResponse<
      WithResponse<ListResponse<BasicCustomerList[]>>
    > = await getInstance().post('/v3/ua/customer/basic/list', {
      searchField,
      noOfEntries,
      pageNo,
      owIds,
      owStatusIds,
      ...(owStatusIds?.length ? { owStatusIds } : {}),
      ...(typeof provideRunningCampaigns === 'boolean'
        ? { provideRunningCampaigns }
        : {}),
    })

    return response.data.data
  } catch (e) {
    throw (e as AxiosError).response
  }
}

export const getCustomerList = async ({
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getCustomerListKey)['keys']>>) => {
  const { status } = queryKey[0] || {}
  try {
    const searchParams = {
      pageNo: '-1',
      ...(status?.length ? { status } : {}),
    }

    const param = new URLSearchParams(searchParams).toString()

    const response: AxiosResponse<
      WithRecordResponse<NestedWorkspaceCustomerList[]>
    > = await getInstance().get(`v3/ua/customers/list/multi-level?${param}`)
    return response.data.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const getSharedGroupCustomerListKey = {
  keys: (scope: string, groupId: number) => [{ scope, groupId } as const],
}

export const getSharedGroupCustomerList = async ({
  queryKey,
  signal,
}: QueryFunctionContext<
  ReturnType<(typeof getSharedGroupCustomerListKey)['keys']>
>) => {
  const { groupId } = queryKey[0] ?? {}
  const { CancelToken } = axios
  const source = CancelToken.source()
  signal?.addEventListener('abort', () => {
    source.cancel('Query was cancelled')
  })
  try {
    const response: AxiosResponse<WithResponse<SharedGroupCustomer[]>> =
      await getInstance().get(`/v3/inv/group/${groupId}/shared/customer/list`, {
        cancelToken: source.token,
      })
    return response.data.data
  } catch (e) {
    return Promise.reject(e)
  }
}

export interface UpdateGroupWithCustomer {
  groupId: number
  addOWIds: number[]
  removeOWIds: number[]
}

export const shareGroupWithCustomer = async ({
  groupId,
  addOWIds,
  removeOWIds,
}: UpdateGroupWithCustomer) => {
  try {
    const response: AxiosResponse<WithResponse<string>> =
      await getInstance().patch(
        `/v3/inv/group/${groupId}/shared/customers/edit`,
        {
          addOWIds,
          removeOWIds,
        }
      )
    return response.data.data
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}
export const getCustomerCount = async ({
  signal,
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getCustomerCountKey)['keys']>>) => {
  try {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    const { owIds } = queryKey?.[0] || {}
    const response: AxiosResponse<WithResponse<CustomerStatus[]>> =
      await getInstance().get(`/v3/ua/customers/count`, {
        signal,
        params: {
          ...(owIds ? { owIds: owIds.join(',') } : {}),
        },
      })
    return response.data.data
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}

export const getAssociatedCustomer = async ({
  signal,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getAssociatedCustomerKey)['keys']>
>) => {
  try {
    const { dealId } = queryKey[0]!
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    const response: AxiosResponse<WithResponse<number[]>> =
      await getInstance().get(
        `/v3/inv/pmp/deals/${dealId}/associated-customers`,
        {
          signal,
        }
      )
    return response.data.data
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}
