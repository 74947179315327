import { getInstance } from '@applift/platform'
import {
  BlockInventoryRequest,
  SaveToExistingGroupRequest,
  SaveToNewGroupRequest,
} from '../models/OpenExchange'
import { AxiosError, AxiosResponse } from 'axios'
import {
  DeleteInventroyGroupArgs,
  SidebarInventoryGroup,
} from '../models/Group'
import { WithResponse } from '../models/Response'

export const saveToExistingGroup = async (
  saveToExistingGroupPayload: SaveToExistingGroupRequest
) => {
  try {
    const response = await getInstance().post(
      '/v3/inv/group/addMappings',
      saveToExistingGroupPayload
    )
    return response?.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
export const saveToNewGroup = async (
  saveToNewGroupPayload: SaveToNewGroupRequest
) => {
  try {
    const response: AxiosResponse<WithResponse<SidebarInventoryGroup>> =
      await getInstance().post('/v3/inv/groups', saveToNewGroupPayload)
    return response?.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

export const blockInventory = async (
  BlockInventoryPayload: BlockInventoryRequest
) => {
  try {
    const response = await getInstance().post(
      '/v3/inv/inventories/block',
      BlockInventoryPayload
    )
    return response?.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const deleteInventroyGroup = async ({
  id,
}: DeleteInventroyGroupArgs) => {
  try {
    const response = await getInstance().delete(`/v3/inv/groups/${id}`)
    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
