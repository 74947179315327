import * as React from 'react'
import { RowProps, Row } from '@applift/factor'

interface TabPanelProps {
  children?: React.ReactNode
  value: number
  index: number
}

export const TabPanel = React.forwardRef<
  HTMLDivElement,
  TabPanelProps & RowProps
>((props, ref) => {
  const { children, index, value, ...other } = props
  return value === index ? (
    <Row ref={ref} aria-labelledby={`simple-tab-${index}`} {...other}>
      {children}
    </Row>
  ) : null
})

TabPanel.displayName = 'TabPanel'
