import type { ValidationError } from '@tanstack/react-form'
import { useForm } from '@tanstack/react-form'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Col,
  Row,
  LoadingButton,
} from '@applift/factor'

import { getIcon } from '../../../registry'

interface CreateNewGroupDialogProps {
  onClose: () => void
  onConfirmation: (groupName: string) => void
  groupType: 'PMP' | 'open exchange' | 'contextual'
  isLoading: boolean
}

const iconMapper = {
  PMP: getIcon('InventoryPmpDeals', { fontSize: 32 }),
  'open exchange': getIcon('InventoryOpenExchange', { fontSize: 32 }),
  contextual: getIcon('InventoryContextual', { fontSize: 32 }),
}

export const CreateNewGroupDialog = (props: CreateNewGroupDialogProps) => {
  const { onClose, onConfirmation, groupType, isLoading } = props

  const form = useForm({
    defaultValues: {
      groupName: '',
    },
    onSubmit: async ({ value }) =>
      onConfirmation(value.groupName.trimStart().trimEnd()),
  })

  const getTextFieldLabel = () => {
    const capitalizedGroupType =
      groupType.charAt(0).toUpperCase() + groupType.slice(1)
    return `${capitalizedGroupType} Group Name`
  }

  const getGroupNameHelperText = (errors: ValidationError[]) =>
    errors.length ? (
      <Typography variant="label" color="error">
        {errors[0]}
      </Typography>
    ) : null

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <DialogTitle
          onClose={onClose}
          sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
        >
          {iconMapper[groupType as keyof typeof iconMapper]}
          <Typography weight="demi"> Create New Group </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Row sx={{ mb: 16 }}>
            <Col xs={12}>
              <Typography>{`Enter name of the new ${groupType} group where you want to add all the selected inventories.`}</Typography>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <form.Field
                name="groupName"
                validators={{
                  onChange: ({ value }) => {
                    if (!value || value.trimStart().trimEnd().length > 255) {
                      return !value
                        ? 'Group name is required'
                        : 'Character limit exceeded'
                    }
                    return undefined
                  },
                }}
              >
                {field => (
                  <TextField
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={e => field.handleChange(e.target.value)}
                    label={getTextFieldLabel()}
                    placeholder="Enter group name"
                    InputLabelProps={{
                      renderSuffix: <>{field.state.value.trim().length}/255</>,
                    }}
                    variant="outlinedDash"
                    helperText={getGroupNameHelperText(field.state.meta.errors)}
                    fullWidth
                    error={Boolean(field.state.meta.errors.length)}
                  />
                )}
              </form.Field>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <form.Subscribe
            // @ts-ignore
            selector={state => [state.canSubmit]}
          >
            {/* @ts-ignore */}
            {([canSubmit]) => (
              <>
                <Button color="secondary" onClick={onClose}>
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                  disabled={!canSubmit}
                >
                  Create
                </LoadingButton>
              </>
            )}
          </form.Subscribe>
        </DialogActions>
      </form>
    </Dialog>
  )
}
