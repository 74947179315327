import * as React from 'react'
import {
  TextField,
  Typography,
  BaseTextFieldProps,
  SelectAdvanceComponents,
} from '@applift/factor'

import { IconTextCell } from '../../CellTypes'
import { useCampaignCreativeType } from '../../../hooks'
import { CampaignCreativeType } from '../../../models/Filters'

interface CreativeTypeFilterDropdownProps {
  TextFieldProps?: Omit<BaseTextFieldProps<CampaignCreativeType>, 'selectProps'>
  value: CampaignCreativeType[]
  onChange: (creative: CampaignCreativeType[]) => void
}

export const CreativeTypeFilterDropdown = (
  props: CreativeTypeFilterDropdownProps
) => {
  const { TextFieldProps, value, onChange } = props
  const apiRef =
    SelectAdvanceComponents.useSelectAdvanceApiRef<CampaignCreativeType>()

  const currentSelection = React.useMemo(() => {
    return value?.reduce((acc: { [key: number]: boolean }, curr) => {
      acc[curr.id] = true
      return acc
    }, {})
  }, [value])

  const renderItem =
    SelectAdvanceComponents.DefaultListOptionItemWrapper<CampaignCreativeType>({
      disableCheckbox: true,
      selectionStyle: 'none',
      arrowPosition: 'right',
    })

  const { data: creativeTypeData, isError } = useCampaignCreativeType()

  return (
    !isError && (
      <TextField
        {...TextFieldProps}
        select="advanceSelect"
        placeholder={
          TextFieldProps?.InputProps?.readOnly ? '-' : 'Select Creative Types'
        }
        variant="outlinedDash"
        label="Creative Types"
        SelectProps={{
          slotProps: {
            PaperProps: { sx: { height: 'auto' } },
          },
          hideSearch: true,
          hideSelectNav: true,
          data: creativeTypeData?.creativeTypeList as CampaignCreativeType[],
          multiple: true,
          apiRef: apiRef,
          getRowId: row => `${row.id}`,
          rowCount: creativeTypeData?.totalRecords || 0,
          renderValue: value => {
            const selectedItem = Array.isArray(value)
              ? value.map(creative => creative.name)
              : []
            return <Typography>{selectedItem.join(', ')}</Typography>
          },
          renderListItem: renderItem,
          renderOption: ({ row }) => {
            const creative = row.original
            return (
              <IconTextCell
                iconName={creative.name}
                iconSize={24}
                label={creative.name}
              />
            )
          },
        }}
        value={currentSelection}
        onChange={selectedRowData => {
          // @ts-ignore
          const rowSelection = selectedRowData(currentSelection)
          const selectedIds = Object.keys(rowSelection).map(id => parseInt(id))
          const value = creativeTypeData?.creativeTypeList?.filter(data =>
            selectedIds.includes(data?.id)
          )
          onChange(value?.length ? value : [])
        }}
      />
    )
  )
}
