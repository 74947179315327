import * as React from 'react'
import {
  Button,
  Chip,
  Box,
  Typography,
  Link,
  enqueueSnackbar,
  clamp,
} from '@applift/factor'
import { useRouter } from '@tanstack/react-router'
import { useQueryClient } from '@tanstack/react-query'
import { WithResponse } from '@applift/platform'

import {
  CreateNewGroupDialog,
  ConfirmationDialog,
} from '../../../../components/Dialogs'
import { formatNumberWithSuffix } from '../../../../utils/format'
import { SaveToExisitingGroupDialog } from '../../../../components/Dialogs/SaveToExistingGroupDialog/SaveToExisitingGroupDialog'

import {
  useBlockInventory,
  useSaveToExistingGroup,
  useSaveToNewGroup,
} from '../../../../hooks/useAction'
import {
  SaveToExistingGroupRequest,
  SaveToNewGroupRequest,
} from '../../../../models/OpenExchange'
import {
  MAX_INVENTORY_DISPLAY_COUNT,
  OPEN_EXCHANGE_INVENTORY_ID,
} from '../../../../constants/exchange'
import { OpenExchangeSidebarSearchValues } from '../..'
import { getPayloadFromFilterSelectionArray } from './helpers'
import {
  DownloadTableAction,
  FileTypes,
} from '../../../../components/DownloadTableAction'
import { SidebarInventoryGroup } from '../../../../models/Group'
import { getGroupCreatedMessage } from '../../../../utils/messages'

interface OpenExchangeSelectionChipProps {
  onClear: () => void
  selectedCount: number
  totalCount: string
  isGlobalSelectAllEnabled: boolean
}

const SelectionChip = (props: OpenExchangeSelectionChipProps) => {
  const { selectedCount, totalCount, onClear, isGlobalSelectAllEnabled } = props

  const chipLabel = `${isGlobalSelectAllEnabled ? totalCount : selectedCount} 
  out of ${totalCount} selected`
  return (
    <Chip
      size="small"
      label={chipLabel}
      sx={{ borderRadius: 2 }}
      color="secondary"
      onDelete={onClear}
    />
  )
}

interface OpenExchangeGlobalSelectLabelProps {
  enableGlobalSelectAll: () => void
  selectedCount: number
  totalCount: string
}

const GlobalSelectLabel = (props: OpenExchangeGlobalSelectLabelProps) => {
  const { selectedCount, totalCount, enableGlobalSelectAll } = props

  return (
    <Box
      sx={{
        width: 100,
        bgColor: 'neutral-75',
        borderRadius: 4,
        py: 8,
        px: 12,
        justifyContent: 'center',
        display: 'flex',
        mt: 12,
      }}
    >
      <Typography
        gutterBottom={false}
      >{`All ${selectedCount > MAX_INVENTORY_DISPLAY_COUNT ? '10K' : formatNumberWithSuffix(selectedCount)} Open Exchanges on this page are selected.`}</Typography>
      <Link
        sx={{ textWeight: 'demi', ml: 8, textColor: 'primary-600' }}
        Component="button"
        onClick={enableGlobalSelectAll}
      >
        {`Select all ${totalCount} Open Exchanges`}
      </Link>
    </Box>
  )
}

interface ActionPanelProps {
  filters: OpenExchangeSidebarSearchValues
  selectedRecordsCount: number
  totalRecords: number
  isLocalSelectAll: boolean
  toggleGlobalSelectAllStatus: React.Dispatch<React.SetStateAction<boolean>>
  globalSelectAllEnabled: boolean
  selectedIds: number[]
  isTableLoading?: boolean
  hideTableCTAs?: boolean
  clearRowSelection: () => void
  displayDownloadIcon: boolean
  handleFileDownload: (fileTypes: FileTypes) => void
  isDownloadInProgress: boolean
}

export const OpenExchangeActionPanel = (props: ActionPanelProps) => {
  const {
    selectedRecordsCount,
    isLocalSelectAll,
    totalRecords,
    toggleGlobalSelectAllStatus,
    globalSelectAllEnabled,
    selectedIds,
    handleFileDownload,
    filters,
    hideTableCTAs,
    clearRowSelection,
    displayDownloadIcon,
    isDownloadInProgress,
  } = props

  const router = useRouter()
  const queryClient = useQueryClient()

  const [dialogToShow, setDialogToShow] = React.useState<
    'newGroup' | 'existingGroup' | 'block'
  >()

  const hideDialog = () => setDialogToShow(undefined)

  const totalRecordsToDisplay = formatNumberWithSuffix(
    clamp(totalRecords, 0, MAX_INVENTORY_DISPLAY_COUNT)
  )

  const onNewGroupCreateActionComplete = () => {
    hideDialog()
    clearRowSelection()
  }

  const onNewGroupCreateActionSuccess = (
    data: WithResponse<SidebarInventoryGroup>,
    payload: SaveToNewGroupRequest
  ) => {
    enqueueSnackbar(getGroupCreatedMessage(payload.groupName), {
      variant: 'success',
    })
    const appliedFilters =
      router.state.location.search.currentlyAppliedGroupFilters
    if (data?.data?.id) {
      router.navigate({
        to: '/groups/$groupId',
        params: { groupId: String(data.data.id) },
        ...(appliedFilters?.length
          ? {
              search: {
                currentlyAppliedGroupFilters: appliedFilters,
              },
            }
          : {}),
      })
    }
  }

  const onSaveToExistingGroupActionError = (e: any) => {
    enqueueSnackbar(
      e.errorObjects
        ? (e.errorObjects[0]?.error as string)
        : 'Something went wrong',
      {
        variant: 'error',
      }
    )
    hideDialog()
    clearRowSelection()
  }

  const onSaveToExistingGroupActionSuccess = (
    _: any,
    payload: SaveToExistingGroupRequest
  ) => {
    if (payload.groupIds) {
      router.navigate({
        to: '/groups/$groupId',
        params: { groupId: String(payload.groupIds[0]) },
      })
    }
    enqueueSnackbar(`Selected inventories saved to the group successfully`, {
      variant: 'success',
    })
  }

  const onBlockActionSuccess = () => {
    enqueueSnackbar('Selected inventories blocked successfully', {
      variant: 'success',
    })
    clearRowSelection()
    toggleGlobalSelectAllStatus(false)
    hideDialog()
    queryClient.resetQueries({
      predicate: (query: any) =>
        [
          'getOpenExchangeListingData',
          'getDistributionData',
          'getExchangeCountDataFromCSV',
          'getDistributionDataFromCSV',
          'getExchangeCountData',
        ].includes(query.queryKey?.[0]?.scope),
    })
  }

  const {
    mutate: saveToExisiting,
    isLoading: isSaveToExistingGroupInProgress,
  } = useSaveToExistingGroup(
    onSaveToExistingGroupActionError,
    onSaveToExistingGroupActionSuccess
  )

  const { mutate: saveToNew, isLoading: newGroupSaveInProgress } =
    useSaveToNewGroup({
      onSettled: onNewGroupCreateActionComplete,
      onSuccess: onNewGroupCreateActionSuccess,
    })
  const { mutate: blockInventory, isLoading: isBlockInProgress } =
    useBlockInventory(onBlockActionSuccess)

  const searchPayload = getPayloadFromFilterSelectionArray(filters)
  const isFilterEmpty = [
    searchPayload.categories,
    searchPayload.countries,
    searchPayload.creativeDurations,
    searchPayload.creativeSizes,
    searchPayload.creativeTypes,
    searchPayload.deviceTypes,
    searchPayload.exchanges,
    searchPayload.keywords,
    searchPayload.trafficTypes,
    searchPayload.videoPlayerSizes,
    searchPayload.inventoryTypes,
  ].every(arr => arr.length === 0)

  const handleSave = (groupId: number | undefined) => {
    if (groupId) {
      const payload: SaveToExistingGroupRequest = {
        groupIds: [groupId],
        ...(globalSelectAllEnabled
          ? isFilterEmpty
            ? { isAllInventories: true }
            : searchPayload
          : { inventoryIds: selectedIds }),
        groupTypeId: OPEN_EXCHANGE_INVENTORY_ID,
      }
      saveToExisiting(payload)
    }
  }

  const handleSaveToNew = (groupName: string) => {
    if (groupName) {
      const payload: SaveToNewGroupRequest = {
        groupName: groupName,
        ...(globalSelectAllEnabled
          ? isFilterEmpty
            ? { isAllInventories: true }
            : searchPayload
          : { inventoryIds: selectedIds }),
        groupTypeId: OPEN_EXCHANGE_INVENTORY_ID,
      }
      saveToNew(payload)
    }
  }

  const handleBlock = () =>
    blockInventory(
      globalSelectAllEnabled
        ? searchPayload
        : {
            inventoryIds: selectedIds,
          }
    )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', px: 24, mt: 12 }}>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {selectedRecordsCount > 0 && (
            <SelectionChip
              onClear={clearRowSelection}
              totalCount={totalRecordsToDisplay}
              isGlobalSelectAllEnabled={globalSelectAllEnabled}
              selectedCount={selectedRecordsCount}
            />
          )}
          <Box
            sx={{
              visibility:
                hideTableCTAs === true || selectedRecordsCount === 0
                  ? 'hidden'
                  : 'visible',
            }}
          >
            <Button
              size="small"
              color="primary"
              variant="outlined"
              sx={{ marginLeft: 8 }}
              onClick={() => setDialogToShow('newGroup')}
            >
              Save To New Group
            </Button>
            <Button
              size="small"
              color="primary"
              variant="outlined"
              sx={{ marginLeft: 8 }}
              onClick={() => setDialogToShow('existingGroup')}
            >
              Save To Existing Group
            </Button>
            <Button
              onClick={() => setDialogToShow('block')}
              color="error"
              size="small"
              variant="outlined"
              sx={{ marginLeft: 8 }}
            >
              Block
            </Button>
          </Box>
        </Box>

        {displayDownloadIcon && (
          <Box
            sx={{
              ml: 'auto',
            }}
          >
            <DownloadTableAction
              downloadfile={handleFileDownload}
              isDownloading={isDownloadInProgress}
            />
          </Box>
        )}
      </Box>
      {isLocalSelectAll && (
        <GlobalSelectLabel
          enableGlobalSelectAll={() => toggleGlobalSelectAllStatus(true)}
          totalCount={totalRecordsToDisplay}
          selectedCount={selectedRecordsCount}
        />
      )}
      {dialogToShow === 'newGroup' && (
        <CreateNewGroupDialog
          onClose={hideDialog}
          onConfirmation={handleSaveToNew}
          groupType="open exchange"
          isLoading={newGroupSaveInProgress}
        />
      )}
      {dialogToShow === 'existingGroup' && (
        <SaveToExisitingGroupDialog
          onClose={hideDialog}
          onConfirmation={handleSave}
          groupType="open exchange"
          groupId={OPEN_EXCHANGE_INVENTORY_ID}
          isLoading={isSaveToExistingGroupInProgress}
        />
      )}
      {dialogToShow === 'block' && (
        <ConfirmationDialog
          onClose={hideDialog}
          onConfirmation={handleBlock}
          primaryBtnText="Block"
          isActionLoading={isBlockInProgress}
          theme="error"
          title="Block Selected Inventories"
          dialogContent="Are you sure you want to block the selected inventories? The platform will immediately stop serving ads to them."
        />
      )}
    </Box>
  )
}
