import { enqueueSnackbar } from '@applift/factor'
import { WithResponse } from '@applift/platform'
import { useQuery } from '@tanstack/react-query'

import {
  getDealStatus,
  getTrafficTypes,
  getInventoryTypes,
  getDealCuration,
  getPaymentTypes,
  getExchanges,
} from '../api/filters'
import {
  getDealStatusKey,
  getTrafficTypesKey,
  getInventoryTypesKey,
} from '../api/QueryKeys'

const onError = (e: any) => {
  enqueueSnackbar?.(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong!',
    {
      variant: 'error',
    }
  )
}
export const useTrafficTypesData = (options?: { enabled?: boolean }) => {
  const queryData = useQuery(
    getTrafficTypesKey.keys('getTrafficTypes'),
    getTrafficTypes,
    {
      enabled: options?.enabled,
    }
  )
  if (queryData.isError) {
    onError(queryData.error)
  }
  return queryData
}

export const useInventoryTypesData = (options?: { enabled?: boolean }) => {
  const queryData = useQuery(
    getInventoryTypesKey.keys('getInventoryTypes'),
    getInventoryTypes,
    {
      enabled: options?.enabled,
    }
  )
  if (queryData.isError) {
    onError(queryData.error)
  }
  return queryData
}

export const usePaymentTypes = (options?: { enabled?: boolean }) => {
  const queryData = useQuery(
    getDealStatusKey.keys('getPaymentTypes'),
    getPaymentTypes,
    {
      enabled: options?.enabled,
      cacheTime: Infinity,
    }
  )
  if (queryData.isError) {
    onError(queryData.error)
  }
  return queryData
}

export const useDealStatusData = (options?: { enabled?: boolean }) => {
  const queryData = useQuery(
    getDealStatusKey.keys('getDealStatus'),
    getDealStatus,
    {
      enabled: options?.enabled,
    }
  )
  if (queryData.isError) {
    onError(queryData.error)
  }
  return queryData
}

export const useDealCurationData = (options?: { enabled?: boolean }) => {
  const queryData = useQuery(
    getDealStatusKey.keys('getDealCuration'),
    getDealCuration,
    {
      enabled: options?.enabled,
    }
  )
  if (queryData.isError) {
    onError(queryData.error)
  }
  return queryData
}

export const getExchangeListKey = {
  keys: (args: { scope: string }) => [args],
}

export const useExchangeList = () => {
  const data = useQuery(
    getExchangeListKey.keys({ scope: 'getExchangeList' }),
    getExchanges,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e?.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      keepPreviousData: true,
    }
  )
  return data
}
