import React from 'react'
import { Layout } from '@applift/factor'
import { SortingState } from '@applift/datagrid'
import { useAppContext } from '@applift/platform'
import { createFileRoute, useNavigate } from '@tanstack/react-router'

import { getPgList } from '../../api/pg'
import { Sidebar } from './-component/sidebar'
import { usePgList } from '../../hooks/usePgList'
import { getPgListKey } from '../../api/QueryKeys'
import { EmptyPage } from './-component/EmptyPage'
import { DEFAULT_PAGE_SIZE } from '../../constants/list'
import { ExchangeFilterType } from '../../models/Filters'
import { ScreenLoader } from '../../components/ScreenLoader'
import { PgGridWrapper } from './-component/PgGrid/PgGridWrapper'
import { usePaymentTypes } from '../../hooks'

type PgSearchParams = {
  keywords: string
  dealStatus: number[]
  exchangeList: ExchangeFilterType[]
  sortBy?: SortingState
}

export const Route = createFileRoute('/pg/')({
  validateSearch: (search: PgSearchParams): Partial<PgSearchParams> => {
    return {
      ...(search.keywords ? { keywords: search.keywords } : {}),
      ...(search.exchangeList?.length
        ? { exchangeList: search.exchangeList }
        : {}),
      ...(search.dealStatus?.length ? { dealStatus: search.dealStatus } : {}),
    }
  },
  loader: ({ context, location }) => {
    const search: PgSearchParams | {} = location.search
    const getStatusId = () => {
      if ((search as PgSearchParams)?.dealStatus?.length === 1) {
        return (search as PgSearchParams)?.dealStatus[0]
      }
      return undefined
    }
    // @ts-ignore
    return context?.queryClient?.fetchInfiniteQuery(
      getPgListKey.keys({
        scope: 'getPgList',
        //@ts-ignore
        searchField: search.keywords ?? '',
        //@ts-ignore
        statusIds: getStatusId(),
        //@ts-ignore
        exchangeIds: search.exchangeList
          ? //@ts-ignore
            search.exchangeList
              .map((exchange: ExchangeFilterType) => exchange.value)
              .join(',')
          : '',
        noOfEntries: DEFAULT_PAGE_SIZE,
        order: 'DESC',
        sortBy: 'created',
      }),
      getPgList
    )
  },
  pendingComponent: () => <ScreenLoader />,
  errorComponent: () => (
    <Layout
      sidebarWidth={320}
      maxWidth={320}
      minWidth={320}
      sidebar={<Sidebar />}
      hideResizerHandle
    >
      <PgGridWrapper
        data={[]}
        loading={false}
        isError={true}
        filteredRecords={0}
        totalRecords={0}
      />
    </Layout>
  ),
  component: Pg,
})

function Pg() {
  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType
  const searchParams = Route.useSearch()

  const navigate = useNavigate({ from: Route.fullPath })

  React.useEffect(() => {
    if (orgType === 'WORKSPACE' || orgType === 'SUPER') {
      navigate({
        to: '/exchange',
        replace: true,
      })
    }
  }, [orgType, navigate])

  const getStatusId = () => {
    if (searchParams?.dealStatus?.length === 1) {
      return searchParams?.dealStatus[0]
    }
    return undefined
  }

  const {
    data: pgList,
    fetchNextPage,
    isFetching,
    isError,
  } = usePgList(
    searchParams.keywords ?? '',
    searchParams.exchangeList?.length
      ? searchParams.exchangeList.map(exchange => exchange.value).join(',')
      : '',
    getStatusId(),
    searchParams.sortBy,
    DEFAULT_PAGE_SIZE
  )

  const { isLoading } = usePaymentTypes()

  const flatData = React.useMemo(() => {
    return (
      pgList?.pages
        ?.map(page => {
          return page?.pgDealData ?? []
        })
        .flat(1) || []
    )
  }, [pgList])

  const filteredRecords = React.useMemo(
    () => pgList?.pages?.[0]?.filteredRecords,
    [pgList]
  )

  const totalRecords = React.useMemo(() => {
    return pgList?.pages?.[0]?.totalRecords || 0
  }, [pgList])

  const noData = React.useMemo(() => {
    return !totalRecords && !isError && !isFetching
  }, [isError, totalRecords, isFetching])

  return (
    <Layout
      sidebarWidth={320}
      maxWidth={320}
      minWidth={320}
      sidebar={
        !noData ? (
          <Sidebar totalRecords={totalRecords} isLoading={isFetching} />
        ) : undefined
      }
      hideResizerHandle
    >
      {noData ? (
        <EmptyPage />
      ) : (
        <>
          <PgGridWrapper
            data={flatData}
            loading={isFetching || isLoading}
            isError={isError}
            totalRecords={totalRecords}
            filteredRecords={filteredRecords || 0}
            onFetchRows={() => {
              // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
              setTimeout(fetchNextPage, 100)
            }}
          />
        </>
      )}
    </Layout>
  )
}
