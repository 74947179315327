import * as React from 'react'
import { Box, Col, Paper, Row } from '@applift/factor'

import { colDef } from './colDef'
import { useBasicCustomerList } from '../../../../../hooks'
import { CustomerGrid } from './CustomerGrid'

import styles from './styles.module.scss'

export const DEFAULT_PAGE_SIZE = 20

export interface DialogCustomerListWrapperPropTypes {
  customersIds: number[]
}

export const CustomerGridWrapper = (
  props: DialogCustomerListWrapperPropTypes
) => {
  const { customersIds } = props

  const {
    data: customerData,
    isFetching: customerListLoading,
    fetchNextPage,
    isError,
  } = useBasicCustomerList({
    searchField: '',
    owIds: customersIds,
    provideRunningCampaigns: true,
  })

  const flatData = React.useMemo(() => {
    return (
      customerData?.pages
        ?.map(page => {
          return page.data ?? []
        })
        .flat(1) || []
    )
  }, [customerData])

  const filteredRecords = React.useMemo(
    () => customerData?.pages[0]?.filteredRecords,
    [customerData?.pages]
  )

  const totalRecords = React.useMemo(
    () => customerData?.pages[0]?.totalRecords,
    [customerData?.pages]
  )

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if (
      !flatData.length &&
      !customerListLoading &&
      !filteredRecords &&
      totalRecords
    ) {
      return 'noResult'
    }
    if (!flatData.length && !customerListLoading) {
      return 'noResult'
    }

    return undefined
  }, [
    flatData.length,
    filteredRecords,
    customerListLoading,
    totalRecords,
    isError,
  ])

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 100,
      }}
    >
      <Row
        className={styles.action}
        sx={{ px: 24, alignItems: 'center', justifyContent: 'end' }}
        xs={{ gutterSize: 0, gutterDirection: 'x' }}
      >
        <Col xs={4} sx={{ display: 'flex' }}>
          <Box></Box>
        </Col>
      </Row>
      <Row sx={{ flexGrow: 1 }}>
        <Col>
          <Box sx={{ height: 100 }}>
            <CustomerGrid
              data={flatData}
              columnDef={colDef}
              totalRecords={totalRecords || 0}
              filteredRecords={filteredRecords || 0}
              rowIdKey="owId"
              pageSize={DEFAULT_PAGE_SIZE}
              loading={customerListLoading}
              onFetchRows={() => {
                // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                setTimeout(fetchNextPage, 100)
              }}
              overlay={overlay}
            />
          </Box>
        </Col>
      </Row>
    </Paper>
  )
}
