import { GridActionCellParams } from '@applift/datagrid'
import { IconButton, Tooltip, Box } from '@applift/factor'
import { Delete } from '@applift/icons'

import { CampaignInfoType } from '../../../../models/Group'

export interface AssignCampaignDeleteCellProps {
  onAction?: (params: GridActionCellParams<string, any>) => void
  row?: CampaignInfoType
  meta?: any
}

export const AssignCampaignDeleteCell = (
  props: AssignCampaignDeleteCellProps
) => {
  const { onAction, row } = props

  const handleRemoveSelection = () => {
    onAction?.({
      actionName: 'removeRow',
      metaData: { rowId: row?.id },
    })
  }
  const expiredCamapign = row?.status === 'expired'

  const deleteDisabled = expiredCamapign

  const tooltipMessage = () => {
    if (expiredCamapign) {
      return 'PMP Deals can’t be removed from expired campaigns'
    }
    return ''
  }

  return (
    <Tooltip title={tooltipMessage()} placement="top" arrow>
      <Box>
        <IconButton
          onClick={handleRemoveSelection}
          sx={{ fontSize: 24, textColor: 'neutral-400' }}
          borderRadius="circle"
          disabled={deleteDisabled}
        >
          <Delete />
        </IconButton>
      </Box>
    </Tooltip>
  )
}
