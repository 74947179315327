import { enqueueSnackbar } from '@applift/factor'
import { useQuery } from '@tanstack/react-query'
import {
  getContextualKeywordRecommendations,
  getContextualKeywordSuggestions,
  getURLPreview,
} from '../api/index'

import {
  getContextualKeywordRecommendationsKey,
  getContextualKeywordSuggestionsKey,
  getContextualURLPreviewKey,
} from '../api/QueryKeys'
import { WithResponse } from '../models/Response'

const onError = (e: WithResponse) => {
  enqueueSnackbar?.(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong!',
    {
      variant: 'error',
    }
  )
}

export const useContextualKeywordSuggestions = (
  keyword: string,
  options?: { enabled?: boolean }
) => {
  const queryData = useQuery(
    getContextualKeywordSuggestionsKey.keys({
      keyword,
      scope: 'getContextualKeywordSuggestions',
    }),
    getContextualKeywordSuggestions,
    {
      onError,
      enabled: options?.enabled,
    }
  )
  return queryData
}

export const useContextualKeywordRecommendations = (
  keyword: string,
  options?: { enabled?: boolean }
) => {
  const queryData = useQuery(
    getContextualKeywordRecommendationsKey.keys({
      keyword,
      scope: 'getContextualKeywordRecommendations',
    }),
    getContextualKeywordRecommendations,
    {
      enabled: options?.enabled,
    }
  )
  return queryData
}

export const useContextualURLPreview = (
  url: string,
  options?: { enabled?: boolean }
) => {
  const queryData = useQuery(
    getContextualURLPreviewKey.keys({
      url,
      scope: 'getURLPreview',
    }),
    getURLPreview,
    {
      enabled: options?.enabled,
    }
  )
  return queryData
}
