import {
  Typography,
  Box,
  Tooltip,
  TypoTooltip,
  bindMenu,
  bindTrigger,
  IconButton,
  Menu,
  MenuItem,
  usePopupState,
  Badge,
  Col,
  Row,
} from '@applift/factor'
import { EllipsisVertical, FolderConnect } from '@applift/icons'

import { formatNumberWithSuffix } from '../../../utils/format'
import { getIcon } from '../../../registry'
import { SidebarInventoryGroup } from '../../../models/Group'

import styles from './index.module.scss'

interface Props {
  actionOptions?: {
    label: string
    value: string
    onClick: (groupInfo: SidebarInventoryGroup) => void
  }[]
  displayGroupIcon?: boolean
  secondaryInfo: { label: string; value: string }[]
  isAdvertiser: boolean
  showAllowedBlockedCount?: boolean
  groupInfo: SidebarInventoryGroup
}

const iconMapper = {
  2: getIcon('InventoryPmpDeals', { fontSize: 20 }),
  1: getIcon('InventoryOpenExchange', { fontSize: 20 }),
  3: getIcon('InventoryContextual', { fontSize: 20 }),
}

export const DisplayInventoryGroupItem = (props: Props) => {
  const {
    actionOptions,
    secondaryInfo,
    isAdvertiser,
    displayGroupIcon,
    showAllowedBlockedCount,
    groupInfo,
  } = props

  const {
    groupTypeId,
    id: groupId,
    isShared,
    name: groupName,
    whiteListedCampaignIds,
    blackListedCampaignIds,
    sharedCount: sharedWithCount,
  } = groupInfo

  const popupState = usePopupState({
    variant: 'popper',
    popupId: `${groupId}-groupAction-popper`,
  })

  const displayActionMenu = Boolean(actionOptions?.length)
  let filteredAction:
    | {
        label: string
        value: string
        onClick: (groupInfo: SidebarInventoryGroup) => void
      }[]
    | undefined = actionOptions

  if (isAdvertiser && isShared) {
    filteredAction = filteredAction?.filter(item => {
      if (item.value === 'delete' || item.value === 'changeName') {
        return false
      } else {
        return true
      }
    })
  }

  const disableKebabMenu = isAdvertiser && isShared && groupInfo.count === 0

  return (
    <>
      <Box
        sx={{
          width: 100,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: 100,
            gap: 4,
            overflow: 'hidden',
            alignItems: 'center',
          }}
        >
          {isShared && isAdvertiser && (
            <Tooltip title="Shared Inventory group" placement="top" arrow>
              <FolderConnect
                sx={{ textColor: 'neutral-500', flexShrink: 0 }}
                fontSize={24}
              />
            </Tooltip>
          )}
          {displayGroupIcon &&
            groupTypeId &&
            iconMapper[groupTypeId as keyof typeof iconMapper]}
          <TypoTooltip
            placement="top"
            arrow
            TypgraphyProps={{
              gutterBottom: false,
              sx: {
                display: 'block',
                textWeight: 'demi',
                alignSelf: 'center',
                width: 100,
              },
            }}
          >
            {groupName}
          </TypoTooltip>

          <Box
            sx={{
              ml: 'auto',
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              flexShrink: 0,
            }}
          >
            {isShared && !isAdvertiser && (
              <Tooltip
                title={`Inventory group shared with ${sharedWithCount} customers`}
                placement="top"
                arrow
              >
                <FolderConnect
                  sx={{ textColor: 'neutral-500' }}
                  fontSize={24}
                />
              </Tooltip>
            )}
            {isShared && !isAdvertiser && (
              <Typography
                variant="bodySmall"
                sx={{ textColor: 'neutral-500', textWeight: 'demi' }}
                gutterBottom={false}
              >
                {sharedWithCount}
              </Typography>
            )}
            {showAllowedBlockedCount && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <Badge color="success" variant="dot" size="small" />
                <Typography
                  variant="bodySmall"
                  sx={{
                    textColor: 'neutral-500',
                    textWeight: 'demi',
                    ml: 'auto',
                  }}
                  gutterBottom={false}
                >
                  {(whiteListedCampaignIds.length ?? 0) >= 10000
                    ? formatNumberWithSuffix(whiteListedCampaignIds.length ?? 0)
                    : whiteListedCampaignIds.length}
                </Typography>
              </Box>
            )}
            {showAllowedBlockedCount && (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <Badge color="error" variant="dot" size="small" />
                <Typography
                  variant="bodySmall"
                  sx={{ textColor: 'neutral-500', textWeight: 'demi' }}
                  gutterBottom={false}
                >
                  {(blackListedCampaignIds.length ?? 0) >= 10000
                    ? formatNumberWithSuffix(blackListedCampaignIds.length ?? 0)
                    : blackListedCampaignIds.length}
                </Typography>
              </Box>
            )}
          </Box>

          {displayActionMenu && !disableKebabMenu && (
            // @ts-ignore
            <IconButton
              {...bindTrigger(popupState)}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                popupState.open(e)
              }}
              id={`${groupId}-groupAction`}
              selected={popupState.isOpen}
              color="secondary"
            >
              <EllipsisVertical fontSize={16} />
            </IconButton>
          )}

          {displayActionMenu && disableKebabMenu && (
            <Tooltip
              placement="top"
              arrow
              title="No actions are available for empty groups"
            >
              <EllipsisVertical
                fontSize={16}
                sx={{
                  textColor: 'neutral-300',
                }}
              />
            </Tooltip>
          )}
        </Box>

        {secondaryInfo?.length > 0 && (
          <Row
            sx={{
              flexWrap: 'wrap',
              pt: 8,
            }}
            xs={{ gutterSize: 24 }}
          >
            {secondaryInfo.map(info => (
              <Col
                sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}
                key={info.label}
              >
                <Typography variant="label" sx={{ textColor: 'neutral-500' }}>
                  {info.label}
                </Typography>
                <Typography>{info.value}</Typography>
              </Col>
            ))}
          </Row>
        )}
      </Box>
      {displayActionMenu && (
        // @ts-ignore
        <Menu
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ border: 1 }}
          classes={{
            paper: styles.actionDropdown,
          }}
        >
          {filteredAction?.map(option => (
            <MenuItem
              value={option.value}
              key={option.value}
              onClick={e => {
                e.preventDefault()
                e.stopPropagation()
                option.onClick(groupInfo)
                popupState.close()
              }}
            >
              <Typography variant="label" sx={{ textColor: 'neutral-1000' }}>
                {option.label}
              </Typography>
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}
