import { Container, Box, Row, Col, Typography } from '@applift/factor'
import { ShieldCheck, WebsiteSlider, ClipboardCheck } from '@applift/icons'
import { BlockedInventories } from '@applift/illustrations'
import { EmptyPageFeatures } from '../../../../components/EmptyPageFeature'

export const EmptyPage = () => {
  const features = [
    {
      icon: (
        <ShieldCheck fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Brand Protection',
      description:
        'Safeguard brand reputation by preventing ads from appearing on inappropriate or undesirable websites, minimizing the risk of association with harmful content.',
    },
    {
      icon: (
        <WebsiteSlider
          fontSize={40}
          sx={{ textColor: 'primary-600', mb: 16 }}
        />
      ),
      title: 'Improved Ad Placement Control',
      description:
        'Maintain greater control over ad placements by easily managing and updating blacklisted inventory, ensuring ads are displayed in safe and appropriate environments.',
    },
    {
      icon: (
        <ClipboardCheck
          fontSize={40}
          sx={{ textColor: 'primary-600', mb: 16 }}
        />
      ),
      title: 'Regulatory Compliance',
      description:
        'Ensure compliance with industry regulations and advertising standards by effectively managing blacklisted inventory, reducing the likelihood of ad placements in non-compliant environments.',
    },
  ]

  return (
    <Container
      sx={{
        width: 100,
        height: 100,

        p: 40,
        bgColor: 'neutral-0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Row
        xs={{ gutterSize: 40 }}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mb: 48,
          width: 100,
        }}
      >
        <Col xs={12} sm={5} md={4}>
          <Box sx={{ textColor: 'primary-500' }}>
            <BlockedInventories sx={{ width: 100, height: 'auto' }} />
          </Box>
        </Col>
        <Col xs={12} sm={7} md={8}>
          <Typography component="h1" variant="h5" weight="demi" sx={{ mb: 8 }}>
            No Blocklisted Inventories
          </Typography>
          <Typography
            component="h4"
            variant="bodyLarge"
            lineHeight="multi-line"
            weight="normal"
            sx={{ mb: 8 }}
          >
            {'Currently, you do not have any blocklisted inventories.'}
          </Typography>
          <Typography
            component="h4"
            variant="bodyLarge"
            lineHeight="multi-line"
            weight="normal"
            sx={{ mb: 16 }}
          >
            {
              'Easily manage blacklisted inventory to safeguard brand reputation and ensure ad placements align with brand values.'
            }
          </Typography>
        </Col>
      </Row>
      <Row xs={{ gutterSize: 40 }} sx={{ justifyContent: 'center' }}>
        {features.map(feature => (
          <Col xs={features.length < 3 ? 5 : 4} key={feature.title}>
            <EmptyPageFeatures
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}
