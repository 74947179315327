import * as React from 'react'
import { RowSelectionState } from '@applift/datagrid'
import { InfiniteData } from '@tanstack/react-query'
import { Box, useDebounceValue } from '@applift/factor'

import { Exchange } from '../../../../../models/OpenExchange'
import { SidebarInventoryGroup } from '../../../../../models/Group'
import {
  useDownloadOpenExchangeGroupInventoriesMutation,
  useOpenExchangeGroupListingData,
} from '../../../../../hooks'
import { DEFAULT_NO_OF_ENTRIES } from '../../../../../constants/list'
import { OpenExchangeGroupActionPanel } from './OpenExchangeGroupActionPanel'
import { OpenExchangeGroupGrid } from './OpenExchangeGroupGrid'
import { FileTypes } from '../../../../../components/DownloadTableAction'

interface OpenExchangeGroupGridWrapperProps {
  groupDetail: SidebarInventoryGroup
  isNonEditableGroup: boolean
  toggleDisplayDistributionData: React.Dispatch<React.SetStateAction<boolean>>
}

export const OpenExchangeGroupGridWrapper = (
  props: OpenExchangeGroupGridWrapperProps
) => {
  const { groupDetail, isNonEditableGroup, toggleDisplayDistributionData } =
    props

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [search, setSearch] = React.useState<string>('')

  const debouncedSearch = useDebounceValue(search, 250)

  const {
    data: data,
    isError,
    isFetching,
    fetchNextPage,
  } = useOpenExchangeGroupListingData({
    groupId: groupDetail.id,
    searchField: debouncedSearch,
    noOfEntries: DEFAULT_NO_OF_ENTRIES,
  })

  const downloadOpenExchangeGroupMutation =
    useDownloadOpenExchangeGroupInventoriesMutation()

  const getFlattenedData = (
    data?: InfiniteData<{
      openExchangeList?: Exchange[]
    }>
  ) => data?.pages?.map(page => page?.openExchangeList ?? []).flat(1) ?? []

  // below effect is used to select duplicates of current selection in new incoming data as user scrolls down
  React.useEffect(() => {
    setRowSelection(prevRowSelection => {
      // Create a set to store selected row IDs
      const selectedRowIdsSet: Set<number> = new Set()
      const flatData = getFlattenedData(data)
      // Populate the set with IDs from rowSelection
      Object.keys(prevRowSelection).forEach(selectedId => {
        const rowData = flatData.find(data => data.uniqueId === selectedId)
        if (rowData?.id) {
          selectedRowIdsSet.add(rowData.id)
        }
      })
      // Convert the set to an array
      const selectedRowIdsArray = Array.from(selectedRowIdsSet)
      // Create a set to store the corresponding _id values
      const correspondingIdsSet: Set<string> = new Set()
      // Populate the set with _id values corresponding to the selected row IDs
      selectedRowIdsArray.forEach(id => {
        flatData.forEach(item => {
          if (item.id === id) {
            correspondingIdsSet.add(item.uniqueId)
          }
        })
      })
      const correspondingIdsArray = Array.from(correspondingIdsSet)
      const updatedSelection: { [key: string]: boolean } = {}
      correspondingIdsArray.forEach(itemId => {
        updatedSelection[itemId] = true
      })
      return {
        ...prevRowSelection,
        ...updatedSelection,
      }
    })
  }, [data])

  const totalRecords = data?.pages[0]?.totalRecords

  const finalDataToDisplay = getFlattenedData(data)

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if (!finalDataToDisplay?.length && !isFetching && search) {
      return 'noResult'
    }
    if (totalRecords === 0 && !isFetching) {
      return 'noRows'
    }
    return undefined
  }, [isError, finalDataToDisplay, isFetching, search, totalRecords])

  const setRowSelectionWrapper = (_value: any) => {
    const value = typeof _value === 'function' ? _value() : _value

    // logic to remove all the selection of duplicates once an item's selection is removed
    const isRemovalAction =
      Object.keys(value).length < Object.keys(rowSelection).length
    if (isRemovalAction) {
      const uniqueIdOfRemovedItem = Object.keys(rowSelection).find(
        key => !(key in value)
      ) as string
      const inventoryIdOfRemovedItem = finalDataToDisplay.find(
        item => item.uniqueId === uniqueIdOfRemovedItem
      )?.id
      const associatedUniqueIds = finalDataToDisplay
        .filter(item => item.id === inventoryIdOfRemovedItem)
        .map(filteredItem => filteredItem.uniqueId)
      associatedUniqueIds.forEach(
        associatedUniqueId => delete value[associatedUniqueId]
      )

      return setRowSelection(value)
    }

    // logic to select all the duplicates once an item is selected
    const selectedItemsInventoryIds = finalDataToDisplay
      .filter(item => Object.keys(value).includes(item.uniqueId))
      .map(filteredItem => filteredItem.id)

    const newSelection: RowSelectionState = {}
    selectedItemsInventoryIds.forEach(inventoryId => {
      finalDataToDisplay
        .filter(item => item.id === inventoryId)
        .map(filteredItem => filteredItem.uniqueId)
        .forEach(newUniqueId => {
          newSelection[newUniqueId] = true
        })
    })

    setRowSelection(newSelection)
  }

  const uniqueSelectedInventoryIdsSet = new Set(
    Object.keys(rowSelection ?? {}).map(
      uniqueSelectedId =>
        finalDataToDisplay.find(data => data.uniqueId === uniqueSelectedId)?.id
    )
  )

  const selectedIds = React.useMemo(() => {
    const selectedRowIdsSet: Set<number> = new Set()
    const flatData = getFlattenedData(data)
    // Populate the set with IDs from rowSelection
    Object.keys(rowSelection).forEach(selectedId => {
      const rowData = flatData.find(data => data.uniqueId === selectedId)
      if (rowData?.id) {
        selectedRowIdsSet.add(rowData.id)
      }
    })
    return Array.from(selectedRowIdsSet)
  }, [rowSelection, data])

  const onFetchRowsWrapper = () => {
    if (fetchNextPage && !isError) {
      setTimeout(fetchNextPage, 100)
    }
  }

  const clearSelection = () => setRowSelection({})

  const handleDownload = (fileType: FileTypes) => {
    downloadOpenExchangeGroupMutation.mutate({
      groupId: groupDetail.id,
      fileType,
    })
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <OpenExchangeGroupActionPanel
        setSearch={setSearch}
        search={search}
        groupDetail={groupDetail}
        overlay={overlay}
        selectedCount={uniqueSelectedInventoryIdsSet.size}
        clearSelection={clearSelection}
        totalRecords={data?.pages?.[0]?.uniqueRecords ?? 0}
        toggleDisplayDistributionData={toggleDisplayDistributionData}
        selectedIds={selectedIds}
        isDownloadInProgress={downloadOpenExchangeGroupMutation.isLoading}
        handleDownload={handleDownload}
      />

      <OpenExchangeGroupGrid
        key={groupDetail.id}
        groupId={groupDetail.id}
        data={finalDataToDisplay}
        checkboxSelectionDisabled={isNonEditableGroup}
        sharedGroup={groupDetail.isShared}
        overlay={overlay}
        loading={isFetching}
        // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
        onFetchRows={onFetchRowsWrapper}
        rowSelection={rowSelection}
        totalRecords={data?.pages[0]?.totalRecords ?? 0}
        setRowSelectionWrapper={setRowSelectionWrapper}
      />
    </Box>
  )
}
