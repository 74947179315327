export function isEqual(obj1: any, obj2: any): boolean {
  // Check if both arguments are objects
  if (
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    obj1 === null ||
    obj2 === null
  ) {
    return obj1 === obj2
  }

  const obj1Props = Object.getOwnPropertyNames(obj1)
  const obj2Props = Object.getOwnPropertyNames(obj2)

  if (obj1Props.length !== obj2Props.length) {
    return false
  }

  // Recursively compare the values of each property
  for (const prop of obj1Props) {
    if (obj1[prop] instanceof Array && obj2[prop] instanceof Array) {
      // If the property values are arrays, compare them element by element
      if (!isEqual(obj1[prop], obj2[prop])) {
        return false
      }
    } else if (
      typeof obj1[prop] === 'object' &&
      typeof obj2[prop] === 'object'
    ) {
      // If the property values are objects, recursively compare them
      if (!isEqual(obj1[prop], obj2[prop])) {
        return false
      }
    } else if (obj1[prop] !== obj2[prop]) {
      // If the property values are not equal, the objects are not equal
      return false
    }
  }

  // If all properties are equal, the objects are equal
  return true
}

export const getUrlIfValid = (value: string) => {
  let url = ''
  try {
    const urlObj = new URL(value)
    url = urlObj.href
  } catch {
    url = ''
  }
  return url
}
export const getIconMapping = (lable: string) => {
  const iconMapping: { [key: string]: string } = {
    Site: 'Globe',
    'Android App': 'BrandAndroid',
    'IOS App': 'BrandApple',
    'Other App': 'EllipsisHorizontalCircle',
  }

  return iconMapping[lable]
}

export function getChangedKeys<T>(
  initialState: T,
  currentState: T
): Partial<T> {
  const changedState: Partial<T> = {}

  // @ts-ignore
  const initDataKeys = Object.keys(initialState) as (keyof T)[]

  initDataKeys.forEach(key => {
    if (!isEqual(initialState[key], currentState[key])) {
      // @ts-ignore
      changedState[key] = currentState[key]
    }
  })

  return changedState
}

export function filterObjectByIdArray(
  obj: Record<string, any>,
  idArray: number[]
) {
  return Object.keys(obj)
    .filter(key => idArray?.includes(Number(key)))
    .reduce((result, key) => {
      // @ts-ignore
      result[key] = obj[key]
      return result
    }, {})
}
