import { GridActionCellParams } from '@applift/datagrid'
import { Box, IconButton, Tooltip } from '@applift/factor'
import { Delete } from '@applift/icons'
import { NormalizedCustomerList } from '@applift/platform/dist/models/customerList'

export interface ActionCellProps {
  onAction?: (params: GridActionCellParams<string, any>) => void
  rowData: NormalizedCustomerList & { id: number; campaignCount?: number }
}
export const ActionCell = (props: ActionCellProps) => {
  const { onAction, rowData: { id, campaignCount = 0 } = {} } = props
  const disabled = campaignCount > 0

  return (
    <Tooltip
      title={
        disabled
          ? 'Can’t remove this customer as their campaigns are using this inventory group'
          : ''
      }
      placement="left"
      arrow
    >
      <Box>
        <IconButton borderRadius="circle" disabled={disabled}>
          <Delete
            sx={{ textColor: 'neutral-400' }}
            onClick={() =>
              onAction?.({ actionName: 'removeRow', metaData: { id } })
            }
          />
        </IconButton>
      </Box>
    </Tooltip>
  )
}
