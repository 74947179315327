import { QueryFunctionContext } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import {
  DeletePmpDealResponse,
  DeleteRequest,
  PmpDealResponse,
  PmpTableResponse,
} from '../models/Pmp'
import { getInstance } from './instance'
import { WithResponse } from '../models/Response'
import { getPmpDealKey, getPmpListKey } from './QueryKeys'

export const getPmpList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getPmpListKey)['keys']>
>): Promise<PmpTableResponse> => {
  const {
    pageSize,
    searchField,
    creativeTypeList,
    exchangeList,
    order,
    sortBy,
    statusIds,
  } = queryKey[0] || {}
  try {
    const params = {
      ...(creativeTypeList ? { creativeTypes: creativeTypeList } : {}),
      ...(exchangeList ? { exchanges: exchangeList } : {}),
      ...(searchField ? { searchKeywords: searchField } : {}),
      ...(statusIds ? { active: statusIds === 1 } : {}),
      ...(sortBy?.length
        ? { sortBy: `${order === 'DESC' ? '-' : '+'}${sortBy}` }
        : {}),
      pageNo: String(pageParam),
      ...(typeof pageSize === 'number'
        ? { noOfEntries: String(pageSize) }
        : {}),
    }

    const response: AxiosResponse<WithResponse<PmpTableResponse>> =
      await getInstance().post(`/v3/inv/pmp/deals/list`, { ...params })

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}

export interface CreatePmpDealParams {
  dealId: string
  dealName: string
  exchangeId: number
  active: boolean
  description?: string
  cpm?: number
  dealCurationTypeId?: number
  /**
   * creative type ID array
   */
  creativeTypes?: number[]
  /**
   * Customer ID array
   */
  assignToCustomers?: number[]
}

export interface CreatePmpDeaResponse {
  message: string
  id: number
}

export const createPmpDeal = async (requestParams: CreatePmpDealParams) => {
  try {
    const response: AxiosResponse<WithResponse<CreatePmpDeaResponse>> =
      await getInstance().post(`/v3/inv/pmp/deals/add`, {
        ...requestParams,
      })
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

interface EditPmpDealRequest {
  dealId: number
  params: Partial<CreatePmpDealParams>
}

export const editPmpDeal = async ({ dealId, params }: EditPmpDealRequest) => {
  try {
    const response: AxiosResponse<WithResponse<CreatePmpDeaResponse>> =
      await getInstance().patch(`/v3/inv/pmp/deals/${dealId}`, {
        ...params,
      })
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

export const deletePmpDeal = async (
  props: DeleteRequest
): Promise<DeletePmpDealResponse> => {
  const { ids } = props
  const params = new URLSearchParams({ ids: ids.join(',') })

  try {
    const response: AxiosResponse<DeletePmpDealResponse> =
      await getInstance().delete(`/v3/inv/pmp/deals?${params.toString()}`)
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

export const getPmpDeal = async ({
  signal,
  queryKey,
}: QueryFunctionContext<ReturnType<(typeof getPmpDealKey)['keys']>>) => {
  try {
    const { id } = queryKey[0]!
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    const response: AxiosResponse<WithResponse<PmpDealResponse>> =
      await getInstance().get(`/v3/inv/pmp/deals/${id}`, { signal })
    return response.data.data
  } catch (e) {
    throw (e as AxiosError)?.response?.data
  }
}

const UpdateAction = {
  assign: 'assign',
  deassign: 'deassign',
} as const

export interface AssignToCampaignOperation {
  action: keyof typeof UpdateAction
  campaignIds?: number[]
  pgDealIds?: number[]
  pmpDealIds?: number[]
}

export interface AssignToCampaignRequest {
  operations: AssignToCampaignOperation[]
}

export interface AssingToCampaignPmpRequest {
  params: AssignToCampaignRequest
}

export const assingToCampaignPmp = async ({
  params,
}: AssingToCampaignPmpRequest) => {
  try {
    const response: AxiosResponse<WithResponse<any>> =
      await getInstance().patch(`/v3/cmp/pmp/campaigns/mappings`, {
        ...params,
      })

    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}
