import { createFileRoute } from '@tanstack/react-router'
import { SelectObject } from '@applift/illustrations'
import {
  Badge,
  Box,
  Col,
  Paper,
  Row,
  Skeleton,
  Typography,
} from '@applift/factor'

import { useGroupsStatistics } from '../../hooks'
import { GroupsStatistics } from '../../models/Group'

export const Route = createFileRoute('/groups/')({
  component: GroupsIndexComponent,
})

const groupInfoText: { [key: string]: string } = {
  totalInventoryGroups: ' Total Inventory Groups',
  unUsedGroups: 'Unused Inventory Groups',
  includedGroups: 'Groups used as Allowed',
  excludedGroups: 'Groups used as Blocked',
}

function GroupsIndexComponent() {
  const { data, isLoading } = useGroupsStatistics()

  const groupStatistics = data as GroupsStatistics

  return (
    <Box
      sx={{
        py: 24,
        px: 16,
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        height: 100,
        width: 100,
      }}
    >
      <Row xs={{ gutterSize: 16 }}>
        {Object.keys(groupInfoText).map(stat => (
          <Col key={stat} xs={3} sx={{ height: 100 }}>
            <Paper elevation={2} sx={{ py: 16, pr: 16, pl: 40 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                {stat === 'includedGroups' && !isLoading && (
                  <Badge variant="dot" color="success" size="medium" />
                )}
                {stat === 'excludedGroups' && !isLoading && (
                  <Badge variant="dot" color="error" size="medium" />
                )}

                {isLoading || !groupStatistics ? (
                  <Skeleton width={70} height={40} />
                ) : (
                  <Typography
                    component="h2"
                    variant="h4"
                    weight="demi"
                    sx={{ display: 'block' }}
                    gutterBottom={false}
                  >
                    {groupStatistics[stat as keyof GroupsStatistics]}
                  </Typography>
                )}
              </Box>
              <Typography sx={{ mt: 12 }}>
                {isLoading ? (
                  <Skeleton width={150} height={20} />
                ) : (
                  groupInfoText[stat]
                )}
              </Typography>
            </Paper>
          </Col>
        ))}
      </Row>
      <Paper
        elevation={2}
        sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <SelectObject
          sx={{ height: 50, width: 50, textColor: 'primary-500', mb: 24 }}
        />
        <Typography sx={{ display: 'block' }} variant="bodyMedium">
          Select an inventory group to view the details
        </Typography>
      </Paper>
    </Box>
  )
}
