import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  LoadingButton,
  TypoTooltip,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'

import { CampaignInfoType } from '../../../models/Group'
import { useBasicIoCampaignList } from '../../../hooks/useCampaign'
import {
  AssignToCampaignsDealDialog,
  AssingToCampaignPgApiRef,
} from './AssignToCampaignsDealsDialog'
import { ScreenLoader } from '../../ScreenLoader'
import {
  NormalizedIOCampaignList,
  normalizeCampaignData,
  normalizeIOCampaignData,
} from '@applift/platform'
import { useMutation } from '@tanstack/react-query'
import { AssignToCampaignOperation, assingToCampaignPg } from '../../../api'
import { PgListResponse } from '../../../models/Pg'
import { queryClient } from '../../../cache'

interface AssingToCampaignDealWrapperProps {
  onClose: () => void
  attachedCampaign: number[] | []
  onSuccess?: () => void
  selectedDealData: PgListResponse[]
}

export const AssingToCampaignDealWrapper = (
  props: AssingToCampaignDealWrapperProps
) => {
  const { onClose, onSuccess, selectedDealData } = props
  const [campaignsInfo, setCampaignsInfo] = React.useState<CampaignInfoType[]>(
    []
  )
  const [isInitialized, setIsInitialized] = React.useState<boolean>(false)

  const assignToCampaignRef = React.useRef<AssingToCampaignPgApiRef>(null)

  const dealIds = selectedDealData.map(deal => deal.id)

  const activeIds = selectedDealData[0]?.activeCampaignIds ?? []
  const otherIds = selectedDealData[0]?.otherCampaignIds ?? []
  const campaignsIds = [...activeIds, ...otherIds]

  const { data: ioCampaignData, isFetching } = useBasicIoCampaignList({
    searchField: '',
    campaignIds: campaignsIds,
    options: {
      enabled: selectedDealData.length === 1 && !!campaignsIds.length,
    },
  })

  // if length is more than 1 don't want to show the table
  // using below to modify cause some time when length > 1 , getting data from cache
  const ioCampaignList =
    selectedDealData.length === 1 ? ioCampaignData : undefined

  const flatIoData = React.useMemo(() => {
    return (
      ioCampaignList?.pages
        ?.map(page => {
          return page?.ioCampaignsList ?? []
        })
        .flat(1) || []
    )
  }, [ioCampaignList?.pages])

  const flatCampaignData = React.useMemo(() => {
    return (
      flatIoData
        .map(io => {
          return (
            io?.campaigns.map(cmp => ({
              ...cmp,
              ioId: io.ioId,
              ioName: io.ioName,
              ioBudgetTypeId: io.ioBudgetTypeId,
            })) ?? []
          )
        })
        .flat(1) || []
    )
  }, [flatIoData])

  // Formating the data for the selection
  const initData = flatCampaignData.map(cmp => {
    return {
      id: cmp.campaignId,
      name: cmp.name,
      creativeTypeId: cmp.creativeTypeId,
      status: cmp.status,
      ioId: cmp.ioId,
      ioName: cmp.ioName,
      ioBudgetTypeId: cmp.ioBudgetTypeId,
    }
  })

  React.useEffect(() => {
    if (initData?.length && !campaignsInfo.length && !isInitialized) {
      setCampaignsInfo(initData)
      setIsInitialized(true)
    }
  }, [initData, campaignsInfo, isInitialized])

  const intialNormalizedData: Record<string, NormalizedIOCampaignList> = {}
  const normalizedParent = normalizeIOCampaignData(flatIoData)

  flatIoData?.forEach((item, index) => {
    const parent = normalizedParent?.[index]
    if (parent !== undefined && item.campaigns !== undefined) {
      const data = normalizeCampaignData(item.campaigns, parent)
      data?.map(val => {
        intialNormalizedData[val.value] = val
      })
    }
  })

  const assingToCampaignPgMutation = useMutation(assingToCampaignPg, {
    mutationKey: ['assingToCampaignPg'],
    onSuccess: () => {
      queryClient.refetchQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getPgList',
      })
      enqueueSnackbar('Changes have been saved successfully', {
        variant: 'success',
      })
      onSuccess?.()
      onClose()
    },
    onError: e => {
      // @ts-ignore
      const errorMessage = e?.errorObjects?.[0]?.error as string

      enqueueSnackbar(
        errorMessage?.length
          ? errorMessage
          : 'Something went wrong. Please try after some time.',
        {
          variant: 'error',
        }
      )
      onSuccess?.()
      onClose()
    },
  })

  function compareWithoutOrder(
    intialData: CampaignInfoType[],
    currentData: CampaignInfoType[]
  ) {
    // Parse the JSON strings into JavaScript objects

    // Sort the arrays of objects
    const sortFunction = (a: { id: number }, b: { id: number }) => {
      return a.id - b.id
    }

    intialData.sort(sortFunction)
    currentData.sort(sortFunction)

    // Convert the sorted arrays back to JSON strings
    const sortedJson1 = JSON.stringify(intialData)
    const sortedJson2 = JSON.stringify(currentData)

    // Compare the resulting strings
    return sortedJson1 === sortedJson2
  }

  const disableUpdate =
    compareWithoutOrder(initData, campaignsInfo) || isFetching

  const getParam = React.useCallback((): AssignToCampaignOperation[] => {
    const assingToCampaignPara: AssignToCampaignOperation[] = []

    const initCampIds = initData.map(cmp => cmp.id)
    const currentIds = campaignsInfo.map(cmp => cmp.id)

    const isMultiSelected = selectedDealData.length > 1

    // ADD
    if (isMultiSelected) {
      selectedDealData.forEach(deal => {
        const addedIds = currentIds.filter(
          id => !deal?.activeCampaignIds?.includes(id)
        )
        if (addedIds.length) {
          assingToCampaignPara.push({
            action: 'assign',
            campaignIds: addedIds,
            pgDealIds: [deal.id],
          })
        }
      })
    } else {
      const addedIds = currentIds.filter(id => !initCampIds.includes(id))

      if (addedIds.length) {
        assingToCampaignPara.push({
          action: 'assign',
          campaignIds: addedIds,
          pgDealIds: dealIds,
        })
      }
    }

    // REMOVE
    if (initCampIds.length) {
      const removedIds = initCampIds.filter(id => !currentIds.includes(id))
      if (removedIds.length) {
        assingToCampaignPara.push({
          action: 'deassign',
          campaignIds: removedIds,
          pgDealIds: dealIds,
        })
      }
    }
    return assingToCampaignPara
  }, [initData, campaignsInfo, dealIds, selectedDealData])

  function removeDeletedEntries(data: any) {
    const result = {}
    const rejectedStatus = ['deleted', 'expired', 'rejected']

    for (const key in data) {
      if (!rejectedStatus.includes(data[key].status)) {
        // @ts-ignore
        result[key] = data[key]
      }
    }

    return result
  }

  const getHeadingText = () => {
    if (selectedDealData.length === 1) {
      return (
        <Typography component="p" sx={{ display: 'flex' }}>
          {`Select the PG Campaigns below to which you want to assign the deal `}
          <TypoTooltip
            style={{ maxWidth: '450px' }}
            TypgraphyProps={{ component: 'span', weight: 'demi' }}
            sx={{ mx: 4 }}
            arrow
            placement="top"
          >
            {selectedDealData[0]?.dealId}
          </TypoTooltip>{' '}
          {` for:`}
        </Typography>
      )
    }
    return (
      <Typography component="p" sx={{ display: 'flex' }}>
        {'Select the PG Campaigns below to which you want to assign the '}
        <Typography component="span" weight="demi" sx={{ mx: 4 }}>
          {selectedDealData.length}
        </Typography>
        {' selected deals for:'}
      </Typography>
    )
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="xl"
      fullWidth
      sx={{ height: 100 }}
      PaperProps={{ sx: { height: 100 } }}
    >
      {isFetching ? (
        <ScreenLoader />
      ) : (
        <AssignToCampaignsDealDialog
          ref={assignToCampaignRef}
          onClose={onClose}
          campaignsInfo={campaignsInfo}
          setCampaignsInfo={setCampaignsInfo}
          initData={initData}
          intialNormalizedData={removeDeletedEntries(intialNormalizedData)}
          headingText={getHeadingText()}
        />
      )}
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          disabled={disableUpdate}
          loading={assingToCampaignPgMutation.isLoading}
          onClick={() => {
            assingToCampaignPgMutation.mutate({
              params: { operations: getParam() },
            })
          }}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
