import * as React from 'react'
import { ClickAwayListener, Layout } from '@applift/factor'
import { createFileRoute } from '@tanstack/react-router'

import { ContextualContent, ContextualSidebar } from './-pages/index'
import { findParentWithId } from '../../utils/common'
import { ConfirmationDialog } from '../../components/Dialogs'

export const Route = createFileRoute('/contextual/')({
  component: Contextual,
})

function Contextual() {
  const [addedKeywords, setAddedKeywords] = React.useState<string[]>([])
  const [addedURLs, setAddedURLs] = React.useState<string[]>([])
  const [discardDialogVisibility, toggleDiscardDialogVisibility] =
    React.useState(false)

  const capturedMouseEvent = React.useRef<MouseEvent | TouchEvent>()

  const onClickAway = (e: MouseEvent | TouchEvent) => {
    if (
      (addedKeywords.length || addedURLs.length) &&
      findParentWithId(e.target, 'inventory-app-tabs')
    ) {
      capturedMouseEvent.current = e
      e.stopImmediatePropagation()
      e.stopPropagation()
      e.preventDefault()
      toggleDiscardDialogVisibility(true)
    }
  }

  return (
    <>
      <ClickAwayListener
        disableReactTree
        enableCapturePhase
        onClickAway={onClickAway}
      >
        <Layout
          sidebar={
            <ContextualSidebar
              setAddedKeywords={setAddedKeywords}
              addedKeywords={addedKeywords}
              addedURLs={addedURLs}
              setAddedURLs={setAddedURLs}
            />
          }
          sidebarWidth={320}
          maxWidth={320}
          minWidth={320}
          hideResizerHandle
        >
          <ContextualContent
            setAddedKeywords={setAddedKeywords}
            addedKeywords={addedKeywords}
            addedURLs={addedURLs}
            setAddedURLs={setAddedURLs}
          />
        </Layout>
      </ClickAwayListener>
      {discardDialogVisibility && (
        <ConfirmationDialog
          onClose={() => toggleDiscardDialogVisibility(false)}
          onConfirmation={() => {
            setAddedKeywords([])
            setAddedURLs([])
            toggleDiscardDialogVisibility(false)
          }}
          primaryBtnText="Discard"
          theme="error"
          title="Discard Unsaved Changes"
          dialogContent="You have unsaved changes. All the selections will be cleared on changing the tab."
        />
      )}
    </>
  )
}
