import * as React from 'react'
import {
  ColumnDef,
  DataGrid,
  GridActionCellParams,
  RowSelectionState,
  SortingState,
} from '@applift/datagrid'
import { sx } from '@applift/factor'
import { ORGANIZATION_TYPE, useAppContext } from '@applift/platform'
import { SearchData, Warning, Marketplace } from '@applift/illustrations'

import { useTimezone } from '../../../../../hooks'
import { PmpListResponse } from '../../../../../models/Pmp'
import { NoResultsOverlay } from '../../../../../components/GridOverLay'
import { AddToGroupOverlay } from '../../../-components/AddToGroupOverlay'
import { useRouter } from '@tanstack/react-router'

interface PmpGroupGridProps {
  loading: boolean
  groupId: number
  totalRecords: number
  sorting: SortingState
  sharedGroup?: boolean
  data: PmpListResponse[]
  rowSelection: RowSelectionState
  colDef: ColumnDef<PmpListResponse>[]
  overlay?: 'error' | 'noRows' | 'noResult'
  organizationType: keyof typeof ORGANIZATION_TYPE
  isSelectionEnabled?: boolean
  onFetchRows?: () => void
  onAction: (params: GridActionCellParams<any, any>) => void
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}

export const PmpGroupGrid = (props: PmpGroupGridProps) => {
  const {
    data,
    colDef,
    loading,
    groupId,
    overlay,
    sorting,
    sharedGroup,
    rowSelection,
    totalRecords,
    organizationType,
    isSelectionEnabled = true,
    onAction,
    onFetchRows,
    setRowSelection,
    onSortingChange,
  } = props
  const { data: timezoneDate } = useTimezone()
  const router = useRouter()

  const setRowSelectionWrapper = React.useCallback(
    (_value: any) => {
      let value = _value
      if (typeof _value === 'function') {
        value = _value()
      }
      setRowSelection({ ...value })
    },

    [setRowSelection]
  )

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const isAddInventoryEnabled = React.useMemo(() => {
    if (orgType === 'ADVERTISER') {
      return !sharedGroup
    }
    return true
  }, [orgType, sharedGroup])

  const organizationTimezoneId = React.useMemo(
    //@ts-ignore
    () => ctx.appMeta.profileData.organizationTimezone,
    [ctx]
  )
  const organizationTimezone = React.useMemo(() => {
    return timezoneDate?.filter(
      timezone => timezone.id === organizationTimezoneId
    )[0]?.value
  }, [timezoneDate, organizationTimezoneId])

  return (
    <>
      <DataGrid
        data={data}
        loading={loading}
        columns={colDef}
        rowCount={totalRecords}
        overlay={overlay}
        onAction={onAction}
        components={{
          NoResultsOverlay,
          ErrorOverlay: NoResultsOverlay,
          NoRowsOverlay: AddToGroupOverlay,
        }}
        componentsProps={{
          noResultsOverlay: {
            text: 'No results found',
            subText: 'We can’t find any items matching your search.',
            illustration: (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  width: 100,
                  height: 'auto',
                  mb: 40,
                }}
              />
            ),
          },
          // @ts-ignore
          errorOverlay: {
            // @ts-ignore
            subText:
              'Oops, something went wrong. Please try again after some time.',
            illustration: (
              <Warning
                sx={{
                  textColor: 'primary-500',
                  width: 100,
                  height: 'auto',
                  mb: 40,
                }}
              />
            ),
          },
          // @ts-ignore
          noRowsOverlay: {
            // @ts-ignore
            actionText: isAddInventoryEnabled ? 'Add PMP Deals' : '',
            // @ts-ignore
            subText: isAddInventoryEnabled
              ? 'No PMP Deals added in the group yet. Please add PMP Deals in the group to assign it to the campaigns'
              : 'No PMP Deals added in the group yet.',
            illustration: (
              <Marketplace
                sx={{
                  textColor: 'primary-500',
                  width: 100,
                  height: 'auto',
                  mb: 40,
                }}
              />
            ),
            onAction: () => {
              router.navigate({
                to: '/groups/$groupId/edit',
                params: {
                  groupId: String(groupId),
                },
              })
            },
          },
        }}
        getRowId={(row: any) => {
          if (row && typeof row['id'] === 'number') {
            return `${row['id']}`
          }
          return row['id']
        }}
        state={{
          rowSelection,
          sorting,
        }}
        hideHeader
        hideFooter
        rowHeight={40}
        checkboxSelection={isSelectionEnabled}
        overscrollBehaviorX="contain"
        showColumnRightBorder
        showCellRightBorder
        disableRowSelectionOnClick
        classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
        onRowSelectionChange={setRowSelectionWrapper}
        onFetchRows={onFetchRows}
        onSortingChange={onSortingChange}
        meta={{ organizationTimezoneId, organizationTimezone }}
        initialState={{
          columnVisibility: {
            _customer: organizationType !== ORGANIZATION_TYPE.ADVERTISER,
            dealCurationTypeId:
              organizationType !== ORGANIZATION_TYPE.ADVERTISER,
          },
        }}
      />
    </>
  )
}
