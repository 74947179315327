import * as React from 'react'
import {
  TextField,
  IconButton,
  Button,
  Chip,
  Box,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { Search, Close } from '@applift/icons'
import { RowSelectionState } from '@applift/datagrid'
import { WithResponse } from '@applift/platform'
import { useRouter } from '@tanstack/react-router'

import {
  CreateNewGroupDialog,
  ConfirmationDialog,
} from '../../../../components/Dialogs/index'
import {
  SaveToExistingGroupRequest,
  SaveToNewGroupRequest,
} from '../../../../models/OpenExchange'
import {
  useSaveToExistingGroup,
  useSaveToNewGroup,
} from '../../../../hooks/useAction'
import { CONTEXTUAL_INVENTORY_ID } from '../../../../constants/contextual'
import { SaveToExisitingGroupDialog } from '../../../../components/Dialogs/SaveToExistingGroupDialog/SaveToExisitingGroupDialog'
import { SidebarInventoryGroup } from '../../../../models/Group'
import { getGroupCreatedMessage } from '../../../../utils/messages'
import { ContextualTableData } from '../../../../models/Contextual'

interface ContextualActionPanelProps {
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  rowSelection: RowSelectionState
  clearRowSelection: () => void
  tableData: ContextualTableData[]
  setAddedKeywords: React.Dispatch<React.SetStateAction<string[]>>
  setAddedURLs: React.Dispatch<React.SetStateAction<string[]>>
  hideTableCTAs?: boolean
}

export const ContextualActionPanel = (props: ContextualActionPanelProps) => {
  const {
    search,
    setSearch,
    rowSelection,
    clearRowSelection,
    tableData,
    setAddedKeywords,
    setAddedURLs,
    hideTableCTAs,
  } = props

  const [dialogToShow, setDialogToShow] = React.useState<
    'newGroup' | 'existingGroup' | 'delete'
  >()

  const router = useRouter()

  const selectedItemIds = Object.keys(rowSelection).map(id => Number(id))
  const selectedItems = tableData.filter(item =>
    selectedItemIds.includes(item.id)
  )
  const selectedItemsCount = selectedItems.length

  const chipLabel = (
    <Typography
      sx={{ textWeight: 'demi' }}
    >{`${selectedItemsCount} out of ${tableData.length} selected`}</Typography>
  )

  const selectedKeywords: string[] = []
  const selectedURLs: string[] = []

  Object.keys(rowSelection).map(item => {
    const selectedData = tableData[Number(item)]
    if (selectedData?.type === 'Keyword') {
      selectedKeywords.push(selectedData.name)
    } else if (selectedData?.type === 'URL') {
      selectedURLs.push(selectedData?.name)
    }
  })

  const hideDialog = () => setDialogToShow(undefined)

  const onDeleteClick = () => {
    const newTableData = tableData.filter(
      item => !selectedItemIds.includes(item.id)
    )

    const newKeywordData = newTableData
      .filter(item => item.type === 'Keyword')
      .map(filteredKeyword => filteredKeyword.name)
    const newURLData = newTableData
      .filter(item => item.type === 'URL')
      .map(filteredURL => filteredURL.name)
    clearRowSelection()
    hideDialog()
    setSearch('')
    setAddedKeywords(newKeywordData)
    setAddedURLs(newURLData)

    enqueueSnackbar('Selected inventories deleted successfully.', {
      variant: 'success',
    })
  }

  const onNewGroupCreateActionComplete = () => {
    hideDialog()
    clearRowSelection()
  }

  const onNewGroupCreateActionSuccess = (
    data: WithResponse<SidebarInventoryGroup>,
    payload: SaveToNewGroupRequest
  ) => {
    enqueueSnackbar(getGroupCreatedMessage(payload.groupName), {
      variant: 'success',
    })
    const appliedFilters =
      router.state.location.search.currentlyAppliedGroupFilters
    if (data?.data?.id) {
      router.navigate({
        to: '/groups/$groupId',
        params: { groupId: String(data.data.id) },
        ...(appliedFilters?.length
          ? {
              search: {
                currentlyAppliedGroupFilters: appliedFilters,
              },
            }
          : {}),
      })
    }
  }

  const onSaveToExistingGroupActionError = (e: any) => {
    enqueueSnackbar(
      e.errorObjects
        ? (e.errorObjects[0]?.error as string)
        : 'Something went wrong',
      {
        variant: 'error',
      }
    )
    hideDialog()
    clearRowSelection()
  }

  const onSaveToExistingGroupActionSuccess = (
    _: any,
    payload: SaveToExistingGroupRequest
  ) => {
    if (payload.groupIds) {
      router.navigate({
        to: '/groups/$groupId',
        params: { groupId: String(payload.groupIds[0]) },
      })
    }
    enqueueSnackbar(`Selected inventories saved to the group successfully`, {
      variant: 'success',
    })
  }

  const { mutate: saveToNew, isLoading: newGroupSaveInProgress } =
    useSaveToNewGroup({
      onSettled: onNewGroupCreateActionComplete,
      onSuccess: onNewGroupCreateActionSuccess,
    })
  const {
    mutate: saveToExisiting,
    isLoading: isSaveToExistingGroupInProgress,
  } = useSaveToExistingGroup(
    onSaveToExistingGroupActionError,
    onSaveToExistingGroupActionSuccess
  )

  const handleSaveToNew = (groupName: string) => {
    if (groupName) {
      const payload: SaveToNewGroupRequest = {
        groupName: groupName,
        contextualKeywords: selectedKeywords,
        contextualUrls: selectedURLs,
        groupTypeId: CONTEXTUAL_INVENTORY_ID,
      }
      saveToNew(payload)
    }
  }

  const handleSaveExisting = (groupId: number | undefined) => {
    if (groupId) {
      const payload: SaveToExistingGroupRequest = {
        groupIds: [groupId],
        contextualKeywords: selectedKeywords,
        contextualUrls: selectedURLs,
        groupTypeId: CONTEXTUAL_INVENTORY_ID,
      }
      saveToExisiting(payload)
    }
  }
  return (
    <>
      <Box sx={{ px: 24, mt: 16, display: 'flex' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {selectedItemsCount > 0 && (
            <Chip
              size="small"
              label={chipLabel}
              color="secondary"
              onDelete={clearRowSelection}
            />
          )}

          {!hideTableCTAs && selectedItemsCount > 0 && (
            <Box sx={{ display: 'inline-flex' }}>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                sx={{ marginLeft: 8 }}
                onClick={() => setDialogToShow('newGroup')}
              >
                Save To New Group
              </Button>
            </Box>
          )}
          {!hideTableCTAs && selectedItemsCount > 0 && (
            <Box sx={{ display: 'inline-flex' }}>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                sx={{ marginLeft: 8 }}
                onClick={() => setDialogToShow('existingGroup')}
              >
                Save To Existing Group
              </Button>
            </Box>
          )}
          {!hideTableCTAs && selectedItemsCount > 0 && (
            <Button
              onClick={() => setDialogToShow('delete')}
              color="error"
              size="small"
              variant="outlined"
              sx={{ marginLeft: 8 }}
            >
              Delete
            </Button>
          )}
        </Box>

        <Box
          sx={{
            ml: 'auto',
          }}
        >
          <TextField
            type="text"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder="Search by Name or Type"
            variant="outlinedDash"
            sx={{ ml: 8 }}
            InputProps={{
              startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
              slotProps: { root: { style: { width: '220px' } } },
              endAdornment:
                search.trim().length > 0 ? (
                  <IconButton
                    onClick={() => {
                      setSearch('')
                    }}
                    size="small"
                  >
                    <Close fontSize={16} />
                  </IconButton>
                ) : null,
            }}
          />
        </Box>
      </Box>
      {dialogToShow === 'newGroup' && (
        <CreateNewGroupDialog
          onClose={hideDialog}
          onConfirmation={handleSaveToNew}
          groupType="contextual"
          isLoading={newGroupSaveInProgress}
        />
      )}
      {dialogToShow === 'existingGroup' && (
        <SaveToExisitingGroupDialog
          onClose={hideDialog}
          onConfirmation={handleSaveExisting}
          groupType="contextual"
          groupId={CONTEXTUAL_INVENTORY_ID}
          isLoading={isSaveToExistingGroupInProgress}
        />
      )}
      {dialogToShow === 'delete' && (
        <ConfirmationDialog
          onClose={hideDialog}
          onConfirmation={onDeleteClick}
          primaryBtnText="Delete"
          theme="error"
          title="Delete Selected Inventories"
          dialogContent="Are you sure you want to delete the selected inventories?"
        />
      )}
    </>
  )
}
