import {
  MutationOptions,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'
import { WithResponse } from '@applift/platform'
import { SortingState } from '@applift/datagrid'

import {
  getContextualGroupDetailKey,
  getGroupListKey,
  getGroupPmpListKey,
  getGroupsStatisticsKey,
  getOpenExchangeGroupCountDataKey,
  getOpenExchangeGroupDistributionDataKey,
  getOpenExchangeGroupListingDataKey,
} from '../api/QueryKeys'
import {
  ExcludeInventoryGroupFromCampaignArgs,
  changeGroupName,
  downloadContextualGroupInventories,
  downloadOpenExchangeGroupInventories,
  downloadPMPGroupInventories,
  excludeIncludeInventoryGroupFromCampaignWithLatency,
  getContextualGroupDetail,
  getGroupCount,
  getGroupList,
  getGroupPmpList,
  getGroupStatistics,
  getOpenExchangeGroupCountData,
  getOpenExchangeGroupDistributionData,
  getOpenExchangeGroupListingData,
  removeInventoriesFromGroup,
} from '../api/groups'
import {
  GroupChangeNameRequest,
  GroupChangeNameResponse,
  GroupRemovalRequest,
  GroupsListRequest,
  SidebarInventoryGroup,
} from '../models/Group'
import { PmpListResponse, PmpTableResponse } from '../models/Pmp'
import { DEFAULT_NUMBER_OF_GROUP_SIDEBAR_RECORDS } from '../constants/group'
import { DEFAULT_NUMBER_OF_LIST_RECORDS } from '../constants/exchange'

const onError = (e: WithResponse) => {
  enqueueSnackbar?.(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong!',
    {
      variant: 'error',
    }
  )
}

export const useGrouplist = (payload: GroupsListRequest, enabled?: boolean) => {
  const queryData = useInfiniteQuery(
    getGroupListKey.keys({
      scope: 'getGroupList',
      noOfEntries: DEFAULT_NUMBER_OF_GROUP_SIDEBAR_RECORDS,
      ...payload,
    }),
    getGroupList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce((prev, one) => {
          const datalength = one?.inventoryGroupList?.length
          if (datalength) {
            return prev + datalength
          }
          return prev
        }, 0)
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return undefined
      },
      cacheTime: 5000,
      enabled,
      onError,
    }
  )
  return queryData
}

export const useGroupsStatistics = () =>
  useQuery(
    getGroupsStatisticsKey.keys('getGroupStatistics'),
    getGroupStatistics,
    {
      onError,
      cacheTime: 0,
    }
  )

export const useGroupCount = () =>
  useQuery(getGroupsStatisticsKey.keys('getGroupCount'), getGroupCount, {
    onError,
    cacheTime: 0,
  })

export const useContextualGroupDetail = (groupId: string, enabled?: boolean) =>
  useQuery(
    getContextualGroupDetailKey.keys({
      scope: 'getContextualGroupDetail',
      groupId,
    }),
    getContextualGroupDetail,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e?.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      enabled,
      cacheTime: 0,
    }
  )

export const usePmpGroupList = (props: {
  groupId: number
  searchField?: string
  pageSize?: number
  sorting?: SortingState
  noOfEntries?: number
  options?: {
    enabled?: UseInfiniteQueryOptions<PmpListResponse>['enabled']
  }
}): UseInfiniteQueryResult<PmpTableResponse> => {
  const {
    searchField = '',
    pageSize,
    sorting,
    noOfEntries,
    options,
    groupId,
  } = props

  const queryData = useInfiniteQuery(
    getGroupPmpListKey.keys({
      scope: 'getGroupPmpList',
      groupId,
      pageSize,
      searchField,
      noOfEntries,
      sortBy: sorting,
    }),
    getGroupPmpList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce((prev, one) => {
          const datalength = one?.pmpDealData?.length
          if (datalength) {
            return prev + datalength
          }
          return prev
        }, 0)
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return null
      },
      enabled: options?.enabled ?? true,
      cacheTime: 0,
    }
  )
  return queryData
}

export const useRemoveInventoriesMutation = (
  onSettled?: MutationOptions<
    SidebarInventoryGroup | undefined,
    any,
    GroupRemovalRequest
  >['onSettled'],
  onSuccess?: MutationOptions<
    SidebarInventoryGroup | undefined,
    any,
    GroupRemovalRequest
  >['onSuccess']
) =>
  useMutation(removeInventoriesFromGroup, {
    mutationKey: ['removeInventoriesFromGroup'],
    onSuccess,
    onError,
    onSettled,
  })

export const useOpenExchangeGroupCountData = (
  payload: { groupId: number | string; searchField?: string },
  options?: {
    enabled?: boolean
  }
) =>
  useQuery(
    getOpenExchangeGroupCountDataKey.keys({
      ...payload,
      scope: 'getOpenExchangeGroupCountData',
    }),
    getOpenExchangeGroupCountData,
    {
      onError,
      enabled: options?.enabled,
      cacheTime: 0,
    }
  )

export const useOpenExchangeGroupDistributionData = (
  payload: { groupId: number | string; searchField?: string },
  options?: { enabled?: boolean }
) =>
  useQuery(
    getOpenExchangeGroupDistributionDataKey.keys({
      ...payload,
      scope: 'getOpenExchangeGroupDistributionData',
    }),
    getOpenExchangeGroupDistributionData,
    {
      onError,
      enabled: options?.enabled,
      cacheTime: 0,
    }
  )

export const useOpenExchangeGroupListingData = (
  payload: {
    groupId: number | string
    searchField?: string
    noOfEntries?: number
  },
  options?: {
    enabled?: boolean
  }
) =>
  useInfiniteQuery(
    getOpenExchangeGroupListingDataKey.keys({
      scope: 'getOpenExchangeGroupListingData',
      ...payload,
    }),
    getOpenExchangeGroupListingData,
    {
      getNextPageParam: (lastPage, pages) =>
        (lastPage?.openExchangeList?.length ?? 0) <
        (payload.noOfEntries ?? DEFAULT_NUMBER_OF_LIST_RECORDS)
          ? undefined
          : pages.length + 1,
      enabled: options?.enabled,
      cacheTime: 0,
    }
  )

export const useDownloadOpenExchangeGroupInventoriesMutation = () =>
  useMutation(downloadOpenExchangeGroupInventories, {
    mutationKey: ['downloadOpenExchangeGroupInventories'],
    onError,
  })

export const useDownloadContextualGroupInventoriesMutation = () =>
  useMutation(downloadContextualGroupInventories, {
    mutationKey: ['downloadContextualGroupInventories'],
    onError,
  })

export const useDownloadPMPGroupInventoriesMutation = () =>
  useMutation(downloadPMPGroupInventories, {
    mutationKey: ['downloadPMPGroupInventories'],
    onError,
  })

export const useChangeGroupNameMutation = (
  onSuccess?: MutationOptions<
    GroupChangeNameResponse | undefined,
    any,
    GroupChangeNameRequest
  >['onSuccess']
) =>
  useMutation(changeGroupName, {
    mutationKey: ['changeGroupName'],
    onError,
    onSuccess,
  })

export const useAssignGroupToCampaign = (options: {
  onSuccess: (
    response: any,
    payload: ExcludeInventoryGroupFromCampaignArgs
  ) => void
  onError: (error: any) => void
}) =>
  useMutation(excludeIncludeInventoryGroupFromCampaignWithLatency, {
    mutationKey: ['AssignGroupToCampaign'],
    onError: options.onError,
    onSuccess: options.onSuccess,
  })
