import { SortingState } from '@applift/datagrid'
import { GroupsListRequest } from '../models/Group'
import {
  ExchangeListRequest,
  ExchangeMatrixCountRequestParams,
} from '../models/OpenExchange'
import { scopeTypes } from './ScopeNames'
import { OpenExchangeSidebarSearchValues } from '../routes/exchange'

export const getDealStatusKey = {
  keys: (scope: scopeTypes) => [{ scope } as const],
}

export const getTrafficTypesKey = {
  keys: (scope: scopeTypes) => [{ scope } as const],
}

export const getInventoryTypesKey = {
  keys: (scope: scopeTypes) => [{ scope } as const],
}

export const getGroupListKey = {
  keys: (
    args: GroupsListRequest & {
      scope: scopeTypes
    }
  ) => [args],
}

export const getGroupDetailKey = {
  keys: (args: { groupId: string; scope: scopeTypes }) => [args],
}

export const getOpenExchangeGroupListingDataKey = {
  keys: (args: {
    groupId: string | number
    scope: scopeTypes
    searchField?: string
    noOfEntries?: number
  }) => [args],
}

export const getOpenExchangeGroupCountDataKey = {
  keys: (args: {
    groupId: string | number
    scope: scopeTypes
    searchField?: string
  }) => [args],
}

export const getOpenExchangeGroupDistributionDataKey = {
  keys: (args: {
    groupId: string | number
    scope: scopeTypes
    searchField?: string
  }) => [args],
}

export const getGroupPmpListKey = {
  keys: (args: {
    scope: scopeTypes
    groupId: number
    searchField?: string
    noOfEntries?: number
    pageNo?: number
    sortBy?: SortingState
    pageSize?: number
  }) => [args],
}

export const getContextualGroupDetailKey = {
  keys: (args: { groupId: string; scope: scopeTypes }) => [args],
}

export const getPmpListKey = {
  keys: (args: {
    scope: scopeTypes
    searchField?: string[]
    creativeTypeList: any
    exchangeList: any
    statusIds?: number
    order?: string // 'DESC' | 'ASC'
    sortBy?: string
    pageSize?: number
    pageNo?: number
  }) => [args],
}
export const getBlocklistKey = {
  keys: (args: {
    scope: scopeTypes
    searchField?: string
    noOfEntries: number
    pageNo?: number
  }) => [args],
}

export const getOpenExchangeListingDataKey = {
  keys: (args: {
    scope: scopeTypes
    filters: OpenExchangeSidebarSearchValues
  }) => [args],
}

export const getPgListKey = {
  keys: (args: {
    scope: scopeTypes
    searchField?: string
    exchangeIds: string
    paymentTypeIds?: number
    statusIds?: number
    ids?: string
    order?: string // 'DESC' | 'ASC'
    sortBy?: string
    noOfEntries: number
    pageNo?: number
  }) => [args],
}

export const getBasicCampaignListKey = {
  keys: (args: {
    scope: scopeTypes
    ioIdsList?: number[]
    ids?: number[]
    responseFields?: string[]
    status?: string[]
    owIds?: number[]
    campaignIds?: number[]
    searchField?: string
    noOfEntries?: number
    sortBy?: SortingState
    timezoneId?: number
    campaignStatus?: string
    creativeTypeIds?: number[]
    pageNo?: number
  }) => [args],
}
export const getBasicIoCampaignListKey = {
  keys: (args: {
    scope: scopeTypes
    ioIdList?: number[]
    ids?: number[]
    owIdList?: string[]
    ioBudgetTypeIdsList?: number[]
    ioStatusIdsList?: number[]
    campaignIds?: number[]
    noOfEntries?: number
    sortBy?: SortingState
    offset?: number
    isAllOWIds?: boolean
    creativeTypeIds?: number[]
    pageNo?: number
    searchField: string
    campaignStatus?: string[]
  }) => [args],
}

export const getBasicCustomersListKey = {
  keys: (args: {
    scope: scopeTypes
    searchField?: string
    noOfEntries?: number
    pageNo?: number
    owIds?: number[]
    owStatusIds?: number[]
    provideRunningCampaigns?: boolean
  }) => [args],
}

export const getCustomerListKey = {
  keys: (args: { scope: scopeTypes; status?: string }) => [args],
}

export const getDistributionDataKey = {
  keys: (args: {
    filters: OpenExchangeSidebarSearchValues
    scope: scopeTypes
  }) => [args],
}

export const getDistributionDataFromCSVKey = {
  keys: (scope: string, fileName: string, fileSize: number) => [
    { scope, fileName, fileSize } as const,
  ],
}

export const getExchangeMatrixCountKey = {
  keys: (args: ExchangeMatrixCountRequestParams) => [args],
}

export const getExchangeMatrixCountCSVKey = {
  keys: (scope: scopeTypes, fileName: string, fileSize: number) => [
    { scope, fileName, fileSize } as const,
  ],
}

export const getOpenExchangeListingDataFromCSVKey = {
  keys: (scope: string, fileName: string, fileSize: number) => [
    { scope, fileName, fileSize } as const,
  ],
}

export const getOpenExchangeCountDataFromCSVKey = {
  keys: (scope: string, fileName: string, fileSize: number) => [
    { scope, fileName, fileSize } as const,
  ],
}

export const getContextualKeywordSuggestionsKey = {
  keys: (args: { keyword: string; scope: scopeTypes }) => [args],
}

export const getContextualKeywordRecommendationsKey = {
  keys: (args: { keyword: string; scope: scopeTypes }) => [args],
}

export const getContextualURLPreviewKey = {
  keys: (args: { url: string; scope: scopeTypes }) => [args],
}

export const getOpenExchangeCountDataKey = {
  keys: (
    args: {
      scope: scopeTypes
    } & ExchangeListRequest
  ) => [args],
}

export const getPmpDealKey = {
  keys: (args: { id: string; scope: scopeTypes }) => [args],
}

export const campaignsDealCountKey = {
  keys: (args: { campaignTypeId: string; scope: scopeTypes }) => [args],
}

export const getCustomerCountKey = {
  keys: (args: { scope: scopeTypes; owIds?: number[] }) => [args],
}

export const getAssociatedCustomerKey = {
  keys: (args: { dealId: string; scope: scopeTypes }) => [args],
}

export const getGroupsStatisticsKey = {
  keys: (scope: scopeTypes) => [{ scope } as const],
}
