import * as React from 'react'
import { Deal } from '@applift/illustrations'
import { Container, Box, Row, Col, Typography, Button } from '@applift/factor'
import { Add, ShieldLock, CalendarDollar, WebsiteCheck } from '@applift/icons'

import { PgDialog } from '../PgDialog'
import { EmptyPageFeatures } from '../../../../components/EmptyPageFeature'

export const EmptyPage = () => {
  const [open, setOpen] = React.useState<boolean>(false)
  const features = [
    {
      icon: (
        <WebsiteCheck fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Guaranteed Ad Placements',
      description:
        'Secure ad inventory in advance at fixed rates, ensuring guaranteed ad placements and impressions, which helps in campaign planning and budget allocation.',
    },
    {
      icon: (
        <ShieldLock fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Premium Inventory Access',
      description:
        "Gain access to premium ad placements on high-quality publishers' websites, enhancing brand visibility and audience engagement.",
    },
    {
      icon: (
        <CalendarDollar
          fontSize={40}
          sx={{ textColor: 'primary-600', mb: 16 }}
        />
      ),
      title: 'Budget Predictability',
      description:
        'Plan advertising budgets more effectively with fixed pricing and guaranteed impressions, providing financial predictability and minimizing ad spend uncertainty.',
    },
  ]

  return (
    <Container
      sx={{
        width: 100,
        height: 100,
        p: 40,
        bgColor: 'neutral-0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Row
        xs={{ gutterSize: 40 }}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mb: 48,
        }}
      >
        <Col xs={12} sm={6} md={5}>
          <Box sx={{ textColor: 'primary-500' }}>
            <Deal sx={{ width: 100, height: 'auto' }} />
          </Box>
        </Col>
        <Col xs={12} sm={6} md={7}>
          <Typography component="h1" variant="h5" weight="demi" sx={{ mb: 8 }}>
            Programmatic Guaranteed (PG) Deals
          </Typography>
          <Typography
            component="h4"
            variant="bodyLarge"
            lineHeight="multi-line"
            weight="normal"
            sx={{ mb: 16 }}
          >
            {
              'Ensure guaranteed ad placements and budget predictability with Programmatic Guaranteed (PG) deals, securing premium inventory for tailored campaign success.'
            }
          </Typography>
          <Button
            startIcon={<Add />}
            variant="contained"
            color="primary"
            disableRipple
            onClick={() => {
              setOpen(true)
            }}
          >
            Add New Deal
          </Button>
        </Col>
      </Row>
      <Row xs={{ gutterSize: 40 }} sx={{ justifyContent: 'center' }}>
        {features.map(feature => (
          <Col xs={features.length < 3 ? 5 : 4} key={feature.title}>
            <EmptyPageFeatures
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          </Col>
        ))}
      </Row>
      {open && (
        <PgDialog
          onClose={() => {
            setOpen(false)
          }}
        />
      )}
    </Container>
  )
}
