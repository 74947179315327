import * as React from 'react'
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  LoadingButton,
  Tooltip,
  TypoTooltip,
  Typography,
} from '@applift/factor'
import { ArrowLeft, ChevronRight } from '@applift/icons'
import { useRouter } from '@tanstack/react-router'

import { SidebarInventoryGroup } from '../../../../../models/Group'
import { getIcon } from '../../../../../registry'
import { DiscardChangesDialog } from '../DiscardChangesDialog'

interface EditGroupSectionHeaderProps {
  groupDetail: SidebarInventoryGroup
  enableSaveToGroupBtn: boolean
  isLoading: boolean
  error?: string
  saveToGroup: () => void
}

const iconMapper = {
  2: getIcon('InventoryPmpDeals', {
    fontSize: 24,
    sx: { flexShrink: 0, textColor: 'primary-600' },
  }),
  1: getIcon('InventoryOpenExchange', {
    fontSize: 24,
    sx: { flexShrink: 0, textColor: 'primary-600' },
  }),
  3: getIcon('InventoryContextual', {
    fontSize: 24,
    sx: { flexShrink: 0, textColor: 'primary-600' },
  }),
}

const groupTypeMapper = {
  2: 'PMP Deals',
  1: 'Inventories',
  3: 'Contextual Inventory',
}

export const EditGroupSectionHeader = (props: EditGroupSectionHeaderProps) => {
  const { groupDetail, enableSaveToGroupBtn, isLoading, saveToGroup, error } =
    props

  const [discardDialogVisibility, toggleDiscardDialog] = React.useState(false)

  const router = useRouter()

  const navigateToGroupsPage = () => {
    router.navigate({
      to: '/groups/$groupId',
      params: { groupId: String(groupDetail.id) },
    })
  }

  return (
    <>
      <Box
        sx={{ display: 'flex', width: 75, alignItems: 'center', height: 100 }}
      >
        <IconButton
          onClick={() => {
            if (enableSaveToGroupBtn) {
              toggleDiscardDialog(true)
            } else {
              navigateToGroupsPage()
            }
          }}
          color="secondary"
        >
          <ArrowLeft
            sx={{ textColor: 'neutral-400', flexShrink: 0 }}
            fontSize={24}
          />
        </IconButton>
        <Link
          Component="button"
          underline="hover"
          sx={{ textColor: 'neutral-600' }}
          onClick={e => {
            e.preventDefault()
            if (enableSaveToGroupBtn) {
              toggleDiscardDialog(true)
            } else {
              navigateToGroupsPage()
            }
          }}
        >
          <Typography>Groups</Typography>
        </Link>
        <ChevronRight
          sx={{ textColor: 'neutral-400', flexShrink: 0, mx: 4 }}
          fontSize={16}
        />
        {iconMapper[groupDetail.groupTypeId as keyof typeof iconMapper]}
        <TypoTooltip
          TypgraphyProps={{
            weight: 'demi',
            variant: 'bodySmall',
            gutterBottom: false,
            sx: { ml: 4 },
          }}
        >
          {groupDetail?.name}
        </TypoTooltip>
        <Divider
          orientation="vertical"
          color="secondary"
          flexItem
          sx={{ height: 50, mx: 8, alignSelf: 'center' }}
        />
        <Typography
          variant="bodySmall"
          gutterBottom={false}
          sx={{ textColor: 'neutral-500', flexShrink: 0 }}
        >
          {`${
            groupTypeMapper[
              groupDetail.groupTypeId as keyof typeof groupTypeMapper
            ]
          }`}
        </Typography>
        <Typography
          sx={{ textColor: 'neutral-1000', flexShrink: 0, ml: 4 }}
        >{`${groupDetail.count}`}</Typography>
      </Box>
      <Tooltip title={error} arrow placement="top">
        <Box sx={{ marginLeft: 'auto' }}>
          <LoadingButton
            size="small"
            color="primary"
            variant="contained"
            disabled={!enableSaveToGroupBtn || Boolean(error?.length)}
            loading={isLoading}
            loadingPosition="start"
            startIcon={
              isLoading ? (
                <CircularProgress size={10} thickness={1} sx={{ mr: 12 }} />
              ) : undefined
            }
            onClick={saveToGroup}
          >
            Save To Group
          </LoadingButton>
        </Box>
      </Tooltip>
      {discardDialogVisibility && (
        <DiscardChangesDialog
          onClose={() => toggleDiscardDialog(false)}
          onConfirmation={navigateToGroupsPage}
        />
      )}
    </>
  )
}
