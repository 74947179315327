import {
  Box,
  Button,
  Col,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Row,
  TypoTooltip,
  Typography,
} from '@applift/factor'

import { useExchangeList } from '../../../../../hooks'
import { PmpListResponse } from '../../../../../models/Pmp'
import { CustomerGridWrapper } from './CustomerGridWrapper'

import styles from './styles.module.scss'

interface PmpCustomerDialogProps {
  onClose: () => void
  data?: PmpListResponse
}

export const PmpCustomerDialog = ({
  onClose,
  data,
}: PmpCustomerDialogProps) => {
  const getTitle = (title: string) => (
    <Typography
      variant="bodySmall"
      component="label"
      sx={{
        mr: 2,
        flexShrink: 0,
        textColor: 'neutral-500',
        flexWrap: 'nowrap',
      }}
    >
      {title}
    </Typography>
  )

  const getValue = (val: string | number | JSX.Element) => (
    <TypoTooltip
      style={{ maxWidth: '250px' }}
      placement="top"
      arrow
      TypgraphyProps={{
        gutterBottom: false,
        sx: { display: 'block', mr: 4, flexShrink: 0 },
      }}
    >
      {val}
    </TypoTooltip>
  )

  const { data: exchangeData } = useExchangeList()

  const exchange = data?.exchanges?.[0]
    ? // @ts-ignore
      exchangeData?.exchangeIdMapping[data?.exchanges?.[0] as string]
    : ''

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      sx={{ height: 100 }}
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle onClose={onClose}>
        View Customers{' '}
        <Typography component="span" variant="bodyLarge">
          {`(${data?.assignedToCustomers?.length ? data?.assignedToCustomers?.length : ''})`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Container
          sx={{ height: 100, display: 'flex', flexDirection: 'column', p: 0 }}
        >
          <Row>
            <Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'between',
              }}
            >
              <Row
                sx={{
                  width: 100,
                  flexWrap: 'nowrap',
                  overflowX: 'hidden',
                }}
                xs={{ gutterSize: 4 }}
              >
                <Col className={styles.infoItem} xs={'auto'}>
                  <Box sx={{ display: 'flex' }}>
                    {getTitle('Deal ID:')}
                    {getValue(data?.dealId as string)}
                  </Box>
                </Col>
                <Col className={styles.infoItem} xs="auto">
                  <Box sx={{ display: 'flex' }}>
                    {getTitle('Name:')}
                    {data?.dealId ? getValue(data?.dealName) : ''}
                  </Box>
                </Col>
                <Col className={styles.infoItem} xs="auto">
                  <Box sx={{ display: 'flex' }}>
                    {getTitle('Exchange:')}
                    {getValue(exchange as string)}
                  </Box>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row sx={{ flexGrow: 1, mt: 24 }}>
            <Col xs={12}>
              <CustomerGridWrapper
                customersIds={
                  data?.assignedToCustomers?.length
                    ? data.assignedToCustomers
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
