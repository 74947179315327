import { GridActionCellParams } from '@applift/datagrid'
import { useQueryClient } from '@tanstack/react-query'

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Box,
  enqueueSnackbar,
  LoadingButton,
  TypoTooltip,
} from '@applift/factor'
import {
  CustomerDropdown,
  CustomerListApiRefType,
  useAppContext,
} from '@applift/platform'
import { NormalizedCustomerList } from '@applift/platform/dist/models/customerList'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CustomerTable } from './CustomerTable'

import styles from './index.module.scss'
import {
  useSharedGroupCustomerList,
  useUpdateGroupWithCustomer,
} from '../../../../hooks'

interface AssignToCustomerDialogProps {
  onClose: () => void
  groupName: string
  groupId: number
}

export const AssignToCustomerDialog = (props: AssignToCustomerDialogProps) => {
  const { onClose, groupName, groupId } = props

  const queryClient = useQueryClient()
  const appContext = useAppContext()
  const [customerSelectionData, setCustomerSelectionData] = useState<
    Record<string, NormalizedCustomerList & { campaignCount?: number }>
  >({})

  const ref = useRef<CustomerListApiRefType | undefined>(null)

  const customerTableData = useMemo(() => {
    return Object.keys(customerSelectionData)?.map(key => {
      return { id: key, ...customerSelectionData[key] }
    })
  }, [customerSelectionData])

  const actions = {
    removeRow: ({ id }: { id: number }) => {
      ref.current?.removeSelection(id.toString())
    },
  }

  const { data: initialCustomers, isLoading } = useSharedGroupCustomerList({
    groupId,
  })

  const [isSubmitting, setSubmitting] = useState(false)

  const updateCustomer = useUpdateGroupWithCustomer({
    onError: () => {
      setSubmitting(false)
      enqueueSnackbar({
        message: 'Something went wrong. Please try after some time.',
        variant: 'error',
      })
    },
    onSuccess: () => {
      enqueueSnackbar({
        message: 'Inventory group assigned to selected customers successfully',
        variant: 'success',
      })
      queryClient.refetchQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getGroupList',
      })
      setSubmitting(false)
      onClose()
    },
  })

  useEffect(() => {
    const initialSelection = (initialCustomers?.reduce((prev, curr) => {
      const activeCampaignsCount =
        ref.current
          ?.getCurrentData()
          ?.find(obj => obj.value === String(curr.owId))
          ?.activeCampaignsCount ?? 0

      return {
        ...prev,
        [curr.owId]: { ...curr, activeCampaignsCount },
      }
    }, {}) ?? {}) as Record<string, NormalizedCustomerList>

    ref.current?.reinitializeRowSelection(initialSelection)
    setCustomerSelectionData(initialSelection)
  }, [initialCustomers])

  const onAction = (params: GridActionCellParams) => {
    actions[params.actionName as keyof typeof actions](params.metaData)
  }

  const campaignCountMapping =
    initialCustomers?.reduce((prev, curr) => {
      return {
        ...prev,
        [curr.owId]: curr.campaignCount,
      }
    }, {}) ?? {}

  const onChange = (
    updatedData: Record<
      string,
      NormalizedCustomerList & { campaignCount?: number }
    >
  ) => {
    Object.keys(updatedData).forEach(key => {
      if (key in campaignCountMapping) {
        // @ts-ignore
        updatedData[key as keyof typeof updatedData] = {
          ...(updatedData[key as keyof typeof updatedData] ?? {}),
          campaignCount:
            campaignCountMapping[key as keyof typeof campaignCountMapping],
        }
      }
    })
    setCustomerSelectionData(updatedData)
  }

  const { addOWIds, removeOWIds } = useMemo(() => {
    const initialSelectedOWIds = initialCustomers?.map(one => one.owId) ?? []
    const currentSelectedOWIds =
      Object.keys(customerSelectionData).map(Number) ?? []

    const addOWIds =
      currentSelectedOWIds.filter(
        key => !initialSelectedOWIds?.includes(key)
      ) ?? []
    const removeOWIds =
      initialSelectedOWIds?.filter?.(
        id => !currentSelectedOWIds?.includes(id)
      ) ?? []
    return { addOWIds, removeOWIds }
  }, [initialCustomers, customerSelectionData])

  const handleClick = () => {
    try {
      setSubmitting(true)
      updateCustomer.mutate({
        groupId: groupId,
        addOWIds,
        removeOWIds,
      })
    } catch (e) {
      setSubmitting(false)
    }
  }

  return (
    <Dialog
      open
      maxWidth="xl"
      fullWidth
      classes={{ paper: styles.dialogPaper }}
    >
      <DialogTitle onClose={onClose}>
        <Typography weight="demi">Assign To Customer</Typography>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          width: 100,
          height: 100,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 4,
          }}
        >
          <Typography
            variant="bodySmall"
            component="span"
            noWrap={false}
            sx={{ flexShrink: 0 }}
          >
            Select the customers below to whom you want to assign the inventory
            group
          </Typography>
          <TypoTooltip title={groupName} arrow>
            <Typography weight="demi" component="span" variant="bodySmall">
              {groupName}
            </Typography>
          </TypoTooltip>
        </Box>

        <Typography
          variant="bodySmall"
          sx={{ textColor: 'neutral-500', mb: 24 }}
        >
          This will make this Inventory Group available in the Inventory App in
          the customer's account immediately.
        </Typography>
        {/* @ts-ignore */}
        <CustomerDropdown
          loggedInOwId={appContext.appMeta.loggedInOwId}
          userType={
            appContext.appMeta.organizationType as 'SUPER' | 'WORKSPACE'
          }
          data={customerSelectionData}
          apiType="workspace"
          style={{ width: 250 }}
          hideSelectNav
          multiple
          getOptionDisabled={data => {
            if (
              data.value in campaignCountMapping &&
              campaignCountMapping[
                data.value as keyof typeof campaignCountMapping
              ] > 0
            ) {
              return true
            }
            return false
          }}
          slotProps={{
            PaperProps: { style: { maxWidth: 530 } },
            InputBaseProps: {
              placeholder: 'Search by Customer ID, Status or Email',
            },
          }}
          ref={ref}
          onChange={onChange}
        />
        {(customerTableData.length > 0 || isLoading) && (
          <CustomerTable
            data={customerTableData}
            onAction={onAction}
            loading={isLoading}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          variant="contained"
          onClick={handleClick}
          disabled={!(addOWIds.length + removeOWIds.length)}
          loading={isSubmitting}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
