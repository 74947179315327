import * as React from 'react'
import {
  ColumnDef,
  DataGrid,
  GridActionCellParams,
  RowSelectionState,
  SortingState,
} from '@applift/datagrid'
import { sx } from '@applift/factor'
import { ORGANIZATION_TYPE, useAppContext } from '@applift/platform'
import { SearchData, Warning } from '@applift/illustrations'

import { useTimezone } from '../../../../hooks'
import { PmpListResponse } from '../../../../models/Pmp'
import { NoResultsOverlay } from '../../../../components/GridOverLay'

interface PmpGridProps {
  loading: boolean
  totalRecords: number
  sorting: SortingState
  data: PmpListResponse[]
  rowSelection: RowSelectionState
  colDef: ColumnDef<PmpListResponse>[]
  overlay?: 'error' | 'noRows' | 'noResult'
  organizationType: keyof typeof ORGANIZATION_TYPE
  isSelectionEnabled?: boolean
  onFetchRows?: () => void
  onAction: (params: GridActionCellParams<any, any>) => void
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
}

export const PmpGrid = (props: PmpGridProps) => {
  const {
    data,
    colDef,
    loading,
    overlay,
    sorting,
    rowSelection,
    totalRecords,
    organizationType,
    isSelectionEnabled = true,
    onAction,
    onFetchRows,
    setRowSelection,
    onSortingChange,
  } = props
  const { data: timezoneDate } = useTimezone()

  const setRowSelectionWrapper = React.useCallback(
    (_value: any) => {
      let value = _value
      if (typeof _value === 'function') {
        value = _value()
      }
      setRowSelection({ ...value })
    },

    [setRowSelection]
  )

  const ctx = useAppContext()

  const organizationTimezoneId = React.useMemo(
    //@ts-ignore
    () => ctx.appMeta.profileData.organizationTimezone,
    [ctx]
  )
  const organizationTimezone = React.useMemo(() => {
    return timezoneDate?.filter(
      timezone => timezone.id === organizationTimezoneId
    )[0]?.value
  }, [timezoneDate, organizationTimezoneId])

  return (
    <>
      <DataGrid
        data={data}
        loading={loading}
        columns={colDef}
        rowCount={totalRecords}
        overlay={overlay}
        onAction={onAction}
        components={{
          NoResultsOverlay,
          ErrorOverlay: NoResultsOverlay,
        }}
        componentsProps={{
          noResultsOverlay: {
            text: 'No results found',
            subText: 'We can’t find any items matching your search.',
            illustration: (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  width: 100,
                  height: 'auto',
                  mb: 40,
                }}
              />
            ),
          },
          // @ts-ignore
          errorOverlay: {
            // @ts-ignore
            subText:
              'Oops, something went wrong. Please try again after some time.',
            illustration: (
              <Warning
                sx={{
                  textColor: 'primary-500',
                  width: 100,
                  height: 'auto',
                  mb: 40,
                }}
              />
            ),
          },
        }}
        getRowId={(row: any) => {
          if (row && typeof row['id'] === 'number') {
            return `${row['id']}`
          }
          return row['id']
        }}
        state={{
          rowSelection,
          sorting,
        }}
        hideHeader
        hideFooter
        rowHeight={40}
        checkboxSelection={isSelectionEnabled}
        overscrollBehaviorX="contain"
        showColumnRightBorder
        showCellRightBorder
        disableRowSelectionOnClick
        classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
        onRowSelectionChange={setRowSelectionWrapper}
        onFetchRows={onFetchRows}
        onSortingChange={onSortingChange}
        meta={{ organizationTimezoneId, organizationTimezone }}
        initialState={{
          columnVisibility: {
            _customer: organizationType !== ORGANIZATION_TYPE.ADVERTISER,
            dealCurationTypeId:
              organizationType !== ORGANIZATION_TYPE.ADVERTISER,
          },
        }}
      />
    </>
  )
}
