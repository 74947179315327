import * as React from 'react'
import { Layout } from '@applift/factor'
import { RowSelectionState } from '@applift/datagrid'

import {
  ContextualSidebar,
  ContextualContent,
} from '../../../../../routes/contextual/-pages'
import { SidebarInventoryGroup } from '../../../../../models/Group'
import { SaveToExistingGroupRequest } from '../../../../../models/OpenExchange'
import { ContextualGridWrapperApiRefType } from '../../../../../routes/contextual/-component'
import { ContextualTableData } from '../../../../../models/Contextual'
import { CONTEXTUAL_INVENTORY_ID } from '../../../../../constants/contextual'

interface ContextualGridWrapperProps {
  groupDetail: SidebarInventoryGroup
  setSaveToExistingGroupPayload: React.Dispatch<
    React.SetStateAction<SaveToExistingGroupRequest | undefined>
  >
  contextualGridWrapperRef: React.MutableRefObject<
    ContextualGridWrapperApiRefType | undefined
  >
}

export const ContextualGroupEditWrapper = (
  props: ContextualGridWrapperProps
) => {
  const {
    setSaveToExistingGroupPayload,
    groupDetail,
    contextualGridWrapperRef,
  } = props

  const [addedKeywords, setAddedKeywords] = React.useState<string[]>([])
  const [addedURLs, setAddedURLs] = React.useState<string[]>([])

  const onRowSelectionChange = React.useCallback(
    (rowSelection: RowSelectionState, tableData: ContextualTableData[]) => {
      const selectedKeywords: string[] = []
      const selectedURLs: string[] = []

      Object.keys(rowSelection).map(item => {
        const selectedData = tableData[Number(item)]
        if (selectedData?.type === 'Keyword') {
          selectedKeywords.push(selectedData.name)
        } else if (selectedData?.type === 'URL') {
          selectedURLs.push(selectedData?.name)
        }
      })
      const payload: SaveToExistingGroupRequest = {
        groupIds: [groupDetail.id],
        contextualKeywords: selectedKeywords,
        contextualUrls: selectedURLs,
        groupTypeId: CONTEXTUAL_INVENTORY_ID,
      }
      setSaveToExistingGroupPayload(payload)
    },
    [groupDetail, setSaveToExistingGroupPayload]
  )

  return (
    <Layout
      sidebar={
        <ContextualSidebar
          setAddedKeywords={setAddedKeywords}
          addedKeywords={addedKeywords}
          addedURLs={addedURLs}
          setAddedURLs={setAddedURLs}
        />
      }
      sidebarWidth={320}
      maxWidth={320}
      minWidth={320}
      hideResizerHandle
    >
      <ContextualContent
        setAddedKeywords={setAddedKeywords}
        addedKeywords={addedKeywords}
        addedURLs={addedURLs}
        setAddedURLs={setAddedURLs}
        contextualGridWrapperRef={contextualGridWrapperRef}
        onRowSelectionChange={onRowSelectionChange}
        hideTableCTAs
      />
    </Layout>
  )
}
