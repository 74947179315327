import React from 'react'
import { Box, Button, Typography, sx, SvgIconProps } from '@applift/factor'
import { ChannelApp, ChannelCTV, ChannelOTT, ChannelWeb } from '@applift/icons'

import { useTrafficTypesData } from '../../../hooks/useFilterData'
import { CommonFilter } from '../../../models/Filters'

const TRAFFIC_TYPE_ICON_MAP: {
  [key: number]: React.MemoExoticComponent<
    React.ForwardRefExoticComponent<
      Omit<SvgIconProps, 'children'> & React.RefAttributes<HTMLOrSVGElement>
    >
  >
} = {
  11: ChannelApp,
  14: ChannelCTV,
  13: ChannelOTT,
  12: ChannelWeb,
}

interface TrafficTypesFilterProps {
  value: CommonFilter[]
  onChange: (selectedTrafficTypes: CommonFilter[]) => void
}

export const TrafficTypesFilter = (props: TrafficTypesFilterProps) => {
  const { value, onChange } = props

  const { data: trafficTypes } = useTrafficTypesData()

  return (
    <Box
      sx={{
        display: 'flex',
        width: 100,
        justifyContent: 'around',
        gap: 4,
        flexWrap: 'wrap',
      }}
    >
      {trafficTypes?.data?.map(trafficType => {
        const Icon = TRAFFIC_TYPE_ICON_MAP[trafficType.value]
        const isSelected = value
          ?.map(selection => selection.value)
          .includes(trafficType.value)
        return (
          <Button
            key={trafficType.value}
            sx={{ display: 'flex', flexDirection: 'column' }}
            classes={{
              selected: sx({ border: 1, borderColor: 'primary-600' }),
            }}
            iconOrientation="vertical"
            variant="text"
            selected={isSelected}
            onClick={() =>
              onChange(
                value
                  .map(prevSelections => prevSelections.value)
                  .includes(trafficType.value)
                  ? value.filter(type => type.value !== trafficType.value)
                  : [...value, trafficType]
              )
            }
          >
            {/* @ts-ignore */}
            <Icon sx={{ mb: 4 }} />
            <Typography
              sx={{
                textColor: 'neutral-1000',
                textWeight: isSelected ? 'demi' : 'normal',
              }}
              variant="label"
            >
              {trafficType.label}
            </Typography>
          </Button>
        )
      })}
    </Box>
  )
}
