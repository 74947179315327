export const GROUP_TYPE_IDS_TO_CATEGORIES = {
  1: 'Inventories', //"Open Exchange"
  2: 'PMP Deals', //"PMP"
  3: 'Contextual',
}

export const MAX_BLOCK_INVENTORY_DISPLAY_COUNT = 10000

export const GROUP_TYPE_IDS = {
  OPEN_EXCHANGE: 1,
  PMP: 2,
  CONTEXTUAL: 3,
}
