import * as React from 'react'
import { LayoutContainer, Paper, enqueueSnackbar } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import {
  createFileRoute,
  useLoaderData,
  useRouter,
} from '@tanstack/react-router'
import { InfiniteData, QueryClient } from '@tanstack/react-query'

import { ScreenLoader } from '../../../components/ScreenLoader'
import {
  GroupListTableResponse,
  SidebarInventoryGroup,
} from '../../../models/Group'
import { getGroupList } from '../../../api'
import { getGroupListKey } from '../../../api/QueryKeys'
import { EditGroupSectionHeader } from './-components/EditGroupSectionHeader/index'
import { OpenExchangeGroupEditPageWrapper } from './-components/OpenExchangeGroupEditPageWrapper/index'
import { SaveToExistingGroupRequest } from '../../../models/OpenExchange'
import { GROUP_TYPE_IDS } from '../../../constants/common'
import { OpenExchangeGridWrapperApiRefType } from '../../exchange/-component/ExchangeGrid/OpenExchangeGridWrapper'
import { useSaveToExistingGroup } from '../../../hooks'
import { PmpGroupEditPageWrapper } from './-components/PmpGroupEditPageWrapper'
import { PmpGridWrapperApiRefType } from '../../pmp/-component/PmpGrid/PmpGridWrapper'
import { ContextualGroupEditWrapper } from './-components/ContextualGroupEditWrapper/index'
import { ContextualGridWrapperApiRefType } from '../../contextual/-component'

export const Route = createFileRoute('/groups/$groupId/edit')({
  component: AddInventoriesToGroup,
  pendingComponent: () => <ScreenLoader />,
  loader: async ({ context: { queryClient }, params }) =>
    (queryClient as QueryClient).fetchInfiniteQuery(
      getGroupListKey.keys({
        scope: 'getGroupList',
        ids: params.groupId,
      }),
      getGroupList,
      { cacheTime: 0, staleTime: 0 }
    ),
})

function AddInventoriesToGroup() {
  const data = useLoaderData({
    from: Route.fullPath,
  }) as InfiniteData<GroupListTableResponse | undefined> | undefined

  const [saveToExistingGroupPayload, setSaveToExistingGroupPayload] =
    React.useState<SaveToExistingGroupRequest>()
  const [error, setError] = React.useState('')

  const pmpGridWrapperRef = React.useRef<PmpGridWrapperApiRefType>()
  const openExchangeGridWrapperRef =
    React.useRef<OpenExchangeGridWrapperApiRefType>()
  const contextualGridWrapperRef =
    React.useRef<ContextualGridWrapperApiRefType>()

  const appContext = useAppContext()
  const router = useRouter()

  const groupDetail = (data?.pages
    ?.map(page => page?.inventoryGroupList ?? [])
    .flat(1)?.[0] || {}) as SidebarInventoryGroup

  const isNonEditableGroup =
    appContext.appMeta.organizationType === 'ADVERTISER' && groupDetail.isShared

  const clearRowSelections = () => {
    if (groupDetail.groupTypeId === GROUP_TYPE_IDS.OPEN_EXCHANGE) {
      openExchangeGridWrapperRef.current?.clearRowSelection()
    }
    if (groupDetail.groupTypeId === GROUP_TYPE_IDS.PMP) {
      pmpGridWrapperRef.current?.clearRowSelection()
    }
    if (groupDetail.groupTypeId === GROUP_TYPE_IDS.CONTEXTUAL) {
      contextualGridWrapperRef.current?.clearRowSelection()
    }
  }

  const onSaveActionSuccess = (_: any, payload: SaveToExistingGroupRequest) => {
    enqueueSnackbar(
      `Selected ${payload.dealIds ? 'deals' : 'inventories'} saved to the group successfully`,
      {
        variant: 'success',
      }
    )
    clearRowSelections()
    // to refetch loader data in edit.tsx file
    router.invalidate()
  }

  const onSaveActionError = (e: any) => {
    enqueueSnackbar(
      e.errorObjects
        ? (e.errorObjects[0]?.error as string)
        : 'Something went wrong',
      {
        variant: 'error',
      }
    )
    clearRowSelections()
  }

  const {
    mutate: saveToExistingGroup,
    isLoading: isSaveToExistingGroupInProgress,
  } = useSaveToExistingGroup(onSaveActionError, onSaveActionSuccess)

  // check if any selection's present
  const {
    groupIds: _groupIds,
    groupTypeId: _groupTypeId,
    ...filteredPayload
  } = (saveToExistingGroupPayload ?? {}) as SaveToExistingGroupRequest

  const enableSaveToGroupBtn = React.useMemo(
    () =>
      Object.values(filteredPayload).some(payload =>
        typeof payload === 'boolean' ? payload : payload?.length
      ),
    [filteredPayload]
  )

  const saveToGroup = () => {
    saveToExistingGroup(
      saveToExistingGroupPayload as SaveToExistingGroupRequest
    )
  }

  // incorrect groupId in route or non editable groupId in route
  if (Object.values(groupDetail).length === 0 || isNonEditableGroup) {
    return router.navigate({
      to: '/groups',
      search: { currentlyAppliedGroupFilters: [] },
    })
  }

  return (
    <LayoutContainer
      header={
        <Paper
          elevation={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            px: 12,
            py: 4,
            width: 100,
          }}
        >
          <EditGroupSectionHeader
            groupDetail={groupDetail}
            enableSaveToGroupBtn={enableSaveToGroupBtn}
            isLoading={isSaveToExistingGroupInProgress}
            saveToGroup={saveToGroup}
            error={error}
          />
        </Paper>
      }
    >
      {groupDetail.groupTypeId === GROUP_TYPE_IDS.OPEN_EXCHANGE && (
        <OpenExchangeGroupEditPageWrapper
          setSaveToExistingGroupPayload={setSaveToExistingGroupPayload}
          groupDetail={groupDetail}
          openExchangeGridWrapperRef={openExchangeGridWrapperRef}
        />
      )}
      {groupDetail.groupTypeId === GROUP_TYPE_IDS.PMP && (
        <PmpGroupEditPageWrapper
          setSaveToExistingGroupPayload={setSaveToExistingGroupPayload}
          groupDetail={groupDetail}
          pmpGridWrapperRef={pmpGridWrapperRef}
          setError={setError}
        />
      )}
      {groupDetail.groupTypeId === GROUP_TYPE_IDS.CONTEXTUAL && (
        <ContextualGroupEditWrapper
          setSaveToExistingGroupPayload={setSaveToExistingGroupPayload}
          groupDetail={groupDetail}
          contextualGridWrapperRef={contextualGridWrapperRef}
        />
      )}
    </LayoutContainer>
  )
}
