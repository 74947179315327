import { TypographyProps } from '@applift/factor'

import { useCampaignCreativeType } from '../../hooks'
import { RawDataCell } from './RawDataCell'

interface CreativeIdsCellProps {
  creativeIds: number[]
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const CreativeIdsCell = (props: CreativeIdsCellProps) => {
  const { creativeIds, textTypographyProps } = props
  const { data: creativeTypeData } = useCampaignCreativeType()
  const creativeName = creativeIds
    ?.map(id => creativeTypeData?.creativeIdMapping[id])
    ?.filter(creative => creative?.length)
  const creatives = creativeName?.join(', ')
  return creatives?.length ? (
    <RawDataCell textTypographyProps={textTypographyProps} value={creatives} />
  ) : (
    '-'
  )
}
