import * as React from 'react'
import { Layout } from '@applift/factor'
import { createFileRoute, useNavigate, useSearch } from '@tanstack/react-router'

import { Sidebar } from './-component/sidebar'
import { EmptyPage } from './-component/EmptyPage'
import { PmpGridWrapper } from './-component/PmpGrid/PmpGridWrapper'
import { DEFAULT_PAGE_SIZE } from '../../constants/list'
import { usePmpList } from '../../hooks'
import { getPmpListKey } from '../../api/QueryKeys'
import { getPmpList } from '../../api/pmp'
import { ScreenLoader } from '../../components/ScreenLoader'
import { PmpSearch } from '../../models/Pmp'
import { PmpSidebar } from './-pages/PmpSidebar'
import { DEFAULT_PMP_LIST_SORTING } from '../../constants/pmp'
import { SortingState } from '@applift/datagrid'

export const Route = createFileRoute('/pmp/')({
  validateSearch: (search: PmpSearch): Partial<PmpSearch> => {
    return {
      ...(search.keywords ? { keywords: search.keywords } : {}),
      ...(search.creativeTypeList
        ? { creativeTypeList: search.creativeTypeList }
        : {}),
      ...(search.exchangeList ? { exchangeList: search.exchangeList } : {}),
      ...(search.dealStatus ? { dealStatus: search.dealStatus } : {}),
    }
  },
  loader: ({ context, location }) => {
    const search = location.search as PmpSearch
    const getStatusId = () => {
      if (search?.dealStatus?.length === 1) {
        return search?.dealStatus[0]
      }
      return undefined
    }
    // @ts-ignore
    return context?.queryClient?.fetchInfiniteQuery(
      getPmpListKey.keys({
        scope: 'getPmpList',
        pageSize: DEFAULT_PAGE_SIZE,
        searchField: [],
        creativeTypeList: [],
        exchangeList: [],
        statusIds: getStatusId(),
        order: 'ASC',
        sortBy: 'created',
      }),
      getPmpList
    )
  },
  errorComponent: () => {
    return (
      <Layout
        sidebarWidth={320}
        maxWidth={320}
        minWidth={320}
        sidebar={<Sidebar filters={{}} />}
        hideResizerHandle
      >
        <PmpGridWrapper filters={{}} />
      </Layout>
    )
  },
  component: Pmp,
  pendingComponent: () => {
    return <ScreenLoader />
  },
})

function Pmp() {
  const search = Route.useSearch()
  const navigate = useNavigate({ from: '/pmp' })
  const searchParams = useSearch({ from: '/pmp/' })

  const getStatusId = () => {
    if (search?.dealStatus?.length === 1) {
      return search?.dealStatus[0]
    }
    return undefined
  }

  const {
    data: pmpList,
    isFetching,
    isError,
  } = usePmpList({
    searchField: [],
    creativeTypeList: [],
    exchangeList: [],
    statusIds: getStatusId(),
    order: 'ASC',
    sortBy: 'created',
  })

  const totalRecords = React.useMemo(() => {
    return pmpList?.pages?.[0]?.totalRecords || 0
  }, [pmpList])

  const noData = React.useMemo(() => {
    return !totalRecords && !isError && !isFetching
  }, [isError, totalRecords, isFetching])

  const setFilters = (value: PmpSearch) => {
    navigate({
      to: '/pmp',
      search: {
        ...searchParams,
        ...(typeof value.keywords === 'string'
          ? { keywords: value.keywords }
          : {}),
        ...(value.creativeTypeList
          ? { creativeTypeList: value.creativeTypeList }
          : {}),
        ...(value.exchangeList ? { exchangeList: value.exchangeList } : {}),
        ...(value.dealStatus ? { dealStatus: value.dealStatus } : {}),
      },
    })
  }

  const resetFilters = () => {
    navigate({
      to: '/pmp',
      search: {
        sortBy: DEFAULT_PMP_LIST_SORTING,
      },
    })
  }

  const onSortingChangeWrapper = (
    value: React.SetStateAction<SortingState>
  ) => {
    const currentSort = search.sortBy
    // @ts-ignore
    const sortValue = value(currentSort)
    navigate({ to: Route.to, search: { ...search, sortBy: sortValue } })
  }

  return (
    <Layout
      sidebarWidth={320}
      maxWidth={320}
      minWidth={320}
      sidebar={
        !noData ? (
          <PmpSidebar
            filters={search}
            setFilters={setFilters}
            resetFilters={resetFilters}
          />
        ) : undefined
      }
      hideResizerHandle
    >
      {noData ? (
        <EmptyPage />
      ) : (
        <>
          <PmpGridWrapper
            filters={search}
            onSortingChange={onSortingChangeWrapper}
          />
        </>
      )}
    </Layout>
  )
}
