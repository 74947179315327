export const DEFAULT_NUMBER_OF_GROUP_SIDEBAR_RECORDS = 50

export const DEFAULT_GROUP_FILTERS = {
  currentlyAppliedGroupFilters: [] as string[],
  createGroup: false,
}
export const STORAGE_INVENTOY_GROUP_SHOW_DELETE_DIALOG_NAME =
  'inventoryGroupShowDeleteDialog'

export const CREATED_BY_SELF_GROUP_FILTER_ID = '2'

export const SHARED_BY_ADMIN_GROUP_FILTER_ID = '1'
