export const findParentWithId = (elem: any, idToFound: string) => {
  if (!elem) {
    return false
  }
  let parent = elem.parentElement
  while (parent) {
    const id = parent.getAttribute('id')
    if (id === idToFound) {
      return true
    }
    parent = parent.parentElement
  }
  return false
}
