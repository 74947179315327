import * as React from 'react'
import { Col, Paper, Row, useDebounceValue } from '@applift/factor'
import { useAppContext } from '@applift/platform'

import { PmpGroupGridActionPanel } from './PmpGroupGridActionPanel'
import { colDef } from '../../../../../routes/pmp/-component/PmpGrid/colDef'
import {
  GridActionCellParams,
  RowSelectionState,
  SortingState,
} from '@applift/datagrid'
import { ACTION, ActionNameType } from './action'
import { PmpListResponse } from '../../../../../models/Pmp'
import { usePmpGroupList } from '../../../../../hooks'
import { PmpCustomerDialog } from '../../../../pmp/-component/Dialog/PmpCustomerDialog'
import { PmpCampaignDialog } from '../../../../../routes/pmp/-component/Dialog'
import { SidebarInventoryGroup } from '../../../../../models/Group'
import { DEFAULT_PMP_LIST_SORTING } from '../../../../../constants/pmp'
import { PmpGroupGrid } from './PmpGroupGrid'

interface PmpGroupGridWrapperProps {
  groupDetail: SidebarInventoryGroup
  isNonEditableGroup: boolean
}

export const PmpGroupGridWrapper = (props: PmpGroupGridWrapperProps) => {
  const { groupDetail, isNonEditableGroup } = props

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [search, setSearch] = React.useState<string>('')
  const [sorting, setSorting] = React.useState<SortingState>(
    DEFAULT_PMP_LIST_SORTING
  )
  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    { data?: PmpListResponse; id: string; params: any }
  > | null>(null)

  const [pmpData, setPmpData] = React.useState<PmpListResponse>()
  const [openCustomerDialog, setOpenCustomerDialog] =
    React.useState<boolean>(false)
  const [openCampaignDialog, setOpenCampaignDialog] =
    React.useState<boolean>(false)

  const debouncedSearch = useDebounceValue(search, 500)

  const {
    data: pmpGroupData,
    isFetching,
    isError,
    fetchNextPage,
  } = usePmpGroupList({
    groupId: groupDetail.id,
    searchField: debouncedSearch,
    sorting,
  })

  React.useEffect(() => {
    switch (action?.actionName) {
      case ACTION.OPEN_CUSTOMER_DIALOG: {
        const actionRowId = action?.metaData?.data?.id
        if (actionRowId) {
          setOpenCustomerDialog(true)
          setPmpData(action.metaData.data)
        }
        break
      }
      case ACTION.OPEN_CAMPAIGN_DIALOG: {
        const actionRowId = action?.metaData?.data?.id
        if (actionRowId) {
          setOpenCampaignDialog(true)
          setPmpData(action.metaData.data)
        }
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  const onSortingChangeWrapper = (
    value: React.SetStateAction<SortingState>
  ) => {
    setRowSelection({})
    setSorting(value)
  }

  const flatData = React.useMemo(() => {
    return (
      pmpGroupData?.pages
        ?.map(page => {
          return page?.pmpDealData ?? []
        })
        .flat(1) || []
    )
  }, [pmpGroupData])

  const filteredRecords = React.useMemo(
    () => pmpGroupData?.pages?.[0]?.filteredRecords || 0,
    [pmpGroupData]
  )

  const totalRecords = React.useMemo(() => {
    return pmpGroupData?.pages?.[0]?.totalRecords || 0
  }, [pmpGroupData])

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if (!flatData?.length && !isFetching && search) {
      return 'noResult'
    }
    if (totalRecords === 0 && !isFetching) {
      return 'noRows'
    }
    return undefined
  }, [isError, flatData, isFetching, search, totalRecords])

  return (
    <>
      <Row sx={{ height: 100, py: 24, px: 16 }}>
        <Col>
          <Paper
            elevation={2}
            sx={{
              flexGrow: 1,
              height: 100,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <PmpGroupGridActionPanel
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              groupDetail={groupDetail}
              isTableLoading={isFetching}
              search={search}
              setSearch={setSearch}
              totalRecords={totalRecords}
              overlay={overlay}
            />
            <Row sx={{ flexGrow: 1 }}>
              <Col>
                <PmpGroupGrid
                  key={groupDetail.id}
                  groupId={groupDetail.id}
                  data={flatData as any}
                  colDef={colDef}
                  overlay={overlay}
                  loading={isFetching}
                  sharedGroup={groupDetail.isShared}
                  organizationType={orgType}
                  rowSelection={rowSelection}
                  sorting={sorting || []}
                  totalRecords={filteredRecords}
                  onAction={setAction}
                  isSelectionEnabled={!isNonEditableGroup}
                  onFetchRows={() => {
                    // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                    setTimeout(fetchNextPage, 100)
                  }}
                  setRowSelection={setRowSelection}
                  onSortingChange={onSortingChangeWrapper}
                />
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
      {openCustomerDialog && (
        <PmpCustomerDialog
          onClose={() => {
            setOpenCustomerDialog(false)
          }}
          data={pmpData}
        />
      )}
      {openCampaignDialog && (
        <PmpCampaignDialog
          onClose={() => {
            setOpenCampaignDialog(false)
          }}
          data={pmpData}
        />
      )}
    </>
  )
}
