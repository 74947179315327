import * as React from 'react'
import {
  DataGrid,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useGridApiRef,
} from '@applift/datagrid'
import { useRouter } from '@tanstack/react-router'
import { sx } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import { ClipboardRemove } from '@applift/icons'
import { SearchData, Warning, SearchWeb } from '@applift/illustrations'

import { NoResultsOverlay } from '../../../../components/GridOverLay/index'
import { contextualGroupColDef } from './contextualGroupColDef'
import { AddToGroupOverlay } from '../../-components/AddToGroupOverlay'
import { ContextualTableData } from '../../../../models/Contextual'
import { DEFAULT_CONTEXTUAL_LIST_SORTING } from '../../../../constants/contextual'

interface ContextualGridProps {
  data: ContextualTableData[]
  groupId: number
  setRowSelection: (value: React.SetStateAction<RowSelectionState>) => void
  rowSelection: RowSelectionState
  loading: boolean
  sharedGroup?: boolean
  isNonEditableGroup?: boolean
  overlay?: 'error' | 'noRows' | 'noResult'
}

export const ContextualGroupGrid = (props: ContextualGridProps) => {
  const {
    data,
    setRowSelection,
    rowSelection,
    loading,
    sharedGroup,
    isNonEditableGroup,
    overlay,
    groupId,
  } = props

  const [height, setHeight] = React.useState(0)
  const [sorting, setSorting] = React.useState<SortingState>(
    DEFAULT_CONTEXTUAL_LIST_SORTING
  )

  const router = useRouter()
  const apiRef = useGridApiRef()

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const isAddInventoryEnabled = React.useMemo(() => {
    if (orgType === 'ADVERTISER') {
      return !sharedGroup
    }
    return true
  }, [orgType, sharedGroup])

  const onSortingChangeWrapper = (value: any) => {
    setRowSelection({})
    setSorting(value)
  }

  const setRowSelectionWrapper = (_value: any) => {
    const value = typeof _value === 'function' ? _value() : _value
    setRowSelection(value)
  }

  React.useEffect(() => {
    apiRef.current.subscribeEvent('viewportInnerSizeChange', params => {
      setHeight(params.height)
    })
  }, [apiRef])

  return (
    <DataGrid
      apiRef={apiRef}
      data={data}
      loading={loading}
      columns={contextualGroupColDef}
      overlay={overlay}
      getRowId={row =>
        row && typeof row['id'] === 'number' ? `${row['id']}` : row['id']
      }
      state={{
        sorting,
        rowSelection,
        columnOrder: ['__check__', 'keyword', 'url'],
      }}
      hideHeader
      components={{
        NoResultsOverlay,
        ErrorOverlay: NoResultsOverlay,
        NoRowsOverlay: AddToGroupOverlay,
      }}
      componentsProps={{
        noResultsOverlay: {
          text: 'No results found',
          subText: 'We can’t find any items matching your search.',
          illustration:
            height <= 250 ? (
              <ClipboardRemove
                sx={{ textColor: 'neutral-400', mb: 8 }}
                fontSize={36}
              />
            ) : (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  width: height <= 400 ? 50 : 100,
                  height: 'auto',
                  mb: height <= 400 ? 24 : 40,
                }}
              />
            ),
        },
        // @ts-ignore
        errorOverlay: {
          // @ts-ignore
          subText:
            'Oops, something went wrong. Please try again after some time.',
          illustration: (
            <Warning
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
        // @ts-ignore
        noRowsOverlay: {
          // @ts-ignore
          actionText: isAddInventoryEnabled ? 'Add Contextual Inventory' : '',
          // @ts-ignore
          subText: isAddInventoryEnabled
            ? 'No Contextual Inventory added in the group yet. Please add Contextual Inventory in the group to assign it to the campaigns'
            : 'No Contextual Inventory added in the group yet.',
          illustration: (
            <SearchWeb
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
          onAction: () => {
            router.navigate({
              to: '/groups/$groupId/edit',
              params: {
                groupId: String(groupId),
              },
            })
          },
        },
      }}
      hideFooter
      rowHeight={40}
      checkboxSelection={!isNonEditableGroup}
      overscrollBehaviorX="contain"
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      rowCount={data.length}
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      onSortingChange={onSortingChangeWrapper}
      onRowSelectionChange={setRowSelectionWrapper}
      getSortedRowModel={getSortedRowModel()}
    />
  )
}
