import { GridActionCellParams } from '@applift/datagrid'
import {
  Box,
  TypographyProps,
  Typography,
  Badge,
  Link,
  Tooltip,
} from '@applift/factor'

import { ACTION } from '../PmpGrid/action'
import { PmpListResponse } from '../../../../models/Pmp'

interface StatusCellProps {
  active: boolean
  rowData: PmpListResponse
  position?: 'after' | 'before'
  action?: (args: GridActionCellParams) => any
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const StatusCell = ({ active, rowData, action }: StatusCellProps) => {
  const campaigns = rowData?.assignedToCampaigns ?? []
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: 100,
      }}
    >
      {active ? (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Badge color="success" variant="dot" sx={{ m: 4 }} size="small" />
          {campaigns.length === 0 && (
            <Typography variant="bodySmall" gutterBottom={false} sx={{ mr: 4 }}>
              Active
            </Typography>
          )}

          {campaigns?.length > 0 ? (
            <Tooltip
              arrow
              placement="top"
              title="Click to view the assigned campaigns"
            >
              <Link
                Component="button"
                color="primary"
                onClick={() => {
                  action?.({
                    actionName: ACTION.OPEN_CAMPAIGN_DIALOG,
                    metaData: { data: rowData },
                  })
                }}
              >
                <Typography
                  variant="bodySmall"
                  gutterBottom={false}
                  sx={{ mr: 4, textColor: 'neutral-1000' }}
                >
                  Active
                </Typography>
                {`(${campaigns.length})`}
              </Link>
            </Tooltip>
          ) : (
            <Typography variant="bodySmall" gutterBottom={false}>
              (0)
            </Typography>
          )}
        </Box>
      ) : (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Badge color="error" variant="dot" sx={{ m: 4 }} size="small" />
          <Typography variant="bodySmall" gutterBottom={false}>
            Inactive
          </Typography>
        </Box>
      )}
    </Box>
  )
}
