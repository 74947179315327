import * as React from 'react'
import {
  Col,
  TextField,
  IconButton,
  Chip,
  Box,
  Button,
  Typography,
  Link,
  enqueueSnackbar,
} from '@applift/factor'
import { RowSelectionState } from '@applift/datagrid'
import { Close, Search } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'

import { BlocklistResponse } from '../../../../models/BlocklistType'
import { formatNumberWithSuffix } from '../../../../utils/format'
import { useUnblockInventories } from '../../../../hooks'
import { MAX_BLOCK_INVENTORY_DISPLAY_COUNT } from '../../../../constants/common'
import { ConfirmationDialog } from '../../../../components/Dialogs'

interface OpenExchangeSelectionChipProps {
  onClear: () => void
  selectedCount: number
  totalCount: number
}

const SelectionChip = (props: OpenExchangeSelectionChipProps) => {
  const { selectedCount, totalCount, onClear } = props

  const chipLabel = `${formatNumberWithSuffix(selectedCount)} out of ${formatNumberWithSuffix(Math.min(MAX_BLOCK_INVENTORY_DISPLAY_COUNT, totalCount))} selected`

  return (
    <Chip
      size="small"
      label={chipLabel}
      sx={{ borderRadius: 2 }}
      color="secondary"
      onDelete={onClear}
    />
  )
}

interface OpenExchangeGlobalSelectLabelProps {
  selectedCount: number
  totalCount: number
  toggleGlobalSelectAllStatus: React.Dispatch<React.SetStateAction<boolean>>
}

const GlobalSelectLabel = (props: OpenExchangeGlobalSelectLabelProps) => {
  const { selectedCount, totalCount, toggleGlobalSelectAllStatus } = props

  return (
    <Box
      sx={{
        width: 100,
        bgColor: 'neutral-75',
        borderRadius: 4,
        mt: 8,
        py: 8,
        px: 12,
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <Typography
        gutterBottom={false}
      >{`All ${formatNumberWithSuffix(selectedCount)} Inventories on this page are selected.`}</Typography>
      <Link
        sx={{ textWeight: 'demi', ml: 8, textColor: 'primary-600' }}
        Component="button"
        onClick={() => toggleGlobalSelectAllStatus(true)}
      >
        {`Select all ${formatNumberWithSuffix(Math.min(MAX_BLOCK_INVENTORY_DISPLAY_COUNT, totalCount))} blocked inventories`}
      </Link>
    </Box>
  )
}

interface ActionPanelProps {
  isTableLoading: boolean
  rowSelection: RowSelectionState
  clearSelection: () => void
  setSearchField: React.Dispatch<React.SetStateAction<string>>
  searchField: string
  data: BlocklistResponse[]
  filteredRecords?: number
  isLocalSelectAll: boolean
  toggleGlobalSelectAllStatus: React.Dispatch<React.SetStateAction<boolean>>
  globalSelectAllEnabled: boolean
  selectedCount: number
  totalUniqueCount: number
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    searchField,
    setSearchField,
    rowSelection,
    clearSelection,
    data,
    isLocalSelectAll,
    toggleGlobalSelectAllStatus,
    selectedCount,
    globalSelectAllEnabled,
    totalUniqueCount,
  } = props

  const [isUnblockDialogVisible, toggleUnblockDialogVisibility] =
    React.useState(false)

  const queryClient = useQueryClient()

  const selectedIds = React.useMemo(() => {
    const newIds: Set<number> = new Set()
    Object.keys(rowSelection).map(_id => {
      data.map(item => {
        if (item._id === _id) {
          newIds.add(item.id)
        }
      })
    })
    return Array.from(newIds)
  }, [data, rowSelection])

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearSelection()
    toggleGlobalSelectAllStatus(false)
    setSearchField(e.target.value)
  }

  const onBlockSuccess = () => {
    queryClient.resetQueries(['getBlocklist'])
    queryClient.refetchQueries({
      predicate: (query: any) => query.queryKey?.[0]?.scope === 'getBlocklist',
    })
    enqueueSnackbar(
      'Selected inventories removed from blocklist successfully',
      {
        variant: 'success',
      }
    )
    toggleGlobalSelectAllStatus(false)
    clearSelection()
    toggleUnblockDialogVisibility(false)
  }

  const { mutate: unblock, isLoading: isUnblockInProgress } =
    useUnblockInventories(onBlockSuccess)

  const handleUnblock = () => {
    const payload = globalSelectAllEnabled
      ? { searchField }
      : { inventoryIds: selectedIds }
    unblock(payload)
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Col
        xs={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'between',
        }}
      >
        <Box>
          {selectedCount > 0 && (
            <Box sx={{ display: 'flex', gap: 8 }}>
              <SelectionChip
                totalCount={totalUniqueCount}
                onClear={() => {
                  toggleGlobalSelectAllStatus(false)
                  clearSelection()
                }}
                selectedCount={selectedCount}
              />
              <Button
                size="small"
                variant="outlined"
                onClick={() => toggleUnblockDialogVisibility(true)}
                sx={{ marginLeft: 8 }}
              >
                Unblock
              </Button>
            </Box>
          )}
        </Box>
        <TextField
          type="text"
          placeholder="Search by ID, Name or Publisher"
          variant="outlinedDash"
          value={searchField}
          onChange={handleSearch}
          sx={{ ml: 8 }}
          InputProps={{
            startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
            endAdornment:
              searchField?.length > 0 ? (
                <IconButton
                  onClick={() => {
                    setSearchField('')
                    toggleGlobalSelectAllStatus(false)
                    clearSelection()
                  }}
                  size="small"
                >
                  <Close fontSize={16} />
                </IconButton>
              ) : null,
          }}
          style={{ width: 265 }}
        />
      </Col>

      {isLocalSelectAll && (
        <GlobalSelectLabel
          toggleGlobalSelectAllStatus={toggleGlobalSelectAllStatus}
          totalCount={totalUniqueCount}
          selectedCount={selectedCount}
        />
      )}
      {isUnblockDialogVisible && (
        <ConfirmationDialog
          onClose={() => toggleUnblockDialogVisibility(false)}
          onConfirmation={handleUnblock}
          isActionLoading={isUnblockInProgress}
          primaryBtnText="Unblock"
          theme="primary"
          dialogMaxWidth="sm"
          title="Unblock selected inventories"
          dialogContent="Are you sure you want to unblock the selected inventories? The platform will immediately start serving ads to them."
        />
      )}
    </Box>
  )
}
