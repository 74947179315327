import React from 'react'
import { Box, Switch, sx } from '@applift/factor'
import { GridActionCellParams } from '@applift/datagrid'

import { CampaignInfoType } from '../../models/Group'

interface AllowedBlockedListCellProps {
  isWhiteListed: boolean
  row?: CampaignInfoType
  onAction?: (params: GridActionCellParams<string, any>) => void
}

export const AllowedBlockedListCell = ({
  isWhiteListed = true,
  onAction,
  row,
}: AllowedBlockedListCellProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onAction?.({
      actionName: 'updateRow',
      metaData: {
        rowId: row?.id,
        payload: {
          included: e.target.checked ? 1 : 0,
          excluded: e.target.checked ? 0 : 1,
        },
      },
    })
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: 100 }}>
      <Switch
        checked={isWhiteListed}
        size="small"
        color="success"
        onChange={handleChange}
        classes={{
          thumb: sx({ bgColor: isWhiteListed ? 'success-500' : 'danger-500' }),
          track: sx({ bgColor: isWhiteListed ? 'success-200' : 'danger-200' }),
        }}
      />
    </Box>
  )
}
