import React from 'react'
import { RowSelectionState } from '@applift/datagrid'
import {
  Box,
  Col,
  Container,
  enqueueSnackbar,
  FormControlLabel,
  Paper,
  Row,
  Skeleton,
  Switch,
  Tooltip,
  TypoTooltip,
} from '@applift/factor'
import { InfoCircle } from '@applift/icons'

import { DisplayDistributionDataWrapper } from '../../../components/DisplayDistributionData/DisplayDistributionWrapper'
import { formatNumberWithSuffix } from '../../../utils/format'
import {
  OpenExchangeGridWrapper,
  OpenExchangeGridWrapperApiRefType,
} from '../-component/ExchangeGrid/OpenExchangeGridWrapper'
import {
  useExchangeCountData,
  useExchangeCountDataFromCSV,
  useExchangeDistributionData,
  useExchangeDistributionDataFromCSV,
} from '../../../../src/hooks'
import { OpenExchangeSidebarSearchValues } from '..'
import { getPayloadFromFilterSelection } from '../-component/ExchangeGrid/helpers'
import {
  DistributionResponse,
  Exchange,
  ExchangeMatrixCountResponse,
  OpenExchangeSidebarUploadedCSVFileInfo,
} from '../../../models/OpenExchange'

export const INFO_TEXT = {
  INVENTORIES: 'Number of inventories matches the searched criteria',
  PUBLISHERS: 'Number of publishers from the filtered inventories',
  IMPRESSIONS: 'Number of times the ads have been reached to the inventory',
  REACH: 'Number of unique views captured from the filtered inventory',
}

interface InfoItemProps {
  label: string
  tooltip: string
  isLoading: boolean
  value: number
}

const InfoItem = (props: InfoItemProps) => {
  const { label, tooltip, isLoading, value } = props

  return (
    <Col xs={3} sx={{ flexDirection: 'column', flexShrink: 0 }}>
      <Box sx={{ width: 100, display: 'flex', alignItems: 'center' }}>
        <TypoTooltip
          TypgraphyProps={{
            gutterBottom: false,
            sx: { display: 'block', mr: 4, textColor: 'neutral-600' },
          }}
        >
          {label}
        </TypoTooltip>

        <Tooltip title={tooltip} arrow placement="top">
          <InfoCircle sx={{ fontSize: 16, textColor: 'neutral-400' }} />
        </Tooltip>
      </Box>

      {isLoading ? (
        <Skeleton height={30} width={50} />
      ) : (
        <TypoTooltip
          TypgraphyProps={{
            variant: 'h6',
            gutterBottom: false,
            sx: { display: 'block', textWeight: 'demi' },
          }}
        >
          {formatNumberWithSuffix(value)}
        </TypoTooltip>
      )}
    </Col>
  )
}

interface OpenExchangeProps {
  filters: OpenExchangeSidebarSearchValues
  hideTableCTAs?: boolean
  openExchangeGridWrapperRef?: React.MutableRefObject<
    OpenExchangeGridWrapperApiRefType | undefined
  >
  onRowSelectionChange?: (
    rowSelection: RowSelectionState,
    tableData: Exchange[]
  ) => void
  uploadedFileForSearch: OpenExchangeSidebarUploadedCSVFileInfo | null
}

export const OpenExchangePage = (props: OpenExchangeProps) => {
  const {
    filters,
    hideTableCTAs,
    openExchangeGridWrapperRef,
    onRowSelectionChange,
    uploadedFileForSearch,
  } = props

  const [displayDistributionData, toggleDisplayDistributionData] =
    React.useState(false)

  const { data: exchangeCountData, isFetching: isCountFetchingFromFilters } =
    useExchangeCountData(getPayloadFromFilterSelection(filters), {
      enabled: uploadedFileForSearch === null,
    })

  const { data: countDataFromCSV, isFetching: isCountFetchingFromCSVFile } =
    useExchangeCountDataFromCSV(
      uploadedFileForSearch as OpenExchangeSidebarUploadedCSVFileInfo,
      {
        enabled: Boolean(uploadedFileForSearch),
      }
    )

  const {
    data: distributionDataFromSidebarSearch,
    isFetching: fetchingDistributionDataFromSidebarSearch,
  } = useExchangeDistributionData(filters as OpenExchangeSidebarSearchValues, {
    enabled: Boolean(!uploadedFileForSearch && displayDistributionData),
  })

  const {
    data: distributionDataFromCSV,
    isFetching: fetchingDistributionDataFromCSVFile,
  } = useExchangeDistributionDataFromCSV(
    uploadedFileForSearch as OpenExchangeSidebarUploadedCSVFileInfo,
    {
      enabled: Boolean(uploadedFileForSearch) && displayDistributionData,
    }
  )

  const distributionData = (
    uploadedFileForSearch
      ? distributionDataFromCSV ?? {}
      : distributionDataFromSidebarSearch ?? {}
  ) as DistributionResponse

  const isCountBeingFetched =
    isCountFetchingFromCSVFile || isCountFetchingFromFilters

  const finalCountToDisplay = (
    uploadedFileForSearch ? countDataFromCSV ?? {} : exchangeCountData ?? {}
  ) as ExchangeMatrixCountResponse

  React.useEffect(() => {
    toggleDisplayDistributionData(false)
  }, [filters, uploadedFileForSearch])

  React.useEffect(() => {
    if (countDataFromCSV?.inventories) {
      enqueueSnackbar(
        `${countDataFromCSV.inventories} inventories found from the CSV file`,
        {
          variant: 'success',
        }
      )
    }
  }, [countDataFromCSV])

  return (
    <Container
      sx={{
        height: 100,
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: 16,
        py: 24,
        px: 16,
      }}
    >
      <Box
        sx={{
          gap: 24,
          alignItems: 'center',
          justifyContent: 'between',
          display: 'flex',
        }}
      >
        <Box
          sx={{
            borderRadius: 4,
            boxShadow: 200,
            flexGrow: 1,
            flexShrink: 1,
            p: 16,
            width: 100,
          }}
        >
          <Row>
            <InfoItem
              label="Inventories"
              tooltip={INFO_TEXT.INVENTORIES}
              value={finalCountToDisplay.inventories}
              isLoading={isCountBeingFetched}
            />
            <InfoItem
              label="Publishers"
              tooltip={INFO_TEXT.PUBLISHERS}
              value={finalCountToDisplay.publishers}
              isLoading={isCountBeingFetched}
            />
            <InfoItem
              label="Impressions"
              tooltip={INFO_TEXT.IMPRESSIONS}
              value={finalCountToDisplay.impressions}
              isLoading={isCountBeingFetched}
            />
            <InfoItem
              label="Reach"
              tooltip={INFO_TEXT.REACH}
              value={finalCountToDisplay.reach}
              isLoading={isCountBeingFetched}
            />
          </Row>
        </Box>
        <FormControlLabel
          checked={displayDistributionData}
          disabled={
            isCountBeingFetched ||
            !Object.values(finalCountToDisplay).some(val => val)
          }
          onChange={event =>
            // @ts-ignore
            toggleDisplayDistributionData(event.target.checked)
          }
          control={<Switch size="small" color="primary" />}
          label="Inventory Overview"
          sx={{ flexShrink: 0, ml: 16 }}
        />
      </Box>

      <Row sx={{ p: 0 }}>
        <Col xs={12}>
          {displayDistributionData && (
            <DisplayDistributionDataWrapper
              data={distributionData}
              isFetching={
                fetchingDistributionDataFromSidebarSearch ||
                fetchingDistributionDataFromCSVFile
              }
            />
          )}
        </Col>
      </Row>

      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          width: 100,
          pt: 12,
          borderRadius: 4,
          alignItems: 'center',
          flexGrow: 1,
        }}
      >
        <OpenExchangeGridWrapper
          ref={openExchangeGridWrapperRef}
          totalRecords={
            typeof finalCountToDisplay?.inventories === 'number'
              ? finalCountToDisplay.inventories
              : Infinity
          }
          filters={filters}
          hideTableCTAs={hideTableCTAs}
          onRowSelectionChange={onRowSelectionChange}
          uploadedFileForSearch={uploadedFileForSearch}
        />
      </Paper>
    </Container>
  )
}
