import {
  UseInfiniteQueryResult,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import {
  getBasicCustomersListKey,
  getCustomerListKey,
  getAssociatedCustomerKey,
  getCustomerCountKey,
} from '../api/QueryKeys'
import {
  getBasicCustomersList,
  getCustomerList,
  getSharedGroupCustomerList,
  getSharedGroupCustomerListKey,
  shareGroupWithCustomer,
  getAssociatedCustomer,
  getCustomerCount,
} from '../api/customer'
import { ListResponse } from '../models/Response'
import { BasicCustomerList } from '../models/Customer'

export interface useBasicCustomerListProps {
  searchField: string
  owIds?: number[]
  owStatusIds?: number[]
  provideRunningCampaigns?: boolean
  options?: {
    enabled?: boolean
  }
}

export const useBasicCustomerList = (
  props: useBasicCustomerListProps
): UseInfiniteQueryResult<ListResponse<BasicCustomerList[]>> => {
  const { searchField, owIds, owStatusIds, provideRunningCampaigns, options } =
    props
  const queryData = useInfiniteQuery(
    getBasicCustomersListKey.keys({
      scope: 'getBasicCustomersList',
      searchField,
      owIds,
      owStatusIds,
      provideRunningCampaigns,
      pageNo: -1,
    }),
    getBasicCustomersList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.data?.length) || 0,
          0
        )
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return null
      },

      enabled: options?.enabled ?? true,
    }
  )

  if (queryData.isError) {
    const error = queryData.error as any
    enqueueSnackbar(
      error?.data?.errorObjects.length
        ? (error?.data?.errorObjects[0].error as string)
        : 'Something went wrong!',
      {
        variant: 'error',
      }
    )
  }
  return queryData
}

interface CustomerHookProps {
  status?: number[]
  enabled?: boolean
}

export const useCustomerList = (props: CustomerHookProps) => {
  const { status: customerStatus, enabled = true } = props
  const status = customerStatus?.length ? customerStatus?.join(',') : ''

  const data = useQuery(
    getCustomerListKey.keys({ scope: 'getCustomerList', status }),
    getCustomerList,
    {
      keepPreviousData: true,
      cacheTime: 5000,
      enabled: enabled,
    }
  )
  if (data.isError) {
    // @ts-ignore
    const error = e?.errorObjects[0]?.error || ('' as string)
    enqueueSnackbar(error.length ? error : 'Something went wrong!', {
      variant: 'error',
    })
  }
  return data
}

export const useSharedGroupCustomerList = ({
  groupId,
}: {
  groupId: number
}) => {
  const queryData = useQuery(
    getSharedGroupCustomerListKey.keys('getSharedGroupCustomerList', groupId),
    getSharedGroupCustomerList,
    {
      cacheTime: 0,
      staleTime: 0,
    }
  )
  return queryData
}
export const useCustomerCount = (
  options?: {
    enabled?: UseQueryOptions<any>['enabled']
  },
  parameters?: { owIds: number[] }
) => {
  const queryData = useQuery(
    getCustomerCountKey.keys({
      scope: 'getCustomerCount',
      ...(parameters?.owIds ? { owIds: parameters.owIds } : {}),
    }),
    getCustomerCount,
    {
      enabled: options?.enabled,
    }
  )
  return queryData
}

interface UseUpdateGroupWithCustomer {
  onError?: () => void
  onSuccess?: () => void
}
export const useUpdateGroupWithCustomer = ({
  onError,
  onSuccess,
}: UseUpdateGroupWithCustomer) => {
  const mutationData = useMutation(shareGroupWithCustomer, {
    onSuccess,
    onError,
  })
  return mutationData
}

export const useAssociatedCustomer = (
  dealId: string,
  options?: {
    enabled?: UseQueryOptions<any>['enabled']
  }
) => {
  const queryData = useQuery(
    getAssociatedCustomerKey.keys({
      scope: 'getAssociatedCustomer',
      dealId,
    }),
    getAssociatedCustomer,
    {
      cacheTime: 0,
      enabled: options?.enabled,
    }
  )
  return queryData
}
