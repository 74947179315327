import * as React from 'react'
import {
  Col,
  Box,
  Chip,
  Button,
  Tooltip,
  enqueueSnackbar,
} from '@applift/factor'
import {
  ColumnDef,
  RowSelectionState,
  GridActionCellParams,
} from '@applift/datagrid'

import { ACTION } from './action'
import { queryClient } from '../../../../cache'
import { PgListResponse } from '../../../../models/Pg'
import { usePgDelete } from '../../../../hooks/usePgList'
import { DEAL_STATUS } from '../../../../constants/filters'
import { ConfirmationDialog } from '../../../../components/Dialogs'

interface ActionPanelProps {
  totalRecords: number
  isTableLoading: boolean
  rowSelection: RowSelectionState
  selectedItems?: PgListResponse[]
  colDef: ColumnDef<PgListResponse>[]
  setAction: (params: GridActionCellParams<any, any>) => void
  clearSelection: () => void
}

export const ActionPanel = (props: ActionPanelProps) => {
  const {
    setAction,
    totalRecords,
    rowSelection,
    selectedItems,
    isTableLoading,
    clearSelection,
  } = props

  const [showDeleteDialog, toggleDeleteDialog] = React.useState(false)

  const selectedItemsCount = React.useMemo(
    () => Object.keys(rowSelection).length,
    [rowSelection]
  )

  const hasCampaignAttached = selectedItems?.some(
    deal => deal?.activeCampaignIds?.length || deal?.otherCampaignIds?.length
  )

  const chipLabel = React.useMemo(() => {
    return `${selectedItemsCount} out of ${totalRecords} selected`
  }, [totalRecords, selectedItemsCount])

  const isEditDisabled = selectedItemsCount > 1

  const disabledDeleteDealSelected = selectedItems?.some(
    deal => !deal?.deleteAllowed
  )

  const inactiveDealSeleted = React.useMemo(
    () => selectedItems?.some(deal => deal.statusId === DEAL_STATUS.INACTIVE),
    [selectedItems]
  )

  const editBtnTooltip =
    selectedItemsCount > 1 ? 'Multiple deals can’t be edited' : undefined

  const deleteSuccess = (res: any) => {
    queryClient.resetQueries({
      predicate: (query: any) => query.queryKey?.[0]?.scope === 'getPgList',
    })
    enqueueSnackbar({
      message: res.data.length ? res.data : 'PMP Deal deleted successfully',
      variant: 'success',
    })
    toggleDeleteDialog(false)
    clearSelection()
  }
  const deleteError = (prop: any) => {
    enqueueSnackbar({
      message: prop?.errorObjects?.length
        ? prop?.errorObjects?.[0]?.error
        : 'Something went wrong!',
      variant: 'error',
    })
    clearSelection()
    toggleDeleteDialog(false)
  }

  const deletePgMutation = usePgDelete({
    onSuccess: deleteSuccess,
    onError: deleteError,
  })

  const onDelete = () => {
    const ids = Object.keys(rowSelection).map(id => parseInt(id))
    deletePgMutation.mutate({ ids })
  }

  const deleteActionTooltip = React.useMemo(() => {
    if (disabledDeleteDealSelected && hasCampaignAttached) {
      return 'PG Deals assigned to Pending, Running, Paused or Expired Campaigns can’t be deleted'
    }
    return ''
  }, [disabledDeleteDealSelected, hasCampaignAttached])

  const isDeleteDisabled = React.useMemo(() => {
    return disabledDeleteDealSelected
  }, [disabledDeleteDealSelected])

  let deleteDialogContent =
    'Are you sure that you want to delete the selected deal?'
  if (hasCampaignAttached) {
    deleteDialogContent =
      'Once the deal is deleted, it will be removed from the assigned campaigns. Are you sure you want to delete it?'
  }
  if (selectedItemsCount > 1) {
    deleteDialogContent = `Are you sure that you want to delete the ${selectedItemsCount} deals?`
  }

  // TODO: Check for the different type of the payment type
  // in multi select condition only same payment type of deal shold be assign to campaign
  const assignToCampaignBtnTooltip = () => {
    if (selectedItemsCount > 25) {
      return 'Maximum 25 deals can be assigned to a campaign'
    }
    if (inactiveDealSeleted) {
      return 'Only active PG deals can be assigned to a campaign'
    }
    return ''
  }

  const isAssignToCampaignDisabled =
    inactiveDealSeleted || selectedItemsCount > 25

  return (
    <>
      {selectedItemsCount > 0 && !isTableLoading && (
        <Col xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Chip
            size="small"
            label={chipLabel}
            color="secondary"
            onDelete={clearSelection}
          />
          <Tooltip title={editBtnTooltip} arrow placement="top">
            <Box>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                sx={{ marginLeft: 8 }}
                disabled={isEditDisabled}
                onClick={() => {
                  setAction?.({
                    actionName: ACTION.EDIT_PG_DEAL,
                    metaData: { data: selectedItems?.[0] },
                  })
                }}
              >
                Edit
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title={assignToCampaignBtnTooltip()} arrow placement="top">
            <Box>
              <Button
                size="small"
                color="primary"
                variant="outlined"
                sx={{ marginLeft: 8 }}
                disabled={isAssignToCampaignDisabled}
                onClick={() => {
                  setAction?.({
                    actionName: ACTION.ASSIGN_TO_CAMPAIGN,
                    metaData: { params: selectedItems },
                  })
                }}
              >
                Assign To Campaign
              </Button>
            </Box>
          </Tooltip>
          <Tooltip title={deleteActionTooltip} arrow placement="top">
            <Box>
              <Button
                size="small"
                color="error"
                variant="outlined"
                sx={{ marginLeft: 8 }}
                disabled={isDeleteDisabled}
                onClick={() => {
                  toggleDeleteDialog(true)
                }}
              >
                Delete
              </Button>
            </Box>
          </Tooltip>
        </Col>
      )}
      {showDeleteDialog && (
        <ConfirmationDialog
          onClose={() => toggleDeleteDialog(false)}
          onConfirmation={onDelete}
          primaryBtnText="Delete"
          theme="error"
          title="Delete Deal?"
          dialogContent={deleteDialogContent}
          isActionLoading={deletePgMutation.isLoading}
        />
      )}
    </>
  )
}
