import { sx } from '@applift/factor'
import {
  ColumnDef,
  DataGrid,
  getSortedRowModel,
  useGridApiRef,
} from '@applift/datagrid'
import { SearchData, Warning } from '@applift/illustrations'

import { NoResultsOverlay } from '../../../../../components/GridOverLay'
import { BasicCustomerList } from '../../../../../models/Customer'

export interface CustomerGridProp {
  data: BasicCustomerList[]
  columnDef: ColumnDef<BasicCustomerList>[]
  totalRecords?: number
  filteredRecords?: number
  rowIdKey: string
  pageSize: number
  loading: boolean
  onFetchRows?: () => void
  overlay?: 'error' | 'noRows' | 'noResult' | undefined
}

export const CustomerGrid = (props: CustomerGridProp) => {
  const {
    rowIdKey,
    columnDef,
    data,
    loading,
    pageSize,
    onFetchRows,
    filteredRecords,
    overlay,
  } = props

  const apiRef = useGridApiRef()

  return (
    <DataGrid
      apiRef={apiRef}
      data={data}
      columns={columnDef}
      loading={loading}
      pageSize={pageSize}
      getRowId={(row: any) => {
        if (row && typeof row[rowIdKey] === 'number') {
          return `${row[rowIdKey]}`
        }
        return row[rowIdKey]
      }}
      hideFooter
      hideHeader
      rowHeight={46}
      showColumnRightBorder
      showCellRightBorder
      overlay={overlay}
      overscrollBehaviorX="contain"
      components={{
        NoResultsOverlay,
        ErrorOverlay: NoResultsOverlay,
      }}
      componentsProps={{
        noResultsOverlay: {
          text: 'No results found',
          subText: 'We can’t find any items matching your search.',
          illustration: (
            <SearchData
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
        // @ts-ignore
        errorOverlay: {
          // @ts-ignore
          subText:
            'Oops, something went wrong. Please try again after some time.',
          illustration: (
            <Warning
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
      }}
      disableRowSelectionOnClick
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      rowCount={filteredRecords}
      onFetchRows={onFetchRows}
      getSortedRowModel={getSortedRowModel()}
    />
  )
}
