import React from 'react'
import { createFileRoute } from '@tanstack/react-router'
import { Alert, Box, Paper, useDebounceValue } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import { RowSelectionState } from '@applift/datagrid'

import { EmptyPage } from './-component/EmptyPage'
import { useBlocklist } from '../../hooks/useBlocklist'
import { BlocklistGridWrapper } from './-component/BlocklistGrid/BlocklistGridWrapper'
import { DEFAULT_PAGE_SIZE } from '../../constants/list'
import { getBlocklistKey } from '../../api/QueryKeys'
import { getBlockList } from '../../api/blocklist'
import { ScreenLoader } from '../../components/ScreenLoader'
import { ErrorPage } from './-component/ErrorPage/ErrorPage'

export const Route = createFileRoute('/global-blocklist/')({
  component: GlobalBlocklist,
  loader: ({ context }) => {
    // @ts-ignore
    context?.queryClient?.invalidateQueries({
      queryKey: [{ scope: 'getBlocklist' }],
    })
    // @ts-ignore
    return context?.queryClient?.fetchInfiniteQuery(
      getBlocklistKey.keys({
        scope: 'getBlocklist',
        noOfEntries: DEFAULT_PAGE_SIZE,
        searchField: '',
      }),
      getBlockList
    )
  },
  pendingComponent: () => {
    return <ScreenLoader />
  },
  errorComponent: () => {
    return <ErrorPage />
  },
})

function GlobalBlocklist() {
  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const [searchField, setSearchField] = React.useState('')

  let AlertMessage
  if (orgType === 'ADVERTISER') {
    AlertMessage =
      'No ads from any campaign will be served on Blocked Inventories. You are allowed to manage the Block Inventories list.'
  } else if (orgType === 'WORKSPACE' || orgType === 'SUPER') {
    AlertMessage =
      "These blocked inventories will be added to all your customers' Global Blocklists. However, the customers are allowed to manage their own Global Blocklist."
  }
  const debounceSearch = useDebounceValue(searchField, 500)
  const {
    data: blocklist,
    fetchNextPage,
    isFetching,
    isError,
  } = useBlocklist(debounceSearch, DEFAULT_PAGE_SIZE)

  const flatData = React.useMemo(() => {
    return (
      blocklist?.pages
        ?.map(page => {
          return page?.globalBlockedInventoryData ?? []
        })
        .flat(1) || []
    )
  }, [blocklist])

  const filteredRecords = blocklist?.pages[0]?.filteredRecords
  const totalUniqueCount = blocklist?.pages[0]?.uniqueRecords

  const noData = !flatData?.length && !isError && !isFetching && !searchField

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})

  // below effect is used to select duplicates of current selection in new incoming data as user scrolls down
  React.useEffect(() => {
    // Create a set to store selected row IDs
    const selectedRowIdsSet: Set<number> = new Set()

    // Populate the set with IDs from rowSelection
    Object.keys(rowSelection).forEach(selectedId => {
      const rowData = flatData.find(data => data._id === selectedId)
      if (rowData?.id) {
        selectedRowIdsSet.add(rowData.id)
      }
    })

    // Convert the set to an array
    const selectedRowIdsArray = Array.from(selectedRowIdsSet)

    // Create a set to store the corresponding _id values
    const correspondingIdsSet: Set<string> = new Set()

    // Populate the set with _id values corresponding to the selected row IDs
    selectedRowIdsArray.forEach(id => {
      flatData.forEach(item => {
        if (item.id === id) {
          correspondingIdsSet.add(item._id)
        }
      })
    })

    // Update the row selection state
    setRowSelection(prevRowSelection => {
      const correspondingIdsArray = Array.from(correspondingIdsSet)
      const updatedSelection: { [key: string]: boolean } = {}

      correspondingIdsArray.forEach(itemId => {
        updatedSelection[itemId] = true
      })

      return {
        ...prevRowSelection,
        ...updatedSelection,
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatData])

  return (
    <Box
      sx={{
        height: 100,
        width: 100,
        px: 16,
        py: 24,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Alert variant="standard" severity="info" sx={{ mb: 24 }}>
        {AlertMessage}
      </Alert>
      <Paper elevation={2} sx={{ flexGrow: 1 }}>
        {noData ? (
          <EmptyPage />
        ) : (
          <BlocklistGridWrapper
            searchField={searchField}
            setSearchField={setSearchField}
            isLoading={isFetching}
            rowSelection={rowSelection}
            filteredRecords={filteredRecords}
            setRowSelection={setRowSelection}
            totalUniqueCount={totalUniqueCount || 0}
            data={flatData}
            isError={isError}
            onFetchRows={() => {
              // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
              setTimeout(fetchNextPage, 100)
            }}
          />
        )}
      </Paper>
    </Box>
  )
}
