import {
  Col,
  Row,
  Dialog,
  Button,
  Container,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@applift/factor'

import { useExchangeList } from '../../../../../hooks'
import { PmpListResponse } from '../../../../../models/Pmp'
import { CampaignGridWrapper } from './CampaignGridWrapper'
import { BasicInfoList } from '../../../../../components/BasicInfoList'

interface PmpCampaignDialogProps {
  onClose: () => void
  data?: PmpListResponse
}

export const PmpCampaignDialog = ({
  onClose,
  data,
}: PmpCampaignDialogProps) => {
  const { data: exchangeData } = useExchangeList()

  const exchange = data?.exchanges?.[0]
    ? exchangeData?.exchangeIdMapping[data?.exchanges?.[0]]
    : ''

  const infoList = [
    { key: 'Deal ID', value: data?.dealId as string },
    { key: 'Name', value: data?.dealId ? data?.dealName : '' },
    { key: 'Exchange', value: exchange as string },
  ]

  return (
    <Dialog
      open
      maxWidth="lg"
      fullWidth
      sx={{ height: 100 }}
      PaperProps={{ sx: { height: 100 } }}
    >
      <DialogTitle onClose={onClose}>
        Assigned Campaigns{' '}
        <Typography component="span" variant="bodyLarge">
          {`(${data?.assignedToCampaigns?.length ? data?.assignedToCampaigns?.length : ''})`}
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Container
          sx={{ height: 100, display: 'flex', flexDirection: 'column', p: 0 }}
        >
          <Row>
            <Col
              xs={12}
              sx={{
                display: 'flex',
                justifyContent: 'between',
              }}
            >
              <BasicInfoList data={infoList} />
            </Col>
          </Row>
          <Row sx={{ flexGrow: 1, mt: 24 }}>
            <Col xs={12}>
              <CampaignGridWrapper
                campaignIds={
                  data?.assignedToCampaigns?.length
                    ? data.assignedToCampaigns
                    : []
                }
              />
            </Col>
          </Row>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          onClick={onClose}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
