import { PmpSearch } from '../../../models/Pmp'
import { DEFAULT_PAGE_SIZE } from '../../../constants/list'

export const getPmpListPayloadFromFilterSelection = (search: PmpSearch) => {
  return {
    searchField: search.keywords ? search.keywords.split(',') : [],
    creativeTypeList: search.creativeTypeList
      ? search.creativeTypeList.map(creative => creative.value)
      : [],
    exchangeList: search.exchangeList
      ? search.exchangeList.map(exchange => exchange.value)
      : [],
    statusIds:
      search?.dealStatus?.length === 1 ? search?.dealStatus[0] : undefined,
    pageSize: DEFAULT_PAGE_SIZE,
    order: search.sortBy?.[0]?.desc ? 'DESC' : 'ASC',
    sortBy: search.sortBy?.[0]?.id ?? 'created',
  }
}
