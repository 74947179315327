import { Alert, Box, Paper } from '@applift/factor'
import { useAppContext } from '@applift/platform'

import { BlocklistGridWrapper } from '../BlocklistGrid/BlocklistGridWrapper'

export const ErrorPage = () => {
  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  let AlertMessage
  if (orgType === 'ADVERTISER') {
    AlertMessage =
      'No ads from any campaign will be served on Blocked Inventories. You are allowed to manage the Block Inventories list.'
  } else if (orgType === 'WORKSPACE' || orgType === 'SUPER') {
    AlertMessage =
      "These blocked inventories will be added to all your customers' Global Blocklists. However, the customers are allowed to manage their own Global Blocklist."
  }
  return (
    <Box
      sx={{
        height: 100,
        width: 100,
        px: 16,
        py: 24,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Alert variant="standard" severity="info" sx={{ mb: 24 }}>
        {AlertMessage}
      </Alert>
      <Paper elevation={2} sx={{ flexGrow: 1 }}>
        <BlocklistGridWrapper
          searchField={''}
          isLoading={false}
          rowSelection={{}}
          filteredRecords={0}
          totalUniqueCount={0}
          data={[]}
          isError={true}
          setRowSelection={() => {}}
          setSearchField={() => {}}
        />
      </Paper>
    </Box>
  )
}
