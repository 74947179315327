import * as React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  Typography,
  enqueueSnackbar,
} from '@applift/factor'
import { useMutation } from '@tanstack/react-query'

import { createPgDeal } from '../../../../api'
import {
  PgFormState,
  PgCreationForm,
  PgCreationApiRef,
  DEFAULT_PG_FORM_STATE,
} from './PgCreationForm'
import { queryClient } from '../../../../cache'
import { usePaymentTypes } from '../../../../hooks'
import { PAYMENT_TYPE } from '../../../../constants/filters'
import { PaymentTypeObject } from '../../../../models/Filters'

interface PgDialogProps {
  onClose: () => void
}

export const PgDialog = (props: PgDialogProps) => {
  const { onClose } = props

  const [formState, setFormState] = React.useState<PgFormState>(
    DEFAULT_PG_FORM_STATE
  )
  const pgFormRef = React.useRef<PgCreationApiRef>(null)

  const createPgMutation = useMutation(createPgDeal, {
    mutationKey: ['createPgDeal'],
    onSuccess: () => {
      onClose()
      queryClient.resetQueries({
        predicate: (query: any) => query.queryKey?.[0]?.scope === 'getPgList',
      })
      enqueueSnackbar('PG Deal added successfully', {
        variant: 'success',
      })
    },
    onError: e => {
      // @ts-ignore
      const errorMessage = e?.errorObjects?.[0]?.error as string

      if (errorMessage?.includes('already exists')) {
        pgFormRef.current?.setError({
          dealId:
            'Deal ID already exists for selected exchange. Use a different Deal ID or Exchange',
        })
      } else {
        enqueueSnackbar(
          errorMessage?.length
            ? errorMessage
            : 'Something went wrong. Please try after some time.',
          {
            variant: 'error',
          }
        )
      }
    },
  })

  const onSubmit = async (value: PgFormState) => {
    createPgMutation.mutate({
      dealId: value.dealId.trim(),
      dealName: value.dealName.trim(),
      description: value.description.trim(),
      paymentTypeId: value.paymentTo.map(creative => creative.id)[0],
      exchangeId: value.exchange[0]?.value as number,
      statusId: value.dealStatus,
    })
  }

  const { data: paymentData } = usePaymentTypes()

  const defaultValue = React.useMemo(() => {
    const paymentDefaultValue = paymentData?.pgPaymentTypes.filter(
      payment => payment.id === PAYMENT_TYPE.PLATFORM
    )
    return {
      ...DEFAULT_PG_FORM_STATE,
      paymentTo: paymentDefaultValue as PaymentTypeObject[],
    }
  }, [paymentData])

  const disableSubmit =
    !pgFormRef.current?.isAllRequiredFilled() || pgFormRef.current?.hasError()

  return (
    <Dialog open maxWidth="lg" fullWidth>
      <DialogTitle
        onClose={onClose}
        sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
      >
        <Typography weight="demi">Add Deal</Typography>
      </DialogTitle>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          // form.handleSubmit()
          if (formState) {
            onSubmit(formState)
          }
        }}
      >
        <DialogContent dividers>
          <PgCreationForm
            ref={pgFormRef}
            initValue={defaultValue}
            onChange={state => {
              setFormState(state)
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={disableSubmit}
            loading={createPgMutation.isLoading}
          >
            Add
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
