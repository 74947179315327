import * as React from 'react'
import {
  SortingState,
  RowSelectionState,
  GridActionCellParams,
} from '@applift/datagrid'
import { useAppContext } from '@applift/platform'
import { Row, Col, Paper, Container } from '@applift/factor'

import { colDef } from './colDef'
import { PmpGrid } from './PmpGrid'
import { ActionPanel } from './ActionPanel'
import { ACTION, ActionNameType } from './action'
import { isEqual } from '../../../../utils/helper'
import { PmpListResponse, PmpSearch } from '../../../../models/Pmp'
import { PmpCustomerDialog } from '../Dialog/PmpCustomerDialog'
import {
  AssingToCampaignDialog,
  EditPmpDealDialog,
  PmpCampaignDialog,
} from '../Dialog'
import { DEFAULT_SIDEBAR_STATE } from '../../../../constants/pmp'
import { usePmpList } from '../../../../hooks'
import { getPmpListPayloadFromFilterSelection } from '../../-pages'

import styles from './styles.module.scss'

interface PmpGridWrapperProps {
  filters: PmpSearch
  hideTableCTAs?: boolean
  onSortingChange?: React.Dispatch<React.SetStateAction<SortingState>>
  onRowSelectionChange?: (
    rowSelection: RowSelectionState,
    tableData: PmpListResponse[]
  ) => void
}

export interface PmpGridWrapperApiRefType {
  clearRowSelection: () => void
  isInactiveDealSelected: () => boolean
}

export const PmpGridWrapper = React.forwardRef<
  PmpGridWrapperApiRefType | undefined,
  PmpGridWrapperProps
>((props, ref) => {
  const {
    filters,
    hideTableCTAs = false,
    onSortingChange,
    onRowSelectionChange,
  } = props
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})
  const [action, setAction] = React.useState<GridActionCellParams<
    ActionNameType,
    { data?: PmpListResponse; id: string; params: any }
  > | null>(null)
  const [pmpData, setPmpData] = React.useState<PmpListResponse>()
  const [assignToCampaignData, setAssignToCampaignData] = React.useState<any>()
  const [dialogToShow, setDialogToShow] = React.useState<
    'customer' | 'pmpEdit' | 'campaign' | 'assignToCampaign'
  >()
  const closeDialog = () => setDialogToShow(undefined)

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const {
    data: pmpList,
    fetchNextPage,
    isFetching,
    isError,
  } = usePmpList(getPmpListPayloadFromFilterSelection(filters))

  const data = React.useMemo(() => {
    return (
      pmpList?.pages
        ?.map(page => {
          return page?.pmpDealData ?? []
        })
        .flat(1) || []
    )
  }, [pmpList])

  const filteredRecords = React.useMemo(
    () => pmpList?.pages?.[0]?.filteredRecords || 0,
    [pmpList]
  )

  const totalRecords = React.useMemo(() => {
    return pmpList?.pages?.[0]?.totalRecords || 0
  }, [pmpList])

  const onSortingChangeWrapper = (
    value: React.SetStateAction<SortingState>
  ) => {
    onSortingChange?.(value)
    setRowSelection({})
  }

  React.useEffect(() => {
    setRowSelection({})
  }, [filters])

  React.useEffect(() => {
    switch (action?.actionName) {
      case ACTION.OPEN_CUSTOMER_DIALOG: {
        const actionRowId = action?.metaData?.data?.id
        if (actionRowId) {
          setDialogToShow('customer')
          setPmpData(action.metaData.data)
        }
        break
      }
      case ACTION.EDIT_PMP_DEAL: {
        const actionRowId = action?.metaData?.data?.id
        if (actionRowId) {
          setDialogToShow('pmpEdit')
          setPmpData(action.metaData.data)
        }
        break
      }
      case ACTION.OPEN_CAMPAIGN_DIALOG: {
        const actionRowId = action?.metaData?.data?.id
        if (actionRowId) {
          setDialogToShow('campaign')
          setPmpData(action.metaData.data)
        }
        break
      }
      case ACTION.ASSIGN_TO_CAMPAIGN: {
        const actionRow = action?.metaData?.params
        if (actionRow.length) {
          setDialogToShow('assignToCampaign')
          setAssignToCampaignData(action.metaData.params)
        }
        break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action])

  const overlay = React.useMemo(() => {
    const isStateChanged = !isEqual(filters, DEFAULT_SIDEBAR_STATE)
    if (isError) {
      return 'error'
    }
    if (!data?.length && !isFetching && isStateChanged) {
      return 'noResult'
    }
    return undefined
  }, [data?.length, isFetching, isError, filters])

  const selectedIds = React.useMemo(
    () => Object.keys(rowSelection).map(id => String(id)),
    [rowSelection]
  )

  const selectedItems = React.useMemo(
    () =>
      data.length
        ? data.filter(data => selectedIds.includes(String(data?.id)))
        : [],
    [selectedIds, data]
  )

  const inventoryIds = React.useMemo(() => {
    const selectedRowIdsSet: Set<number> = new Set()

    // Populate the set with IDs from rowSelection
    Object.keys(rowSelection).forEach(selectedId => {
      const rowData = data.find(data => data.id === Number(selectedId))
      if (rowData?.id) {
        selectedRowIdsSet.add(rowData.id)
      }
    })
    return Array.from(selectedRowIdsSet)
  }, [rowSelection, data])

  // pass back selected data in group edit mode
  React.useEffect(() => {
    onRowSelectionChange?.(rowSelection, selectedItems)
  }, [rowSelection, data, onRowSelectionChange, selectedItems])

  const clearRowSelection = () => {
    setRowSelection({})
  }

  React.useImperativeHandle(ref, () => ({
    clearRowSelection,
    isInactiveDealSelected: () => selectedItems.some(deal => !deal.active),
  }))

  return (
    <Container className={styles.container} sx={{ height: 100 }}>
      <Row sx={{ height: 100, py: 24, pl: 16, pr: 4 }}>
        <Col sx={{ pl: 4 }}>
          <Paper
            elevation={2}
            sx={{
              flexGrow: 1,
              height: 100,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Row
              className={styles.actionRow}
              sx={{ px: 24, alignItems: 'center' }}
              xs={{ gutterSize: 0, gutterDirection: 'x' }}
            >
              <ActionPanel
                colDef={colDef}
                setAction={setAction}
                isTableLoading={isFetching}
                rowSelection={rowSelection}
                totalRecords={totalRecords}
                selectedItems={selectedItems}
                hideTableCTAs={hideTableCTAs}
                clearRowSelection={clearRowSelection}
                inventoryIds={inventoryIds}
              />
            </Row>
            <Row sx={{ flexGrow: 1 }}>
              <Col>
                <PmpGrid
                  data={data}
                  colDef={colDef}
                  overlay={overlay}
                  loading={isFetching}
                  organizationType={orgType}
                  rowSelection={rowSelection}
                  sorting={filters.sortBy || []}
                  totalRecords={filteredRecords}
                  onAction={setAction}
                  onFetchRows={() => {
                    // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                    setTimeout(fetchNextPage, 100)
                  }}
                  setRowSelection={setRowSelection}
                  onSortingChange={onSortingChangeWrapper}
                />
              </Col>
            </Row>
          </Paper>
        </Col>
      </Row>
      {dialogToShow === 'customer' && (
        <PmpCustomerDialog onClose={closeDialog} data={pmpData} />
      )}
      {dialogToShow === 'pmpEdit' && pmpData && (
        <EditPmpDealDialog
          onClose={closeDialog}
          onSuccess={clearRowSelection}
          data={pmpData}
        />
      )}
      {dialogToShow === 'campaign' && (
        <PmpCampaignDialog onClose={closeDialog} data={pmpData} />
      )}
      {dialogToShow === 'assignToCampaign' && (
        <AssingToCampaignDialog
          onClose={closeDialog}
          selectedDealData={assignToCampaignData}
          onSuccess={clearRowSelection}
        />
      )}
    </Container>
  )
})

PmpGridWrapper.displayName = 'PmpGridWrapper'
