import * as React from 'react'
import { useForm } from '@tanstack/react-form'
import {
  Avatar,
  Box,
  enqueueSnackbar,
  IconButton,
  Link,
  Skeleton,
  SkeletonSwitch,
  SkeletonSwitchProvider,
  TextField,
  Typography,
  TypoTooltip,
  useDebounceValue,
} from '@applift/factor'
import { Close } from '@applift/icons'
import {
  UploadFile,
  SampleFile,
  getExcelOrCsvFileData,
  getValidURL,
} from '@applift/app-utils'

import { useContextualURLPreview } from '../../../hooks/useContextual'

interface URLTabProps {
  addedURLs: string[]
  setAddedURLs: React.Dispatch<React.SetStateAction<string[]>>
}

export const URLTab = (props: URLTabProps) => {
  const { addedURLs, setAddedURLs } = props

  const [urlToPreview, setUrltoPreview] = React.useState('')

  const debouncedPreviewURL = useDebounceValue(urlToPreview, 250)

  const { data, isFetching: isFetchingPreviewInfo } = useContextualURLPreview(
    debouncedPreviewURL,
    {
      enabled: Boolean(getValidURL(debouncedPreviewURL)),
    }
  )

  const form = useForm({
    defaultValues: { url: '' },
  })

  const onURLAdd = (incomingURL: string) => {
    const previouslyAddedURLs = addedURLs
    const validURL = getValidURL(incomingURL)
    if (!validURL) {
      return enqueueSnackbar('Please enter valid URL', {
        variant: 'error',
      })
    }

    const isURLAlreadyAdded = previouslyAddedURLs.includes(validURL)
    if (isURLAlreadyAdded) {
      return enqueueSnackbar('URL already added.', {
        variant: 'warning',
      })
    }
    setAddedURLs([...addedURLs, validURL])

    enqueueSnackbar(`URL succesfully added.`, {
      variant: 'success',
    })
  }

  const onFileUpload = async (files: FileList) => {
    const file = files[0] as File

    if (file.size > 3145728) {
      return enqueueSnackbar({
        variant: 'error',
        message: "File size can't be larger that 3MB",
      })
    }
    getExcelOrCsvFileData(
      file,
      (data: string[][]) => {
        const header = data.shift()
        const byType = header && header?.[0]?.toLowerCase()

        if ((byType ?? '').toLowerCase() !== 'url') {
          return enqueueSnackbar({
            message: 'Unable to read file content. Please try again.',
            variant: 'error',
          })
        }

        if (data) {
          const previouslyAddedURLs = addedURLs.map(item => item.toLowerCase())
          const removeDuplicates = (arr: string[]) => [
            ...new Set(arr.map(element => element.toLowerCase())),
          ]
          const fileURLs = removeDuplicates(data.flat(1))
          const validURLs = fileURLs
            .map(fileUrl => getValidURL(fileUrl))
            .filter(url => Boolean(url))

          if (!validURLs.length && fileURLs.length) {
            return enqueueSnackbar({
              message: 'Please upload file with valid URLs',
              variant: 'error',
            })
          }

          const newURLs = validURLs.filter(
            url => !previouslyAddedURLs.includes(url.toLowerCase())
          )

          if (newURLs.length) {
            setAddedURLs([...addedURLs, ...newURLs])

            return enqueueSnackbar({
              message: `${validURLs.length} URLs added successfully`,
              variant: 'success',
            })
          } else if (validURLs.length) {
            return enqueueSnackbar({
              message: 'URL(s) already added',
              variant: 'warning',
            })
          }
        }
      },
      () => {
        enqueueSnackbar({
          message: 'Upload file error',
          variant: 'error',
        })
      }
    )
  }

  return (
    <Box sx={{ width: 100 }}>
      <form>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            bgColor: 'neutral-0',
          }}
        >
          <Box sx={{ mb: 12 }}>
            <Typography sx={{ display: 'block', mb: 8 }}>
              Type the URL and press ‘Enter’ to add
            </Typography>
            <form.Field name="url">
              {field => (
                // @ts-ignore
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TextField
                    type="text"
                    value={field.state.value}
                    onChange={e => {
                      field.handleChange(e.target.value)
                      setUrltoPreview(e.target.value.trimStart().trimEnd())
                    }}
                    placeholder="Add URL"
                    variant="outlined"
                    sx={{ width: 100 }}
                    InputProps={{
                      endAdornment: field.state.value.trim().length ? (
                        <IconButton
                          onClick={() => field.setValue('')}
                          size="small"
                          color="secondary"
                        >
                          <Close fontSize={16} />
                        </IconButton>
                      ) : null,
                    }}
                  />
                  <Link
                    sx={{ textWeight: 'demi', ml: 8 }}
                    Component="button"
                    color="primary"
                    onClick={e => {
                      e.preventDefault()
                      onURLAdd(field.state.value)
                      setUrltoPreview('')
                      field.setValue('')
                    }}
                    disabled={!getValidURL(field.state.value).length}
                  >
                    <Typography>Add</Typography>
                  </Link>
                </Box>
              )}
            </form.Field>
          </Box>
        </Box>
      </form>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 12,
          my: 12,
        }}
      >
        <UploadFile
          fileTypes={['csv', 'xlsx']}
          onUpload={onFileUpload}
          uploadBtnText="Upload"
          uploadBtnVariant="outlined"
          buttonProps={{ sx: { textWeight: 'normal' } }}
        />
        <SampleFile
          sampleFileText="Sample File"
          sampleFileURL={`${window.location.origin}/example-files/contextual-audience-urls-sample.csv`}
          sampleFileName="contextual-audience-urls-example"
          buttonProps={{
            sx: { textWeight: 'normal' },
            variant: 'text',
            startIcon: undefined,
          }}
        />
      </Box>

      <Typography sx={{ display: 'block', textColor: 'neutral-500', mb: 16 }}>
        Allow Only to Upload XLSX/CSV files
      </Typography>
      {Object.values(data ?? {}).length > 0 || isFetchingPreviewInfo ? (
        <SkeletonSwitchProvider
          animation="wave"
          loading={isFetchingPreviewInfo}
        >
          <Box
            sx={{
              bgColor: 'neutral-75',
              borderRadius: 4,
              p: 12,
              width: 100,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography sx={{ mb: 8, display: 'block' }}>
              URL Preview
            </Typography>
            <Box
              sx={{
                display: 'flex',
                gap: 16,
                alignItems: 'center',
                mb: 12,
                width: 100,
              }}
            >
              <Box>
                <SkeletonSwitch>
                  {loading =>
                    loading ? (
                      <Skeleton height={70} width={70} />
                    ) : (
                      <>
                        {data?.image ? (
                          <Avatar src={data.image} size="medium" />
                        ) : null}
                      </>
                    )
                  }
                </SkeletonSwitch>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 4,
                  flexGrow: 1,
                  width: 75,
                }}
              >
                <SkeletonSwitch height={20}>
                  {loading => (
                    <TypoTooltip
                      TypgraphyProps={{
                        component: 'h2',
                        variant: 'bodySmall',
                        gutterBottom: false,
                      }}
                      arrow
                    >
                      {loading ? 'Title Title' : data?.title ?? ''}
                    </TypoTooltip>
                  )}
                </SkeletonSwitch>
                <SkeletonSwitch height={20}>
                  <TypoTooltip
                    TypgraphyProps={{
                      variant: 'label',
                      gutterBottom: false,
                      sx: {
                        textColor: 'neutral-500',
                      },
                    }}
                    arrow
                  >
                    {isFetchingPreviewInfo
                      ? 'Description Description Description'
                      : data?.description ?? ''}
                  </TypoTooltip>
                </SkeletonSwitch>
              </Box>
            </Box>
            <SkeletonSwitch height={20}>
              {loading => (
                <TypoTooltip
                  TypgraphyProps={{
                    variant: 'label',
                    gutterBottom: false,
                  }}
                  arrow
                >
                  {loading ? 'Url Url Url Url Url Url Url' : data?.url ?? ''}
                </TypoTooltip>
              )}
            </SkeletonSwitch>
          </Box>
        </SkeletonSwitchProvider>
      ) : null}
    </Box>
  )
}
