import { Container } from '@applift/factor'

import { SidebarInventoryGroup } from '../../../../models/Group'
import { PmpGroupGridWrapper } from './PmpGroupGrid'

interface PmpGroupSectionProps {
  groupDetail: SidebarInventoryGroup
  isNonEditableGroup: boolean
}

export const PmpGroupSection = (props: PmpGroupSectionProps) => {
  const { groupDetail, isNonEditableGroup } = props

  return (
    <Container
      sx={{
        height: 100,
        width: 100,
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'stretch',
      }}
    >
      <PmpGroupGridWrapper
        groupDetail={groupDetail}
        isNonEditableGroup={isNonEditableGroup}
      />
    </Container>
  )
}
