export const formatCurrency = (
  value: number,
  formatProps?: Intl.NumberFormatOptions
): string => {
  if (typeof value !== 'number') {
    return '—'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...formatProps,
  })
  return formatter.format(value)
}

export const formatNumber = (
  value: number,
  formatProps?: Intl.NumberFormatOptions
) => {
  if (typeof value !== 'number') {
    return '—'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...formatProps,
  })

  return formatter.format(value)
}

export const formatNumberWithSuffix = (num: number) => {
  if (typeof num !== 'number') {
    return '—'
  }
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits: 1,
  })
  return formatter.format(num)
}

export const toInitCap = (value: string) =>
  value.length ? `${value[0]?.toLocaleUpperCase()}${value.slice(1)}` : ''

export const dateFormatShort = (
  value: Date | number,
  formatProps?: Intl.DateTimeFormatOptions
) => {
  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    ...formatProps,
  })

  return formatter.format(value)
}
