import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Row,
  Col,
  Typography,
  Button,
  LoadingButton,
  enqueueSnackbar,
} from '@applift/factor'
import { useQueryClient } from '@tanstack/react-query'
import { useForm, ValidationError } from '@tanstack/react-form'
import { useRouter } from '@tanstack/react-router'

import { GroupTypeSelection } from './GroupTypeSelection'
import { useSaveToNewGroup } from '../../../../hooks'
import { WithResponse } from '../../../../models/Response'
import { SidebarInventoryGroup } from '../../../../models/Group'
import { SaveToNewGroupRequest } from '../../../../models/OpenExchange'
import { getGroupCreatedMessage } from '../../../../utils/messages'

interface CreateGroupDialogProps {
  onClose: () => void
  onSuccess?: () => void
}

export const CreateGroupDialog = (props: CreateGroupDialogProps) => {
  const { onClose, onSuccess } = props

  const router = useRouter()
  const queryClient = useQueryClient()

  const onGroupCreateSuccess = (
    data: WithResponse<SidebarInventoryGroup>,
    payload: SaveToNewGroupRequest
  ) => {
    onSuccess?.()
    enqueueSnackbar(getGroupCreatedMessage(payload.groupName), {
      variant: 'success',
    })
    if (data?.data?.id) {
      router.navigate({
        replace: true,
        to: '/groups/$groupId',
        params: { groupId: String(data.data.id) },
      })
      queryClient.resetQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getGroupList',
      })
      queryClient.resetQueries({
        predicate: (query: any) =>
          query.queryKey?.[0]?.scope === 'getGroupCount',
      })
      queryClient.resetQueries({
        predicate: (query: any) => {
          return query.queryKey?.[0]?.scope === 'getGroupStatistics'
        },
      })
    }
  }

  const { mutate: saveToNew, isLoading: newGroupSaveInProgress } =
    useSaveToNewGroup({
      onSuccess: onGroupCreateSuccess,
    })

  const form = useForm({
    defaultValues: {
      groupName: '',
      groupTypeId: 0,
    },
    onSubmit: async ({ value }) => {
      saveToNew({
        groupName: value.groupName.trim(),
        groupTypeId: value.groupTypeId,
        isEmptyGroup: true,
      })
    },
  })

  const getGroupNameHelperText = (errors: ValidationError[]) =>
    errors.length ? (
      <Typography variant="label" color="error">
        {errors[0]}
      </Typography>
    ) : null

  return (
    <Dialog open maxWidth="sm" fullWidth onClose={onClose}>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <DialogTitle onClose={onClose}>Create Group</DialogTitle>
        <DialogContent dividers>
          <Container
            sx={{ display: 'flex', flexDirection: 'column', gapRow: 24, px: 0 }}
          >
            <Row>
              <Col>
                <form.Field
                  name="groupName"
                  validators={{
                    onChange: ({ value }) => {
                      if (!value.trim() || value.trim().length > 255) {
                        return !value.trim()
                          ? 'Group name is required'
                          : 'Character limit exceeded'
                      }
                      return undefined
                    },
                  }}
                >
                  {field => (
                    <TextField
                      id={field.name}
                      name={field.name}
                      value={field.state.value}
                      onBlur={field.handleBlur}
                      onChange={e => field.handleChange(e.target.value)}
                      label="Group Name"
                      required
                      InputLabelProps={{
                        renderSuffix: (
                          <>{field.state.value.trim().length}/255</>
                        ),
                      }}
                      placeholder="Enter group name"
                      variant="outlinedDash"
                      helperText={getGroupNameHelperText(
                        field.state.meta.errors
                      )}
                      fullWidth
                      error={Boolean(field.state.meta.errors.length)}
                    />
                  )}
                </form.Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <form.Field name="groupTypeId">
                  {field => (
                    <GroupTypeSelection
                      value={field.state.value}
                      onChange={value => {
                        field.handleChange(value)
                      }}
                    />
                  )}
                </form.Field>
              </Col>
            </Row>
          </Container>
        </DialogContent>
        <DialogActions>
          <form.Subscribe selector={state => [state.canSubmit, state.values]}>
            {([canSubmit, value]) => {
              const isMedatoryFieldFilled =
                // @ts-ignore
                value && value?.groupName && value?.groupTypeId
              return (
                <>
                  <Button color="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={newGroupSaveInProgress}
                    disabled={
                      !canSubmit ||
                      newGroupSaveInProgress ||
                      !isMedatoryFieldFilled
                    }
                  >
                    Create
                  </LoadingButton>
                </>
              )
            }}
          </form.Subscribe>
        </DialogActions>
      </form>
    </Dialog>
  )
}
