import * as React from 'react'
import { GridRenderEditCellParams } from '@applift/datagrid'
import {
  Tooltip,
  TextField,
  Popover,
  Box,
  usePreviousProps,
  Alert,
  IconButton,
  Divider,
} from '@applift/factor'
import { CheckCircle, CloseCircle } from '@applift/icons'

import styles from './index.module.scss'

interface Props extends GridRenderEditCellParams {
  initialValue?: any
  shouldIgnoreModifications?: (inputValue: any, originalRow: any) => boolean
  validate?: (str: string) => boolean
  disableConfirmCheck?: (value: string) => boolean
}

export const EditableCell = (props: Props) => {
  const {
    error,
    initialValue,
    apiRef,
    row,
    column,
    hasFocus,
    value,
    shouldIgnoreModifications,
    validate,
    disableConfirmCheck,
  } = props

  const dataRow = apiRef.tableInstance.getRow(row.id).original
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [inputElement, setInputElement] =
    React.useState<HTMLInputElement | null>(null)
  const [inputElementContainer, setInputElementContainer] =
    React.useState<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = React.useState(
    initialValue || value || ''
  )
  const inputRef = React.useRef<any>(null)
  const rowId = React.useMemo(() => row.id, [row])
  const field = React.useMemo(() => column.id, [column])
  const cellElement = React.useMemo(
    () => apiRef.getCellElement(rowId, field),
    [rowId, field, apiRef]
  )
  const inputWrapperId = React.useRef(
    `table-number-inline-input-container-${crypto.randomUUID()}`
  )
  const inputId = React.useRef(
    `table-number-inline-input-${crypto.randomUUID()}`
  )
  const prevError = usePreviousProps(error)
  const ignoreModifications = shouldIgnoreModifications
    ? shouldIgnoreModifications(inputValue, dataRow)
    : false

  React.useLayoutEffect(() => {
    if (hasFocus && inputRef.current) {
      inputRef.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFocus, inputRef.current])

  React.useEffect(() => {
    setAnchorEl(cellElement)
  }, [cellElement])

  React.useEffect(() => {
    if (anchorEl) {
      setTimeout(() => {
        setInputElement(
          document.getElementById(inputId.current) as HTMLInputElement
        )
        setInputElementContainer(
          document.getElementById(inputWrapperId.current) as HTMLInputElement
        )
      }, 0)
    }
  }, [anchorEl])

  React.useEffect(() => {
    if (cellElement && inputElement && inputElementContainer) {
      const cellWidth = cellElement.getBoundingClientRect().width
      const cellHeight = cellElement.getBoundingClientRect().height
      // @ts-ignore
      inputElement.style.width = `${cellWidth - 84}px`
      // @ts-ignore
      inputElement.style.height = `${cellHeight - 20 - 2}px` // 20 is the padding and 2 is for border
      // @ts-ignore
      inputElementContainer.style.height = `${cellHeight}px`
    }
  }, [cellElement, inputElement, inputElementContainer])

  const setInputValueWrapper = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value

    // Trim the input value to the first 1000 characters
    if (value.length > 1000) {
      value = value.slice(0, 1000)
    }

    const isValid = validate ? validate(value) : true

    if (!isValid) {
      return
    }

    setInputValue(value)
    props.apiRef.setEditCellValue({
      rowId: props.row.id,
      field: props.cell.column.id,
      value: value || '',
      debounceMs: 100,
    })
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const isValid = validate ? validate(inputValue) : true
    if (e.key === 'Enter' && !error && isValid) {
      apiRef.stopCellEditMode({ rowId, field, ignoreModifications })
    }
    if (e.key === 'Escape') {
      apiRef.stopCellEditMode({ rowId, field, ignoreModifications: true })
    }
  }

  const isConfirmDisabled = error || disableConfirmCheck?.(inputValue)

  return (
    <Tooltip
      title={
        <Alert
          sx={{ p: 0, alignItems: 'start', boxShadow: 0, border: 0 }}
          severity="error"
          variant="outlined"
        >
          {error || prevError}
        </Alert>
      }
      open={!!error}
      arrow
      placement="top"
      severity="error"
    >
      <Box
        style={{
          height: `${cellElement ? cellElement.getBoundingClientRect().height : 0}px`,
        }}
      >
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null)
            apiRef.stopCellEditMode({ rowId, field, ignoreModifications: true })
          }}
          PaperProps={{ sx: { overflow: 'visible' } }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Box id={inputWrapperId.current}>
            <TextField
              id={inputId.current}
              type="text"
              inputRef={inputRef}
              value={inputValue}
              error={Boolean(error)}
              sx={{ height: 100 }}
              onChange={setInputValueWrapper}
              onKeyDown={onKeyDown}
              InputProps={{
                classes: { root: styles.input },
                endAdornment: (
                  <>
                    <Divider
                      color="success"
                      orientation="vertical"
                      sx={{
                        textColor: 'primary-600',
                        borderColor: error ? 'danger-500' : 'primary-600',
                      }}
                    />
                    <Box style={isConfirmDisabled ? { cursor: 'default' } : {}}>
                      <IconButton
                        disabled={isConfirmDisabled}
                        color="success"
                        onClick={() => {
                          apiRef.stopCellEditMode({
                            rowId,
                            field,
                            ignoreModifications,
                          })
                        }}
                      >
                        <CheckCircle />
                      </IconButton>
                    </Box>
                    <IconButton
                      color="error"
                      onClick={() => {
                        apiRef.stopCellEditMode({
                          rowId,
                          field,
                          ignoreModifications: true,
                        })
                      }}
                    >
                      <CloseCircle />
                    </IconButton>
                  </>
                ),
              }}
              InputLabelProps={{
                sx: { border: 0, borderRadius: 0, bgColor: 'danger-400' },
              }}
              color="primary"
              variant="outlined"
            />
          </Box>
        </Popover>
      </Box>
    </Tooltip>
  )
}
