import {
  Badge,
  bindMenu,
  bindTrigger,
  Box,
  IconButton,
  Link,
  Menu,
  MenuItem,
  SvgIcon,
  sx,
  Typography,
  usePopupState,
} from '@applift/factor'
import { RowSelectionState } from '@applift/datagrid'
import { Filter, Redo } from '@applift/icons'
import { useAppContext } from '@applift/platform'
import { useRouter } from '@tanstack/react-router'
import clsx from 'clsx'

import { getIcon } from '../../../../registry'
import {
  CREATED_BY_SELF_GROUP_FILTER_ID,
  SHARED_BY_ADMIN_GROUP_FILTER_ID,
} from '../../../../constants/group'
import { GROUP_TYPE_IDS } from '../../../../constants/common'

import styles from './index.module.scss'

const GROUP_TYPE_FILTERS = [
  {
    label: 'Open Exchange',
    value: `groupType-${GROUP_TYPE_IDS.OPEN_EXCHANGE}`,
    icon: getIcon('InventoryOpenExchange', { fontSize: 20 }),
  },
  {
    label: 'PMP',
    value: `groupType-${GROUP_TYPE_IDS.PMP}`,
    icon: getIcon('InventoryPmpDeals', { fontSize: 20 }),
  },
  {
    label: 'Contextual',
    value: `groupType-${GROUP_TYPE_IDS.CONTEXTUAL}`,
    icon: getIcon('InventoryContextual', { fontSize: 20 }),
  },
]

const INVENTORY_GROUP_FILTERS = [
  {
    label: 'Added by Me',
    value: `groupFilter-${CREATED_BY_SELF_GROUP_FILTER_ID}`,
  },
  {
    label: 'Shared By Admin',
    value: `groupFilter-${SHARED_BY_ADMIN_GROUP_FILTER_ID}`,
  },
]

interface GroupFilterProps {
  setRowSelection: React.Dispatch<React.SetStateAction<RowSelectionState>>
  setSearchText: React.Dispatch<React.SetStateAction<string>>
}

export const GroupsFilter = (props: GroupFilterProps) => {
  const { setRowSelection, setSearchText } = props

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'filterPopper',
  })

  const router = useRouter()
  const isAdvertiser = useAppContext().appMeta.organizationType === 'ADVERTISER'

  const currentlyAppliedGroupFilters =
    router.state.location.search.currentlyAppliedGroupFilters ?? []

  const updateFilterSelection = (filter: string) => {
    const newFilters = currentlyAppliedGroupFilters.includes(filter)
      ? currentlyAppliedGroupFilters.filter(item => item !== filter)
      : [
          ...currentlyAppliedGroupFilters.filter(
            item => !item.includes('groupFilter')
          ),
          filter,
        ]
    setRowSelection({})
    setSearchText('')
    router.navigate({
      params: { groupId: '' },
      search: {
        currentlyAppliedGroupFilters: newFilters,
      },
    })
  }

  return (
    <>
      <Badge
        overlap="circular"
        badgeContent={currentlyAppliedGroupFilters.length}
        color="warning"
      >
        {/*@ts-ignore */}
        <IconButton
          {...bindTrigger(popupState)}
          id="mainlist-filter-button"
          selected={popupState.isOpen}
          color="secondary"
        >
          <Filter />
        </IconButton>
      </Badge>
      {/*@ts-ignore */}
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{ border: 1 }}
        classes={{
          paper: clsx(
            sx({ border: 1, borderColor: 'primary-500' }),
            styles.filterDropdown
          ),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'between',
            borderBottom: 1,
            px: 12,
            py: 8,
          }}
        >
          <Typography variant="bodySmall" weight="demi" sx={{ mb: 0 }}>
            Filter
          </Typography>
          {currentlyAppliedGroupFilters.length > 0 && (
            <Link
              Component="button"
              color="primary"
              underline="none"
              onClick={() => {
                setRowSelection({})
                router.navigate({
                  params: { groupId: '' },
                })
              }}
            >
              <SvgIcon sx={{ mr: 2 }} fontSize={20}>
                <Redo />
              </SvgIcon>
              Clear Filters
            </Link>
          )}
        </Box>

        <Typography
          variant="bodySmall"
          weight="demi"
          gutterBottom={false}
          sx={{ display: 'block', m: 8 }}
        >
          Group Type
        </Typography>
        {GROUP_TYPE_FILTERS?.map(val => (
          <MenuItem
            value={val.value}
            key={val.value}
            selected={currentlyAppliedGroupFilters.includes(val.value)}
            onClick={() => updateFilterSelection(val.value)}
          >
            <Box sx={{ display: 'flex', gap: 4 }}>
              <Box>{val.icon}</Box>
              <Typography gutterBottom={false} sx={{ display: 'block' }}>
                {val.label}
              </Typography>
            </Box>
          </MenuItem>
        ))}

        <Typography
          variant="bodySmall"
          weight="demi"
          gutterBottom={false}
          sx={{ display: isAdvertiser ? 'block' : 'none', m: 8 }}
        >
          Inventory Groups
        </Typography>

        {INVENTORY_GROUP_FILTERS?.map(val => (
          <MenuItem
            value={val.value}
            sx={{ display: isAdvertiser ? 'block' : 'none' }}
            key={val.value}
            selected={currentlyAppliedGroupFilters.includes(val.value)}
            onClick={() => updateFilterSelection(val.value)}
          >
            {val.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
