import { ColumnDef } from '@applift/datagrid'

import { IconTextCell, RawDataCell } from '../../../../components/CellTypes'
import { ContextualTableData } from '../../../../models/Contextual'

export const contextualGroupColDef: ColumnDef<ContextualTableData>[] = [
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Name',
    size: 550,
    minSize: 100,
    enableResizing: true,
    cell: props => {
      return (
        <RawDataCell
          value={props.renderValue() as string}
          textTypographyProps={{ variant: 'bodySmall' }}
        />
      )
    },
  },
  {
    accessorKey: 'type',
    id: 'type',
    header: 'Type',
    size: 220,
    minSize: 100,
    enableSorting: false,
    enableResizing: true,
    cell: props => {
      return (
        <IconTextCell
          label={props.renderValue() as string}
          textTypographyProps={{ variant: 'bodySmall' }}
          iconSize={20}
          iconName={
            props.row.original.type === 'Keyword' ? 'Key' : 'LinkHorizontal'
          }
        />
      )
    },
  },
]
