import * as React from 'react'
import {
  Button,
  Chip,
  Box,
  IconButton,
  TextField,
  enqueueSnackbar,
  clamp,
} from '@applift/factor'
import { Search, Close } from '@applift/icons'
import { useQueryClient } from '@tanstack/react-query'

import { ConfirmationDialog } from '../../../../../components/Dialogs'
import { formatNumberWithSuffix } from '../../../../../utils/format'
import { MAX_INVENTORY_DISPLAY_COUNT } from '../../../../../constants/exchange'
import { useRemoveInventoriesMutation } from '../../../../../hooks'
import { GROUP_TYPE_IDS } from '../../../../../constants/common'
import {
  DownloadTableAction,
  FileTypes,
} from '../../../../../components/DownloadTableAction'
import { SidebarInventoryGroup } from '../../../../../models/Group'

interface ActionPanelProps {
  selectedCount: number
  totalRecords: number
  selectedIds: number[]
  groupDetail: SidebarInventoryGroup
  toggleDisplayDistributionData: React.Dispatch<React.SetStateAction<boolean>>
  search: string
  setSearch: React.Dispatch<React.SetStateAction<string>>
  overlay?: 'error' | 'noResult' | 'noRows'
  clearSelection: () => void
  handleDownload: (fileType: FileTypes) => void
  isDownloadInProgress: boolean
}

export const OpenExchangeGroupActionPanel = (props: ActionPanelProps) => {
  const {
    selectedCount,
    clearSelection,
    totalRecords,
    selectedIds,
    groupDetail,
    toggleDisplayDistributionData,
    setSearch,
    search,
    overlay,
    handleDownload,
    isDownloadInProgress,
  } = props

  const queryClient = useQueryClient()

  const [showRemoveFromGroupDialog, toggleRemoveFromGroupDialogVisibility] =
    React.useState(false)

  const totalRecordsToDisplay = formatNumberWithSuffix(
    clamp(totalRecords, 0, MAX_INVENTORY_DISPLAY_COUNT)
  )

  const chipLabel = `${selectedCount} 
  out of ${totalRecordsToDisplay} selected`

  const onSettled = () => {
    toggleDisplayDistributionData(false)
    clearSelection()
    toggleRemoveFromGroupDialogVisibility(false)
  }

  const onSuccess = () => {
    enqueueSnackbar({
      message: `Selected inventories removed from the group successfully.`,
      variant: 'success',
    })

    queryClient.invalidateQueries({
      predicate: (query: any) => query.queryKey?.[0]?.scope === 'getGroupList',
    })

    queryClient.resetQueries({
      predicate: (query: any) =>
        [
          'getOpenExchangeGroupCountData',
          'getOpenExchangeGroupListingData',
          'getOpenExchangeGroupDistributionData',
        ].includes(query.queryKey?.[0]?.scope),
    })
  }

  const removeFromGroupMutation = useRemoveInventoriesMutation(
    onSettled,
    onSuccess
  )

  const onRemoveClick = () => {
    const payload = {
      groupId: groupDetail.id,
      inventoryIds: selectedIds,
      groupTypeId: GROUP_TYPE_IDS.OPEN_EXCHANGE,
    }
    removeFromGroupMutation.mutate(payload)
  }

  const deleteDialogText =
    'Are you sure you want to remove the selected inventories from the group?'

  return (
    <>
      <Box sx={{ display: 'flex', px: 24, mt: 12, justifyContent: 'between' }}>
        {overlay !== 'noRows' && (
          <>
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {selectedCount > 0 && (
                  <Chip
                    size="small"
                    label={chipLabel}
                    color="secondary"
                    onDelete={clearSelection}
                  />
                )}

                {selectedCount > 0 && (
                  <Button
                    size="small"
                    color="error"
                    variant="outlined"
                    sx={{ marginLeft: 8 }}
                    onClick={() => toggleRemoveFromGroupDialogVisibility(true)}
                  >
                    Remove From Group
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', gap: 4 }}>
              <TextField
                type="text"
                value={search}
                onChange={e => {
                  setSearch(e.target.value)
                  clearSelection()
                }}
                placeholder="Search by ID, Name or Publisher"
                variant="outlinedDash"
                sx={{ ml: 8 }}
                InputProps={{
                  startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
                  slotProps: { root: { style: { width: '270px' } } },
                  endAdornment:
                    search.trim().length > 0 ? (
                      <IconButton
                        onClick={() => {
                          setSearch('')
                          clearSelection()
                        }}
                        size="small"
                      >
                        <Close fontSize={16} />
                      </IconButton>
                    ) : null,
                }}
              />

              <DownloadTableAction
                downloadfile={handleDownload}
                isDownloading={isDownloadInProgress}
              />
            </Box>
          </>
        )}
      </Box>
      {showRemoveFromGroupDialog && (
        <ConfirmationDialog
          onClose={() => toggleRemoveFromGroupDialogVisibility(false)}
          onConfirmation={onRemoveClick}
          isActionLoading={removeFromGroupMutation.isLoading}
          primaryBtnText="Remove"
          theme="error"
          title="Remove Selected Inventories"
          dialogContent={deleteDialogText}
        />
      )}
    </>
  )
}
