import {
  UseInfiniteQueryResult,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query'

import { PmpDealResponse, PmpTableResponse } from '../models/Pmp'
import { getPmpDealKey, getPmpListKey } from '../api/QueryKeys'
import { deletePmpDeal, getPmpDeal, getPmpList } from '../api/pmp'
import { DEFAULT_PAGE_SIZE } from '../constants/list'

export const usePmpList = (props: {
  searchField: string[]
  creativeTypeList: number[]
  exchangeList: number[]
  statusIds?: number
  pageSize?: number
  order?: string // 'DESC' | 'ASC'
  sortBy?: string
}): UseInfiniteQueryResult<PmpTableResponse> => {
  const {
    searchField,
    creativeTypeList,
    exchangeList,
    statusIds,
    pageSize = DEFAULT_PAGE_SIZE,
    order,
    sortBy,
  } = props
  const queryData = useInfiniteQuery(
    getPmpListKey.keys({
      scope: 'getPmpList',
      pageSize,
      searchField,
      creativeTypeList,
      exchangeList,
      statusIds,
      order: sortBy && order ? order : undefined,
      sortBy: sortBy ?? '',
    }),
    getPmpList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce((prev, one) => {
          const datalength = one?.pmpDealData?.length
          if (datalength) {
            return prev + datalength
          }
          return prev
        }, 0)
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return null
      },
      cacheTime: 0,
    }
  )
  return queryData
}

export const usePmpDeal = (
  id: string,
  options?: {
    enabled?: UseQueryOptions<PmpDealResponse>['enabled']
  }
) => {
  const queryData = useQuery(
    getPmpDealKey.keys({ scope: 'getPmpDeal', id }),
    getPmpDeal,
    {
      cacheTime: 0,
      enabled: options?.enabled,
    }
  )
  return queryData
}

export const usePmpDelete = (options?: {
  onSuccess?: (res: any, payload: any) => void
  onError?: (props: any) => void
  onSettled?: (props: any) => void
}) => {
  const mutationResult = useMutation(deletePmpDeal, {
    mutationKey: ['deletePmpDeal'],
    onSuccess: options?.onSuccess,
    onError: options?.onError,
    onSettled: options?.onSettled,
  })

  return mutationResult
}
