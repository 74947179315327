import * as React from 'react'
import { Box, Layout } from '@applift/factor'
import { Outlet, createFileRoute, useRouter } from '@tanstack/react-router'

import {
  GroupsSidebar,
  GroupsSidebarApiRef,
} from './groups/-pages/GroupSidebar'
import { DEFAULT_GROUP_FILTERS } from '../constants/group'
import { ScreenLoader } from '../components/ScreenLoader'
import { GroupsEmptyPage } from './groups/-components/GroupsEmptyPage/GroupsEmptyPage'
import { getGroupsStatisticsKey } from '../api/QueryKeys'
import { getGroupStatistics } from '../api'
import { CreateGroupDialog } from './groups/-components/CreateGroupDialog'

export const Route = createFileRoute('/groups')({
  validateSearch: (routeSearch: Partial<typeof DEFAULT_GROUP_FILTERS>) => {
    return {
      ...(routeSearch.currentlyAppliedGroupFilters?.length ||
      typeof routeSearch.createGroup === 'boolean'
        ? {
            currentlyAppliedGroupFilters:
              routeSearch.currentlyAppliedGroupFilters,
            createGroup: routeSearch.createGroup,
          }
        : {}),
    }
  },
  loader: ({ context }) => {
    // @ts-ignore
    return context?.queryClient?.fetchQuery(
      getGroupsStatisticsKey.keys('getGroupStatistics'),
      getGroupStatistics
    )
  },
  pendingComponent: () => <ScreenLoader />,
  component: Groups,
})

function Groups() {
  const data = Route.useLoaderData()

  const router = useRouter()

  const sidebarRef = React.useRef<GroupsSidebarApiRef>(null)

  const groupTotalRecords = data?.['totalInventoryGroups']

  const search = Route.useSearch()

  const selectedGroupId =
    (router.routeTree.useParams() as { [key: string]: string })?.groupId ?? ''
  const currentlyAppliedGroupFilters =
    router.state.location.search.currentlyAppliedGroupFilters ?? []

  const isCreateOpen = search.createGroup ?? false

  return (
    <>
      {groupTotalRecords === 0 ? (
        <GroupsEmptyPage />
      ) : (
        <Layout
          sidebarWidth={320}
          maxWidth={320}
          minWidth={320}
          hideResizerHandle
          sidebar={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                pt: 16,
                bgColor: 'neutral-0',
                height: 100,
              }}
            >
              <GroupsSidebar ref={sidebarRef} />
            </Box>
          }
        >
          <Outlet />
        </Layout>
      )}
      {isCreateOpen && (
        <CreateGroupDialog
          onClose={() => {
            if (selectedGroupId) {
              router.navigate({
                to: '/groups/$groupId',
                params: {
                  groupId: selectedGroupId,
                },
                search: {
                  ...(currentlyAppliedGroupFilters.length
                    ? { currentlyAppliedGroupFilters }
                    : {}),
                  createGroup: false,
                },
              })
            } else {
              router.navigate({
                to: '/groups',
                search: {
                  ...(currentlyAppliedGroupFilters.length
                    ? { currentlyAppliedGroupFilters }
                    : {}),
                  createGroup: false,
                },
              })
            }
          }}
          onSuccess={() => {
            sidebarRef.current?.clearSearch()
          }}
        />
      )}
    </>
  )
}
