import { useQuery } from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import { Timezones } from '../api/timezone'
import { WithResponse } from '../models/Response'

export const getTimezoneKey = {
  keys: (args: { scope: string }) => [args],
}

export const useTimezone = () => {
  const data = useQuery(
    getTimezoneKey.keys({ scope: 'getTimezones' }),
    Timezones,
    {
      onError: (e: WithResponse) => {
        enqueueSnackbar(
          e?.errorObjects
            ? (e.errorObjects[0]?.error as string)
            : 'Something went wrong!',
          {
            variant: 'error',
          }
        )
      },
      keepPreviousData: true,
      cacheTime: 5000,
    }
  )
  return data
}
