import * as React from 'react'
import {
  DataGrid,
  SortingState,
  useGridApiRef,
  getSortedRowModel,
  GridActionCellParams,
} from '@applift/datagrid'

import { colDef } from './colDef'
import { CampaignInfoType } from '../../../../../models/Group'
import { Warning } from '@applift/illustrations'
import { sx } from '@applift/factor'

interface CampaignGridProps {
  data: CampaignInfoType[]
  onAction: (params: GridActionCellParams) => void
}

export const CampaignGrid = (props: CampaignGridProps) => {
  const { data, onAction } = props

  const [sorting, setSortinig] = React.useState<SortingState>([
    { id: 'id', desc: true },
  ])
  const tableApiRef = useGridApiRef()

  return (
    <DataGrid
      apiRef={tableApiRef}
      onAction={onAction}
      data={data}
      columns={colDef}
      rowCount={data?.length}
      componentsProps={{
        // @ts-ignore
        errorOverlay: {
          // @ts-ignore
          subText:
            'Oops, something went wrong. Please try again after some time.',
          illustration: (
            <Warning
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
      }}
      hideHeader
      hideFooter
      rowHeight={40}
      checkboxSelection={false}
      overscrollBehaviorX="contain"
      showColumnRightBorder
      showCellRightBorder
      disableSelectAll
      disableRowSelectionOnClick
      state={{ sorting }}
      getSortedRowModel={getSortedRowModel()}
      onSortingChange={value => {
        setSortinig(value)
      }}
      classes={{
        root: sx({
          borderRadius: 0,
          border: 0,
          borderTop: 1,
        }),
      }}
    />
  )
}
