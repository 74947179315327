import { SortingState } from '@applift/datagrid'
import {
  UseInfiniteQueryResult,
  UseQueryOptions,
  useInfiniteQuery,
  useQuery,
} from '@tanstack/react-query'
import { enqueueSnackbar } from '@applift/factor'

import {
  campaignsDealCountKey,
  getBasicCampaignListKey,
  getBasicIoCampaignListKey,
} from '../api/QueryKeys'
import {
  BasicCampaignListApiData,
  BasicIoCampaignListApiData,
  getBasicCampaignList,
  getBasicIoCampaignList,
  getCampaignsDealCount,
} from '../api/campaign'

export const useBasicCampaignList = (
  searchField: string,
  campaignIds: number[],
  sortBy: SortingState,
  ids?: number[],
  creativeTypeIds?: number[],
  status?: string[],
  options?: {
    enabled?: any
  }
): UseInfiniteQueryResult<BasicCampaignListApiData> => {
  const queryData = useInfiniteQuery(
    getBasicCampaignListKey.keys({
      scope: 'getBasicCampaignList',
      searchField,
      ids,
      campaignIds,
      creativeTypeIds,
      status,
      sortBy,
      pageNo: -1,
    }),
    getBasicCampaignList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.data?.length) || 0,
          0
        )
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return null
      },
      enabled: options?.enabled ?? true,
    }
  )
  return queryData
}
export const useBasicIoCampaignList = (props: {
  searchField: string
  campaignIds?: number[]
  options?: {
    enabled?: any
  }
  ioIdList?: number[]
  ids?: number[]
  owIdList?: string[]
  ioBudgetTypeIdsList?: number[]
  ioStatusIdsList?: number[]
  noOfEntries?: number
  sortBy?: SortingState
  offset?: number
  isAllOWIds?: boolean
  creativeTypeIds?: number[]
  campaignStatus?: string[]
}): UseInfiniteQueryResult<BasicIoCampaignListApiData> => {
  const {
    searchField,
    campaignIds,
    options,
    ioIdList,
    ids,
    owIdList,
    ioBudgetTypeIdsList,
    ioStatusIdsList,
    noOfEntries,
    sortBy,
    offset,
    isAllOWIds,
    creativeTypeIds,
    campaignStatus,
  } = props
  const queryData = useInfiniteQuery(
    getBasicIoCampaignListKey.keys({
      scope: 'getBasicIoCampaignList',
      searchField,
      ids,
      campaignIds,
      creativeTypeIds,
      sortBy,
      ioBudgetTypeIdsList,
      ioStatusIdsList,
      ioIdList,
      isAllOWIds,
      noOfEntries,
      offset,
      owIdList,
      campaignStatus,
      pageNo: -1,
    }),
    getBasicIoCampaignList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce(
          (prev, one) => prev + Number(one.ioCampaignsList?.length) || 0,
          0
        )
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return null
      },
      enabled: options?.enabled ?? true,
    }
  )
  if (queryData.isError) {
    const e = queryData.error as any
    enqueueSnackbar(
      e?.data?.errorObjects
        ? (e.data?.errorObjects[0]?.error as string)
        : 'Something went wrong!',
      {
        variant: 'error',
      }
    )
  }
  return queryData
}

export const useCampaignDealCount = (
  campaignTypeId: string,
  options?: {
    enabled?: UseQueryOptions<any>['enabled']
  }
) => {
  const queryData = useQuery(
    campaignsDealCountKey.keys({
      scope: 'getCampaignsDealCount',
      campaignTypeId,
    }),
    getCampaignsDealCount,
    {
      cacheTime: 0,
      enabled: options?.enabled,
    }
  )
  return queryData
}
