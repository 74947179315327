import { Container, Box, Row, Col, Typography, Button } from '@applift/factor'
import { Increase, Target, Folders, Add } from '@applift/icons'
import { CollectData } from '@applift/illustrations'
import { useRouter } from '@tanstack/react-router'

import { EmptyPageFeatures } from '../../../../components/EmptyPageFeature'

export const GroupsEmptyPage = () => {
  const router = useRouter()

  const features = [
    {
      icon: <Folders fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />,
      title: 'Simplified Management',
      description:
        'Streamline campaign management by grouping inventory for open exchange, PMP deals, and contextual targeting, enhancing organizational efficiency.',
    },
    {
      icon: <Target fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />,
      title: 'Targeting Flexibility',
      description:
        'Customize targeting strategies by creating separate inventory groups for each inventory type, allowing for precise audience segmentation and campaign optimization.',
    },
    {
      icon: (
        <Increase fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Improved Campaign Performance',
      description:
        'Optimize ad placements and maximize reach by efficiently managing inventory groups, leading to improved campaign performance and ROI.',
    },
  ]

  return (
    <Container
      sx={{
        width: 100,
        height: 100,
        p: 40,
        bgColor: 'neutral-0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Row
        xs={{ gutterSize: 40 }}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mb: 48,
        }}
      >
        <Col xs={12} sm={6} md={5}>
          <Box sx={{ textColor: 'primary-500' }}>
            <CollectData sx={{ width: 100, height: 'auto' }} />
          </Box>
        </Col>
        <Col xs={12} sm={6} md={7}>
          <Typography component="h1" variant="h5" weight="demi" sx={{ mb: 8 }}>
            Inventory Groups
          </Typography>
          <Typography
            component="h4"
            variant="bodyLarge"
            lineHeight="multi-line"
            weight="normal"
            sx={{ mb: 16 }}
          >
            Effortlessly manage and organize inventory groups for open exchange,
            PMP deals, and contextual inventory, streamlining campaign targeting
            and optimization.
          </Typography>
          <Button
            startIcon={<Add fontSize={20} />}
            variant="contained"
            color="primary"
            disableRipple
            onClick={() => {
              router.navigate({
                to: '/groups',
                search: {
                  createGroup: true,
                },
              })
            }}
          >
            Create Group
          </Button>
        </Col>
      </Row>
      <Row xs={{ gutterSize: 40 }} sx={{ justifyContent: 'center' }}>
        {features.map(feature => (
          <Col xs={features.length < 3 ? 5 : 4} key={feature.title}>
            <EmptyPageFeatures
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}
