import { QueryFunctionContext } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { getBlocklistKey } from './QueryKeys'
import {
  BlocklistTableResponse,
  CommentPayload,
  UnblockPayload,
} from '../models/BlocklistType'
import { getInstance } from './instance'
import { WithResponse } from '../models/Response'

export const getBlockList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getBlocklistKey)['keys']>
>): Promise<BlocklistTableResponse> => {
  const { searchField, noOfEntries } = queryKey[0] || {}
  try {
    const params = {
      ...(searchField ? { searchField: searchField } : {}),
      pageNo: String(pageParam),
      ...(typeof noOfEntries === 'number'
        ? { noOfEntries: String(noOfEntries) }
        : {}),
    }
    const paramString = new URLSearchParams(params).toString()
    const response: AxiosResponse<WithResponse<BlocklistTableResponse>> =
      await getInstance().get(`/v3/inv/blocked-inventories?${paramString}`)

    const modifiedBlocklist = {
      ...response.data.data,
      globalBlockedInventoryData:
        response.data.data.globalBlockedInventoryData.map(item => ({
          ...item,
          _id: crypto.randomUUID(),
        })),
    }
    return modifiedBlocklist
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}

export const addComment = async (commentPayload: CommentPayload) => {
  try {
    await getInstance().post('/v3/inv/comment/add', commentPayload)
    return commentPayload
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}

export const unblockInventories = async (unblockPayload: UnblockPayload) => {
  try {
    await getInstance().post('/v3/inv/inventories/unblock', unblockPayload)
    return unblockPayload
  } catch (e) {
    return Promise.reject((e as AxiosError).response?.data)
  }
}
