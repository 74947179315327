import { DEFAULT_EXCHANGE_SIDEBAR_STATE } from '../../../../constants/exchange'
import { OpenExchangeSidebarSearchValues } from '../..'

export const getPayloadFromFilterSelection = (
  search: OpenExchangeSidebarSearchValues
) => ({
  keywords:
    search.searchedInventories ??
    DEFAULT_EXCHANGE_SIDEBAR_STATE.searchedInventories,
  countries: search?.country?.length
    ? (search.country[0]?.value.toString() as string)
    : '',
  deviceTypes: search.deviceTypes?.length
    ? search.deviceTypes.map(item => item.value).toString()
    : '',
  trafficTypes: search.trafficTypes?.length
    ? search.trafficTypes.map(item => item.value).toString()
    : '',
  inventoryTypes: search.inventoryTypes?.length
    ? search.inventoryTypes.map(item => item.value).toString()
    : '',
  creativeTypes: search.creativeTypes?.length
    ? search.creativeTypes.map(item => item.value).toString()
    : '',
  categories: search.adCategories?.length
    ? search.adCategories.map(item => item.value).toString()
    : '',
  exchanges: search.exchanges?.length
    ? search.exchanges.map(item => item.value).toString()
    : '',
  videoPlayerSizes: search.creativeSizes?.length
    ? search.creativeSizes
        .filter(item => item.value.includes('size-'))
        .map(filteredItem => filteredItem.value.replace('size-', ''))
        .toString()
    : '',
  creativeSizes: search.creativeSizes?.length
    ? search.creativeSizes
        .filter(item => item.value.includes('standard-'))
        .map(filteredItem => filteredItem.value.replace('standard-', ''))
        .toString()
    : '',
  creativeDurations: search.creativeSizes?.length
    ? search.creativeSizes
        .filter(item => item.value.includes('duration-'))
        .map(filteredItem => filteredItem.value.replace('duration-', ''))
        .toString()
    : '',
})

export const getPayloadFromFilterSelectionArray = (
  search: OpenExchangeSidebarSearchValues
) => ({
  keywords: search.searchedInventories
    ? search.searchedInventories.split(',').filter(keyword => Boolean(keyword))
    : [],
  countries: search?.country?.length ? [search.country[0]?.value] : [],
  deviceTypes: search.deviceTypes?.length
    ? search.deviceTypes.map(item => item.value)
    : [],
  trafficTypes: search.trafficTypes?.length
    ? search.trafficTypes.map(item => item.value)
    : [],
  inventoryTypes: search.inventoryTypes?.length
    ? search.inventoryTypes.map(item => item.value)
    : [],
  creativeTypes: search.creativeTypes?.length
    ? search.creativeTypes.map(item => item.value)
    : [],
  categories: search.adCategories?.length
    ? search.adCategories.map(item => item.value)
    : [],
  exchanges: search.exchanges?.length
    ? search.exchanges.map(item => item.value)
    : [],
  videoPlayerSizes: search.creativeSizes?.length
    ? search.creativeSizes
        .filter(item => item.value.includes('size-'))
        .map(filteredItem => filteredItem.value.replace('size-', ''))
    : [],
  creativeSizes: search.creativeSizes?.length
    ? search.creativeSizes
        .filter(item => item.value.includes('standard-'))
        .map(filteredItem => filteredItem.value.replace('standard-', ''))
    : [],
  creativeDurations: search.creativeSizes?.length
    ? search.creativeSizes
        .filter(item => item.value.includes('duration-'))
        .map(filteredItem => filteredItem.value.replace('duration-', ''))
    : [],
})
