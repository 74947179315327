import React from 'react'
import {
  Outlet,
  UseNavigateResult,
  createRootRouteWithContext,
  useLocation,
  useNavigate,
} from '@tanstack/react-router'
import { QueryClient } from '@tanstack/react-query'
import { LayoutContainer, Tab, Tabs, sx } from '@applift/factor'
import { InfoBar, AppBar, useAppContext } from '@applift/platform'
import { useDatadogTrackViewsManually } from '@applift/app-utils'

import { DEFAULT_PMP_LIST_SORTING } from '../constants/pmp'
import { DEFAULT_PG_LIST_SORTING } from '../constants/pg'

import styles from './styles.module.scss'

type RootContext =
  | ReturnType<typeof useAppContext>
  | { queryClient: QueryClient }

export const Route = createRootRouteWithContext<RootContext>()({
  component: RootComponent,
})

const TanStackRouterDevTools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then(res => ({
          default: res.TanStackRouterDevtools,
        }))
      )

interface InventoryAppTabs {
  setSelectedTab: React.Dispatch<React.SetStateAction<number>>
  selectedTab: number
  navigate: UseNavigateResult<'/'>
  orgType: 'ADVERTISER' | 'SUPER' | 'WORKSPACE'
}

const InventoryAppTabs = (props: InventoryAppTabs) => {
  const { selectedTab, setSelectedTab, orgType, navigate } = props
  return (
    <Tabs
      value={selectedTab}
      aria-label="Navigation"
      behavior="scrollable"
      scrollButtons="auto"
      allowScrollButtonsMobile
      id="inventory-app-tabs"
      onChange={(_, value) => {
        setSelectedTab(value)
      }}
      size="large"
      classes={{ indicator: sx({ display: 'none' }) }}
    >
      <Tab
        classes={{ root: styles.navTab }}
        label="Open Exchange"
        onClick={() => {
          navigate({
            to: '/exchange',
          })
        }}
        {...navTabProps(0)}
      />
      <Tab
        classes={{ root: styles.navTab }}
        label="PMP"
        onClick={() => {
          navigate({
            to: '/pmp',
            search: {
              sortBy: DEFAULT_PMP_LIST_SORTING,
            },
          })
        }}
        {...navTabProps(1)}
      />
      {orgType === 'ADVERTISER' ? (
        <Tab
          classes={{ root: styles.navTab }}
          label="PG"
          onClick={() => {
            navigate({
              to: '/pg',
              search: {
                sortBy: DEFAULT_PG_LIST_SORTING,
              },
            })
          }}
          {...navTabProps(2)}
        />
      ) : null}

      <Tab
        classes={{ root: styles.navTab }}
        label="Contextual"
        onClick={() => {
          navigate({
            to: '/contextual',
          })
        }}
        {...navTabProps(3)}
      />
      <Tab
        classes={{ root: styles.navTab }}
        label="Groups"
        onClick={() => {
          navigate({
            to: '/groups',
          })
        }}
        {...navTabProps(4)}
      />
      <Tab
        classes={{ root: styles.navTab }}
        label="Global Blocklist"
        onClick={() => {
          navigate({
            to: '/global-blocklist',
          })
        }}
        {...navTabProps(5)}
      />
    </Tabs>
  )
}

function RootComponent() {
  // This hook will track datadog views manually on route change, required for SPA
  useDatadogTrackViewsManually()
  const navigate = useNavigate({ from: Route.fullPath })
  const location = useLocation()
  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType
  const [selectedTab, setSelectedTab] = React.useState<number>(
    activeTab(location.pathname, orgType)
  )

  React.useEffect(() => {
    setSelectedTab(activeTab(location.pathname, orgType))
  }, [location, orgType])

  return (
    <>
      <LayoutContainer
        header={
          <>
            <InfoBar />
            <AppBar>
              <InventoryAppTabs
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
                navigate={navigate}
                orgType={orgType}
              />
            </AppBar>
          </>
        }
      >
        <Outlet />
      </LayoutContainer>
      <React.Suspense>
        <TanStackRouterDevTools />
      </React.Suspense>
    </>
  )
}

function navTabProps(index: number) {
  return {
    id: `navTab${index}`,
    'aria-controls': `navTabPanel${index}`,
  }
}

function activeTab(
  pathname: string,
  orgType: 'SUPER' | 'WORKSPACE' | 'ADVERTISER'
) {
  // if any new tab is added add them to the following array in the order shown in UI
  const tabsValue =
    orgType === 'ADVERTISER'
      ? ['exchange', 'pmp', 'pg', 'contextual', 'groups', 'global-blocklist']
      : ['exchange', 'pmp', 'contextual', 'groups', 'global-blocklist']

  // Loop through tabs to find the matching tab
  for (let i = 0; i < tabsValue.length; i++) {
    if (pathname.includes(`/${tabsValue[i]}`)) {
      return i
    }
  }

  return 0
}
