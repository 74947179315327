import { enqueueSnackbar } from '@applift/factor'
import { WithResponse } from '@applift/platform'
import { useQuery, useInfiniteQuery, useMutation } from '@tanstack/react-query'

import {
  getDistributionData,
  getDistributionDataFromCSV,
  getOpenExchangeListingDataFromCSV,
  getOpenExchangeListingData,
  getExchangeCountData,
  getExchangeCountDataFromCSV,
  downloadOpenExchangeSearchedInventories,
} from '../api/openExchange'
import {
  getDistributionDataFromCSVKey,
  getDistributionDataKey,
  getOpenExchangeCountDataFromCSVKey,
  getOpenExchangeCountDataKey,
  getOpenExchangeListingDataFromCSVKey,
  getOpenExchangeListingDataKey,
} from '../api/QueryKeys'
import { DEFAULT_NUMBER_OF_LIST_RECORDS } from '../constants/exchange'
import {
  CSVDistributionRequestParams,
  CSVListingDataRequestParams,
  ExchangeListRequest,
} from '../models/OpenExchange'
import { OpenExchangeSidebarSearchValues } from '../routes/exchange'

const onError = (e: WithResponse) => {
  enqueueSnackbar?.(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong!',
    {
      variant: 'error',
    }
  )
}

export const useExchangeCountData = (
  payload?: ExchangeListRequest,
  options?: {
    enabled?: boolean
  }
) => {
  const queryData = useQuery(
    getOpenExchangeCountDataKey.keys({
      ...payload,
      scope: 'getExchangeCountData',
    }),
    getExchangeCountData,
    {
      onError,
      enabled: options?.enabled,
    }
  )
  return queryData
}

export const useExchangeDistributionData = (
  filters: OpenExchangeSidebarSearchValues,
  options?: { enabled?: boolean }
) => {
  const queryData = useQuery(
    getDistributionDataKey.keys({
      filters,
      scope: 'getDistributionData',
    }),
    getDistributionData,
    {
      onError,
      enabled: options?.enabled,
    }
  )
  return queryData
}

export const useExchangeDistributionDataFromCSV = (
  params: Omit<CSVDistributionRequestParams, 'scope'> | null,
  options?: { enabled?: boolean }
) => {
  const queryData = useQuery(
    getDistributionDataFromCSVKey.keys(
      'getDistributionDataFromCSV',
      params?.fileName ?? '',
      params?.fileSize ?? 0
    ),
    getDistributionDataFromCSV,
    {
      onError,
      enabled: options?.enabled,
      meta: {
        formData: params?.formData,
      },
    }
  )
  return queryData
}

export const useExchangeCountDataFromCSV = (
  params: Omit<CSVListingDataRequestParams, 'scope'> | null,
  options?: { enabled?: boolean }
) => {
  const queryData = useQuery(
    getOpenExchangeCountDataFromCSVKey.keys(
      'getExchangeCountDataFromCSV',
      params?.fileName ?? '',
      params?.fileSize ?? 0
    ),
    getExchangeCountDataFromCSV,
    {
      onError,
      enabled: options?.enabled,
      meta: {
        formData: params?.formData,
      },
    }
  )
  return queryData
}

export const useExchangeListingDataFromCSV = (
  params: Omit<CSVListingDataRequestParams, 'scope'> | null,
  options?: { enabled?: boolean }
) => {
  const queryData = useQuery(
    getOpenExchangeListingDataFromCSVKey.keys(
      'getOpenExchangeListingDataFromCSV',
      params?.fileName ?? '',
      params?.fileSize ?? 0
    ),
    getOpenExchangeListingDataFromCSV,
    {
      onError,
      enabled: options?.enabled,
      meta: { formData: params?.formData },
    }
  )
  return queryData
}

export const useExchangeListingData = (
  filters: OpenExchangeSidebarSearchValues,
  options?: {
    enabled?: boolean
  }
) => {
  const queryData = useInfiniteQuery(
    getOpenExchangeListingDataKey.keys({
      scope: 'getOpenExchangeListingData',
      filters,
    }),
    getOpenExchangeListingData,
    {
      getNextPageParam: (lastPage, pages) =>
        lastPage?.inventoryDataList.length < DEFAULT_NUMBER_OF_LIST_RECORDS
          ? undefined
          : pages.length + 1,
      enabled: options?.enabled,
    }
  )
  return queryData
}

export const useDownloadOpenExchangeInventories = () =>
  useMutation(downloadOpenExchangeSearchedInventories, {
    mutationKey: ['downloadOpenExchangeSearchedInventories'],
    onError,
  })
