import * as React from 'react'
import { Box, Col, Row, Tab, Tabs, TypoTooltip } from '@applift/factor'

import { TabPanel } from '../../../components/TabPanel/TabPanel'
import { KeywordTab, URLTab } from '../-component/index'

function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface ContextualSidebarProps {
  setAddedKeywords: React.Dispatch<React.SetStateAction<string[]>>
  addedKeywords: string[]
  addedURLs: string[]
  setAddedURLs: React.Dispatch<React.SetStateAction<string[]>>
}

export const ContextualSidebar = (props: ContextualSidebarProps) => {
  const { addedKeywords, addedURLs, setAddedKeywords, setAddedURLs } = props

  const [visibleSectionIndex, setVisibleSectionIndex] = React.useState(0)

  const addedContextualCount = addedKeywords.length + addedURLs.length

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 100,
        bgColor: 'neutral-0',
        py: 16,
        px: 12,
      }}
    >
      <Box sx={{ mb: 8 }}>
        <TypoTooltip
          TypgraphyProps={{
            sx: { textWeight: 'demi' },
            variant: 'bodyLarge',
          }}
        >
          {`Contextual ${addedContextualCount ? `(${addedContextualCount})` : ''}`}
        </TypoTooltip>
      </Box>

      <Row>
        <Col xs={12}>
          <Box sx={{ borderBottom: 1, mb: 32 }}>
            <Tabs
              value={visibleSectionIndex}
              onChange={(_, value) => setVisibleSectionIndex(value)}
              size="medium"
            >
              <Tab label="By Keywords" {...tabProps(0)} />
              <Tab label="By URLs" {...tabProps(1)} />
            </Tabs>
          </Box>
        </Col>
      </Row>

      <TabPanel value={visibleSectionIndex} index={0}>
        <Col xs={12}>
          <KeywordTab
            setAddedKeywords={setAddedKeywords}
            addedKeywords={addedKeywords}
          />
        </Col>
      </TabPanel>
      <TabPanel value={visibleSectionIndex} index={1}>
        <Col xs={12}>
          <URLTab addedURLs={addedURLs} setAddedURLs={setAddedURLs} />
        </Col>
      </TabPanel>
    </Box>
  )
}
