import * as React from 'react'
import { RowSelectionState } from '@applift/datagrid'
import { InfiniteData } from '@tanstack/react-query'
import { Box } from '@applift/factor'

import { OpenExchangeSidebarSearchValues } from '../../index'
import { OpenExchangeGrid } from './OpenExchangeGrid'
import { OpenExchangeActionPanel } from './OpenExchangeActionPanel'
import {
  Exchange,
  OpenExchangeSidebarUploadedCSVFileInfo,
} from '../../../../models/OpenExchange'
import {
  useDownloadOpenExchangeInventories,
  useExchangeListingData,
  useExchangeListingDataFromCSV,
} from '../../../../hooks'
import { FileTypes } from '../../../../components/DownloadTableAction'

interface OpenExchangeGridWrapperProps {
  totalRecords: number
  hideTableCTAs?: boolean
  filters: OpenExchangeSidebarSearchValues
  onRowSelectionChange?: (
    rowSelection: RowSelectionState,
    tableData: Exchange[]
  ) => void
  uploadedFileForSearch: OpenExchangeSidebarUploadedCSVFileInfo | null
}

export interface OpenExchangeGridWrapperApiRefType {
  clearRowSelection: () => void
  getIsGlobalSelectAllEnabled: () => boolean
}

export const OpenExchangeGridWrapper = React.forwardRef<
  OpenExchangeGridWrapperApiRefType | undefined,
  OpenExchangeGridWrapperProps
>((props, ref) => {
  const {
    totalRecords,
    filters,
    hideTableCTAs,
    onRowSelectionChange,
    uploadedFileForSearch,
  } = props

  const [globalSelectAllEnabled, toggleGlobalSelectAllStatus] =
    React.useState<boolean>(false)
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})

  const {
    data: dataFromSidebarSearch,
    isError: errorFromFetchingDataFromSidebarSearch,
    isFetching: isListingDataBeingFetchedFromSidebarSearch,
    fetchNextPage,
  } = useExchangeListingData(filters, {
    enabled: !uploadedFileForSearch,
  })

  const {
    data: dataFromFile,
    isError: errorWhileFetchingDataFromCSV,
    isFetching: isListingDataBeingFetchedFromCSV,
  } = useExchangeListingDataFromCSV(
    uploadedFileForSearch as OpenExchangeSidebarUploadedCSVFileInfo,
    { enabled: Boolean(uploadedFileForSearch) }
  )

  const { mutate: downloadSearchResults, isLoading: isDownloadInProgress } =
    useDownloadOpenExchangeInventories()

  React.useEffect(() => {
    if (dataFromFile?.matchedInventories.length) {
      const newSelection: RowSelectionState = {}
      dataFromFile.matchedInventories.forEach(item => {
        newSelection[item.uniqueId] = true
      })
      setRowSelection(newSelection)
    }
  }, [dataFromFile, onRowSelectionChange])

  const getFlattenedData = (
    data:
      | InfiniteData<{
          inventoryDataList: Exchange[]
        }>
      | undefined
  ) => data?.pages?.map(page => page?.inventoryDataList ?? []).flat(1) ?? []

  const tableData = React.useMemo(
    () =>
      uploadedFileForSearch
        ? dataFromFile?.matchedInventories ?? []
        : getFlattenedData(dataFromSidebarSearch),
    [dataFromFile, uploadedFileForSearch, dataFromSidebarSearch]
  )

  React.useEffect(() => {
    setRowSelection({})
    toggleGlobalSelectAllStatus(false)
  }, [filters, uploadedFileForSearch, setRowSelection])

  // pass back selected data in group edit mode
  React.useEffect(() => {
    onRowSelectionChange?.(rowSelection, tableData)
  }, [rowSelection, tableData, onRowSelectionChange])

  // below effect is used to select duplicates of current selection in new incoming data as user scrolls down
  React.useEffect(() => {
    setRowSelection(prevRowSelection => {
      // Create a set to store selected row IDs
      const selectedRowIdsSet: Set<number> = new Set()

      Object.keys(prevRowSelection).forEach(selectedId => {
        const rowData = tableData.find(data => data.uniqueId === selectedId)
        if (rowData?.id) {
          selectedRowIdsSet.add(rowData.id)
        }
      })
      // Convert the set to an array
      const selectedRowIdsArray = Array.from(selectedRowIdsSet)
      // Create a set to store the corresponding _id values
      const correspondingIdsSet: Set<string> = new Set()
      // Populate the set with _id values corresponding to the selected row IDs
      selectedRowIdsArray.forEach(id => {
        tableData.forEach(item => {
          if (item.id === id) {
            correspondingIdsSet.add(item.uniqueId)
          }
        })
      })
      const correspondingIdsArray = Array.from(correspondingIdsSet)
      const updatedSelection: { [key: string]: boolean } = {}
      correspondingIdsArray.forEach(itemId => {
        updatedSelection[itemId] = true
      })

      const newSelection = {
        ...prevRowSelection,
        ...updatedSelection,
      }

      return newSelection
    })
  }, [tableData, setRowSelection])

  const isFetching =
    isListingDataBeingFetchedFromSidebarSearch ||
    isListingDataBeingFetchedFromCSV

  const isError =
    errorFromFetchingDataFromSidebarSearch || errorWhileFetchingDataFromCSV

  const getOverlay = React.useCallback(() => {
    if (isError) {
      return 'error'
    }
    if (!tableData?.length && !isFetching) {
      return 'noResult'
    }
    return undefined
  }, [tableData, isFetching, isError])

  const selectedIds = React.useMemo(() => {
    const selectedRowIdsSet: Set<number> = new Set()
    // Populate the set with IDs from rowSelection
    Object.keys(rowSelection).forEach(selectedId => {
      const rowData = tableData?.find(data => data.uniqueId === selectedId)
      if (rowData?.id) {
        selectedRowIdsSet.add(rowData.id)
      }
    })
    return Array.from(selectedRowIdsSet)
  }, [rowSelection, tableData])

  const setRowSelectionWrapper = (_value: any) => {
    const value = typeof _value === 'function' ? _value() : _value

    setRowSelection(prevSelection => {
      const isRemovalAction =
        Object.keys(value).length < Object.keys(prevSelection).length

      // logic to remove all the selection of duplicates once an item's selection is removed
      if (isRemovalAction) {
        toggleGlobalSelectAllStatus(false)
        const uniqueIdOfRemovedItem = Object.keys(prevSelection).find(
          key => !(key in value)
        ) as string
        const inventoryIdOfRemovedItem = tableData.find(
          item => item.uniqueId === uniqueIdOfRemovedItem
        )?.id
        const associatedUniqueIds = tableData
          .filter(item => item.id === inventoryIdOfRemovedItem)
          .map(filteredItem => filteredItem.uniqueId)
        associatedUniqueIds.forEach(
          associatedUniqueId => delete value[associatedUniqueId]
        )
        return value
      }

      // logic to select all the duplicates once an item is selected
      const selectedItemsInventoryIds = tableData
        .filter(item => Object.keys(value).includes(item.uniqueId))
        .map(filteredItem => filteredItem.id)

      const newSelection: RowSelectionState = {}
      selectedItemsInventoryIds.forEach(inventoryId => {
        tableData
          .filter(item => item.id === inventoryId)
          .map(filteredItem => filteredItem.uniqueId)
          .forEach(newUniqueId => {
            newSelection[newUniqueId] = true
          })
      })
      return newSelection
    })
  }

  const uniqueSelectedInventoryIdsSet = new Set(
    Object.keys(rowSelection ?? {}).map(
      uniqueSelectedId =>
        tableData.find(data => data.uniqueId === uniqueSelectedId)?.id
    )
  )

  const isLocalSelectAll =
    tableData.length === Object.keys(rowSelection ?? {}).length &&
    !uploadedFileForSearch &&
    !globalSelectAllEnabled &&
    !isFetching &&
    !isError &&
    uniqueSelectedInventoryIdsSet.size !== totalRecords &&
    Boolean(tableData.length)

  if (
    globalSelectAllEnabled &&
    tableData.length !== Object.keys(rowSelection ?? {}).length
  ) {
    const newSelection: RowSelectionState = {}
    tableData.forEach(item => {
      newSelection[item.uniqueId] = true
    })
    setRowSelection(newSelection)
  }

  if (globalSelectAllEnabled && uploadedFileForSearch) {
    toggleGlobalSelectAllStatus(false)
  }

  const clearRowSelection = () => {
    toggleGlobalSelectAllStatus(false)
    setRowSelection({})
  }

  React.useImperativeHandle(ref, () => ({
    clearRowSelection,
    getIsGlobalSelectAllEnabled: () => globalSelectAllEnabled,
  }))

  const isCSVFileSearch = Boolean(uploadedFileForSearch)
  const isSidebarSearch = Object.values(filters).some(searchFilter =>
    Boolean(searchFilter.length)
  )

  const displayDownloadIcon =
    (isCSVFileSearch || isSidebarSearch) && !isFetching

  const downloadFile = React.useCallback(
    (fileType: FileTypes) => {
      const formData = new FormData()
      if (isCSVFileSearch) {
        formData.append(
          'multipartFile',
          uploadedFileForSearch?.uploadedFile as File
        )
      }
      downloadSearchResults({
        ...(!isCSVFileSearch ? { filters } : {}),
        fileType,
        isCsvSearch: isCSVFileSearch,
        formData: isCSVFileSearch ? formData : undefined,
      })
    },
    [isCSVFileSearch, downloadSearchResults, uploadedFileForSearch, filters]
  )

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
      }}
    >
      <OpenExchangeActionPanel
        isTableLoading={isFetching}
        globalSelectAllEnabled={globalSelectAllEnabled}
        toggleGlobalSelectAllStatus={toggleGlobalSelectAllStatus}
        selectedRecordsCount={uniqueSelectedInventoryIdsSet.size}
        isLocalSelectAll={isLocalSelectAll}
        totalRecords={totalRecords}
        selectedIds={selectedIds}
        filters={filters}
        hideTableCTAs={hideTableCTAs}
        clearRowSelection={clearRowSelection}
        displayDownloadIcon={displayDownloadIcon}
        isDownloadInProgress={isDownloadInProgress}
        handleFileDownload={downloadFile}
      />
      <OpenExchangeGrid
        data={tableData}
        overlay={getOverlay()}
        loading={isFetching}
        // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
        onFetchRows={() => {
          if (fetchNextPage) {
            uploadedFileForSearch || isError
              ? null
              : setTimeout(fetchNextPage, 100)
          }
        }}
        rowSelection={rowSelection}
        totalRecords={totalRecords}
        setRowSelectionWrapper={setRowSelectionWrapper}
      />
    </Box>
  )
})

OpenExchangeGridWrapper.displayName = 'OpenExchangeGridWrapper'
