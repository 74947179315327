import { QueryFunctionContext } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import {
  DeletePgDealResponse,
  DeletePgRequest,
  PgTableResponse,
} from '../models/Pg'
import { getInstance } from './instance'
import { WithResponse } from '../models/Response'
import { getPgListKey } from './QueryKeys'

export const getPgList = async ({
  pageParam = 1,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getPgListKey)['keys']>
>): Promise<PgTableResponse> => {
  const {
    noOfEntries,
    searchField,
    exchangeIds,
    paymentTypeIds,
    statusIds,
    ids,
    order,
    sortBy,
  } = queryKey[0] || {}
  try {
    const params = {
      ...(exchangeIds ? { exchangeIds } : {}),
      ...(searchField ? { searchField } : {}),
      ...(paymentTypeIds ? { paymentTypeIds: String(paymentTypeIds) } : {}),
      ...(statusIds ? { statusIds: String(statusIds) } : {}),
      ...(ids ? { ids } : {}),
      ...(sortBy?.length
        ? { sortBy: `${order === 'DESC' ? '-' : '+'}${sortBy}` }
        : {}),
      pageNo: String(pageParam),
      ...(typeof noOfEntries === 'number'
        ? { noOfEntries: String(noOfEntries) }
        : {}),
    }

    const paramString = new URLSearchParams(params).toString()

    const response: AxiosResponse<WithResponse<PgTableResponse>> =
      await getInstance().get(`/v3/inv/pg/deals/list?${paramString}`)

    return response.data.data
  } catch (e) {
    return Promise.reject((e as AxiosError).response)
  }
}

export interface CreatePgDealParams {
  dealId: string
  dealName: string
  exchangeId: number
  statusId: number
  description?: string
  paymentTypeId?: number
}

export interface CreatePgDeaResponse {
  message: string
  id: number
}

export const createPgDeal = async (requestParams: CreatePgDealParams) => {
  // const {
  //   dealId,
  //   dealName,
  //   description,
  //   paymentTypeId,
  //   exchangeId,
  //   statusId,
  // } = requestParams
  try {
    const response: AxiosResponse<WithResponse<CreatePgDeaResponse>> =
      await getInstance().post('/v3/inv/pg/deals/add', {
        ...requestParams,
      })
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

interface EditPgDealRequest {
  dealId: number
  params: Partial<CreatePgDealParams>
}

export const editPgDeal = async ({ dealId, params }: EditPgDealRequest) => {
  try {
    const response: AxiosResponse<WithResponse<CreatePgDealParams>> =
      await getInstance().patch(`/v3/inv/pg/deals/${dealId}`, {
        ...params,
      })
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

export const deletePgDeal = async (
  props: DeletePgRequest
): Promise<DeletePgDealResponse> => {
  const { ids } = props
  const params = new URLSearchParams({ ids: ids.join(',') })

  try {
    const response: AxiosResponse<DeletePgDealResponse> =
      await getInstance().delete(`/v3/inv/pg/deals?${params.toString()}`)
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}

const UpdateAction = {
  assign: 'assign',
  deassign: 'deassign',
} as const

export interface AssignToCampaignOperation {
  action: keyof typeof UpdateAction
  campaignIds?: number[]
  pgDealIds?: number[]
  pmpDealIds?: number[]
}

export interface AssignToCampaignRequest {
  operations: AssignToCampaignOperation[]
}

export interface AssignToCampaignRequest {
  operations: AssignToCampaignOperation[]
}

export interface AssingToCampaignPgRequest {
  params: AssignToCampaignRequest
}

export const assingToCampaignPg = async ({
  params,
}: AssingToCampaignPgRequest) => {
  try {
    const response: AxiosResponse<WithResponse<any>> =
      await getInstance().patch(`/v3/cmp/pg/campaigns/mappings`, {
        ...params,
      })
    return response.data
  } catch (e) {
    throw (e as AxiosError).response?.data
  }
}
