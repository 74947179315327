import { DataGrid, RowSelectionState } from '@applift/datagrid'
import { sx } from '@applift/factor'
import { SearchData, Warning } from '@applift/illustrations'

import { colDef } from './colDef'
import {
  BlocklistResponse,
  CommentPayload,
} from '../../../../models/BlocklistType'
import { useAddComment } from '../../../../hooks/useAddComment'
import { NoResultsOverlay } from '../../../../components/GridOverLay'

interface PgGridProps {
  data: BlocklistResponse[]
  onFetchRows: (() => void) | undefined
  isLoading: boolean
  rowSelection: RowSelectionState
  overlay?: 'error' | 'noRows' | 'noResult' | undefined
  filteredRecords?: number
  setRowSelectionWrapper: (_value: any) => void
}

export const BlocklistGrid = (props: PgGridProps) => {
  const {
    data,
    onFetchRows,
    isLoading,
    rowSelection,
    overlay,
    filteredRecords,
    setRowSelectionWrapper,
  } = props

  const { mutate: addComment } = useAddComment()

  const RowUpdateWrapper = (
    newRow: BlocklistResponse,
    oldRow: BlocklistResponse
  ) => {
    const allKeys = Object.keys(newRow)
    const modifiedKeys = allKeys?.filter(key => newRow[key] !== oldRow[key])
    if (modifiedKeys?.includes('comment')) {
      const payload: CommentPayload = {
        inventoryId: newRow?.id,
        comment: newRow?.comment,
      }
      addComment(payload)
    }
  }

  return (
    <>
      <DataGrid
        data={data}
        columns={colDef}
        loading={isLoading}
        processRowUpdate={RowUpdateWrapper}
        rowCount={filteredRecords}
        getRowId={(row: any) => {
          return row['_id']
        }}
        overlay={overlay}
        components={{
          NoResultsOverlay,
          ErrorOverlay: NoResultsOverlay,
        }}
        componentsProps={{
          noResultsOverlay: {
            text: 'No results found',
            subText: 'We can’t find any items matching your search.',
            illustration: (
              <SearchData
                sx={{
                  textColor: 'primary-500',
                  width: 100,
                  height: 'auto',
                  mb: 40,
                }}
              />
            ),
          },
          // @ts-ignore
          errorOverlay: {
            // @ts-ignore
            subText:
              'Oops, something went wrong. Please try again after some time.',
            illustration: (
              <Warning
                sx={{
                  textColor: 'primary-500',
                  width: 100,
                  height: 'auto',
                  mb: 40,
                }}
              />
            ),
          },
        }}
        hideHeader
        hideFooter
        rowHeight={40}
        checkboxSelection
        overscrollBehaviorX="contain"
        showColumnRightBorder
        showCellRightBorder
        disableRowSelectionOnClick
        onFetchRows={onFetchRows}
        state={{
          rowSelection,
        }}
        onRowSelectionChange={setRowSelectionWrapper}
        classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      />
    </>
  )
}
