import { createFileRoute, useNavigate, useRouter } from '@tanstack/react-router'
import { Layout, LayoutContainer, Paper } from '@applift/factor'
import { useAppContext } from '@applift/platform'

import { ScreenLoader } from '../../components/ScreenLoader'
import { SectionHeader } from './-components/SectionHeader'
import { OpenExchangeGroupPage } from './-pages/OpenExchangeGroup'
import { GROUP_TYPE_IDS } from '../../constants/common'
import { ContextualGroupPage } from './-pages/ContextualGroup'
import { PmpGroupSection } from './-pages/PmpGroupSection'
import { SidebarInventoryGroup } from '../../models/Group'
import { useGrouplist } from '../../hooks'

export const Route = createFileRoute('/groups/$groupId')({
  component: Group,
})

function Group() {
  const appContext = useAppContext()
  const router = useRouter()
  const navigate = useNavigate()

  const selectedGroupId =
    (router.routeTree.useParams() as { [key: string]: string })?.groupId ?? ''
  const { data: groupData, isFetching: isFetchingGroupData } = useGrouplist(
    {
      ids: selectedGroupId,
    },
    Boolean(selectedGroupId.length)
  )

  const groupDetail =
    groupData?.pages[0]?.inventoryGroupList[0] ?? ({} as SidebarInventoryGroup)

  const isNonEditableGroup = (appContext.appMeta.organizationType ===
    'ADVERTISER' && groupDetail?.isShared) as boolean

  if (!isFetchingGroupData && !Object.keys(groupDetail ?? {}).length) {
    navigate({ to: '/groups' })
  }

  return !Object.keys(groupDetail ?? {}).length || isFetchingGroupData ? (
    <ScreenLoader />
  ) : (
    <LayoutContainer
      // key prop added to remove row selection on switching b/w different groups of same type
      key={groupDetail?.id}
      header={
        <Paper
          elevation={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            py: 12,
            px: 24,
            width: 100,
          }}
        >
          <SectionHeader groupDetail={groupDetail} />
        </Paper>
      }
    >
      <Layout>
        {groupDetail.groupTypeId === GROUP_TYPE_IDS.OPEN_EXCHANGE && (
          <OpenExchangeGroupPage
            groupDetail={groupDetail}
            isNonEditableGroup={isNonEditableGroup}
          />
        )}
        {groupDetail.groupTypeId === GROUP_TYPE_IDS.PMP && (
          <PmpGroupSection
            groupDetail={groupDetail}
            isNonEditableGroup={isNonEditableGroup}
          />
        )}
        {groupDetail.groupTypeId === GROUP_TYPE_IDS.CONTEXTUAL && (
          <ContextualGroupPage
            groupDetail={groupDetail}
            isNonEditableGroup={isNonEditableGroup}
          />
        )}
      </Layout>
    </LayoutContainer>
  )
}
