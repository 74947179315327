import { TypographyProps } from '@applift/factor'

import { useExchangeList } from '../../../../hooks'
import { RawDataCell } from '../../../../components/CellTypes'

interface ExchangeCellProps {
  exchangeId: number
  textTypographyProps?: Omit<TypographyProps, 'children'>
}

export const ExchangeCell = (props: ExchangeCellProps) => {
  const { exchangeId, textTypographyProps } = props
  const { data: exchangeData } = useExchangeList()
  const exchange = exchangeData?.exchangeIdMapping[exchangeId]
  return exchange?.length ? (
    <RawDataCell textTypographyProps={textTypographyProps} value={exchange} />
  ) : (
    '-'
  )
}
