import type { ValidationError } from '@tanstack/react-form'
import { useForm } from '@tanstack/react-form'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Col,
  Row,
  LoadingButton,
} from '@applift/factor'

interface EditNameDialogProps {
  onClose: () => void
  onConfirmation: (groupName: string) => void
  groupName: string
  isLoading: boolean
}

export const EditNameDialog = (props: EditNameDialogProps) => {
  const { onClose, onConfirmation, groupName, isLoading } = props

  const form = useForm({
    defaultValues: {
      groupName: groupName,
    },
    onSubmit: async ({ value }) =>
      onConfirmation(value.groupName.trimStart().trimEnd()),
  })

  const getTextFieldLabel = () => {
    const groupName = form.getFieldValue('groupName')
    const hasError = Boolean(form.state.errors.length)
    return (
      <Box sx={{ display: 'flex', justifyContent: 'between' }}>
        <Typography
          sx={{
            textColor: hasError ? 'danger-400' : undefined,
          }}
          variant="label"
        >{`Name of the group`}</Typography>
        <Typography
          variant="label"
          sx={{
            textColor: hasError ? 'danger-400' : undefined,
          }}
        >
          {`${groupName.trimStart().trimEnd().length}/255`}
        </Typography>
      </Box>
    )
  }

  const getGroupNameHelperText = (errors: ValidationError[]) =>
    errors.length ? (
      <Typography variant="label" color="error">
        {errors[0]}
      </Typography>
    ) : null

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <form
        onSubmit={e => {
          e.preventDefault()
          e.stopPropagation()
          form.handleSubmit()
        }}
      >
        <DialogTitle
          onClose={onClose}
          sx={{ display: 'flex', alignItems: 'center', gap: 4 }}
        >
          <Typography weight="demi"> Change name of the group </Typography>
        </DialogTitle>
        <DialogContent dividers>
          <Row>
            <Col xs={6}>
              <form.Field
                name="groupName"
                validators={{
                  onChange: ({ value }) => {
                    if (
                      !value.trim().length ||
                      value.trimStart().trimEnd().length > 255
                    ) {
                      return !value.trim().length
                        ? 'Group name is required'
                        : 'Character limit exceeded'
                    }
                    return undefined
                  },
                }}
              >
                {field => (
                  <TextField
                    id={field.name}
                    name={field.name}
                    value={field.state.value}
                    onBlur={field.handleBlur}
                    onChange={e => field.handleChange(e.target.value)}
                    label={getTextFieldLabel()}
                    placeholder="Enter group name"
                    variant="outlinedDash"
                    helperText={getGroupNameHelperText(field.state.meta.errors)}
                    fullWidth
                    error={Boolean(field.state.meta.errors.length)}
                  />
                )}
              </form.Field>
            </Col>
          </Row>
        </DialogContent>
        <DialogActions>
          <form.Subscribe
            // @ts-ignore
            selector={state => [state.canSubmit, state.values]}
          >
            {/* @ts-ignore */}
            {([canSubmit, values]) => {
              return (
                <>
                  <Button color="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <LoadingButton
                    type="submit"
                    color="primary"
                    variant="contained"
                    loading={isLoading}
                    disabled={
                      !canSubmit ||
                      (values as { groupName: string })?.groupName === groupName
                    }
                  >
                    Change
                  </LoadingButton>
                </>
              )
            }}
          </form.Subscribe>
        </DialogActions>
      </form>
    </Dialog>
  )
}
