import * as React from 'react'

import { usePmpList } from '../../../hooks'
import { Sidebar } from '../-component/sidebar'
import { PmpSearch } from '../../../models/Pmp'
import { getPmpListPayloadFromFilterSelection } from './helper'

interface PmpSidebarProps {
  groupEditMode?: boolean
  filters: PmpSearch
  setFilters?: (filters: PmpSearch) => void
  resetFilters?: () => void
}

export const PmpSidebar = (props: PmpSidebarProps) => {
  const { filters, setFilters, resetFilters, groupEditMode = false } = props

  const { data: pmpList, isFetching } = usePmpList(
    getPmpListPayloadFromFilterSelection(filters)
  )

  const totalRecords = React.useMemo(() => {
    return pmpList?.pages?.[0]?.totalRecords || 0
  }, [pmpList])

  return (
    <Sidebar
      filters={filters}
      groupEditMode={groupEditMode}
      totalRecords={totalRecords}
      isLoading={isFetching}
      setFilters={setFilters}
      resetFilters={resetFilters}
    />
  )
}
