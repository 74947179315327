import { Container, Box, Row, Col, Typography } from '@applift/factor'
import { Increase, UsersNetwork, Target } from '@applift/icons'
import { SearchWeb } from '@applift/illustrations'

import { EmptyPageFeatures } from '../../../../components/EmptyPageFeature'

export const EmptyContextualPage = () => {
  const features = [
    {
      icon: (
        <UsersNetwork fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Relevance and Engagement',
      description:
        'Deliver ads alongside contextually relevant content, increasing user engagement and fostering a stronger connection with the target audience.',
    },
    {
      icon: <Target fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />,
      title: 'Enhanced Targeting Accuracy',
      description:
        "Utilize contextual signals to target ads based on the content of the webpage, ensuring alignment with users' interests and intent.",
    },
    {
      icon: (
        <Increase fontSize={40} sx={{ textColor: 'primary-600', mb: 16 }} />
      ),
      title: 'Improved Performance',
      description:
        'Experience higher click-through rates and conversion rates as ads are displayed in relevant contexts, leading to more effective advertising campaigns and better ROI.',
    },
  ]

  return (
    <Container
      sx={{
        width: 100,
        height: 100,
        p: 40,
        bgColor: 'neutral-0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Row
        xs={{ gutterSize: 40 }}
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          mb: 48,
        }}
      >
        <Col xs={12} sm={6} md={5}>
          <Box sx={{ textColor: 'primary-500' }}>
            <SearchWeb sx={{ width: 100, height: 'auto' }} />
          </Box>
        </Col>
        <Col xs={12} sm={6} md={7}>
          <Typography component="h1" variant="h5" weight="demi" sx={{ mb: 8 }}>
            Contextual Inventory
          </Typography>
          <Typography
            component="h4"
            variant="bodyLarge"
            lineHeight="multi-line"
            weight="normal"
            sx={{ mb: 16 }}
          >
            Maximize relevance and engagement with contextual inventory,
            delivering ads in alignment with relevant content for optimal
            audience targeting.
          </Typography>
        </Col>
      </Row>
      <Row xs={{ gutterSize: 40 }} sx={{ justifyContent: 'center' }}>
        {features.map(feature => (
          <Col xs={features.length < 3 ? 5 : 4} key={feature.title}>
            <EmptyPageFeatures
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          </Col>
        ))}
      </Row>
    </Container>
  )
}
