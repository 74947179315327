import { DataGrid, RowSelectionState } from '@applift/datagrid'
import { sx } from '@applift/factor'
import { SearchData, Warning } from '@applift/illustrations'

import { NoResultsOverlay } from '../../../../components/GridOverLay'
import { openExchangeColumnDef } from './colDef'
import { ExchangeListResponse } from '../../../../models/OpenExchange'

interface OpenExchangeGridProps {
  loading?: boolean
  totalRecords: number
  data: ExchangeListResponse['inventoryDataList']
  rowSelection: RowSelectionState
  overlay?: 'error' | 'noRows' | 'noResult'
  onFetchRows?: () => void
  setRowSelectionWrapper: (_value: any) => void
}

export const OpenExchangeGrid = (props: OpenExchangeGridProps) => {
  const {
    data,
    loading,
    overlay,
    rowSelection,
    totalRecords,
    onFetchRows,
    setRowSelectionWrapper,
  } = props

  return (
    <DataGrid
      data={data}
      loading={loading}
      columns={openExchangeColumnDef}
      rowCount={totalRecords}
      overlay={overlay}
      components={{
        NoResultsOverlay,
        ErrorOverlay: NoResultsOverlay,
      }}
      componentsProps={{
        noResultsOverlay: {
          text: 'No results found',
          subText: 'We can’t find any items matching your search.',
          illustration: (
            <SearchData
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
        // @ts-ignore
        errorOverlay: {
          // @ts-ignore
          subText:
            'Oops, something went wrong. Please try again after some time.',
          illustration: (
            <Warning
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
      }}
      getRowId={row => row['uniqueId']}
      state={{
        rowSelection,
      }}
      hideHeader
      hideFooter
      rowHeight={40}
      checkboxSelection
      overscrollBehaviorX="contain"
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      onRowSelectionChange={setRowSelectionWrapper}
      onFetchRows={onFetchRows}
    />
  )
}
