import { ThemeProvider } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import {
  createRouter,
  RouterProvider,
  createHashHistory,
} from '@tanstack/react-router'
import { routeTree } from '../routeTree.gen'
import { queryClient } from '../cache'

const hashHistory = createHashHistory()

const router = createRouter({
  routeTree,
  context: undefined!,
  history: hashHistory,
})

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router
  }
}

const ThemeMapper = {
  SUPER: 'blue',
  WORKSPACE: 'blue',
  ADVERTISER: 'blue',
}

export const AppRoute = () => {
  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  return (
    <ThemeProvider
      variant={ThemeMapper[orgType] as any}
      style={{ height: '100%' }}
    >
      <RouterProvider router={router} context={{ ...ctx, queryClient }} />
    </ThemeProvider>
  )
}
