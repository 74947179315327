import * as React from 'react'
import {
  Row,
  Col,
  Box,
  Paper,
  TextField,
  IconButton,
  useDebounceValue,
  enqueueSnackbar,
} from '@applift/factor'
import { Close, Search } from '@applift/icons'
import { ORGANIZATION_TYPE, useAppContext } from '@applift/platform'

import { assignedCampaignColDef as colDef } from '../EditPmpDealDialog/assingnedCampaignColDef'
import { useBasicCampaignList } from '../../../../../hooks/useCampaign'
import { CampaignGrid } from '../../../../../routes/pg/-component/PgCampaignDialog/CampaignGrid'

import styles from './styles.module.scss'

export const DEFAULT_PAGE_SIZE = 20
export const DEFAULT_LIST_SORTING = [{ id: 'id', desc: true }]

export interface DialogCampaignListWrapperPropTypes {
  campaignIds: number[]
}

export const CampaignGridWrapper = (
  props: DialogCampaignListWrapperPropTypes
) => {
  const { campaignIds } = props

  const [search, setSearch] = React.useState('')

  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType
  const debouncedSearch = useDebounceValue(search, 500)

  const {
    data: campaignListData,
    isFetching: campaignListLoading,
    fetchNextPage,
    isError,
    error,
  } = useBasicCampaignList(debouncedSearch, campaignIds, DEFAULT_LIST_SORTING)

  const flatData = React.useMemo(() => {
    return (
      campaignListData?.pages
        ?.map(page => {
          return page.data ?? []
        })
        .flat(1) || []
    )
  }, [campaignListData])

  const filteredRecords = React.useMemo(
    () => campaignListData?.pages[0]?.filteredRecords,
    [campaignListData?.pages]
  )

  const totalRecords = React.useMemo(
    () => campaignListData?.pages[0]?.totalRecords,
    [campaignListData?.pages]
  )

  React.useEffect(() => {
    if (isError) {
      const e = error as any
      enqueueSnackbar(
        e?.data?.errorObjects
          ? (e.data?.errorObjects[0]?.error as string)
          : 'Something went wrong!',
        {
          variant: 'error',
        }
      )
    }
  }, [isError, error])

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if (
      !flatData.length &&
      !campaignListLoading &&
      !filteredRecords &&
      totalRecords
    ) {
      return 'noResult'
    }
    if (!flatData.length && !campaignListLoading) {
      return 'noResult'
    }
    return undefined
  }, [
    flatData.length,
    filteredRecords,
    campaignListLoading,
    totalRecords,
    isError,
  ])

  return (
    <Paper
      elevation={2}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 100,
      }}
    >
      <Row
        className={styles.action}
        sx={{ px: 24, alignItems: 'center', justifyContent: 'end' }}
        xs={{ gutterSize: 0, gutterDirection: 'x' }}
      >
        <Col xs={4} sx={{ display: 'flex' }}>
          <TextField
            placeholder="Search by Campaign ID or Name"
            InputProps={{
              startAdornment: <Search sx={{ textColor: 'neutral-400' }} />,
              endAdornment:
                search?.length > 0 ? (
                  <IconButton
                    onClick={() => {
                      setSearch('')
                    }}
                    size="small"
                  >
                    <Close />
                  </IconButton>
                ) : null,
            }}
            style={{ width: '230px' }}
            variant="outlinedDash"
            value={search}
            sx={{ ml: 'auto', width: 100 }}
            onChange={(e: any) => {
              setSearch(e.target.value)
            }}
          />
        </Col>
      </Row>
      <Row sx={{ flexGrow: 1 }}>
        <Col>
          <Box sx={{ height: 100 }}>
            <CampaignGrid
              data={flatData}
              columnDef={colDef}
              totalRecords={totalRecords || 0}
              filteredRecords={filteredRecords || 0}
              defaultSorting={[{ id: 'id', desc: true }]}
              rowIdKey="id"
              pageSize={DEFAULT_PAGE_SIZE}
              loading={campaignListLoading}
              onFetchRows={() => {
                // he we delay for RQ to set state and we've new page to load and not stale pages otherwise we will have infinite loading issue
                setTimeout(fetchNextPage, 100)
              }}
              overlay={overlay}
              initialState={{
                columnVisibility: {
                  owId: orgType !== ORGANIZATION_TYPE.ADVERTISER,
                  organizationName: orgType !== ORGANIZATION_TYPE.ADVERTISER,
                },
              }}
            />
          </Box>
        </Col>
      </Row>
    </Paper>
  )
}
