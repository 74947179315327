import axios, { AxiosError, AxiosResponse } from 'axios'
import { QueryFunctionContext } from '@tanstack/react-query'

import { getInstance } from './instance'
import { WithResponse } from '../models/Response'
import {
  getContextualKeywordRecommendationsKey,
  getContextualKeywordSuggestionsKey,
  getContextualURLPreviewKey,
} from './QueryKeys'
import { URLPreviewMetadata } from '../models/Contextual'

export const getContextualKeywordSuggestions = async ({
  signal,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getContextualKeywordSuggestionsKey)['keys']>
>) => {
  try {
    const { keyword } = queryKey[0]!
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('Query was cancelled')
    })

    const response: AxiosResponse<WithResponse<string[]>> =
      await getInstance().get(
        `/v3/inv/contextual/autosuggest?keyword=${keyword}`,
        { signal }
      )
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getContextualKeywordRecommendations = async ({
  signal,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getContextualKeywordRecommendationsKey)['keys']>
>) => {
  try {
    const { keyword } = queryKey[0]!
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel(
        `/v3/inv/contextual/recommend?keyword - Query was cancelled`
      )
    })

    const response: AxiosResponse<WithResponse<string[]>> =
      await getInstance().get(
        `/v3/inv/contextual/recommend?keyword=${keyword}`,
        { signal }
      )
    return response.data
  } catch (e) {
    return Promise.reject((e as AxiosError)?.response?.data)
  }
}

export const getURLPreview = async ({
  signal,
  queryKey,
}: QueryFunctionContext<
  ReturnType<(typeof getContextualURLPreviewKey)['keys']>
>) => {
  try {
    const { url } = queryKey[0]!
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    signal?.addEventListener('abort', () => {
      source.cancel('v1/ms/url-preview/generate - Query was cancelled')
    })

    const response: AxiosResponse<WithResponse<URLPreviewMetadata>> =
      await getInstance().post(
        `/v1/ms/url-preview/generate`,
        { url },
        { signal }
      )
    if (response.status === 200) {
      return response.data.data
    }
  } catch (error) {
    return {} as URLPreviewMetadata
  }
}
