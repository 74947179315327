import React from 'react'
import {
  DialogContent,
  Container,
  Paper,
  Row,
  Col,
  Box,
  sx,
} from '@applift/factor'
import {
  SelectIOCampaignApiRefType,
  NormalizedIOCampaignList,
  SelectIOCampaignList,
  useAppContext,
} from '@applift/platform'
import {
  DataGrid,
  SortingState,
  useGridApiRef,
  getSortedRowModel,
  GridActionCellParams,
} from '@applift/datagrid'
import { Warning } from '@applift/illustrations'

import { CampaignInfoType } from '../../../../../models/Group'
import { colDef } from './CampaignInfogrid/colDef'
import { CAMPAIGN_TYPE_BY_NAME } from '../../../../../constants/campaign'

interface AssignToCampaignsDialogContentProps {
  campaignsInfo: CampaignInfoType[]
  setCampaignsInfo: React.Dispatch<React.SetStateAction<CampaignInfoType[]>>
  initData: CampaignInfoType[]
  intialNormalizedData: any
  headingText?: JSX.Element
}

export const AssignToCampaignsDialogContent = (
  props: AssignToCampaignsDialogContentProps
) => {
  const { campaignsInfo, setCampaignsInfo, intialNormalizedData, headingText } =
    props

  const [sorting, setSortinig] = React.useState<SortingState>([
    { id: 'id', desc: true },
  ])

  const ctx = useAppContext()
  const owId = ctx.appMeta.loggedInOwId

  const apiRef = React.useRef<SelectIOCampaignApiRefType>()
  const tableApiRef = useGridApiRef()

  const setCampaignsInfoWrapper = (
    value: Record<string, NormalizedIOCampaignList>,
    _
  ) => {
    const newTableData = [...campaignsInfo]

    const rejectedStatus = ['deleted', 'expired', 'rejected']

    const filteredIncludedIds = campaignsInfo
      ?.filter(item => !rejectedStatus.includes(item.status))
      .map(item => {
        return item.id
      })

    let action = ''
    if (filteredIncludedIds.length < Object.keys(value).length) {
      action = 'add'
    } else {
      action = 'remove'
    }

    if (action === 'add') {
      const idsToBeAdded = Object.keys(value).filter(item => {
        return !filteredIncludedIds.includes(Number(item))
      })

      idsToBeAdded.map(id => {
        const obj: CampaignInfoType = {
          id: Number(value[id]?.value),
          name: value[id]?.label || '',
          creativeTypeId: Number(value[id]?.creativeTypeId),
          status: value[id]?.status || '',
          ioId: Number(value[id]?.parent?.value),
          ioName: value[id]?.parent?.label || '',
          ioBudgetTypeId: Number(value[id]?.parent?.ioBudgetTypeId),
        }
        newTableData.push(obj)
      })
      setCampaignsInfo(newTableData)
    }

    if (action === 'remove') {
      const initDataIds = campaignsInfo
        .filter(item => !rejectedStatus.includes(item.status))
        .map(item => {
          return item.id
        })
      const idsToBeRemoved = initDataIds.filter(item => {
        return !Object.keys(value).includes(String(item))
      })
      const removedTable = campaignsInfo.filter(item => {
        return !idsToBeRemoved.includes(item.id)
      })

      setCampaignsInfo(removedTable)
    }
  }

  const actions = {
    updateRow: (params: { rowId: number; payload: any }) => {
      const { rowId, payload } = params

      setCampaignsInfo(prev => {
        return (
          prev?.map(grp => (grp.id === rowId ? { ...grp, ...payload } : grp)) ??
          []
        )
      })
    },
    removeRow: ({ rowId }: { rowId: number }) => {
      setCampaignsInfo(prev => prev?.filter(grp => grp.id !== rowId))

      apiRef.current?.removeSelection(String(rowId))
    },
  }

  const onAction = (params: GridActionCellParams) => {
    actions[params.actionName as keyof typeof actions](params.metaData)
  }

  return (
    <>
      <DialogContent
        dividers
        style={{ minHeight: '400px', height: 100 }}
        sx={{ display: 'flex', flexDirection: 'column', pb: 24 }}
      >
        <Container
          sx={{
            height: 100,
            display: 'flex',
            flexDirection: 'column',
            p: 0,
            pb: 24,
          }}
        >
          <Row sx={{ mb: 16 }}>
            <Col xs={12}>{headingText}</Col>
          </Row>
          <Row>
            <Col xs={6}>
              <SelectIOCampaignList
                ref={apiRef}
                multiple={true}
                initialData={intialNormalizedData}
                useCase="basic"
                enabled
                slotProps={{
                  PaperProps: {
                    style: {
                      maxWidth: 500,
                    },
                  },
                }}
                params={{
                  owIds: [owId],
                  status: ['running', 'pending', 'paused', 'draft'], // 1:pending
                  campaignTypeIds: [CAMPAIGN_TYPE_BY_NAME.ADVANCED],
                }}
                onChange={(data, reason) => {
                  if (reason !== 'apiAction') {
                    setCampaignsInfoWrapper(data, reason)
                  }
                }}
                TextFieldProps={{
                  variant: 'outlinedDash',
                  style: {
                    width: 245,
                  },
                }}
                // hideSelectNav
              />
            </Col>
          </Row>
          <Row sx={{ flexGrow: 1 }}>
            <Col xs={12}>
              {campaignsInfo.length ? (
                <Paper elevation={2} sx={{ mt: 24, pt: 56, height: 100 }}>
                  <Box
                    sx={{ height: 100 }}
                    style={{
                      minHeight: 96,
                    }}
                  >
                    <DataGrid
                      apiRef={tableApiRef}
                      onAction={onAction}
                      data={campaignsInfo}
                      columns={colDef}
                      rowCount={campaignsInfo?.length}
                      componentsProps={{
                        // @ts-ignore
                        errorOverlay: {
                          // @ts-ignore
                          subText:
                            'Oops, something went wrong. Please try again after some time.',
                          illustration: (
                            <Warning
                              sx={{
                                textColor: 'primary-500',
                                width: 100,
                                height: 'auto',
                                mb: 40,
                              }}
                            />
                          ),
                        },
                      }}
                      hideHeader
                      hideFooter
                      rowHeight={40}
                      checkboxSelection={false}
                      overscrollBehaviorX="contain"
                      showColumnRightBorder
                      showCellRightBorder
                      disableSelectAll
                      disableRowSelectionOnClick
                      state={{ sorting }}
                      initialState={{
                        columnVisibility: {
                          included: false,
                        },
                      }}
                      getSortedRowModel={getSortedRowModel()}
                      onSortingChange={value => {
                        setSortinig(value)
                      }}
                      classes={{
                        root: sx({
                          borderRadius: 0,
                          border: 0,
                          borderTop: 1,
                        }),
                      }}
                    />
                  </Box>
                </Paper>
              ) : null}
            </Col>
          </Row>
        </Container>
      </DialogContent>
    </>
  )
}
