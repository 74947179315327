import { TypoTooltip, SxProps } from '@applift/factor'
import { fromAbsolute, getLocalTimeZone } from '@internationalized/date'

import { useTimezone } from '../../hooks'
import { dateFormatShort } from '../../utils/format'

interface DateCellType {
  /**
   * date should be in unix millisecond format.
   */
  date: number
  timezone?: string
  sx?: SxProps
}

export const DateCell = ({ date, sx, timezone }: DateCellType) => {
  const zonedTime = timezone?.length
    ? dateFormatShort(fromAbsolute(date, timezone).toDate(), {
        timeZone: timezone,
      })
    : dateFormatShort(fromAbsolute(date, getLocalTimeZone()).toDate(), {
        timeZone: getLocalTimeZone(),
      })

  return date != null && date !== 0 ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
        sx,
      }}
    >
      {zonedTime}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}

export const TimezoneCell = ({ timezoneName }: { timezoneName: string }) => {
  const { data: timezoneDate } = useTimezone()

  const timezoneLabel = timezoneDate?.filter(
    timezone => timezone.value === timezoneName
  )[0]?.label

  return timezoneLabel ? (
    <TypoTooltip
      arrow
      placement="top"
      TypgraphyProps={{
        gutterBottom: false,
      }}
    >
      {timezoneLabel}
    </TypoTooltip>
  ) : (
    <>—</>
  )
}
