import * as React from 'react'
import { Box, Button, Divider, TypoTooltip, Typography } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import { Add } from '@applift/icons'
import { useRouter } from '@tanstack/react-router'

import { AssignToCustomerDialog } from '../AssignToCustomerDialog/AssignToCustomerDialog'
import { SidebarInventoryGroup } from '../../../../models/Group'
import { getIcon } from '../../../../registry/icons'
import { AssingToCampaignDialog } from '../AssignToCampaignsDialog'
import {
  useContextualGroupDetail,
  useOpenExchangeGroupListingData,
  usePmpGroupList,
} from '../../../../hooks'
import { DEFAULT_NO_OF_ENTRIES } from '../../../../constants/list'
import { GROUP_TYPE_IDS } from '../../../../constants/common'
import { DEFAULT_PMP_LIST_SORTING } from '../../../../constants/pmp'

interface SectionHeaderProps {
  groupDetail?: SidebarInventoryGroup
}

const iconMapper = {
  2: getIcon('InventoryPmpDeals', {
    fontSize: 24,
    sx: { flexShrink: 0, textColor: 'primary-600' },
  }),
  1: getIcon('InventoryOpenExchange', {
    fontSize: 24,
    sx: { flexShrink: 0, textColor: 'primary-600' },
  }),
  3: getIcon('InventoryContextual', {
    fontSize: 24,
    sx: { flexShrink: 0, textColor: 'primary-600' },
  }),
}

const groupTypeMapper = {
  2: 'PMP Group',
  1: 'Open Exchange Group',
  3: 'Contextual Group',
}

export const SectionHeader = (props: SectionHeaderProps) => {
  const { groupDetail } = props
  const router = useRouter()
  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const [showAssignToCustomerDialog, setShowAssignToCustomerDialog] =
    React.useState(false)

  const isAdvertiser = useAppContext().appMeta.organizationType === 'ADVERTISER'

  const [openAssingToCampaignDialog, setOpenAssingToCampaignDialog] =
    React.useState(false)

  const { data: openExchangeData, isFetching: fetchingOpenExchange } =
    useOpenExchangeGroupListingData(
      {
        groupId: groupDetail?.id as number,
        searchField: '',
        noOfEntries: DEFAULT_NO_OF_ENTRIES,
      },
      {
        enabled:
          Boolean(groupDetail?.id) &&
          groupDetail?.groupTypeId === GROUP_TYPE_IDS.OPEN_EXCHANGE,
      }
    )

  const { data: pmpGroupData, isFetching: fetchingPmp } = usePmpGroupList({
    groupId: groupDetail?.id as number,
    searchField: '',
    sorting: DEFAULT_PMP_LIST_SORTING,
    options: {
      enabled:
        Boolean(groupDetail?.id) &&
        groupDetail?.groupTypeId === GROUP_TYPE_IDS.PMP,
    },
  })

  const callContextualApi =
    Boolean(groupDetail?.id) &&
    groupDetail?.groupTypeId === GROUP_TYPE_IDS.CONTEXTUAL

  const { data: contextualGroupDetail, isFetching: fetchingContextual } =
    useContextualGroupDetail(
      groupDetail?.id?.toString() as string,
      callContextualApi
    )

  const isOpenExchangeListEmpty = openExchangeData?.pages[0]?.totalRecords === 0
  const isPmpListEmpty = pmpGroupData?.pages[0]?.totalRecords === 0
  const isContextualListEmpty = contextualGroupDetail?.totalRecords === 0

  const fetchingGroupDetail =
    fetchingOpenExchange || fetchingPmp || fetchingContextual

  const checkCurrentSelectionEmpty = () => {
    switch (groupDetail?.groupTypeId) {
      case GROUP_TYPE_IDS.OPEN_EXCHANGE:
        return isOpenExchangeListEmpty

      case GROUP_TYPE_IDS.PMP:
        return isPmpListEmpty
      case GROUP_TYPE_IDS.CONTEXTUAL:
        return isContextualListEmpty
      default:
        return false
    }
  }

  const isAddInventoryEnabled = React.useMemo(() => {
    if (orgType === 'ADVERTISER') {
      return !groupDetail?.isShared
    }
    return true
  }, [orgType, groupDetail?.isShared])

  const ActionSection = () => (
    <>
      {isAdvertiser ? (
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          sx={{ marginLeft: 'auto', flexShrink: 0 }}
          onClick={() => setOpenAssingToCampaignDialog(true)}
        >
          Assign To Campaign
        </Button>
      ) : (
        <Button
          size="medium"
          color="primary"
          variant="outlined"
          sx={{ marginLeft: 'auto', flexShrink: 0 }}
          onClick={() => setShowAssignToCustomerDialog(true)}
        >
          Assign To Customer
        </Button>
      )}
      {isAddInventoryEnabled && (
        <Button
          size="medium"
          startIcon={<Add />}
          color="primary"
          variant="outlined"
          sx={{ marginLeft: 8, flexShrink: 0 }}
          onClick={() => {
            if (groupDetail?.id) {
              router.navigate({
                to: '/groups/$groupId/edit',
                params: {
                  groupId: String(groupDetail?.id),
                },
              })
            }
          }}
        >
          {groupDetail?.groupTypeId === GROUP_TYPE_IDS.PMP
            ? 'Add PMP Deals'
            : 'Add Inventory'}
        </Button>
      )}
    </>
  )

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: 75,
          alignItems: 'center',
          height: 100,
          mr: 8,
        }}
      >
        {iconMapper[groupDetail?.groupTypeId as keyof typeof iconMapper]}
        <TypoTooltip
          TypgraphyProps={{
            weight: 'demi',
            variant: 'bodyMedium',
            gutterBottom: false,
            sx: { ml: 4 },
          }}
        >
          {groupDetail?.name}
        </TypoTooltip>
        <Divider
          orientation="vertical"
          color="secondary"
          flexItem
          sx={{ height: 100, mx: 8, alignSelf: 'center' }}
        />
        <Typography
          variant="bodyMedium"
          gutterBottom={false}
          sx={{ textColor: 'neutral-600', flexShrink: 0 }}
        >
          {
            groupTypeMapper[
              groupDetail?.groupTypeId as keyof typeof groupTypeMapper
            ]
          }
        </Typography>
      </Box>
      {!checkCurrentSelectionEmpty() && !fetchingGroupDetail && (
        <ActionSection />
      )}
      {openAssingToCampaignDialog && groupDetail && (
        <AssingToCampaignDialog
          onClose={() => setOpenAssingToCampaignDialog(false)}
          id={groupDetail.id}
          name={groupDetail.name}
          includedIds={groupDetail.whiteListedCampaignIds}
          excludedIds={groupDetail.blackListedCampaignIds}
        />
      )}
      {showAssignToCustomerDialog && groupDetail && (
        <AssignToCustomerDialog
          onClose={() => setShowAssignToCustomerDialog(false)}
          groupName={groupDetail.name}
          groupId={groupDetail.id}
        />
      )}
    </>
  )
}
