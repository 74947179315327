import { UseInfiniteQueryResult, useInfiniteQuery } from '@tanstack/react-query'

import { getBlocklistKey } from '../api/QueryKeys'
import { getBlockList } from '../api/blocklist'
import { BlocklistTableResponse } from '../models/BlocklistType'
import { WithResponse } from '@applift/platform'
import { enqueueSnackbar } from '@applift/factor'

const onError = (e: WithResponse) => {
  enqueueSnackbar?.(
    e.errorObjects
      ? (e.errorObjects[0]?.error as string)
      : 'Something went wrong!',
    {
      variant: 'error',
    }
  )
}

export const useBlocklist = (
  searchField: string,
  noOfEntries = 50
): UseInfiniteQueryResult<BlocklistTableResponse> => {
  const queryData = useInfiniteQuery(
    getBlocklistKey.keys({
      scope: 'getBlocklist',
      searchField,
      noOfEntries,
    }),
    getBlockList,
    {
      getNextPageParam: (lastPage, pages) => {
        const totalRecordsFetched = pages.reduce((prev, one) => {
          const datalength = one?.globalBlockedInventoryData?.length
          if (datalength) {
            return prev + datalength
          }
          return prev
        }, 0)
        if (
          lastPage?.filteredRecords !== undefined &&
          totalRecordsFetched < lastPage?.filteredRecords
        ) {
          return pages.length + 1
        }
        return undefined
      },
      cacheTime: 5000,
      onError: onError,
    }
  )
  return queryData
}
