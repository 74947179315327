import { ColumnDef } from '@applift/datagrid'

import { colDef } from '../../../../../routes/pg/-component/PgCampaignDialog/colDef'
import { BasicCampaignListType } from '../../../../../api/campaign'
import {
  CustomerNameCell,
  RawDataCell,
} from '../../../../../components/CellTypes'

export const assignedCampaignColDef: ColumnDef<BasicCampaignListType>[] = [
  ...colDef,
  {
    accessorKey: 'owId',
    header: 'Customer ID',
    id: 'owId',
    size: 130,
    minSize: 100,
    maxSize: 200,
    cell: props => <RawDataCell value={`${props.renderValue()}`} />,
    meta: {
      description: 'Campaign ID',
      excludeColumnFromColumnVisibility: true,
    },
  },
  {
    accessorKey: 'organizationName',
    id: 'organizationName',
    header: 'Customer Name',
    size: 210,
    minSize: 150,
    maxSize: 500,
    sortingFn: (rowA, rowB) => {
      return rowA.original.organizationName.toLowerCase() <
        rowB.original.organizationName.toLowerCase()
        ? -1
        : 1
    },
    cell: props => {
      const rowData = props.row.original
      return (
        <CustomerNameCell
          customerName={rowData.organizationName}
          avtarPath={rowData?.organizationLogo}
        />
      )
    },
  },
]
