import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonProps,
  TypoTooltip,
  Checkbox,
  Box,
  LoadingButton,
  FormControl,
  FormControlLabel,
} from '@applift/factor'
import { STORAGE_INVENTOY_GROUP_SHOW_DELETE_DIALOG_NAME } from '../../../constants/group'
import { localStorageService } from './healper'
import React from 'react'

interface ConfirmationDialogProps {
  onClose: () => void
  title: string
  onConfirmation: () => void
  primaryBtnText: string
  dialogContent: string | JSX.Element

  /* Optional props */
  dialogMaxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  theme?: ButtonProps['color']
  showDividers?: boolean
  isLoading: boolean
}

export const DeleteDialog = (props: ConfirmationDialogProps) => {
  const {
    onClose,
    dialogMaxWidth = 'sm',
    title,
    onConfirmation,
    primaryBtnText,
    dialogContent,
    theme = 'primary',
    showDividers,
    isLoading,
  } = props

  const [isChecked, setIsChecked] = React.useState(
    localStorageService.getLocal<boolean>(
      STORAGE_INVENTOY_GROUP_SHOW_DELETE_DIALOG_NAME
    )?.data
  )

  const setDialogHidden = (value: boolean) => {
    setIsChecked(value)
    localStorageService.saveLocal<boolean>(
      STORAGE_INVENTOY_GROUP_SHOW_DELETE_DIALOG_NAME,
      value
    )
  }

  return (
    <Dialog open onClose={onClose} maxWidth={dialogMaxWidth} fullWidth>
      <DialogTitle onClose={onClose}>
        <Typography weight="demi"> {title} </Typography>
      </DialogTitle>
      <DialogContent dividers={showDividers}>
        <Box sx={{ display: 'flex', width: 100, overflow: 'hidden', gap: 4 }}>
          <Typography
            sx={{ textColor: 'neutral-500', flexShrink: 0 }}
            variant="bodySmall"
            component="span"
          >
            Delete the
          </Typography>
          <TypoTooltip
            title={dialogContent}
            arrow
            TypgraphyProps={{
              sx: {
                textColor: 'neutral-1000',
              },
              weight: 'demi',
              component: 'span',
            }}
          >
            {dialogContent}
          </TypoTooltip>
          <Typography
            sx={{ textColor: 'neutral-500', flexShrink: 0 }}
            component="span"
          >
            ?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'between', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isChecked}
                  onChange={() => setDialogHidden(!isChecked)}
                />
              }
              label="Don't ask me again"
            />
          </FormControl>
        </Box>
        <Box sx={{ display: 'flex', gap: 16 }}>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            color={theme}
            variant="contained"
            onClick={onConfirmation}
            loading={isLoading}
          >
            {primaryBtnText}
          </LoadingButton>
        </Box>
      </DialogActions>
    </Dialog>
  )
}
