import * as React from 'react'
import { useForm } from '@tanstack/react-form'
import {
  bindFocus,
  bindPopper,
  Box,
  Chip,
  ClickAwayListener,
  enqueueSnackbar,
  IconButton,
  Link,
  SelectAdvanceComponents,
  SelectAdvancePopper,
  Skeleton,
  TextField,
  Typography,
  TypoTooltip,
  useDebounceValue,
  usePopupState,
} from '@applift/factor'
import { Search, Close, Add } from '@applift/icons'
import {
  UploadFile,
  SampleFile,
  getExcelOrCsvFileData,
} from '@applift/app-utils'

import {
  useContextualKeywordSuggestions,
  useContextualKeywordRecommendations,
} from '../../../hooks/useContextual'

interface KeywordTabProps {
  setAddedKeywords: React.Dispatch<React.SetStateAction<string[]>>
  addedKeywords: string[]
}

export const KeywordTab = (props: KeywordTabProps) => {
  const { addedKeywords, setAddedKeywords } = props

  const [enteredSearchKeyword, setSearchKeyword] = React.useState('')
  const [keywordToRecommendBasedOn, setKeywordToRecommendBasedOn] =
    React.useState('')

  const form = useForm({
    defaultValues: { keyword: '' },
  })

  const debouncedKeywordSearch = useDebounceValue(enteredSearchKeyword, 250)

  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'contextualSuggestions',
  })

  const { data: suggestions, isFetching: isSuggestionsBeingFetched } =
    useContextualKeywordSuggestions(debouncedKeywordSearch, {
      enabled: debouncedKeywordSearch.length >= 3,
    })

  const { data: recommendations, isFetching: isRecommendationBeingFetched } =
    useContextualKeywordRecommendations(keywordToRecommendBasedOn, {
      enabled: Boolean(keywordToRecommendBasedOn),
    })

  const suggestionData =
    suggestions?.data.map((item, idx) => ({
      label: item,
      value: `${item}-${idx}`,
    })) ?? []

  const onKeywordAdd = (incomingKeyword: string) => {
    const previouslyAddedKeywords = addedKeywords.map(item =>
      item.toLowerCase()
    )
    const incomingKeywordArr = incomingKeyword
      .trimStart()
      .trimEnd()
      .split(',')
      .filter(splitKeyword => Boolean(splitKeyword))
    const filteredArr = incomingKeywordArr.filter(
      item => !previouslyAddedKeywords.includes(item.toLowerCase())
    )

    if (!filteredArr.length) {
      return enqueueSnackbar('Keyword already added.', {
        variant: 'warning',
      })
    }

    setKeywordToRecommendBasedOn(filteredArr[0] as string)
    form.setFieldValue('keyword', '')

    setAddedKeywords([...addedKeywords, ...filteredArr])

    enqueueSnackbar(
      `Keyword${filteredArr.length > 1 ? 's' : ''} successfully added.`,
      {
        variant: 'success',
      }
    )
  }

  const listItemRenderer =
    SelectAdvanceComponents.DefaultListOptionItemWrapper<{
      label: string
      value: string
    }>({
      disableCheckbox: true,
      selectionStyle: 'none',
      disableRowExpansion: true,
    })

  const onFileUpload = async (files: FileList) => {
    const file = files[0] as File

    if (file.size > 3145728) {
      return enqueueSnackbar({
        variant: 'error',
        message: "File size can't be larger that 3MB",
      })
    }
    getExcelOrCsvFileData(
      file,
      (data: string[][]) => {
        const header = data.shift()
        const byType = header && header?.[0]?.toLowerCase()

        if ((byType ?? '').toLowerCase() !== 'keyword') {
          return enqueueSnackbar({
            message: 'Unable to read file content. Please try again.',
            variant: 'error',
          })
        }

        if (data) {
          const previouslyAddedKeywords = addedKeywords.map(item =>
            item.toLowerCase()
          )
          const removeDuplicates = (arr: string[]) => [
            ...new Set(arr.map(element => element.toLowerCase())),
          ]
          const fileKeywords = removeDuplicates(data.flat(1))

          const newKeywords = fileKeywords.filter(
            keyword => !previouslyAddedKeywords.includes(keyword.toLowerCase())
          )

          if (newKeywords.length) {
            setAddedKeywords([...addedKeywords, ...newKeywords])
            return enqueueSnackbar({
              message: `${fileKeywords.length} Keywords added successfully`,
              variant: 'success',
            })
          } else if (fileKeywords.length) {
            return enqueueSnackbar({
              message: 'Keyword(s) already added',
              variant: 'warning',
            })
          }
        }
      },
      () => {
        enqueueSnackbar({
          message: 'Upload file error',
          variant: 'error',
        })
      }
    )
  }

  const onSuggestionSelect = (newKeyword: string) => {
    const previouslyAddedKeywords = addedKeywords.map(item =>
      item.toLowerCase().trim()
    )
    if (previouslyAddedKeywords.includes(newKeyword.toLowerCase().trim())) {
      return enqueueSnackbar('Keyword already added.', {
        variant: 'warning',
      })
    }

    form.setFieldValue('keyword', newKeyword)
    popupState.close()
  }

  return (
    <Box>
      <ClickAwayListener onClickAway={() => popupState.close()}>
        <>
          <form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                bgColor: 'neutral-0',
              }}
            >
              <Box sx={{ mb: 12 }}>
                <Typography sx={{ display: 'block', mb: 8 }}>
                  Type the keyword and press ‘Enter’ to add
                </Typography>
                <form.Field name="keyword">
                  {field => (
                    // @ts-ignore
                    <Box
                      sx={{ display: 'flex', alignItems: 'center' }}
                      {...bindFocus(popupState)}
                    >
                      <TextField
                        type="text"
                        value={field.state.value}
                        onChange={e => {
                          field.handleChange(e.target.value)
                          setSearchKeyword(e.target.value)
                        }}
                        placeholder="Search by keyword"
                        variant="outlined"
                        sx={{ width: 100 }}
                        InputProps={{
                          startAdornment: (
                            <Search sx={{ textColor: 'neutral-400' }} />
                          ),
                          endAdornment: field.state.value.trim().length ? (
                            <IconButton
                              onClick={() => {
                                field.setValue('')
                                setSearchKeyword('')
                              }}
                              size="small"
                              color="secondary"
                            >
                              <Close fontSize={16} />
                            </IconButton>
                          ) : null,
                        }}
                      />
                      <Link
                        sx={{ textWeight: 'demi', ml: 8 }}
                        Component="button"
                        color="primary"
                        onClick={e => {
                          e.preventDefault()
                          onKeywordAdd(form.getFieldValue('keyword'))
                          popupState.close()
                        }}
                        disabled={!field.state.value.trim()}
                      >
                        <Typography>Add</Typography>
                      </Link>
                    </Box>
                  )}
                </form.Field>
              </Box>
            </Box>
          </form>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              gap: 12,
              my: 12,
            }}
          >
            <UploadFile
              fileTypes={['csv', 'xlsx']}
              onUpload={onFileUpload}
              uploadBtnText="Upload"
              uploadBtnVariant="outlined"
              buttonProps={{ sx: { textWeight: 'normal' } }}
            />
            <SampleFile
              sampleFileText="Sample File"
              sampleFileURL={`${window.location.origin}/example-files/contextual-audience-keywords-sample.csv`}
              sampleFileName="contextual-audience-keywords-example"
              buttonProps={{
                sx: { textWeight: 'normal' },
                variant: 'text',
                startIcon: undefined,
              }}
            />
          </Box>

          <Typography
            sx={{ display: 'block', textColor: 'neutral-500', mb: 16 }}
          >
            Allow Only to Upload XLSX/CSV files
          </Typography>

          {enteredSearchKeyword.length >= 3 &&
            (isSuggestionsBeingFetched || Boolean(suggestionData.length)) && (
              // @ts-ignore
              <SelectAdvancePopper
                {...bindPopper(popupState)}
                size="medium"
                data={suggestionData}
                slotProps={{ PaperProps: { sx: { height: 'auto' } } }}
                rowCount={10}
                hideSearch
                loading={isSuggestionsBeingFetched}
                hideSelectNav
                renderListItem={listItemRenderer}
                renderListItemSkeleton={() =>
                  isSuggestionsBeingFetched ? (
                    <Box
                      sx={{ py: 8, px: 12 }}
                      style={{ width: '100%', height: 'fit-content' }}
                    >
                      <Skeleton height={20} />
                    </Box>
                  ) : (
                    <></>
                  )
                }
                renderOption={rowObject => (
                  <Box
                    sx={{
                      textColor: 'neutral-1000',
                      width: 100,
                      display: 'block',
                    }}
                    onClick={() =>
                      onSuggestionSelect(rowObject.row.original.label)
                    }
                  >
                    <TypoTooltip TypgraphyProps={{ sx: { mr: 'auto' } }}>
                      {rowObject.row.original.label}
                    </TypoTooltip>
                  </Box>
                )}
                value={{}}
              />
            )}
        </>
      </ClickAwayListener>

      <Box
        sx={{
          display:
            isRecommendationBeingFetched || recommendations?.data.length
              ? 'block'
              : 'none',
          bgColor: 'neutral-75',
          borderRadius: 4,
          p: 12,
        }}
      >
        <Typography sx={{ mb: 12, display: 'block' }}>
          Recommended Keywords
        </Typography>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 8 }}>
          {isRecommendationBeingFetched && (
            <> {new Array(12).fill(<Skeleton height={30} width={90} />)}</>
          )}

          {recommendations?.data.map(recommendation => (
            <Chip
              key={recommendation}
              size="small"
              sx={{ mr: 16 }}
              variant="filled"
              onClick={() => onKeywordAdd(recommendation)}
              clickable
              icon={<Add />}
              label={
                <TypoTooltip
                  placement="top"
                  arrow
                  TypgraphyProps={{
                    gutterBottom: false,
                    sx: {
                      display: 'block',
                      textWeight: 'normal',
                    },
                  }}
                >
                  {recommendation}
                </TypoTooltip>
              }
              color="secondary"
            />
          ))}
        </Box>
      </Box>
    </Box>
  )
}
