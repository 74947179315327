import { createFileRoute, useNavigate } from '@tanstack/react-router'
import * as React from 'react'

export const Route = createFileRoute('/')({
  component: Home,
})

function Home() {
  const navigate = useNavigate({ from: Route.fullPath })

  React.useEffect(() => {
    navigate({
      to: '/exchange',
      replace: true,
    })
  })
  return null
}
