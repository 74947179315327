import { Box, Checkbox, FormControlLabel } from '@applift/factor'

import { useInventoryTypesData } from '../../../hooks/useFilterData'
import { CommonFilter } from '../../../models/Filters'

interface InventoryTypesFilterProps {
  value: CommonFilter[]
  onChange: (selectedInventoryTypes: CommonFilter[]) => void
}

export const InventoryTypesFilter = (props: InventoryTypesFilterProps) => {
  const { value, onChange } = props

  const { data: inventoryTypes } = useInventoryTypesData()

  return (
    <Box sx={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
      {inventoryTypes?.data?.map(inventoryType => (
        <FormControlLabel
          key={inventoryType.value}
          onChange={() =>
            onChange(
              value
                .map(prevSelections => prevSelections.value)
                .includes(inventoryType.value)
                ? value.filter(type => type.value !== inventoryType.value)
                : [...value, inventoryType]
            )
          }
          checked={value
            ?.map(inventory => inventory.value)
            .includes(inventoryType.value)}
          control={<Checkbox />}
          label={inventoryType.label}
          labelPlacement="end"
        />
      ))}
    </Box>
  )
}
