import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@applift/factor'
import { Close } from '@applift/icons'

import { useBasicCampaignList } from '../../../../hooks/useCampaign'
import { ViewCampaignGrid } from './ViewCampaignGrid'

interface Props {
  onClose: () => void
  allowedCampaignIds?: number[]
  blockedCampaignIds?: number[]
  primaryText: JSX.Element
  dialogTitle: string
}

const DEFAULT_LIST_SORTING = [{ id: 'campaignId', desc: true }]
const DEFAULT_PAGE_SIZE = 50

export const ViewCampaignDialog = (props: Props) => {
  const {
    onClose,
    allowedCampaignIds,
    blockedCampaignIds,
    primaryText,
    dialogTitle,
  } = props

  let campaignIds: number[] = []
  if (allowedCampaignIds?.length) {
    campaignIds = blockedCampaignIds?.length
      ? campaignIds.concat(allowedCampaignIds).concat(blockedCampaignIds)
      : campaignIds.concat(allowedCampaignIds)
  } else if (blockedCampaignIds?.length) {
    campaignIds = allowedCampaignIds?.length
      ? campaignIds.concat(blockedCampaignIds).concat(allowedCampaignIds)
      : campaignIds.concat(blockedCampaignIds)
  }
  const { data, isFetching } = useBasicCampaignList(
    '',
    campaignIds,
    DEFAULT_LIST_SORTING
  )

  const flatData = data?.pages.map(page => page.data ?? []).flat(1)

  const filteredRecords = data?.pages[0]?.filteredRecords

  const getOverlay = () => {
    if (!data && !isFetching) {
      return 'error'
    }
    if (!flatData?.length && !isFetching) {
      return 'noResult'
    }
    return undefined
  }

  return (
    <Dialog open fullWidth maxWidth="xl" PaperProps={{ sx: { height: 100 } }}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'between',
          alignItems: 'center',
          width: 100,
        }}
      >
        <Box>{dialogTitle}</Box>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent
        dividers
        sx={{ height: 100, display: 'flex', flexDirection: 'column' }}
      >
        <Box sx={{ pb: 16 }}>{primaryText}</Box>
        <ViewCampaignGrid
          data={flatData ?? []}
          isLoading={isFetching}
          rowCount={filteredRecords ?? 0}
          overlay={getOverlay()}
          pageSize={DEFAULT_PAGE_SIZE}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
