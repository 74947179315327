import * as React from 'react'
import { Layout } from '@applift/factor'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { RowSelectionState } from '@applift/datagrid'

import { OpenExchangeSidebarSearchValues } from '../../../../exchange'
import { getPayloadFromFilterSelectionArray } from '../../../../exchange/-component/ExchangeGrid/helpers'
import {
  OpenExchangeSidebar,
  OpenExchangePage,
  getExchangeSidebarDataForURLPopulation,
} from '../../../../exchange/-pages'
import {
  Exchange,
  OpenExchangeSidebarUploadedCSVFileInfo,
  SaveToExistingGroupRequest,
} from '../../../../../models/OpenExchange'
import { OPEN_EXCHANGE_INVENTORY_ID } from '../../../../../constants/exchange'
import { SidebarInventoryGroup } from '../../../../../models/Group'
import { OpenExchangeGridWrapperApiRefType } from '../../../../../routes/exchange/-component/ExchangeGrid/OpenExchangeGridWrapper'

interface OpenExchangeGroupEditPageWrapperProps {
  setSaveToExistingGroupPayload: React.Dispatch<
    React.SetStateAction<SaveToExistingGroupRequest | undefined>
  >
  groupDetail: SidebarInventoryGroup
  openExchangeGridWrapperRef?: React.MutableRefObject<
    OpenExchangeGridWrapperApiRefType | undefined
  >
}

export const OpenExchangeGroupEditPageWrapper = (
  props: OpenExchangeGroupEditPageWrapperProps
) => {
  const {
    setSaveToExistingGroupPayload,
    groupDetail,
    openExchangeGridWrapperRef,
  } = props

  const filters = useSearch({
    from: '/groups/$groupId/edit',
  }) as OpenExchangeSidebarSearchValues

  const navigate = useNavigate({
    from: '/groups/$groupId/edit',
  })

  const [uploadedFileForSearch, setUploadedFileForOpenExchangeSearch] =
    React.useState<OpenExchangeSidebarUploadedCSVFileInfo | null>(null)

  const onRowSelectionChange = React.useCallback(
    (rowSelection: RowSelectionState, tableData: Exchange[]) => {
      const searchPayload = getPayloadFromFilterSelectionArray(filters)
      const isFilterEmpty = [
        searchPayload.categories,
        searchPayload.countries,
        searchPayload.creativeDurations,
        searchPayload.creativeSizes,
        searchPayload.creativeTypes,
        searchPayload.deviceTypes,
        searchPayload.exchanges,
        searchPayload.keywords,
        searchPayload.trafficTypes,
        searchPayload.videoPlayerSizes,
        searchPayload.inventoryTypes,
      ].every(arr => arr.length === 0)

      const getInventoryIds = () => {
        const selectedRowIdsSet: Set<number> = new Set()

        // Populate the set with IDs from rowSelection
        Object.keys(rowSelection).forEach(selectedId => {
          const rowData = tableData.find(data => data.uniqueId === selectedId)
          if (rowData?.id) {
            selectedRowIdsSet.add(rowData.id)
          }
        })
        return Array.from(selectedRowIdsSet)
      }
      const payload: SaveToExistingGroupRequest = {
        groupIds: [groupDetail.id],
        ...(openExchangeGridWrapperRef?.current?.getIsGlobalSelectAllEnabled()
          ? isFilterEmpty
            ? { isAllInventories: true }
            : searchPayload
          : { inventoryIds: getInventoryIds() }),
        groupTypeId: OPEN_EXCHANGE_INVENTORY_ID,
      }

      setSaveToExistingGroupPayload(payload)
    },
    [
      setSaveToExistingGroupPayload,
      filters,
      groupDetail,
      openExchangeGridWrapperRef,
    ]
  )

  const setFilters = (filters: OpenExchangeSidebarSearchValues) => {
    navigate({
      to: '/groups/$groupId/edit',
      search: {
        // set filter values from sidebar in route
        ...getExchangeSidebarDataForURLPopulation(filters),
      },
    })
  }

  const resetFilters = () => {
    navigate({
      to: '/groups/$groupId/edit',
    })
  }

  return (
    <Layout
      sidebar={
        <OpenExchangeSidebar
          groupEditMode
          filters={filters}
          resetFilters={resetFilters}
          setFilters={setFilters}
          uploadedFileForSearch={uploadedFileForSearch}
          setUploadedFileForOpenExchangeSearch={
            setUploadedFileForOpenExchangeSearch
          }
        />
      }
      sidebarWidth={355}
      maxWidth={355}
      minWidth={355}
      hideResizerHandle
    >
      <OpenExchangePage
        filters={filters}
        hideTableCTAs
        openExchangeGridWrapperRef={openExchangeGridWrapperRef}
        onRowSelectionChange={onRowSelectionChange}
        uploadedFileForSearch={uploadedFileForSearch}
      />
    </Layout>
  )
}
