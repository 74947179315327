import * as React from 'react'
import { RowSelectionState } from '@applift/datagrid'
import { Box, Paper, Typography } from '@applift/factor'
import { useAppContext } from '@applift/platform'

import {
  CONTEXTUAL_KEYWORD_TYPE_ID,
  CONTEXTUAL_URL_TYPE_ID,
} from '../../../../constants/contextual'
import { SidebarInventoryGroup } from '../../../../models/Group'
import {
  useContextualGroupDetail,
  useDownloadContextualGroupInventoriesMutation,
} from '../../../../hooks'
import { ContextualGroupActionPanel } from './ContextualGroupActionPanel'
import { ContextualGroupGrid } from './ContextualGroupGrid'
import { ContextualTableData } from '../../../../models/Contextual'
import { FileTypes } from '../../../../components/DownloadTableAction'

interface ContextualGroupPageProps {
  groupDetail: SidebarInventoryGroup
  isNonEditableGroup?: boolean
}

export const ContextualGroupPage = (props: ContextualGroupPageProps) => {
  const { groupDetail, isNonEditableGroup } = props

  const [searchTerm, updateSearchTerm] = React.useState('')
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({})

  const {
    data: contextualGroupData,
    isFetching,
    isError,
  } = useContextualGroupDetail(groupDetail.id.toString())

  const { mutate: download, isLoading: isDownloadInProgress } =
    useDownloadContextualGroupInventoriesMutation()

  const organizationName = useAppContext().appMeta.organizationName

  const contextualGroupDetail = contextualGroupData?.data

  const trimmedLowerCaseSearchTerm = searchTerm
    .trimStart()
    .trimEnd()
    .toLowerCase()

  const groupKeywords =
    (contextualGroupDetail ?? [])
      ?.filter(
        contextualItem =>
          contextualItem.contextualType === CONTEXTUAL_KEYWORD_TYPE_ID
      )
      .map(filteredItem => ({
        name: filteredItem.inventory,
        type: 'Keyword',
        id: filteredItem.id,
      })) ?? []
  const groupURLs =
    (contextualGroupDetail ?? [])
      ?.filter(
        contextualItem =>
          contextualItem.contextualType === CONTEXTUAL_URL_TYPE_ID
      )
      .map(filteredItem => ({
        name: filteredItem.inventory,
        type: 'URL',
        id: filteredItem.id,
      })) ?? []

  const tableData = [...groupKeywords, ...groupURLs] as ContextualTableData[]

  const filteredTableData = searchTerm
    ? tableData.filter(
        tableItem =>
          tableItem.type.toLowerCase().includes(trimmedLowerCaseSearchTerm) ||
          tableItem.name.toLowerCase().includes(trimmedLowerCaseSearchTerm)
      )
    : tableData

  const overlay = React.useMemo(() => {
    if (isError) {
      return 'error'
    }
    if (
      !filteredTableData?.length &&
      !isFetching &&
      trimmedLowerCaseSearchTerm
    ) {
      return 'noResult'
    }
    if (contextualGroupData?.totalRecords === 0 && !isFetching) {
      return 'noRows'
    }
    return undefined
  }, [
    contextualGroupData,
    isFetching,
    isError,
    trimmedLowerCaseSearchTerm,
    filteredTableData,
  ])

  const handleDownload = (fileType: FileTypes) =>
    download({
      groupId: groupDetail.id,
      groupName: groupDetail.name,
      organizationName,
      fileType,
    })

  return (
    <Box
      sx={{
        width: 100,
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        gap: 24,
        p: 24,
      }}
    >
      <Paper
        elevation={2}
        sx={{
          display: 'flex',
          width: 100,
          py: 16,
          px: 24,
          borderRadius: 4,
          gap: 32,
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', gap: 8 }}>
          <Typography variant="bodyMedium" gutterBottom={false}>
            Keywords
          </Typography>
          <Typography
            sx={{ textWeight: 'demi' }}
            gutterBottom={false}
            variant="bodyMedium"
          >
            {groupKeywords.length
              ? groupKeywords.length.toString().padStart(2, '0')
              : groupKeywords.length}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', gap: 8 }}>
          <Typography variant="bodyMedium" gutterBottom={false}>
            URLs
          </Typography>
          <Typography
            sx={{ textWeight: 'demi' }}
            variant="bodyMedium"
            gutterBottom={false}
          >
            {groupURLs.length
              ? groupURLs.length.toString().padStart(2, '0')
              : groupURLs.length}
          </Typography>
        </Box>
      </Paper>

      <Paper elevation={2} sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            height: 100,
            gap: 12,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ContextualGroupActionPanel
            search={searchTerm}
            setSearch={updateSearchTerm}
            rowSelection={rowSelection}
            groupDetail={groupDetail}
            setRowSelection={setRowSelection}
            tableData={tableData}
            overlay={overlay}
            handleDownload={handleDownload}
            isDownloadInProgress={isDownloadInProgress}
          />
          <ContextualGroupGrid
            loading={isFetching}
            groupId={groupDetail.id}
            overlay={overlay}
            data={filteredTableData}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            sharedGroup={groupDetail.isShared}
            isNonEditableGroup={isNonEditableGroup}
          />
        </Box>
      </Paper>
    </Box>
  )
}
