import { ColumnDef } from '@applift/datagrid'
import { IconTextCell, RawDataCell } from '../../../../components/CellTypes'
import { BlocklistResponse } from '../../../../models/BlocklistType'
import { EditCommentCell } from '../CellTypes/EditCommentCell'
import { getIconMapping } from '../../../../utils/helper'
import { ViewCommentCell } from '../CellTypes/ViewCommentCell'

export const colDef: ColumnDef<BlocklistResponse>[] = [
  {
    accessorKey: 'appId',
    id: 'appId',
    header: 'ID',
    size: 346.5,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => {
      return (
        <RawDataCell
          textTypographyProps={{ variant: 'bodySmall' }}
          value={props.renderValue() as string}
        />
      )
    },
  },
  {
    accessorKey: 'name',
    id: 'name',
    header: 'Name',
    size: 250,
    maxSize: 500,
    minSize: 100,
    enableSorting: false,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },
  {
    accessorKey: 'publisher',
    id: 'publisher',
    header: 'Publisher',
    size: 180,
    maxSize: 350,
    minSize: 120,
    enableSorting: false,
    cell: props => (
      <RawDataCell
        textTypographyProps={{ variant: 'bodySmall' }}
        value={props.renderValue() as string}
      />
    ),
  },
  {
    accessorKey: 'inventoryType',
    id: 'inventoryType',
    header: 'Type',
    size: 180,
    maxSize: 350,
    minSize: 100,
    enableSorting: false,
    cell: props => (
      <IconTextCell
        textTypographyProps={{ variant: 'bodyMedium' }}
        iconName={getIconMapping(props.renderValue() as string)}
        iconSize={24}
        label={props.renderValue() as string}
      />
    ),
  },
  {
    accessorKey: 'comment',
    id: 'comment',
    header: 'Comments',
    size: 346.5,
    maxSize: 435,
    minSize: 100,
    enableSorting: false,
    meta: {
      editable: true,
      renderEditCell: props => {
        return <EditCommentCell {...props} />
      },
    },
    cell: props => (
      <ViewCommentCell
        textTypographyProps={{
          variant: 'bodySmall',
          sx: props.renderValue() ? {} : { textColor: 'neutral-500' },
        }}
        value={(props.renderValue() as string) || 'Add comment'}
      />
    ),
  },
]
