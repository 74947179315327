import * as React from 'react'
import { DataGrid, RowSelectionState } from '@applift/datagrid'
import { sx } from '@applift/factor'
import { useAppContext } from '@applift/platform'
import { SearchData, Warning, BidProcess } from '@applift/illustrations'

import { NoResultsOverlay } from '../../../../../components/GridOverLay'
import { ExchangeListResponse } from '../../../../../models/OpenExchange'
import { openExchangeColumnDef } from '../../../../../routes/exchange/-component/ExchangeGrid/colDef'
import { AddToGroupOverlay } from '../../../-components/AddToGroupOverlay'
import { useRouter } from '@tanstack/react-router'

interface OpenExchangeGroupGridProps {
  loading: boolean
  groupId: number
  totalRecords: number
  data: ExchangeListResponse['inventoryDataList']
  rowSelection: RowSelectionState
  overlay?: 'error' | 'noRows' | 'noResult'
  onFetchRows?: () => void
  setRowSelectionWrapper: (_value: any) => void
  checkboxSelectionDisabled: boolean
  sharedGroup?: boolean
}

export const OpenExchangeGroupGrid = (props: OpenExchangeGroupGridProps) => {
  const {
    data,
    loading,
    groupId,
    overlay,
    sharedGroup,
    rowSelection,
    totalRecords,
    onFetchRows,
    setRowSelectionWrapper,
    checkboxSelectionDisabled,
  } = props

  const router = useRouter()
  const ctx = useAppContext()
  const orgType = ctx.appMeta.organizationType

  const isAddInventoryEnabled = React.useMemo(() => {
    if (orgType === 'ADVERTISER') {
      return !sharedGroup
    }
    return true
  }, [orgType, sharedGroup])

  return (
    <DataGrid
      data={data}
      loading={loading}
      columns={openExchangeColumnDef}
      rowCount={totalRecords}
      overlay={overlay}
      components={{
        NoResultsOverlay,
        ErrorOverlay: NoResultsOverlay,
        NoRowsOverlay: AddToGroupOverlay,
      }}
      componentsProps={{
        noResultsOverlay: {
          text: 'No results found',
          subText: 'We can’t find any items matching your search.',
          illustration: (
            <SearchData
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
        // @ts-ignore
        errorOverlay: {
          // @ts-ignore
          subText:
            'Oops, something went wrong. Please try again after some time.',
          illustration: (
            <Warning
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
        },
        // @ts-ignore
        noRowsOverlay: {
          // @ts-ignore
          actionText: isAddInventoryEnabled ? 'Add Inventory' : '',
          // @ts-ignore
          subText: isAddInventoryEnabled
            ? 'No Inventories added in the group yet. Please add inventories in the group to assign it to the campaigns.'
            : 'No Inventories added in the group yet.',
          illustration: (
            <BidProcess
              sx={{
                textColor: 'primary-500',
                width: 100,
                height: 'auto',
                mb: 40,
              }}
            />
          ),
          onAction: () => {
            router.navigate({
              to: '/groups/$groupId/edit',
              params: {
                groupId: String(groupId),
              },
            })
          },
        },
      }}
      getRowId={row => row['uniqueId']}
      state={{
        rowSelection,
      }}
      hideHeader
      hideFooter
      rowHeight={40}
      checkboxSelection={!checkboxSelectionDisabled}
      overscrollBehaviorX="contain"
      showColumnRightBorder
      showCellRightBorder
      disableRowSelectionOnClick
      classes={{ root: sx({ borderRadius: 0, border: 0, borderTop: 1 }) }}
      onRowSelectionChange={setRowSelectionWrapper}
      onFetchRows={onFetchRows}
    />
  )
}
