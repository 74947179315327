import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ButtonProps,
  LoadingButton,
} from '@applift/factor'

interface ConfirmationDialogProps {
  onClose: () => void
  title: string
  onConfirmation: () => void
  primaryBtnText: string
  dialogContent: string | JSX.Element

  /* Optional props */
  dialogMaxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  theme?: ButtonProps['color']
  showDividers?: boolean
  isActionLoading?: boolean
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const {
    onClose,
    dialogMaxWidth = 'sm',
    title,
    onConfirmation,
    primaryBtnText,
    dialogContent,
    theme = 'primary',
    showDividers,
    isActionLoading = false,
  } = props

  return (
    <Dialog open onClose={onClose} maxWidth={dialogMaxWidth} fullWidth>
      <DialogTitle onClose={onClose}>
        <Typography weight="demi"> {title} </Typography>
      </DialogTitle>
      <DialogContent dividers={showDividers}>{dialogContent}</DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton
          color={theme}
          variant="contained"
          onClick={onConfirmation}
          loading={isActionLoading}
        >
          {primaryBtnText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
